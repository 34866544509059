import { useEffect, useState } from 'react';

import folders from './Folders.png';
import {
  useAddTagForCompilationsMutation,
  useDeleteTagForCompilationMutationMutation,
  useGetTagsForCompilationsQuery,
  useUpdateTagForCompilationMutationMutation,
} from '../../../app/api/services/tadForCompilations/tagForCompilations';
import { HeaderCatalog } from '../../../components';
import { errorToast } from '../../../utils';
import { Img, Loader } from '../../../widgets';
import { TagDirectoryCardForCompilations } from '../TagDirectoryCardForCompilations';

import style from './style.module.scss';

const TagDirectoryForCompilations = (): JSX.Element => {
  const [tags, setTags] = useState([]);
  const [tagsName, setTagsName] = useState([]);
  const [isPlugTag, setIsPlugTag] = useState<boolean>(false);
  const [isToast, setIsToast] = useState(false);

  const { data, isLoading, refetch } = useGetTagsForCompilationsQuery(
    { limit: 1000, offset: 0 },
    {
      refetchOnMountOrArgChange: true,
    }
  );

  useEffect(() => {
    if (!isLoading && data) {
      const { response } = data;
      const names = response.tags.map((item) => item.name.toLowerCase());

      setTags(response.tags);
      setTagsName(names);
    }
  }, [data, isLoading]);

  const [createTag, { isError: isErrorAdd }] =
    useAddTagForCompilationsMutation();

  const [editTag, { isError: isErrorUpdate }] =
    useUpdateTagForCompilationMutationMutation();

  const [deleteTag] = useDeleteTagForCompilationMutationMutation();

  const createPrevTag = () => {
    const plugTag = {
      id: null,
      name: '',
    };
    !isPlugTag && setTags((prevState) => [plugTag, ...prevState]);
    setIsPlugTag(true);
  };

  const handleCreate = (ancestorId: number, name: string) => {
    const onError = () => {
      setTags(data?.response.tags);
      ancestorId === 0 && setIsPlugTag(false);
    };

    if (name.trim()) {
      createTag({
        ancestor_id: ancestorId,
        name: `${name}`,
      })
        .unwrap()
        .then(() => {
          refetch();
          ancestorId === null && setIsPlugTag(false);
        })
        .catch(() => {
          onError();
        });
    }
  };

  const handleEdit = (tag_id: number, name: string) => {
    editTag({ tag_id, payload: { name } })
      .unwrap()
      .then(() => {
        refetch();
      });
  };

  const handleDelete = (id: number) => {
    deleteTag(id)
      .unwrap()
      .then(() => {
        refetch();
      });
  };

  useEffect(() => {
    errorToast(isErrorAdd, 'Тег с таким названием уже существует', setIsToast);
    errorToast(
      isErrorUpdate,
      'Тег с таким названием уже существует',
      setIsToast
    );
  }, [isErrorAdd, isErrorUpdate]);

  return (
    <div className={style.container}>
      {isLoading && !isToast ? (
        <Loader />
      ) : (
        <>
          <div className={style.content} id="scroller">
            <div className={style.titleWrapper}>
              <HeaderCatalog
                title="тегов для подборок"
                buttonText="тему"
                onSubmit={() => createPrevTag()}
              />
            </div>
            {!tags.length ? (
              <div className={style.pageMessage}>
                <Img src={folders} className={style.img} />
                <p className={style.emptyCatalog}>Каталог тегов для подборок</p>
              </div>
            ) : (
              <div className={style.catalogWrapper}>
                <ul className={style.catalogGroup}>
                  {tags?.map((tag) => (
                    <TagDirectoryCardForCompilations
                      typeCatalog="tag"
                      item={tag}
                      key={tag.id}
                      onEdit={(id, name) => handleEdit(id, name)}
                      onCreate={(id, name) => handleCreate(id, name)}
                      onDelete={(id) => handleDelete(id)}
                      catalogNames={tagsName}
                    />
                  ))}
                </ul>
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default TagDirectoryForCompilations;
