import { useFormikContext } from 'formik';
import React, { useState } from 'react';

import { TOption } from 'shared/types/typesOptionsSelect';
import { SearchEntity } from 'store/slices/selectSlice';
import { SelectWithOptions } from 'widgets';
import { SelectWrapper } from 'widgets/SelectWrapper';

interface IProps {
  title: string;
  options: TOption;
  field: string;
  handleSelect?: () => void;
  isDisabled?: boolean;
  isError?: boolean;
  searchEntity: SearchEntity;
  className?: string;
}

export const EntitySelect = ({
  title,
  options,
  field,
  handleSelect = () => null,
  isDisabled,
  isError,
  searchEntity,
  className,
}: IProps): JSX.Element => {
  const [isOpen, setIsOpen] = useState(false);
  const [isValueVisible, setIsValueVisible] = useState(false);

  const { setFieldValue, values } = useFormikContext();

  const handleTypeSelect = (option: { id: number; name: string }) => {
    setIsOpen(false);
    setFieldValue(field, option);
    setIsValueVisible(true);

    handleSelect();
  };

  return (
    <SelectWrapper
      isOpen={isOpen}
      isValueVisible={isValueVisible}
      setIsOpen={setIsOpen}
      title={title}
      value={values[field]?.name}
      disabled={isDisabled}
      isError={isError}
      className={className}
    >
      <SelectWithOptions
        searchEntity={searchEntity}
        options={options}
        onChange={handleTypeSelect}
        menuIsOpen={true}
      />
    </SelectWrapper>
  );
};
