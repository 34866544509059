import classNames from 'classnames';
import format from 'date-fns/format';
import ru from 'date-fns/locale/ru';
import { useFormikContext } from 'formik';
import moment from 'moment';
import React, { useState } from 'react';

import { DateInput } from 'pages/Navbar/Dashboards/DashboradsComponents/DateInput';
import { SelectWrapper } from 'widgets/SelectWrapper';

import style from './MonthsSelect.module.scss';

export interface IValues {
  start_month: Date;
  end_month: Date;
}
// ...
export const MonthsSelect = ({
  className,
}: {
  className?: string;
}): JSX.Element => {
  const [isValueVisible, setIsValueVisible] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedDates, setSelectedDates] = useState<string>('');

  const { setFieldValue, values } = useFormikContext<IValues>();

  const { errors } = useFormikContext<{
    start_month: string;
    end_month: string;
  }>();

  const maxDate = moment().subtract(1, 'months').endOf('month').toDate();

  return (
    <SelectWrapper
      title="Период"
      value={`${selectedDates}`}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      isValueVisible={isValueVisible}
      isDate
      className={classNames(style.select, className)}
      isError={!!(errors.start_month || errors?.end_month)}
    >
      <div className={style.inner}>
        <div className={style.inputs}>
          <DateInput
            onChange={(date) => setFieldValue('start_month', date)}
            selected={values.start_month}
            title="Начало"
            dateFormat="LLLL"
            showMonthYearPicker
            placeholderText="Выберите"
            maxDate={maxDate}
            error={errors.start_month}
          />
          <DateInput
            onChange={(date) => setFieldValue('end_month', date)}
            selected={values.end_month}
            title="Конец"
            dateFormat="LLLL"
            showMonthYearPicker
            placeholderText="Выберите"
            maxDate={maxDate}
            error={errors.end_month}
          />
        </div>
        <button
          className={style.button}
          type="submit"
          onClick={() => {
            setIsValueVisible(true);
            setIsOpen(false);
            if (values.start_month && values.end_month) {
              setSelectedDates(
                `${format(values.start_month, 'LLLL', {
                  locale: ru,
                })} - ${format(values.end_month, 'LLLL', { locale: ru })}`
              );
            }
          }}
        >
          Применить
        </button>
      </div>
    </SelectWrapper>
  );
};
