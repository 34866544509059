export const Expand = (): JSX.Element => (
  <svg
    data-testid="expand-icon"
    xmlns="http://www.w3.org/2000/svg"
    width="21"
    height="21"
    fill="none"
    viewBox="0 0 21 21"
  >
    <rect
      width="20"
      height="20"
      x="0.5"
      y="0.5"
      stroke="#6860F1"
      rx="2.5"
    ></rect>
    <path
      fill="#6860F1"
      d="M3.627 17.304a.5.5 0 00.518.482l4.497-.167a.5.5 0 00-.037-1l-3.997.149-.149-3.998a.5.5 0 00-.999.037l.167 4.497zm5.027-5.63L3.76 16.946l.733.68 4.894-5.271-.733-.68zM16.77 4.2a.5.5 0 00-.5-.5h-4.5a.5.5 0 100 1h4v4a.5.5 0 101 0V4.2zm-5.232 5.44l5.086-5.086-.707-.707-5.086 5.086.707.707z"
    ></path>
  </svg>
);
