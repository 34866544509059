import cn from 'classnames';
import React from 'react';
import { Link } from 'react-router-dom';

import { Router } from '../../../components/Router/routerEnum';
import { Edit } from '../../../shared/icons';

import style from './EditButton.module.scss';

interface IProps {
  id: number;
  circle?: boolean;
  link?: string;
}

export const EditButton = React.memo(
  ({ id, circle, link }: IProps): JSX.Element => (
    <Link
      target="_blank"
      to={link ? link : `${Router.Edit}/${id}`}
      className={cn(circle ? style.circle : style.common)}
    >
      <Edit />
    </Link>
  )
);
