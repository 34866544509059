import './Loader.scss';

export const Loader = (): JSX.Element => (
  <div className="pos-center" data-testid="pos-center">
    <div className="loader" data-testid="loader"></div>
  </div>
);

export const LoaderMini = (): JSX.Element => (
  <div data-testid="pos-center">
    <div className="loader-mini" data-testid="loader"></div>
  </div>
);
