import { Suspense, lazy } from 'react';

import { Loader } from '../../../../../../widgets';

const CreateSupermix = lazy(() => import('./CreateSupermix'));

export const CreateSupermix_Lazy = (): JSX.Element => (
  <>
    <Suspense fallback={<Loader />}>
      <CreateSupermix />
    </Suspense>
  </>
);
