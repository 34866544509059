/* eslint-disable max-lines */
/* eslint-disable complexity */
import {
  IBody,
  IPlaylistValues,
  Marks,
  Playlist,
} from '../../../../../app/api/services/playlists/types';
import { getTheIdArray, getTheIdTopics } from '../../../../../utils';
import { getDiffObjects } from '../../../../../utils/diffObjects';
import { createObjectForUpdatePlaylist } from '../utils';

export enum PlaylistFields {
  Name = 'name',
  Format = 'format',
  Title = 'title',
  Name_in_front = 'Name_in_front',
  Images = 'images',
  Full_description = 'full_description',
  Short_description = 'short_description',
  Primary_image = 'primary_image_url',
  Secondary_image = 'secondary_image_url',
  Primary_banner = 'primary_banner_url',
  Secondary_banner = 'secondary_banner_url',
  Primary_image_id = 'primary_image_id',
  Secondary_image_id = 'secondary_image_id',
  Primary_banner_id = 'primary_banner_id',
  Secondary_banner_id = 'secondary_banner_id',
  Keywords = 'keywords',
  Tags = 'tags',
  Navigation_tags = 'navigation_tags',
  Persons = 'persons',
  Authors = 'authors',
  Readers = 'readers',
  Translators = 'translators',
  Channel_id = 'channel_id',
  Is_active = 'is_active',
  Weight = 'weight',
  ISBN = 'ISBN',
  Idbook = 'idbook',
  Release_date = 'release_date',
  Submit_date = 'submit_date', // удалить если старые плейлисты не сломались
  Published_at = 'published_at',
  Date_start_license = 'date_start_license',
  Date_end_license = 'date_end_license',
  Is_removed_from_publication = 'is_removed_from_publication',
  Is_licence_extended = 'is_licence_extended',
  Type_content = 'content_type_id',
  Type_storage = 'type_storage',
  UUID = 'uuid',
  Created_at = 'created_at',
  Updated_at = 'updated_at',
  Url = 'url',
  Ages = 'ages',
  Language = 'language',
  Territories = 'territories',
  Territory_of_use = 'territory_of_use',
  Rate = 'rate_name',
  Rate_ids = 'rate_ids',
  Product = 'product_name',
  Price = 'price',
  Topic = 'topic_ids',
  BookSeries = 'bookSeries_name',
  has_not_own_rates = 'has_not_own_rates',
  Priority_product = 'priority_product',
  Entity_id = 'entity_id',
  Rate_id = 'rate_id',
  Entity_type = 'entity_type',
  K1 = 'k1',
  K2 = 'k2',
  Mark_names = 'mark_names',
  Discounts = 'discounts',
  VAT = 'vat_percent',
  Preview_content = 'preview_content',
  Custom_preview = 'custom_preview',
  UTM = 'utm',
}

export const titleListRelationFeed = [
  'Подборка роликов',
  'Плейлисты',
  'Связанная лента',
  'Книжная серия',
];

// eslint-disable-next-line
export const initialValues = (
  isEdit: boolean,
  playlist: Playlist,
  rssUrl: string
): IPlaylistValues => ({
  [PlaylistFields.Name]: isEdit ? playlist?.name : '',
  [PlaylistFields.Is_active]: isEdit ? playlist?.is_active : false,
  [PlaylistFields.Is_removed_from_publication]: isEdit
    ? playlist?.is_removed_from_publication
    : false,
  [PlaylistFields.Is_licence_extended]: isEdit
    ? playlist?.is_licence_extended
    : false,
  [PlaylistFields.Format]: isEdit ? playlist?.format : 'B',
  [PlaylistFields.ISBN]: isEdit ? playlist?.ISBN : null,
  [PlaylistFields.Idbook]: isEdit ? playlist?.idbook : null,
  [PlaylistFields.Release_date]: isEdit ? playlist?.release_date : '',
  [PlaylistFields.Full_description]: isEdit ? playlist?.full_description : '',
  [PlaylistFields.Short_description]: 'Short description',
  [PlaylistFields.Primary_image]: isEdit ? playlist?.primary_image_url : null,
  [PlaylistFields.Secondary_image]: isEdit
    ? playlist?.secondary_image_url
    : null,
  [PlaylistFields.Primary_banner]: isEdit ? playlist?.primary_banner_url : null,
  [PlaylistFields.Secondary_banner]: isEdit
    ? playlist?.secondary_banner_url
    : null,
  [PlaylistFields.Keywords]: playlist?.keywords.map((keyword) => keyword.id),
  [PlaylistFields.Images]: {},
  [PlaylistFields.Channel_id]: isEdit ? playlist?.channel?.id : 0,
  [PlaylistFields.Tags]: isEdit ? getTheIdArray(playlist?.tags) : [],
  [PlaylistFields.Navigation_tags]: isEdit
    ? getTheIdArray(playlist?.navigation_tags)
    : [],
  [PlaylistFields.Persons]: isEdit ? getTheIdArray(playlist?.persons) : [],
  [PlaylistFields.Authors]: isEdit ? getTheIdArray(playlist?.authors) : [],
  [PlaylistFields.Readers]: isEdit ? getTheIdArray(playlist?.readers) : [],
  [PlaylistFields.Translators]: isEdit
    ? getTheIdArray(playlist?.translators)
    : [],
  [PlaylistFields.Type_content]: isEdit ? playlist?.content_type?.id : 1,
  [PlaylistFields.Title]: isEdit ? playlist?.title : '',
  [PlaylistFields.Product]: isEdit ? playlist?.products[0]?.name : '',
  [PlaylistFields.Rate]: isEdit ? playlist?.products[0]?.rate?.name : '',

  [PlaylistFields.Price]: isEdit
    ? playlist?.products[0]?.rate?.price?.amount
    : null,
  [PlaylistFields.Published_at]: isEdit ? playlist?.published_at : '',
  [PlaylistFields.Date_start_license]: isEdit
    ? playlist?.license_period?.start_date
    : null,
  [PlaylistFields.Date_end_license]: isEdit
    ? playlist?.license_period?.end_date
    : null,
  [PlaylistFields.Language]: isEdit ? playlist?.language : null,
  [PlaylistFields.Territories]: isEdit ? playlist?.territories : [],
  [PlaylistFields.Ages]: isEdit ? playlist?.ages : [],
  [PlaylistFields.Topic]: isEdit ? getTheIdTopics(playlist?.topics) : [],
  [PlaylistFields.BookSeries]: isEdit ? playlist?.book_series?.name : '',
  [PlaylistFields.Url]: isEdit ? rssUrl : null,
  [PlaylistFields.has_not_own_rates]: isEdit
    ? playlist?.has_not_own_rates
    : false,
  [PlaylistFields.Rate_ids]: isEdit
    ? playlist?.products?.map((el) => el.rate.id)
    : [],
  [PlaylistFields.Rate_id]:
    playlist?.products.length || playlist?.related_products?.length
      ? playlist?.products?.find((el) => el.is_priority === true)?.rate?.id ||
        playlist?.related_products?.find((el) => el.is_priority === true)?.rate
          ?.id
      : null,
  [PlaylistFields.Entity_id]:
    playlist?.products.length || playlist?.related_products?.length
      ? playlist?.products?.find((el) => el.is_priority === true)?.entity_id ||
        playlist?.related_products?.find((el) => el.is_priority === true)
          ?.entity_id
      : null,
  [PlaylistFields.Entity_type]:
    playlist?.products.length || playlist?.related_products?.length
      ? playlist?.products?.find((el) => el.is_priority === true)
          ?.entity_type ||
        playlist?.related_products?.find((el) => el.is_priority === true)
          ?.entity_type
      : null,
  [PlaylistFields.Mark_names]: isEdit
    ? playlist?.marks?.map((el) => el.name)
    : [],
  [PlaylistFields.K1]: playlist?.k1,
  [PlaylistFields.K2]: playlist?.k2,
  [PlaylistFields.VAT]: playlist?.vat_percent,
  [PlaylistFields.Preview_content]: playlist?.preview_content
    ? playlist?.preview_content
    : null,
  [PlaylistFields.Custom_preview]: null,
  [PlaylistFields.UTM]: '',
});
//TODO когда бэк будет соответствовать фронту - убрать valueToSend, просто оставить values
// eslint-disable-next-line max-lines-per-function
export const valueCreateToSend = (
  values: IPlaylistValues
): Omit<IBody, 'product_name' | 'rate_name'> => ({
  authors: values.authors,
  channel_id: values.channel_id,
  full_description: values.full_description,
  is_active: values.is_active,
  is_licence_extended: values.is_licence_extended,
  is_removed_from_publication: values.is_removed_from_publication,
  name: values.name,
  persons: values.persons,
  images: values.images,
  primary_banner_url: values.primary_banner_url,
  secondary_banner_url: values.secondary_banner_url,
  primary_image_url: values.primary_image_url,
  secondary_image_url: values.secondary_image_url,
  readers: values.readers,
  short_description: values.short_description,
  tags: values.tags,
  navigation_tags: values.navigation_tags,
  translators: values.translators,
  format: values.format,
  ISBN: values.ISBN !== '' ? values.ISBN : null,
  idbook: values.idbook,
  release_date: new Date(values.release_date || null),
  title: values.title,
  content_type_id: values.content_type_id,
  ages: values.ages,
  language: values.language,
  territories: values.territories,
  prices: values.price ? [{ amount: values.price, currency_id: 1 }] : [],
  published_at: values.published_at || undefined,
  licence_period:
    values.date_start_license === null && values.date_end_license === null
      ? null
      : {
          start_date: values.date_start_license,
          end_date: values.date_end_license,
        },
  topic_ids: values.topic_ids,
  rate_ids: values.rate_ids,
  bookSeries_name: values.name,
  keywords: values.keywords,
  has_not_own_rates: values.has_not_own_rates,
  priority_product:
    values.rate_id === null
      ? null
      : {
          rate_id: values.rate_id,
          entity_id: values.entity_id,
          entity_type: values.entity_type,
        },
  mark_names: values.mark_names,
  k1: values.k1,
  k2: values.k2,
  vat_percent: Number(values.vat_percent),
  custom_preview:
    values.custom_preview !== null
      ? {
          tmpKey: values.custom_preview.tmpKey,
          name: values.custom_preview.name,
        }
      : null,
  utm: values.utm,
});

export const valueUpdateToSend = (
  values: Omit<IBody, 'ISBN'>,
  initialValues: Omit<IBody, 'ISBN'>
): Omit<IBody, 'ISBN' | 'product_name'> => {
  const diffKeys = getDiffObjects(initialValues, values);

  return createObjectForUpdatePlaylist(diffKeys, initialValues, values);
};

export const checkDisabled = (values: IBody): boolean => {
  let isDisabled = null;

  const typeContentCheck =
    values?.content_type_id === 3 || values?.content_type_id === 2;

  const isAudioBook = values?.content_type_id === 2;

  const isISBN = !!values.ISBN;
  const isIdbook = !!values.idbook;
  const isIdButNotISBN = isIdbook && !isISBN;
  const isISBNButNoId = !isIdbook && isISBN;

  if (isAudioBook) {
    isDisabled =
      !values.date_end_license ||
      !values.date_start_license ||
      !values.name?.trim() ||
      !values.full_description?.trim() ||
      !values.primary_image_url?.trim() ||
      !values.channel_id ||
      !values.rate_ids?.length ||
      !values.territories ||
      !values.language ||
      !values.ages ||
      !values.content_type_id ||
      (!(isAudioBook && isISBNButNoId) && !(isAudioBook && isIdButNotISBN)) ||
      (typeContentCheck &&
        (!values.release_date ||
          String(values.release_date).includes('_') ||
          !String(values.release_date).trim()));
  } else {
    isDisabled =
      !values.date_end_license ||
      !values.date_start_license ||
      !values.name?.trim() ||
      !values.full_description?.trim() ||
      !values.primary_image_url?.trim() ||
      !values.channel_id ||
      !values.rate_ids ||
      !values.territories ||
      !values.language ||
      !values.ages ||
      !values.content_type_id ||
      (typeContentCheck &&
        (!values.release_date ||
          String(values.release_date).includes('_') ||
          !String(values.release_date).trim()));
  }

  return isDisabled;
};

export interface IContent {
  id: number;
  name: string;
  name_in_front: string;
  format: 'A' | 'B';
}

export const content: IContent[] = [
  { id: 2, name: 'Аудиокнига', name_in_front: 'Глава', format: 'A' },
  { id: 3, name: 'Сериал', name_in_front: 'Эпизод', format: 'A' },
  { id: 4, name: 'Курс', name_in_front: 'Лекция', format: 'A' },
  { id: 5, name: 'Музыка', name_in_front: 'Трек', format: 'B' },
  { id: 6, name: 'Подкаст', name_in_front: 'Эпизод', format: 'B' },
  {
    id: 1,
    name: 'Передача',
    name_in_front: 'Выпуск',
    format: 'B',
  },
  {
    id: 67,
    name: 'Спектакль',
    name_in_front: 'Акт',
    format: 'A',
  },
  { id: 7, name: 'Поэзия стихи', name_in_front: '', format: 'A' },
  { id: 8, name: 'Поэзия поэма', name_in_front: '', format: 'A' },
  { id: 9, name: 'Литсборник', name_in_front: '', format: 'A' },
];

export const typeStorage = [
  { id: 1, name: 'Наше хранилище' },
  { id: 2, name: 'Название хостинга' },
];

export const ManualMark: Marks[] = [
  { id: 1, name: 'Эксклюзив', priority: 4 },
  { id: 2, name: 'Originals', priority: 3 },
];
