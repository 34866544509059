import { useFormikContext } from 'formik';
import * as _ from 'lodash';
import React from 'react';

import {
  ageLimit,
  languageData,
  territoriesData,
} from '../../../pages/Navbar/Business/Rates/mock/mockdata';
import { PlaylistFields } from '../../../pages/Navbar/Entity/PlayList/CreatePlaylist/PlaylistDataAndFields';
import { ISubmitCreatePlaylist } from '../../../pages/Navbar/Entity/PlayList/CreatePlaylist/ui/helpers';
import { ContainerWrapper, Select } from '../../../widgets';

import style from './LimitersBlock.module.scss';

interface IProps {
  ages?: { name: string }[];
  territories?: { name: string }[];
  language?: { name: string };
}

export const LimitersBlock = ({
  ages = [],
  territories = [],
  language = null,
}: IProps): JSX.Element => {
  const { setFieldValue } = useFormikContext();
  const { errors } = useFormikContext<ISubmitCreatePlaylist>();

  return (
    <ContainerWrapper title="Ограничители" className={style.wrapper}>
      <div className={style.content}>
        <div className={style.highContent}>
          <Select
            defaultOption={
              ages.length &&
              ageLimit.filter(
                (el) => el.name.toLowerCase() === ages[0]?.name.toLowerCase()
              )[0]?.id
            }
            className={style.selectAge}
            title="Возрастное ограничение"
            options={ageLimit}
            placeholder="Выберите ограничение"
            onChange={(option) =>
              // TODO когда сделают сущности ages, language, territories брать их с бэка (поменять filter на id)
              setFieldValue(
                PlaylistFields.Ages,
                option ? [{ name: option?.name }] : []
              )
            }
            isError={!!errors.ages}
            isObligatory={true}
          />
          <Select
            defaultOption={
              !_.isEmpty(language) &&
              languageData.filter(
                (el) => el.name.toLowerCase() === language?.name.toLowerCase()
              )[0]?.id
            }
            className={style.selectLangauge}
            classNameDropdown={style.dropdown}
            title="Язык"
            placeholder="Выберите "
            options={languageData}
            onChange={(option) => {
              setFieldValue(
                PlaylistFields.Language,
                option ? { name: option?.name } : null
              );
            }}
            isError={!!errors.language}
            isObligatory={true}
          />
        </div>
        <div className={style.lowContent}>
          <Select
            defaultOption={
              territories.length &&
              territoriesData.filter(
                (option) =>
                  option?.name.toLowerCase() ===
                  territories[0]?.name.toLowerCase()
              )[0]?.id
            }
            className={style.selectTerritory}
            title="Территория использования"
            options={territoriesData}
            placeholder="Выберите "
            onChange={(option) =>
              setFieldValue(
                PlaylistFields.Territories,
                option ? [{ name: option?.name }] : []
              )
            }
            isError={!!errors.territories}
            isObligatory={true}
          />
        </div>
      </div>
    </ContainerWrapper>
  );
};
