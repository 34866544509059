import { lazy, Suspense } from 'react';

import { Loader } from '../../../widgets';

const InternalCustomers = lazy(() => import('./InternalCustomers'));

export const InternalCustomers_Lazy = (): JSX.Element => (
  <>
    <Suspense fallback={<Loader />}>
      <InternalCustomers />
    </Suspense>
  </>
);
