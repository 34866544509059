import React, { PropsWithChildren } from 'react';

import style from './CatalogWarningModal.module.scss';

interface IProps {
  active: boolean;
  titleWarning: string;
  textWarning: string;
}

export const CatalogWarningModal = ({
  active,
  titleWarning,
  textWarning,
  children,
}: PropsWithChildren<IProps>): JSX.Element => {
  if (!active) {
    return null;
  }

  return (
    <div className={style.modal}>
      <div className={style.modalContent}>
        <div className={style.modalBody}>{children}</div>
        <div className={style.warningTitle}>
          {titleWarning} с таким названием уже существует
        </div>
        <div className={style.warningText}>
          Задайте другое название для {textWarning}.
        </div>
      </div>
    </div>
  );
};

export default CatalogWarningModal;
