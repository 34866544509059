import ru from 'date-fns/locale/ru';
import moment from 'moment';
import React from 'react';
import ReactDatePicker, { ReactDatePickerProps } from 'react-datepicker';

import { CalendarIcon } from '../../helpers';

import style from './DateInput.module.scss';

interface IProps extends ReactDatePickerProps {
  selected: Date;
  onChange: (date: Date) => void;
  title: string;
  dateFormat: string;
  showMonthYearPicker?: boolean;
  showTimeSelect?: boolean;
  maxDate?: Date;
  error?: string;
}

export const DateInput = ({
  selected,
  onChange,
  title,
  dateFormat,
  showMonthYearPicker,
  showTimeSelect,
  maxDate,
  error,
  ...rest
}: IProps): JSX.Element => {
  const now = moment().toDate();

  return (
    <div className={style.datePickerInner}>
      <h3 className={error ? style.error : style.title}>
        {error ? error : title}
      </h3>
      <ReactDatePicker
        {...rest}
        wrapperClassName={style.datePicker}
        selected={selected}
        showMonthYearPicker={showMonthYearPicker}
        showTimeSelect={showTimeSelect}
        onChange={onChange}
        locale={ru}
        showIcon
        icon={<CalendarIcon />}
        dateFormat={dateFormat}
        maxDate={maxDate || now}
      />
    </div>
  );
};
