import { Suspense, lazy } from 'react';

import { Loader } from '../../../widgets';

const NotFound = lazy(() => import('./NotFound'));

export const NotFound_Lazy = (): JSX.Element => (
  <>
    <Suspense fallback={<Loader />}>
      <NotFound />
    </Suspense>
  </>
);
