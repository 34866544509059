import React, { useContext } from 'react';

import { IEntityMerging } from '../../../app/global/types';
import { SearchEntity } from '../../../store/slices/selectSlice';
import { ClickShowContainer } from '../../ClickShowContainer';
import { FrameContext } from '../../MenuOption/ui/MenuOptions';
import { SelectWithOptions } from '../../SelectWithOptions';

import style from './AddManyChangeEntity.module.scss';

interface IProps {
  elementToEdit: IEntityMerging | { id: number; name: string };
  searchEntity: SearchEntity;
  options: { id: number; name: string }[];
  allSelectedOptions: IEntityMerging[] | { id: number; name: string }[];
  setAllSelectedOptions: (val: IEntityMerging[]) => void;
}

export const EditChangeEntity = ({
  elementToEdit,
  searchEntity,
  options,
  allSelectedOptions,
  setAllSelectedOptions,
}: IProps): JSX.Element => {
  const { contextOpened, setContextOpened } = useContext(FrameContext);

  const handleTypeSelect = (option, element) => {
    if (!option) {
      return;
    }

    let newState = allSelectedOptions.map((select) => {
      if (select.id === element.id) {
        return option;
      }

      return select;
    });

    newState = [...new Set(newState)];

    setAllSelectedOptions(newState);
    setContextOpened(false);
  };

  return (
    <div className={style.container_select}>
      {contextOpened && (
        <ClickShowContainer
          dropdown={contextOpened}
          setDropdown={setContextOpened}
        >
          <SelectWithOptions
            searchEntity={searchEntity}
            options={options}
            onChange={(option) => handleTypeSelect(option, elementToEdit)}
          />
        </ClickShowContainer>
      )}
    </div>
  );
};
