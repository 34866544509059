import { Suspense, lazy } from 'react';

import { Loader } from '../../../../../../widgets';

const CreateTranslator = lazy(() => import('./CreateTranslator'));

export const CreateTranslator_Lazy = (): JSX.Element => (
  <>
    <Suspense fallback={<Loader />}>
      <CreateTranslator />
    </Suspense>
  </>
);
