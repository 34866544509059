import { Suspense, lazy } from 'react';

import { Loader } from '../../../../../widgets';

const Tag = lazy(() => import('./Tag'));

export const Tag_Lazy = (): JSX.Element => (
  <>
    <Suspense fallback={<Loader />}>
      <Tag />
    </Suspense>
  </>
);
