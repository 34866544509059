import cn from 'classnames';
import { useFormikContext } from 'formik';
import { useState } from 'react';

import { TopicCatalog } from '../../../app/api/services/topicCatalog/types';
import { IEntityMerging } from '../../../app/global/types';
import { Edit, Ellipsis, Plus, Trash } from '../../../shared/icons';
import { SearchEntity } from '../../../store/slices/selectSlice';
import { ClickShowContainer } from '../../ClickShowContainer';
import { SelectWithOptions } from '../../SelectWithOptions';
import { TextWithTooltip } from '../../TextWithTooltip';
import { TitleWithLink } from '../../TitleWithLink';
import { ObligatoryField } from 'widgets/ObligatoryField';

import style from './AddSingleEntity.module.scss';

interface IProps {
  searchEntity?: SearchEntity;
  label: string;
  options: IEntityMerging[] | { id: number; name: string }[];
  item?: IEntityMerging | TopicCatalog;
  field: string;
  className?: string;
  isLink?: boolean;
  path?: string;
  onChange?: (option) => void;
  showAddButton?: boolean;
  isError?: boolean;
  isDelete?: boolean;
  isObligatory?: boolean;
}

export const AddSingleEntity = ({
  searchEntity,
  label,
  options,
  item,
  field,
  className,
  onChange,
  isLink,
  path,
  showAddButton = true,
  isError = false,
  isDelete = false,
  isObligatory = false,
}: IProps): JSX.Element => {
  const { setFieldValue } = useFormikContext();

  const [showSelect, setShowSelect] = useState(false);
  const [showOption, setShowOption] = useState(false);

  const [currentOption, setCurrentOption] = useState(item || null);

  const clickHandle = (e) => {
    e.preventDefault();
    setShowSelect(true);
  };

  const handleTypeSelect = (option) => {
    setShowSelect(false);
    setShowOption(true);
    setCurrentOption(option);

    setFieldValue(field, option.id);
    onChange(option);
  };

  const editClickHandle = (e) => {
    e.preventDefault();
    setShowSelect(true);
  };

  const deleteClikHandle = () => {
    setShowOption(false);
    setShowSelect(true);
    setCurrentOption(null);
    setFieldValue(field, null);
    onChange(null);
  };

  // useEffect(() => {
  //   onChange && onChange(currentOption);
  // }, [currentOption, onChange]);

  return (
    <div className={cn(style.container, className)}>
      <h3 className={cn(style.label, { [style.errorLabel]: isError })}>
        {label} {isObligatory && <ObligatoryField />}
      </h3>
      {showAddButton && !currentOption && (
        <button className={style.add} onClick={(e) => clickHandle(e)}>
          <Plus />
        </button>
      )}
      {showSelect && (
        <ClickShowContainer
          dropdown={showSelect}
          setDropdown={setShowSelect}
          className={style.select}
        >
          <SelectWithOptions
            searchEntity={searchEntity}
            options={options}
            onChange={handleTypeSelect}
          />
        </ClickShowContainer>
      )}

      {(showOption || currentOption) && (
        <div className={style.editWrapper}>
          {isLink ? (
            <TitleWithLink title="" text={currentOption?.name} link={path} />
          ) : (
            <TextWithTooltip
              text={currentOption?.name}
              className={style.optionDescription}
            />
          )}
          <button
            onClick={(e) => editClickHandle(e)}
            className={style.editButton}
          >
            <div className={style.ellipsis}>
              {isLink ? <Ellipsis /> : <Edit />}
            </div>
          </button>
          {isDelete && (
            <button
              onClick={() => deleteClikHandle()}
              className={style.editButton}
            >
              <div className={style.ellipsis}>
                <Trash />
              </div>
            </button>
          )}
        </div>
      )}
    </div>
  );
};
