import cn from 'classnames';
import { useFormikContext } from 'formik';
import React, { useState } from 'react';

import {
  useGetOwnerLevelsQuery,
  useGetOwnerTypesQuery,
} from '../../../../../app/api/services/owners/owners';
import {
  useGetContentTypeQuery,
  useGetTagsQuery,
} from '../../../../../app/api/services/playlists/playlists';
import { ArrowDown, Check } from '../../../../../shared/icons';
import { ClickShowContainer } from '../../../../../widgets';
import { fieldWithoutSearch } from '../../../helpers';
import { IPropsFilterAndColumn } from '../../../types';
import { AttributeItemWrapper } from '../../AttributeItemWrapper';

import style from './SelectPresent.module.scss';

export const SelectPresent = ({
  filter,
  columnType,
}: IPropsFilterAndColumn): JSX.Element => {
  const { setFieldValue } = useFormikContext();

  //TODO добавить необходимые селекты для недостающих типов

  const { data: ownerTypesOptions } = useGetOwnerTypesQuery(undefined, {
    skip: filter !== 'type',
  });

  const { data: ownerLevelsOptions } = useGetOwnerLevelsQuery(undefined, {
    skip: filter !== 'level',
  });

  const { data: contentType } = useGetContentTypeQuery(undefined, {
    skip: filter !== 'content_type',
  });

  const { data: tags } = useGetTagsQuery(undefined, {
    skip: filter !== 'tags',
  });

  const format =
    filter === 'format'
      ? [
          { id: 'A', name: 'Формат А' },
          { id: 'B', name: 'Формат В' },
        ]
      : undefined;

  const ages =
    filter === 'ages'
      ? [
          { id: 1, name: '0+' },
          { id: 2, name: '6+' },
          { id: 3, name: '12+' },
          { id: 4, name: '16+' },
          { id: 5, name: '18+' },
        ]
      : undefined;

  const level =
    filter === 'level'
      ? [
          { id: 1, name: 'I (высшая категория)' },
          { id: 2, name: 'II (высокочастотник)' },
          { id: 3, name: 'III (среднечастотник)' },
          { id: 4, name: 'IV (низкочастотник)' },
        ]
      : undefined;

  const options =
    ownerTypesOptions?.response ||
    ownerLevelsOptions?.response ||
    contentType?.response ||
    tags?.response ||
    format ||
    ages ||
    level ||
    [];

  const [dropdown, setDropdown] = useState(false);
  const [currentOption, setCurrentOption] = useState(null);

  const onClickHandler = (event) => {
    event.preventDefault();
    setDropdown((prevState) => !prevState);
  };

  return (
    <AttributeItemWrapper
      title={fieldWithoutSearch[filter]}
      filter={filter}
      columnType={columnType}
    >
      <ClickShowContainer
        dropdown={dropdown}
        setDropdown={setDropdown}
        className={cn(style.container, { [style.active]: dropdown })}
        onClick={(event) => onClickHandler(event)}
      >
        <span
          className={cn(style.placeholder, {
            [style.option_choose]: !!currentOption,
          })}
        >
          {!currentOption ? 'Выберите' : currentOption?.name}
        </span>
        <ArrowDown color="#434343" />

        <div className={cn(dropdown ? style.list : style.hide)}>
          {options?.map((option) => (
            <div
              key={option.id}
              className={style.option}
              onClick={() => {
                setCurrentOption(option);
                if (filter === 'ages') {
                  setFieldValue(`${columnType}.${filter}`, {
                    name: option.name,
                  });
                } else if (filter === 'tags') {
                  setFieldValue(`${columnType}.${filter}`, {
                    tag_id: option.id,
                  });
                } else {
                  setFieldValue(
                    `${columnType}.${filter}`,
                    filter === 'format' ? option.id : { id: option.id }
                  );
                }
              }}
            >
              {option?.name}

              {currentOption?.id === option?.id && (
                <Check height="18px" stroke="#434343" width="24px" />
              )}
            </div>
          ))}
        </div>
      </ClickShowContainer>
    </AttributeItemWrapper>
  );
};
