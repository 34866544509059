import {
  createFeedsGroupAdapter,
  getFeedGroupByIdAdapter,
  IResponseGetByIdAdapter,
  updateFeedsGroupAdapter,
} from './adapters';
import {
  IBody,
  IEdit,
  IParams,
  IResponseCreate,
  IResponseGet,
  IResponseGetById,
} from './types';
import { endpoints } from '../../../../shared/api/endpoints/endpoints';
import { tags } from '../../../../shared/api/tags/tags';
import { mainApi } from '../config';

export const feedGroupsApiQuery = mainApi.injectEndpoints({
  endpoints: (builder) => ({
    getFeedGroupByCondition: builder.query<IResponseGet, IParams>({
      query: ({ limit, offset }) => ({
        url: endpoints.feedGroupGet,
        params: { limit, offset },
      }),
      serializeQueryArgs: ({ endpointName }) => endpointName,
      merge(currentCacheData, responseData) {
        const newResponses = responseData.response.filter(
          (newResponse) =>
            !currentCacheData.response.some(
              (currentResponse) => currentResponse.id === newResponse.id
            )
        );
        currentCacheData.response.push(...newResponses);
      },
      forceRefetch({ currentArg, previousArg }) {
        return currentArg !== previousArg;
      },
      providesTags: (result) => {
        if (result) {
          const { response } = result;

          return [
            ...response.map(
              ({ id }) => ({ type: tags.feedGroup, id } as const)
            ),
            { type: tags.feedGroup, id: 'FEED_GROUPS_LIST' },
          ];
        } else {
          return [{ type: tags.feedGroup, id: 'FEED_GROUPS_LIST' }];
        }
      },
    }),
    getFeedGroupByName: builder.query<IResponseGet, string>({
      query: (name) => ({
        url: `${endpoints.feedGroupGetByName}?name=${name}`,
        params: { limit: 5 },
      }),
      providesTags: [tags.feedGroup],
    }),
    getFeedGroupById: builder.query<IResponseGetById, number>({
      query: (id) => ({
        url: `${endpoints.feedGroup}?id=${id}`,
      }),
      providesTags: [tags.feedGroup],
      transformResponse: (data: IResponseGetByIdAdapter): IResponseGetById =>
        getFeedGroupByIdAdapter(data),
    }),
  }),
});

export const feedGroupsApiMutation = mainApi.injectEndpoints({
  endpoints: (builder) => ({
    createFeedGroup: builder.mutation<IResponseCreate, IBody>({
      query: (body) => ({
        url: endpoints.feedGroupCreate,
        method: 'POST',
        data: createFeedsGroupAdapter(body),
      }),
      invalidatesTags: [tags.feedGroup],
    }),

    editFeedGroup: builder.mutation<unknown, IEdit>({
      query: (body) => ({
        url: endpoints.feedGroup,
        method: 'PUT',
        data: updateFeedsGroupAdapter(body),
      }),
      invalidatesTags: [tags.feedGroup],
    }),

    deleteFeedGroup: builder.mutation({
      query: (id) => ({
        url: `${endpoints.feedGroup}?id=${id}`,
        method: 'DELETE',
        id,
      }),
      invalidatesTags: (result, error, arg) => [
        { type: tags.feedGroup, id: arg },
      ],
    }),
  }),
});

export const {
  useGetFeedGroupByIdQuery,
  useGetFeedGroupByConditionQuery,
  useGetFeedGroupByNameQuery,
  usePrefetch,
} = feedGroupsApiQuery;

export const {
  useCreateFeedGroupMutation,
  useDeleteFeedGroupMutation,
  useEditFeedGroupMutation,
} = feedGroupsApiMutation;
