import { Suspense, lazy } from 'react';

import { Loader } from '../../../../../widgets';

const PlayList = lazy(() => import('./PlayList'));

export const PlayList_Lazy = (): JSX.Element => (
  <>
    <Suspense fallback={<Loader />}>
      <PlayList />
    </Suspense>
  </>
);
