import { useFormikContext } from 'formik';
import React, { useState, useEffect } from 'react';

import { ITopics } from '../../../app/api/services/playlists/types';
import { useGetAllTopicsQuery } from '../../../app/api/services/topicCatalog/topic';
import { TopicCatalog } from '../../../app/api/services/topicCatalog/types';
import { useAppDispatch, useAppSelector, useDebounce } from '../../../hooks';
import { ISubmitCreatePlaylist } from '../../../pages/Navbar/Entity/PlayList/CreatePlaylist/ui/helpers';
import { ISelectState, setLoading } from '../../../store/slices/selectSlice';
import {
  AddSingleEntity,
  ContainerWrapper,
  Loader,
  TitleWithDescription,
} from '../../../widgets';

import style from './Themes.module.scss';
interface IProps {
  required: boolean;
  topics?: ITopics;
}

export const Themes = ({ required, topics }: IProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const { errors } = useFormikContext<ISubmitCreatePlaylist>();

  const [currentTopic, setCurrentTopic] = useState<TopicCatalog>(
    topics?.topic || null
  );
  const [currentTopicGroup, setCurrentTopicGroup] = useState<TopicCatalog>(
    topics?.topic_group || null
  );

  const selectedValue = useAppSelector(
    (state) => (state.selectSliceReducer as ISelectState).inputValueInSelect
  );

  const debounceValueThemesSelect = useDebounce(selectedValue?.themes, 500);

  const { data: allTopics, isLoading } = useGetAllTopicsQuery(
    {
      limit: 5,
      offset: 0,
      name: debounceValueThemesSelect,
    },
    { refetchOnMountOrArgChange: true }
  );

  useEffect(() => {
    dispatch(setLoading(isLoading));
  }, [dispatch, isLoading]);

  const onChange = (option: TopicCatalog) => {
    if (option) {
      setCurrentTopic(null);
      setCurrentTopicGroup(null);
    } else {
      setCurrentTopic(null);
      setCurrentTopicGroup(null);
    }
  };

  return (
    <ContainerWrapper title="Темы" className={style.wrapper}>
      <div className={style.content}>
        <TitleWithDescription
          title="Группа тем:"
          description={currentTopicGroup?.name}
          className={style.group}
          withTooltip
        />
        <TitleWithDescription
          title="Тема:"
          description={currentTopic?.name}
          className={style.theme}
          withTooltip
        />
        {isLoading ? (
          <Loader />
        ) : (
          <AddSingleEntity
            searchEntity="themes"
            label={`Подтема${required ? '\u00A0*' : ':'}`}
            options={allTopics?.response}
            field="topic_id"
            className={style.themesWrapper}
            item={topics?.sub_topic}
            onChange={(option) => onChange(option)}
            isError={!!errors.topic}
            isDelete={true}
          />
        )}
      </div>
    </ContainerWrapper>
  );
};
