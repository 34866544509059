import { Suspense, lazy } from 'react';

import { Loader } from '../../../../../widgets';

const Supermix = lazy(() => import('./Supermix'));

export const Supermix_lazy = (): JSX.Element => (
  <>
    <Suspense fallback={<Loader />}>
      <Supermix />
    </Suspense>
  </>
);
