import React, { Suspense } from 'react';

import { Loader } from '../../../../../../widgets';

const CreateBookSeries = React.lazy(() => import('./CreateBookSeries'));

export const CreateBookSeries_Lazy = (): JSX.Element => (
  <>
    <Suspense fallback={<Loader />}>
      <CreateBookSeries />
    </Suspense>
  </>
);
