import Cookies from 'universal-cookie';

import { COOKIE } from '../global/types';

export const clearCookies = (cookies: Cookies): void => {
  cookies.remove(COOKIE.ACCESS_TOKEN);
  cookies.remove(COOKIE.REFRESH_TOKEN);
  cookies.set(COOKIE.LOGGED_IN, false);
};

export const setCookies = (
  cookies: Cookies,
  access_token: string,
  refresh_token: string,
  logged_in: boolean
): void => {
  cookies.set(COOKIE.ACCESS_TOKEN, access_token, {
    path: '/',
  });
  cookies.set(COOKIE.REFRESH_TOKEN, refresh_token, {
    path: '/',
  });
  cookies.set(COOKIE.LOGGED_IN, logged_in, {
    path: '/',
  });
};

export const getAccessTokenCookie = (cookies: Cookies): string =>
  cookies.get(COOKIE.ACCESS_TOKEN);

export const getRefreshTokenCookie = (cookies: Cookies): string =>
  cookies.get(COOKIE.REFRESH_TOKEN);
