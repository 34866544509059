import cn from 'classnames';
import React, { useEffect, useRef, useState } from 'react';
import { Tooltip } from 'react-tooltip';

import { newTagCatalog } from '../../../../app/api/services/tagCatalog/types';
import { useAppDispatch } from '../../../../hooks';
import { PlusInTheCircle } from '../../../../shared/icons';
import {
  CatalogType,
  ICatalogMiddle,
  addMiddleElement,
  deleteElement,
  editName,
} from '../../../../store/slices/catalogSlice';
import {
  ButtonShowAll,
  HangingInput,
  MenuEditOrOpen,
} from '../../../../widgets';
import { Router } from '../../../Router/routerEnum';
import { CardLowCategoryTag } from '../../CardLowCategoryTag';

import style from './style.module.scss';

interface ITheme extends ICatalogMiddle {
  parentId?: number;
  parentTitle: string;
  ancestor_id?: number;
}

interface IProps {
  item: ITheme | newTagCatalog;
  typeCatalog: CatalogType;
  onAddSubTag?: (id: number, name: string) => void;
  onEdit?: (id: number, name: string) => void;
  onDelete?: (id: number) => void;
}

export const CardMiddleCategoryTag = ({
  item,
  typeCatalog,
  onAddSubTag,
  onEdit,
  onDelete,
}: IProps): JSX.Element => {
  const [value, setValue] = useState<string>(item.name || '');
  const [showInput, setShowInput] = useState(false);
  const [showTitleInput, setShowTitleInput] = useState(false);
  const [showTitleMenu, setShowTitleMenu] = useState(false);
  const [showAll, setShowAll] = useState(false);

  const dispatch = useAppDispatch();

  const dispatchEditTitle = (text: string) => {
    if (typeCatalog === 'tag') {
      onEdit(item.id, text);
    } else {
      dispatch(
        editName({
          type: typeCatalog,
          id: item.id,
          name: text,
        })
      );
    }
    setShowTitleInput(false);
  };

  const handleLowCategory = (e) => {
    if (typeCatalog === 'tag') {
      onAddSubTag(item.id, e.target.value);
    } else {
      !!value.trim() &&
        dispatch(
          addMiddleElement({
            type: typeCatalog,
            id: item.id,
            element: {
              id: 2 + Math.random(),
              name: value,
              elements: [],
              categoty: 'IV',
            },
          })
        );
    }
    setValue('');
    setShowInput(false);
  };

  const handleEditTitle = () => {
    setShowTitleInput(true);
    setValue(item?.name);
    setShowTitleMenu(false);
  };

  const handleDeleteTag = () => {
    if (typeCatalog === 'tag') {
      onDelete(item.id);
    } else {
      dispatch(
        deleteElement({
          type: typeCatalog,
          parentId: item.parentId,
          id: item.id,
        })
      );
    }
    setShowTitleMenu(false);
  };

  const listItemRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (listItemRef.current && !listItemRef.current.contains(event.target)) {
        setShowAll(false);
      }
    };

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  return (
    <li
      className={cn(style.listItemTag, { [style.showAll]: showAll })}
      ref={listItemRef}
    >
      <div
        className={cn(style.titleBlock, { [style.titleBlockShow]: showAll })}
      >
        <div className={style.titleAndAdd}>
          <div
            className={cn(style.titleWrapper, {
              [style.titleWrapperShow]: showAll,
            })}
          >
            <h4
              className={cn(style.title, { [style.titleShowAll]: showAll })}
              onClick={() => setShowTitleMenu(true)}
            >
              {item?.name}
            </h4>
            {showTitleMenu && (
              <MenuEditOrOpen
                dropdown={showTitleMenu}
                setDropdown={setShowTitleMenu}
                handleEdit={handleEditTitle}
                handleDelete={handleDeleteTag}
                titleEdit="название"
                titleDelete="среднечастотный тег"
                link={`/${
                  typeCatalog === 'theme' ? Router.Theme : Router.Tag
                }/${Router.Edit}/${item?.id}`}
                className={style.contextMenu}
              />
            )}

            {showTitleInput && (
              <HangingInput
                value={value}
                position="left"
                placeholder="Введите название"
                showInput={showTitleInput}
                onChange={(e) => {
                  setValue(e.target.value);
                }}
                onKeyDown={() => dispatchEditTitle(value)}
                onBlur={() => {
                  dispatchEditTitle(value);
                }}
                className={style.inputWidth1}
              />
            )}
          </div>
          <div
            className={cn(style.addButtonWrapper, {
              [style.addButtonShow]: showAll,
            })}
          >
            {showAll ? (
              <button
                type="button"
                className={style.buttonCancel}
                onClick={() => setShowAll(!showAll)}
              >
                ✕
              </button>
            ) : (
              <button
                type="button"
                className={style.buttonPlusMiddle}
                onClick={() => setShowInput(true)}
                disabled={!item.name?.length}
                data-tooltip-id="plusBtn"
                data-tooltip-content="Добавить низкочастотник"
              >
                <PlusInTheCircle />
              </button>
            )}
            <Tooltip id="plusBtn" place="bottom" />
            {showInput && (
              <HangingInput
                position="left"
                placeholder="Введите название"
                showInput={showInput}
                onChange={(e) => {
                  setValue(e.target.value);
                }}
                onKeyDown={handleLowCategory}
                onBlur={handleLowCategory}
                className={style.inputWidth2}
              />
            )}
          </div>
        </div>
      </div>
      <div className={style.listTag}>
        <ul className={cn(style.listBlock, { [style.listBlockShow]: showAll })}>
          {item?.elements.map((item, index) =>
            index < 6 || showAll ? (
              <CardLowCategoryTag
                typeCatalog={typeCatalog}
                key={item.id}
                item={item}
                onEdit={(id, name) => onEdit(id, name)}
                onDelete={(id) => onDelete(id)}
              />
            ) : null
          )}
        </ul>

        {item?.elements.length > 6 && (
          <ButtonShowAll
            showAll={showAll}
            setShowAll={setShowAll}
            hideText={true}
          />
        )}
      </div>
    </li>
  );
};
