import cn from 'classnames';
import ru from 'date-fns/locale/ru';
import { useFormikContext } from 'formik';
import { useState } from 'react';
import DatePicker from 'react-datepicker';
import MaskedInput from 'react-text-mask';
import 'react-datepicker/dist/react-datepicker.css';

import { releaseDate } from '../../../helpers';
import { IPropsFilterAndColumn } from '../../../types';
import { AttributeItemWrapper } from '../../AttributeItemWrapper';

import style from './ReleaseDate.module.scss';

export const ReleaseDate = ({
  filter,
  columnType,
}: IPropsFilterAndColumn): JSX.Element => {
  const { values, setFieldValue } = useFormikContext();
  const [startDate, setStartDate] = useState<Date>(
    values?.[columnType]?.[filter] &&
      new Date(values?.[columnType]?.[filter]?.gte)
  );

  const handleChange = (date) => {
    const dateCut = new Date(date).toLocaleDateString('en-ca');
    setStartDate(date);
    setFieldValue(`${columnType}.${filter}`, {
      gte: date ? `${dateCut}T00:00:00` : null,
      lte: date ? `${dateCut.split('-')[0]}-12-30T23:59:59` : null,
    });
  };

  return (
    <AttributeItemWrapper
      title={releaseDate[filter]}
      filter={filter}
      columnType={columnType}
    >
      <div className={cn(style.dateBlock)} data-testid="wrapper">
        <td style={{ position: 'relative', zIndex: '10' }}>
          <DatePicker
            placeholderText="2020"
            selected={startDate}
            onChange={handleChange}
            className={style.input}
            dateFormat="yyyy"
            locale={ru}
            showYearPicker
            customInput={
              <MaskedInput
                type="text"
                mask={[
                  /\d/,
                  /\d/,
                  /\d/,
                  /\d/,
                  '.',
                  /\d/,
                  /\d/,
                  '.',
                  /\d/,
                  /\d/,
                ]}
              />
            }
          />
        </td>
      </div>
    </AttributeItemWrapper>
  );
};
