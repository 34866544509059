import cn from 'classnames';

import { Feed } from '../../../../../../../../app/api/services/feeds/types';
import { typeOptions } from '../../../../../../../../shared/types';
import { TextWithTooltip } from '../../../../../../../../widgets';

import style from './FlatFeedViewInRubric.module.scss';

interface IProps {
  title: string;
  feed: Feed;
  isError?: boolean;
  className?: string;
}

export const FlatFeedViewInRubric = ({
  feed,
  title,
  className,
  isError = false,
}: IProps): JSX.Element => (
  <div className={cn(style.container, className)}>
    <span
      className={cn(style.title, { [style.errorTitle]: isError })}
    >{`${title}`}</span>
    <span className={cn(style.text, style.name)}>{feed?.name}</span>
    <span className={cn(style.text, style.typeElement)}>
      {typeOptions[feed?.element_type?.name] ||
        typeOptions[feed?.collection?.collection_type?.name]}
    </span>
    <span className={cn(style.text, style.feedType)}>
      {feed?.feed_type?.name}
    </span>
    <span className={style.feedId}>
      <TextWithTooltip
        text={feed ? feed.uuid : null}
        className={style.tooltip}
      />
    </span>
  </div>
);
