import { memo } from 'react';

import style from './PrimaryButton.module.scss';

interface IProps {
  disabled: boolean;
  label: string;
}
export const PrimaryButton = memo(
  (props: IProps): JSX.Element => (
    <button type="submit" className={style.button} disabled={props.disabled}>
      {props.label}
    </button>
  )
);
