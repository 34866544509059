import cn from 'classnames';

import style from './Text.module.scss';

interface IProps {
  text: string | number;
  className?: string;
}

export const Text = ({ text, className }: IProps): JSX.Element => (
  <div data-testid="text" className={cn(style.text, className)}>
    {text?.toString() ?? ''}
  </div>
);
