import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IParams } from '../../app/api/services/massActions/types';

export interface IStateMassActions {
  isMassActions: boolean;
  chekedElements: number[];
  massActions: { commands: IParams };
}

const initialState = {
  isMassActions: false,
  chekedElements: [],
  massActions: {},
};

export const massActionsSlice = createSlice({
  name: 'massActionsSlice',
  initialState,
  reducers: {
    setIsMassAction(state, { payload }: PayloadAction<boolean>) {
      state.isMassActions = payload;
    },

    setChekedElements(state, { payload }: PayloadAction<number[]>) {
      state.chekedElements = payload;
    },
    addCommand(
      state,
      { payload }: PayloadAction<{ type: string; title: string }>
    ) {
      state.massActions = {
        ...state.massActions,
        [payload.type]: {
          ...state.massActions[payload.type],
          [payload.title]: payload.title !== 'is_active' ? null : false,
        },
      };
    },
    deleteMassAction(
      state,
      { payload }: PayloadAction<{ columnType: string; massAction: string }>
    ) {
      const updatedValues = { ...state.massActions };
      delete updatedValues?.[payload?.columnType]?.[payload?.massAction];
      !Object.keys(updatedValues?.[payload?.columnType]).length &&
        delete updatedValues?.[payload?.columnType];
      state.massActions = updatedValues;
    },
    clearMassActions(state) {
      state.isMassActions = false;
      state.chekedElements = [];
      state.massActions = {};
    },
    changeValueCommands(
      state,
      {
        payload,
      }: PayloadAction<{ columnType: string; massAction: string; value: any }>
    ) {
      state.massActions = {
        ...state.massActions,
        [payload.columnType]: {
          ...state.massActions[payload.columnType],
          [payload.massAction]: payload.value,
        },
      };
    },
  },
});

export const {
  setIsMassAction,
  setChekedElements,
  changeValueCommands,
  addCommand,
  deleteMassAction,
  clearMassActions,
} = massActionsSlice.actions;
export const massActionsReducer = massActionsSlice.reducer;
