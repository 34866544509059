import { lazy, Suspense } from 'react';

import { Loader } from '../../../widgets';

const ClientProfile = lazy(() => import('./ClientProfile'));

export const ClientProfile_Lazy = (): JSX.Element => (
  <>
    <Suspense fallback={<Loader />}>
      <ClientProfile />
    </Suspense>
  </>
);
