import { TSomeReturn } from '../../../utils';
export interface IElement {
  id: number;
  name: string;
  categoty: 'I' | 'II' | 'III' | 'IV';
  primary_image_url?: string;
  secondary_image_url?: string;
  playlists_count?: number;
  created_at?: string;
  is_active?: boolean;
  description?: string;
}
export interface ICatalogGroup extends IElement {
  elements: ICatalogHight[];
}

export interface ICatalogHight extends IElement {
  elements: ICatalogMiddle[];
}

export interface ICatalogMiddle extends IElement {
  elements?: IElement[];
}

export type CatalogType = 'tag' | 'theme';

export interface IType {
  type: CatalogType;
}

export interface ITypeAndId {
  type: CatalogType;
  id: number;
}

export interface IPayloadAddGroup extends IType {
  value: ICatalogGroup;
}

export interface IPayloadEditName extends ITypeAndId {
  name: string;
}

export interface IPayloadAddElement extends ITypeAndId {
  element: ICatalogHight;
}

export interface IPayloadDeleteHightElement extends ITypeAndId {
  parentId: number;
}

export type Entity = {
  [key in CatalogType]: ICatalogGroup[];
};

export interface ISelectState {
  catalogType: Entity;
}

export type TSome = ICatalogMiddle | ICatalogHight | ICatalogGroup;

export const findChooseElement = (
  arr: TSome[],
  id: number
): TSome | TSomeReturn | null => {
  let searchElement: TSome | null = null;
  for (const el of arr) {
    if (el.id === id) {
      return el;
    }
    if ('elements' in el && Array.isArray(el.elements)) {
      searchElement = findChooseElement(el.elements, id);
      if (searchElement !== null) {
        return searchElement;
      }
    }
  }

  return null;
};
