import { Suspense, lazy } from 'react';

import { Loader } from '../../../widgets';

const RegisterPage = lazy(() => import('./RegisterPage'));

export const RegisterPage_Lazy = (): JSX.Element => (
  <>
    <Suspense fallback={<Loader />}>
      <RegisterPage />
    </Suspense>
  </>
);
