import cn from 'classnames';
import { Tooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';

import './TextWithTooltip.scss';

interface IProps {
  text: string | number;
  className?: string;
  mode?: 'normal' | 'link' | 'normalAlt';
}

export const TextWithTooltip = ({
  text,
  className,
  mode = 'normal',
}: IProps): JSX.Element => {
  const content = text?.toString();

  const anchor = `anchor${(Math.random() + 1).toString(36).substring(7)}`;

  return (
    <div className={cn('tooltipWrapper', className)} data-testid="wrapper">
      <Tooltip
        anchorId={anchor}
        place="bottom"
        content={content}
        data-testid="tooltip"
      />

      <span
        id={anchor}
        className={cn(
          'text',
          mode === 'normal' ? 'normal' : mode === 'link' ? 'link' : 'normalAlt'
        )}
        data-testid="span"
      >
        {content}
      </span>
    </div>
  );
};
