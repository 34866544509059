import cn from 'classnames';
import React, { useState } from 'react';
import { Tooltip } from 'react-tooltip';

import { newTagCatalog } from '../../../../app/api/services/tagCatalog/types';
import { newTopicCatalog } from '../../../../app/api/services/topicCatalog/types';
import { useAppDispatch } from '../../../../hooks';
import { PlusInTheCircle } from '../../../../shared/icons';
import {
  CatalogType,
  ICatalogHight,
  addMiddleElement,
} from '../../../../store/slices/catalogSlice';
import {
  ButtonShowAll,
  HangingInput,
  MenuEditOrOpen,
} from '../../../../widgets';
import { Router } from '../../../Router/routerEnum';
import { CardMiddleCategoryTag } from '../../CardMiddleCategoryTag';
import { CardMiddleCategoryTheme } from '../../CardMiddleCategoryTheme';

import style from './CardHightCategory.module.scss';

interface ITheme extends ICatalogHight {
  parentId?: number;
  parentTitle: string;
  ancestor_id?: number;
}

interface IProps {
  item: ITheme | newTopicCatalog | newTagCatalog;
  typeCatalog: CatalogType;
  onCreate?: (id: number, name: string) => void;
  onEdit?: (id: number, name: string) => void;
  onAddSubTopic?: (id: number, name: string) => void;
  onAddSubTag?: (id: number, name: string) => void;
  onDelete?: (id: number) => void;
}

export const CardHightCategory = ({
  item,
  typeCatalog,
  onCreate,
  onEdit,
  onDelete,
  onAddSubTopic,
  onAddSubTag,
}: IProps): JSX.Element => {
  const [value, setValue] = useState<string>(item.name || '');
  const [showInput, setShowInput] = useState(false);
  const [showTitleInput, setShowTitleInput] = useState(false);
  const [showTitleMenu, setShowTitleMenu] = useState(false);
  const [showAll, setShowAll] = useState(false);
  const [isCreate, setIsCreate] = useState(true);
  const [, setIsEditName] = useState(!item?.name);
  const dispatch = useAppDispatch();

  const dispatchEditTitle = (text: string) => {
    if (item.id === 0.5) {
      if (text.trim()) {
        isCreate && onCreate(0, text);
        setIsCreate(false);
      } else {
        setValue('');
      }
    } else {
      onEdit(item.id, text.trim());
      setIsEditName(false);
    }
    setShowTitleInput(false);
  };

  const handleLowCategory = (e) => {
    if (typeCatalog === 'theme') {
      onAddSubTopic(item.id, e.target.value);
    } else if (typeCatalog === 'tag') {
      onAddSubTag(item.id, e.target.value);
    } else {
      !!value.trim() &&
        dispatch(
          addMiddleElement({
            type: typeCatalog,
            id: item.id,
            element: {
              id: 2 + Math.random(),
              name: value,
              elements: [],
              categoty: 'III',
            },
          })
        );
    }
    setValue('');
    setShowInput(false);
  };

  const handleEditTitle = () => {
    setShowTitleInput(true);
    setValue(item?.name);
    setShowTitleMenu(false);
  };

  const handleDelete = () => {
    if (typeCatalog === 'theme' || typeCatalog === 'tag') {
      onDelete(item.id);
    } else {
      dispatch({ type: typeCatalog, id: item.id });
    }

    setShowTitleMenu(false);
  };

  return (
    <li
      className={cn(style.wrapper, {
        [style.typeTag]: typeCatalog === 'tag',
        [style.typeTagExtended]:
          typeCatalog === 'tag' && item?.elements?.length > 5,
      })}
      id={`${item.name}-${item.id}`}
    >
      <div className={style.titleBlock}>
        <div className={style.titleAndAdd}>
          <div className={style.titleWrapper}>
            <h4
              className={cn(style.input, {
                [style.inputTag]: typeCatalog === 'tag',
              })}
              onClick={() => setShowTitleMenu(true)}
            >
              {item?.name}
            </h4>
            {showTitleMenu && (
              <MenuEditOrOpen
                dropdown={showTitleMenu}
                setDropdown={setShowTitleMenu}
                handleEdit={handleEditTitle}
                handleDelete={handleDelete}
                titleEdit="название"
                titleDelete={
                  typeCatalog === 'tag' ? 'высокочастотный тег' : 'тему'
                }
                className={style.contextMenu}
                link={`/${
                  typeCatalog === 'theme' ? Router.Theme : Router.Tag
                }/${Router.Edit}/${item?.id}`}
              />
            )}

            {showTitleInput && (
              <HangingInput
                value={value}
                position="left"
                placeholder="Введите название"
                showInput={showTitleInput}
                onChange={(e) => {
                  setValue(e.target.value);
                }}
                onKeyDown={() => dispatchEditTitle(value)}
                onBlur={() => {
                  dispatchEditTitle(value);
                }}
                className={style.inputWidth}
              />
            )}
          </div>
          <div className={style.addButtonWrapper}>
            <button
              type="button"
              className={style.buttonPlusMiddle}
              onClick={() => setShowInput(true)}
              disabled={!item.name?.length}
              data-tooltip-id="plusBtn"
              data-tooltip-content={
                typeCatalog === 'tag'
                  ? 'Добавить среднечастотник'
                  : 'Добавить подтему'
              }
            >
              <PlusInTheCircle />
            </button>
            <Tooltip id="plusBtn" place="bottom" />
            {showInput && (
              <HangingInput
                position="left"
                placeholder="Введите название"
                showInput={showInput}
                onChange={(e) => {
                  setValue(e.target.value);
                }}
                onKeyDown={(e) => handleLowCategory(e)}
                onBlur={handleLowCategory}
                className={style.inputWidth}
              />
            )}
          </div>
        </div>
        <p className={style.parentName}>&mdash; {item?.parentTitle}</p>
      </div>
      {typeCatalog === 'theme' ? (
        <div className={cn(style.listTheme, { [style.showAll]: showAll })}>
          <ul className={cn(style.listBlock, { [style.showListAll]: showAll })}>
            {item?.elements?.map((item, index) =>
              index < 7 || showAll ? (
                <CardMiddleCategoryTheme
                  typeCatalog={typeCatalog}
                  item={item}
                  key={item.id}
                  onEdit={(id, name) => onEdit(id, name)}
                  onDelete={(id) => onDelete(id)}
                />
              ) : null
            )}
          </ul>

          {item?.elements?.length > 7 && (
            <ButtonShowAll showAll={showAll} setShowAll={setShowAll} />
          )}
        </div>
      ) : (
        <ul
          className={cn(
            { [style.listTagMore]: item?.elements?.length > 5 },
            style.listTag
          )}
        >
          {item.elements.map((item) => (
            <CardMiddleCategoryTag
              typeCatalog={typeCatalog}
              item={item}
              key={item.id}
              onEdit={(id, name) => onEdit(id, name)}
              onDelete={(id) => onDelete(id)}
              onAddSubTag={(id, name) => onAddSubTag(id, name)}
            />
          ))}
        </ul>
      )}
    </li>
  );
};
