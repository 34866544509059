import cn from 'classnames';
import React from 'react';

import style from './HangingInput.module.scss';

interface IProps {
  showInput: boolean;
  onChange: (value) => void;
  onKeyDown: (value) => void;
  onBlur: (value) => void;
  position?: 'right' | 'left';
  className?: string;
  placeholder: string;
  value?: string;
}

export const HangingInput = ({
  value,
  showInput,
  onChange,
  onKeyDown,
  onBlur,
  position = 'left',
  className,
  placeholder,
}: IProps): JSX.Element => (
  <div
    className={cn(
      style.select,
      { [style.left]: position === 'left' },
      className
    )}
  >
    <input
      value={value}
      className={style.input}
      placeholder={placeholder}
      autoFocus={showInput}
      onChange={onChange}
      onKeyDown={(e) => {
        if (e.key === 'Enter') {
          e.preventDefault();
          onKeyDown(e);
        }
      }}
      onBlur={onBlur}
    />
  </div>
);
