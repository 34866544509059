export const AppleBankIcon = (): JSX.Element => (
  <svg
    width="28"
    height="28"
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20.2189 24.7304C19.0128 25.9618 17.6959 25.7673 16.4283 25.1841C15.0868 24.5878 13.856 24.5619 12.4407 25.1841C10.6684 25.9877 9.73305 25.7544 8.67462 24.7304C2.66863 18.2109 3.55476 8.2825 10.373 7.91958C12.0345 8.01031 13.1914 8.87872 14.1637 8.95649C15.616 8.64542 17.0067 7.75109 18.5574 7.86774C20.4158 8.02327 21.8189 8.80095 22.7419 10.2008C18.902 12.6245 19.8128 17.9517 23.3327 19.4422C22.6312 21.3864 21.7204 23.3176 20.2066 24.7434L20.2189 24.7304ZM14.0406 7.84182C13.856 4.95144 16.0837 2.56656 18.6436 2.33325C19.0005 5.67727 15.7637 8.16585 14.0406 7.84182Z"
      fill="#333333"
    />
  </svg>
);

export const TBankIcon = (): JSX.Element => (
  <svg
    width="28"
    height="28"
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.16602 1.1665H26.8327V14.0737C26.8327 17.3835 25.0723 20.435 22.2041 22.096L14.0055 26.8332L5.80694 22.096C2.93868 20.4473 1.17833 17.3835 1.17833 14.0737V1.1665H1.16602Z"
      fill="#FFDD2D"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.20703 7.95752V11.9687C8.76099 11.3535 9.75811 10.9351 10.8906 10.9351H12.134V15.5985C12.134 16.8412 11.8016 17.924 11.2969 18.5269H16.6887C16.184 17.924 15.8516 16.8412 15.8516 15.5985V10.9351H17.095C18.2398 10.9351 19.2369 11.3535 19.7786 11.9687V7.95752H8.20703Z"
      fill="#333333"
    />
  </svg>
);
