interface IBlock {
  supermix_id: number;
  position: number;
  button_text: string;
}

export const InfoSectionFields = {
  Name: 'name',
  Description: 'description',
  Is_active: 'is_active',
  Blocks: 'blocks',
};

export interface IInfoSectionErrors {
  description: boolean;
  name: boolean;
  blocks: boolean;
  button_text: boolean;
  supermix_id: boolean;
}

export interface IInfoSectionValues {
  description: string;
  name: string;
  blocks: IBlock[];
  is_active: boolean;
}

export const validateCreateInfoSection = (
  values: IInfoSectionValues
): object => {
  const errors = {} as IInfoSectionErrors;

  if (!values.name) {
    errors.name = true;
  }

  if (!values.description) {
    errors.description = true;
  }

  if (!values.blocks.length) {
    errors.blocks = true;
  }

  if (values?.blocks) {
    values.blocks.forEach((block, index) => {
      if (!block.button_text) {
        errors[`blocks[${index}].button_text`] = true;
      }
      if (!block.supermix_id) {
        errors[`blocks[${index}].supermix_id`] = true;
      }
    });
  }

  return errors;
};
