import React, { Suspense } from 'react';

import EditTagsForCompilations from './EditTagsForCompilations';
import { Loader } from '../../../../../../widgets';

export const EditTagsForCompilations_Lazy = (): JSX.Element => (
  <>
    <Suspense fallback={<Loader />}>
      <EditTagsForCompilations />
    </Suspense>
  </>
);
