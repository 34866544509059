import { saveAs } from 'file-saver';
import { Form, Formik } from 'formik';
import moment from 'moment';
import React, { useEffect, useState } from 'react';

import { useLazyGetStatsBySupermixQuery } from '../../../../../app/api/services/reportsSupermix';
import {
  DownloadSpreadsheet,
  ErrorAlert,
  IfraimeTable,
  Loader,
} from '../../../../../widgets';
import { MonthSelect } from '../../DashboradsComponents';
import {
  getDate,
  initialValues,
  ISupermixProfitValues,
  SupermixProfitFields,
  validateGetSupermixProfit,
} from '../helpers';
import { SupermixSelect } from '../SupermixProfitComponents';
import { useGetSupermixByConditionSearchQuery } from 'app/api/services/supermix/supermix';
import { errorToast } from 'utils';

import style from './SupermixProfitGeneral.module.scss';

export const SupermixProfitGeneral = (): JSX.Element => {
  const [isValidateCheked, setIsValidateCheked] = useState(false);
  const [pointsUrl, setPointsUrl] = useState(null);
  const [listeningUrl, SetListeningUrl] = useState(null);
  const [cumulativeUrl, setCumulativeUrl] = useState(null);

  const [
    getStats,
    {
      data: stats,
      isLoading: isLoadingStats,
      isFetching: isFetchingStats,
      error: errorStats,
      isError: statsError,
    },
  ] = useLazyGetStatsBySupermixQuery();

  const { data: supermixes, isLoading: isLoadingSupermixes } =
    useGetSupermixByConditionSearchQuery({
      limit: 50, // TODO: переделать селект чтобы он поддерживал пагинацию если супермиксов станет больше 50
      offset: 0,
      enrichments: 'product',
    });

  const handleGetStats = async (values: ISupermixProfitValues) => {
    const date = getDate(values.period);

    await getStats({
      supermix_id: values.supermix_id,
      month: date,
    });
  };

  const downloadFile = async (values) => {
    await handleGetStats(values);

    if (!stats?.response?.download_url) return;

    const res = await fetch(stats?.response?.download_url);

    const blob = await res.blob();
    if (!blob) return;

    const ext = 'xlsx'; // файл всегда xlsx?

    const fileName = `supermix-${values.supermix_id}/${getDate(
      values.period
    )}.${ext}`;

    saveAs(blob, fileName);
  };

  const maxDate = moment().subtract(1, 'months').endOf('month').toDate();

  useEffect(() => {
    if (errorStats) {
      setPointsUrl(null);
      SetListeningUrl(null);
      setCumulativeUrl(null);
    } else {
      const points_url =
        stats?.response?.view_url +
        `/pubhtml?gid=${stats?.response?.gids[0]}&chrome=false`;
      setPointsUrl(points_url);

      const listening_url =
        stats?.response?.view_url +
        `/pubhtml?gid=${stats?.response?.gids[1]}&chrome=false`;
      SetListeningUrl(listening_url);

      const cumulative_url =
        stats?.response?.view_url +
        `/pubhtml?gid=${stats?.response?.gids[2]}&chrome=false`;
      setCumulativeUrl(cumulative_url);
    }
  }, [stats, errorStats]);

  useEffect(() => {
    const message = (errorStats as { data: { message: string } })?.data
      ?.message;

    if (errorStats) {
      errorToast(!!errorStats, message);
    }
  }, [errorStats]);

  if (isLoadingSupermixes) return <Loader />;

  return (
    <div className={style.wrapper}>
      <div className={style.header}>
        <div className={style.left}>
          <h4 className={style.title}>Общий расчёт</h4>
          <Formik
            initialValues={initialValues}
            validate={validateGetSupermixProfit}
            validateOnBlur={isValidateCheked}
            validateOnChange={isValidateCheked}
            onSubmit={handleGetStats}
          >
            {(formik) => {
              if (Object.keys(formik.errors).length) {
                setIsValidateCheked(true);
              }

              return (
                <Form
                  className={style.controls}
                  onKeyDown={(event) =>
                    event.key === 'Enter' && event.preventDefault()
                  }
                >
                  <div>
                    <SupermixSelect
                      isError={!!formik.errors.supermix_id}
                      field={SupermixProfitFields.supermix_id}
                      supermixes={supermixes?.response}
                    />
                    <MonthSelect
                      isError={!!formik.errors.period}
                      field={SupermixProfitFields.period}
                      maxDate={maxDate}
                    />
                  </div>

                  <div className={style.right}>
                    {/* <LastRefreshed date="3 июня, 14:10 "></LastRefreshed> */}
                    <div className={style.buttons}>
                      {/* <CreateButton
                        description="Обновить базу"
                        icon={<RefreshIcon />}
                        onClick={() => formik.handleSubmit()}
                        type="reverse"
                      /> */}
                      <DownloadSpreadsheet
                        // disabled={!data?.response?.points_url}
                        onClick={() => downloadFile(formik.values)}
                      />
                    </div>
                  </div>
                  {Object.keys(formik.errors).length > 0 && (
                    <ErrorAlert text="Пожалуйста, заполните все обязательные поля" />
                  )}
                </Form>
              );
            }}
          </Formik>
        </div>
      </div>

      {isLoadingStats || isFetchingStats ? (
        <Loader />
      ) : (
        stats?.response &&
        listeningUrl &&
        cumulativeUrl &&
        pointsUrl &&
        !statsError && (
          <div className={style.tables}>
            <div className={style.topWrapper}>
              <div>
                <h2 className={style.tableTitle}>Данные по периоду</h2>
                <IfraimeTable url={pointsUrl} height="291px" width="700px" />
              </div>
              <div>
                <h2 className={style.tableTitle}>Нарастающий итог</h2>
                <IfraimeTable
                  url={cumulativeUrl}
                  height="291px"
                  width="602px"
                />
              </div>
            </div>
            <div>
              <IfraimeTable url={listeningUrl} height="563px" width="700px" />
            </div>
          </div>
        )
      )}
    </div>
  );
};
