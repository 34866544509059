import { IRoot, TopicCatalog, newTopicCatalog } from './types';

export interface IResponseGetTopicCatalogAdapter extends IRoot {
  response: TopicCatalog[];
}

export interface IResponseGetTopicCatalog extends IRoot {
  response: {
    topics: newTopicCatalog[];
    subTopics: newTopicCatalog[];
    subSubTopics: newTopicCatalog[];
  };
}

export const getTopicCatalogAdapter = (
  data: IResponseGetTopicCatalogAdapter
): IResponseGetTopicCatalog => {
  const { response } = data;

  const getChildrenTopics = (id) =>
    response.filter((item) => item.ancestor_id === id);

  const getParentTitle = (id) => {
    const topic = response.filter((item) => item.id === id);

    return topic[0]?.name;
  };

  const topicLevelOne: newTopicCatalog[] = data.response
    .filter((item) => item.level === 1)
    .map((item) => ({ ...item, elements: getChildrenTopics(item.id) }));

  const topicLevelTwo: newTopicCatalog[] = data.response
    .filter((item) => item.level === 2)
    .map((item) => ({
      ...item,
      parentTitle: getParentTitle(item.ancestor_id),
      elements: getChildrenTopics(item.id),
    }));

  const topicLevelThree: newTopicCatalog[] = data.response
    .filter((item) => item.level === 3)
    .map((item) => ({
      ...item,
      parentTitle: getParentTitle(item.ancestor_id),
      elements: getChildrenTopics(item.id),
    }));

  return {
    ...data,
    response: {
      topics: topicLevelOne,
      subTopics: topicLevelTwo,
      subSubTopics: topicLevelThree,
    },
  };
};
