import cn from 'classnames';
import React from 'react';

import { Feed } from '../../../app/api/services/feeds/types';
import { Separator } from '../../../widgets';
import {
  FeedListBlock,
  HeaderBlock,
  SwitchBlock,
} from '../componentsForRelationFeed';

import style from './ExpandedViewFeed.module.scss';

export interface IProps {
  feed: Feed;
  className?: string;
}

export const ExpandedViewFeed = ({ feed, className }: IProps): JSX.Element => (
  <div className={cn(style.wrapperFeed, className)}>
    <HeaderBlock title={feed?.collection?.name} feed={feed} />
    <FeedListBlock feed={feed} />
    <SwitchBlock feedListLength={feed?.collection?.elements?.length} />
    <Separator color="grey" className={style.separator} />
  </div>
);
