import React from 'react';

import { Router } from 'components';
import { StatsLinkList } from 'widgets';

import style from './SupermixProfit.module.scss';

const links = [
  { name: 'Общий расчёт', href: Router.General },
  { name: 'Поштучный расчёт', href: Router.Single },
];

export const SupermixProfit = (): JSX.Element => (
  <div className={style.wrapper}>
    <h2 className={style.title}>Система распределения прибыли от супермикса</h2>
    <StatsLinkList links={links} />
  </div>
);
