import { Suspense, lazy } from 'react';

import { Loader } from '../../../../../widgets';

const Rubric = lazy(() => import('./Rubric'));

export const Rubric_Lazy = (): JSX.Element => (
  <>
    <Suspense fallback={<Loader />}>
      <Rubric />
    </Suspense>
  </>
);
