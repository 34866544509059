export enum Fields {
  Name = 'name',
  Title = 'title',
  Description = 'description',
  Img_id = 'img_id',
  Secondary_image = 'secondary_image',
  Primary_banner = 'primary_banner',
  Is_active = 'is_active',
  Weight = 'weight',
}
