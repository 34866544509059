import { useFormikContext } from 'formik';
import { useEffect, useMemo, useState } from 'react';

import { FeedGroup } from '../../../../../../../../app/api/services/feedGroups/types';
import { Feed } from '../../../../../../../../app/api/services/feeds/types';
import { useAppDispatch, useAppSelector } from '../../../../../../../../hooks';
import { Plus } from '../../../../../../../../shared/icons';
import {
  addRubric,
  IRubricState,
} from '../../../../../../../../store/slices/rubricSlice';
import {
  ClickShowContainer,
  SelectWithOptions,
} from '../../../../../../../../widgets';
import { IValidateSection } from '../../../SectionFields';
import { RubricFrameElements } from '../../RubricFrameElements';
import { framesDescription } from '../helpers';

import style from './AddRubricFrame.module.scss';

interface IProps {
  feeds: Feed[];
  feedsGroup: FeedGroup[];
}

export const AddRubricFrame = ({ feeds, feedsGroup }: IProps): JSX.Element => {
  const [showSetFrame, setShowSetFrame] = useState<boolean>(false);
  const [showSelect, setShowSelect] = useState(false);
  const [frameId, setFrameId] = useState('');
  const { errors } = useFormikContext<IValidateSection>();

  const allElements = useAppSelector(
    (state) => (state.rubricReducer as IRubricState).list
  );

  const dispatch = useAppDispatch();

  const clickShowOptionsHandle = (e) => {
    e.preventDefault();
    setShowSetFrame(true);
  };

  const [currentOptions, setCurrentOptions] = useState(null);

  const [currentFrameOptionsId, setCurrentFrameOptionsId] = useState(null);

  const clickHandle = (frame) => {
    setCurrentOptions(() => (frame.id === 'feed' ? feeds : feedsGroup));
    setFrameId(frame.id);
    setCurrentFrameOptionsId(frame.optionsId);
    setShowSetFrame(false);
    setShowSelect(true);
  };

  useEffect(() => {
    setCurrentOptions(() => (frameId === 'feed' ? feeds : feedsGroup));
  }, [frameId, feeds, feedsGroup]);

  const handleTypeSelect = (option) => {
    if (!option) {
      return;
    }

    setShowSelect(false);
    setShowSetFrame(false);
    setCurrentOptions(null);

    pushOptions(option);
  };

  const pushOptions = (option) => {
    const isInArray = !!allElements.find(
      (element) => element?.[frameId]?.id === option.id
    );

    if (isInArray) {
      return;
    }

    dispatch(
      addRubric({
        position: allElements.length + 1,
        entity_type: frameId,
        entity_id: option.id,
        entity: option,
        id: option.id,
      })
    );
  };

  const setValue = useMemo(
    () =>
      allElements.map((element) => ({
        entity_id: element.entity_id,
        position: element.position,
        entity_type: element.entity_type,
      })),
    [allElements]
  );

  const { setFieldValue } = useFormikContext();
  useEffect(() => {
    setFieldValue('elements', setValue);
  }, [setFieldValue, setValue]);

  return (
    <div className={style.container}>
      <button
        onClick={(e) => clickShowOptionsHandle(e)}
        className={
          errors.elements ? style.addButtonWrapperError : style.addButtonWrapper
        }
      >
        <span className={style.add}>
          <Plus />
        </span>
        <span className={style.buttonDescription}>Добавить фрейм</span>
      </button>

      {showSetFrame && (
        <ClickShowContainer
          dropdown={showSetFrame}
          setDropdown={setShowSetFrame}
        >
          <div className={style.frames}>
            {framesDescription.map((frame) => (
              <span
                key={frame.id}
                onClick={() => clickHandle(frame)}
                className={style.option}
              >
                {frame.title}
              </span>
            ))}
          </div>
        </ClickShowContainer>
      )}

      {showSelect && (
        <ClickShowContainer
          dropdown={showSelect}
          setDropdown={setShowSelect}
          className={style.select}
        >
          <SelectWithOptions
            searchEntity={currentFrameOptionsId}
            options={currentOptions}
            onChange={handleTypeSelect}
            withUUID
          />
        </ClickShowContainer>
      )}

      <RubricFrameElements />

      {/* TODO: переделать когда согласуют требования */}
      {errors?.feedBootomIsFeedGroup && (
        <div style={{ color: 'red', marginTop: '15px' }}>
          Последний элемент должен быть лентой
        </div>
      )}
    </div>
  );
};
