import { useFormikContext } from 'formik';
import React, { useState, useContext } from 'react';

import { FrameContext } from '../../MenuOption/ui/MenuOptions';

import style from './AddURL.module.scss';

export interface IProps {
  field: string;
  value: string;
}

export const EditURL = ({ field, value }: IProps): JSX.Element => {
  const { setFieldValue } = useFormikContext();
  const { contextOpened, setContextOpened } = useContext(FrameContext);
  const [valueURL, setValueURL] = useState(value);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValueURL(event.target.value);
  };

  return (
    <div className={style.container}>
      {contextOpened && (
        <div className={style.select_edit}>
          <input
            className={style.input}
            placeholder="Добавьте URL"
            value={valueURL}
            autoFocus={contextOpened}
            onChange={handleChange}
            onKeyDown={(event) => {
              if (event.key === 'Enter') {
                event.preventDefault();
                setFieldValue(field, valueURL);
                setContextOpened(false);
              }
            }}
            onBlur={() => {
              setFieldValue(field, valueURL);
              setContextOpened(false);
            }}
          />
        </div>
      )}
    </div>
  );
};
