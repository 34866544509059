import { useFormikContext } from 'formik';
import React from 'react';

import { TagCatalog } from '../../../../../../../../app/api/services/tagCatalog/types';
import {
  ContainerWrapper,
  Separator,
  Switch,
  TitleWithLink,
} from '../../../../../../../../widgets';
import { TagFields } from '../../../TagFields';

import style from './MainParameters.module.scss';

interface IProps {
  entity: TagCatalog;
}

export const MainParameters = ({ entity }: IProps): JSX.Element => {
  const { values } = useFormikContext<TagCatalog>();

  return (
    <div className={style.gridItem2}>
      <div className={style.wrapper}>
        <div className={style.headerWrapper}>
          <ContainerWrapper title="Главные параметры">
            <div className={style.active}>
              <Switch
                label="Активность"
                field={TagFields.IsActive}
                value={values.is_active}
              />
            </div>

            <Separator color="grey" />

            <div className={style.title1}>
              <TitleWithLink
                title="Ролики:"
                width={26}
                link=""
                text={entity?.clips_count || 0}
              />
              <TitleWithLink
                title="Подборки роликов:"
                width={26}
                link=""
                text={entity?.clip_collections_count || 0}
              />
            </div>
            <div className={style.title2}>
              <TitleWithLink
                title="Плейлисты:"
                width={26}
                link=""
                text={entity?.playlists_count || 0}
              />
              <TitleWithLink
                title="Подборки плейлистов:"
                width={26}
                link=""
                text={entity?.playlist_collections_count || 0}
              />
            </div>
          </ContainerWrapper>
        </div>
      </div>
    </div>
  );
};
