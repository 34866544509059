export const paramsQueryFeedsByName = (
  name: string,
  relation_id: number
): string => {
  if (name && relation_id) {
    return `name=${name}&relation_id=${relation_id}`;
  } else if (relation_id) {
    return `relation_id=${relation_id}`;
  } else {
    return `name=${name}`;
  }
};

export const paramsQueryByRelationId = (
  id: number,
  is_shown_in_related_block: boolean
): string => {
  if (id && is_shown_in_related_block) {
    return `id=${id}&is_shown_in_related_block=${is_shown_in_related_block}`;
  } else if (is_shown_in_related_block) {
    return `is_shown_in_related_block=${is_shown_in_related_block}`;
  } else {
    return `id=${id}`;
  }
};
