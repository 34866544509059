import format from 'date-fns/format';
import ru from 'date-fns/locale/ru';
import { useFormikContext } from 'formik';
import React, { useEffect, useState } from 'react';

import { MonthSelect } from './MonthSelect';
import { EntitySelect } from '../../DashboradsComponents';
import { SupermixSelect } from '../../SupermixProfitGeneral/SupermixProfitComponents';
import {
  IErrorsSupermixSingleProfit,
  ISupermixProfitValues,
  SupermixProfitFields,
} from '../helpers';
import {
  IResponseFilters,
  OwnerForFilter,
  PeriodForFilter,
} from 'app/api/services/reportsSupermix/types';

import style from './Controls.module.scss';

export const Controls = ({
  supermixes,
  filters,
}: {
  supermixes: { id: number; name: string }[];
  filters: IResponseFilters;
}): JSX.Element => {
  const { errors } = useFormikContext<IErrorsSupermixSingleProfit>();
  const { values, setFieldValue } = useFormikContext<ISupermixProfitValues>();
  const [dates, setDates] = useState<Date[]>();
  const [periods, setPeriods] = useState<PeriodForFilter[]>();
  const [owners, setOwners] = useState<OwnerForFilter[]>();

  useEffect(() => {
    if (values.supermix_id) {
      const current_availible_periods = filters?.response?.find(
        (el) => el.id === +values.supermix_id
      )?.periods;

      setPeriods(current_availible_periods);

      const date = values.month ? new Date(values.month) : null;

      const d = date
        ? format(date, 'yyyy-dd', {
            locale: ru,
          })
        : null;

      const current_owners = current_availible_periods?.find(
        (el) => el.value === d
      )?.owners;

      const dates = current_availible_periods?.map((el) => new Date(el.value));

      setOwners(current_owners);
      setDates(dates);
    }
  }, [values.supermix_id, filters?.response, values.month]);

  return (
    <div>
      <SupermixSelect
        isError={!!errors.supermix_id}
        field={SupermixProfitFields.supermix_id}
        supermixes={supermixes}
        onChange={() => {
          setFieldValue(SupermixProfitFields.month, null);
          setFieldValue(SupermixProfitFields.owner, null);
        }}
      />
      <MonthSelect
        isError={!!errors.month}
        field={SupermixProfitFields.month}
        includeDates={dates}
        disabled={!periods}
      />

      <EntitySelect
        isError={!!errors.owner}
        field={SupermixProfitFields.owner}
        options={owners}
        title="Правообладатель"
        isDisabled={false}
        searchEntity="owners"
        className={style.ownersSelect}
      />
      <button className={style.button} type="submit" onClick={() => null}>
        Применить
      </button>
    </div>
  );
};
