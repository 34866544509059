import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Element } from '../../app/api/services/rubrics/types';

export interface IRubricState {
  list: Element[];
}

const initialState: IRubricState = {
  list: [],
};

export const rubricSlice = createSlice({
  name: 'rubric',
  initialState,
  reducers: {
    setRubricList(state, action: PayloadAction<Element[]>) {
      state.list = action.payload;
    },
    addRubric(state, action: PayloadAction<Element>) {
      state.list = [...state.list, action.payload];
    },
    changeRubric(state, action: PayloadAction<Element>) {
      state.list = [...state.list].map((el) =>
        el.position === action.payload.position
          ? {
              ...el,
              feed: action.payload.feed,
              feed_group: action.payload.feed_group,
            }
          : el
      );
    },
    deleteRubric(state, action: PayloadAction<number>) {
      state.list = [...state.list].reduce((res: Element[], el) => {
        if (el.position < action.payload) {
          res.push(el);
        }
        if (el.position > action.payload) {
          res.push({
            ...el,
            position: el.position - 1,
          });
        }

        return res;
      }, []);
    },
  },
});

export const { setRubricList, addRubric, changeRubric, deleteRubric } =
  rubricSlice.actions;

export const rubricReducer = rubricSlice.reducer;
