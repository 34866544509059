import { Suspense, lazy } from 'react';

import { Loader } from '../../../../../widgets';

const Counterparties = lazy(() => import('./Counterparties'));

export const Counterparties_Lazy = (): JSX.Element => (
  <>
    <Suspense fallback={<Loader />}>
      <Counterparties />
    </Suspense>
  </>
);
