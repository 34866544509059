import cn from 'classnames';
import { useFormikContext } from 'formik';
import { useEffect, useState } from 'react';

import style from './Switch.module.scss';

interface IProps {
  value: boolean;
  label?: string;
  className?: string;
  field: string;
  isToggle?: boolean;
}

export const Switch = ({
  label,
  value: propsValue,
  className,
  field,
  isToggle = true,
}: IProps): JSX.Element => {
  const { setFieldValue } = useFormikContext();

  const value = isToggle ? propsValue : false;

  const [isSelected, setIsSelected] = useState(value);

  useEffect(() => {
    setFieldValue(field, isSelected);
  }, [isSelected, setFieldValue, field]);

  useEffect(() => {
    setIsSelected(value);
  }, [value]);

  return (
    <div className={cn(style.wrapper, className)} data-testid="wrapper">
      {label ? <h3 className={style.label}>{label}</h3> : null}
      <div
        data-testid="switch"
        onClick={() =>
          setIsSelected((prevState) => (isToggle ? !prevState : false))
        }
        className={cn(style.container, { [style.selected]: isSelected })}
      >
        <span
          className={cn(style.toggle, {
            [style.toggleSelected]: isSelected,
          })}
        ></span>
      </div>
    </div>
  );
};
