import { Suspense, lazy } from 'react';

import { Loader } from '../../../../../../widgets';

const CreatePlaylist = lazy(() => import('./CreatePlaylist'));

export const CreatePlaylist_Lazy = (): JSX.Element => (
  <>
    <Suspense fallback={<Loader />}>
      <CreatePlaylist />
    </Suspense>
  </>
);
