// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const formatDuration = (duration: number) => {
  if (!duration) {
    return '';
  }

  const hours = Math.floor(duration / 3600);
  const minutes = Math.floor((duration % 3600) / 60);

  return `${hours} ч. ${minutes} мин.`;
};
