import { Suspense, lazy } from 'react';

import { Loader } from '../../../../../widgets';

const RelatedFeed = lazy(() => import('./RelatedFeed'));

export const RelatedFeed_Lazy = (): JSX.Element => (
  <>
    <Suspense fallback={<Loader />}>
      <RelatedFeed />
    </Suspense>
  </>
);
