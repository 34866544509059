import React from 'react';

import style from './RadioButton.module.scss';

interface IProps extends React.InputHTMLAttributes<HTMLInputElement> {
  name: string;
  value: string;
  onChange: (e) => void;
  label?: string;
  checked: boolean;
}

export const RadioButton = ({
  name,
  value,
  onChange,
  label,
  checked,
  ...rest
}: IProps): JSX.Element => (
  <label className={style.label}>
    <input
      className={style.input}
      checked={checked}
      type="radio"
      name={name}
      value={value}
      onChange={onChange}
      {...rest}
    />
    {label}
  </label>
);
