import { lazy, Suspense } from 'react';

import { Loader } from '../../../../../../widgets';

const CreateRubric = lazy(() => import('./CreateRubric'));

export const CreateRubric_Lazy = (): JSX.Element => (
  <>
    <Suspense fallback={<Loader />}>
      <CreateRubric />
    </Suspense>
  </>
);
