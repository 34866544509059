import { lazy, Suspense } from 'react';

import { Loader } from '../../../widgets';

const SemanticField = lazy(() => import('./SemanticField'));

export const SemanticField_Lazy = (): JSX.Element => (
  <>
    <Suspense fallback={<Loader />}>
      <SemanticField />
    </Suspense>
  </>
);
