import S3 from 'aws-sdk/clients/s3';
import cn from 'classnames';
import { useFormikContext } from 'formik';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { CircularProgressbar } from 'react-circular-progressbar';
import { useLocation } from 'react-router-dom';

import { Content } from '../../../app/api/services/clips/types';
import { PreviewContent } from '../../../app/api/services/playlists/types';
import { IValidateClip } from '../../../pages/Navbar/Entity/Clip/CreateClip/ui/helpers';
import { Close, Delete } from '../../../shared/icons';
import { generateUniqNameFile, getTheAudioDuration } from '../../../utils';
import { TextWithTooltip } from '../../TextWithTooltip';
import { ReactComponent as Repeat } from '../assets/repeat.svg';
import { ClipUploadStatus } from '../ClipUploadStatus';
import 'react-circular-progressbar/dist/styles.css';

import style from './ClipUploader.module.scss';

const config = {
  accessKeyId: process.env.REACT_APP_S3_PRIMARY_ACCESS_KEY,
  secretAccessKey: process.env.REACT_APP_S3_PRIMARY_SECRET_KEY,
  endpoint: process.env.REACT_APP_S3_ENDPOINT_URL,
};

const S3Client = new S3(config);

interface IProps<Content, Preview_content> {
  className?: string;
  content?: Content;
  preview_content?: Preview_content;
  setIsLoading?: (e: boolean) => void;
}

enum UploadConstants {
  Failed = 'failed',
  Edit = 'edit',
}

export const ClipUploader = ({
  className,
  content,
  preview_content,
  setIsLoading,
}: IProps<Content, PreviewContent>): JSX.Element => {
  const { setFieldValue } = useFormikContext();
  const { errors } = useFormikContext<IValidateClip>();

  const filePicker = useRef(null);
  const [duration, setDuration] = useState(0);
  const [uploadData, setUploadData] = useState(null);
  const [currentFile, setCurrentFile] = useState<File>();
  const [percentage, setPercentage] = useState(0);
  const [isError, setIsError] = useState(false);
  const [isGettingClip, setIsGettingClip] = useState(true);
  const [headParams, setHeadParams] = useState({ Key: '', Bucket: '' });
  const [isSize, setIsSize] = useState(false);
  const [cancelLoading, setCancelLoading] = useState(false);
  const isEdit = useLocation().pathname.includes(UploadConstants.Edit);

  const pickHandle = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    if (percentage > 0 && percentage <= 100) {
      deleteCurrentFileHandle(e);
      setCancelLoading(true);

      return;
    }
    if (filePicker.current) {
      filePicker.current.click();
    }
  };

  useEffect(() => {
    if (content) {
      if (
        (isEdit && !content) ||
        content?.status?.name === UploadConstants.Failed
      ) {
        setIsError(true);
      }
    }
    if (preview_content) {
      if (
        (isEdit && !preview_content) ||
        preview_content?.audio?.name === UploadConstants.Failed
      ) {
        setIsError(true);
      }
    }
  }, [content, isEdit, preview_content]);

  const uploadAudioFileInBucket = async (file: File) => {
    try {
      setIsError(false);
      setIsLoading(true);
      const paramsUpload = {
        Bucket: `${process.env.REACT_APP_TMP_HOT_STORAGE_BUCKET}/${process.env.REACT_APP_TMP_STORAGE_AUDIO_FOLDER}`,
        ACL: 'private',
        ContentType: file.type,
        ContentDisposition: 'inline',
      };
      const ext = file.name.split('.').at(-1);
      const key = `${generateUniqNameFile(file.name)}.${ext}`;
      const uploadData = (await S3Client.upload({
        ...paramsUpload,
        Key: key,
        Body: file,
      })
        .on('httpUploadProgress', (evt) => {
          const uploaded = Math.round((evt.loaded / evt.total) * 100);
          setPercentage(uploaded);
        })
        .promise()) as {
        Key: string;
        key: string;
        Bucket: string;
        Location: string;
        ETag: string;
      };
      const keyUpload = uploadData?.Key?.split('/')[1];
      setHeadParams({ Key: uploadData?.Key, Bucket: uploadData?.Bucket });

      return { ...uploadData, key: keyUpload, Key: keyUpload };
    } catch (e) {
      setIsLoading(false);
      setIsError(true);
    }
  };

  const changeHandle = async (event: React.ChangeEvent<HTMLInputElement>) => {
    try {
      setIsError(false);
      setCancelLoading(false);
      const [file] = event.target.files;
      setCurrentFile(file);
      setUploadData(await uploadAudioFileInBucket(file));
      setDuration(await getTheAudioDuration(file));
    } catch (e) {
      setIsLoading(false);
      setIsError(true);
    }
  };

  const sizeCheck = useCallback(() => {
    S3Client.headObject(headParams, (err, data) => {
      if (err) {
        setTimeout(() => sizeCheck(), 5000);
      } else {
        data.ContentLength > 0
          ? setIsSize(true)
          : setTimeout(() => sizeCheck(), 5000);
      }
    });
  }, [headParams]);

  useEffect(() => {
    if (duration === 0 || !uploadData || currentFile?.size === 0) {
      return;
    }
    const slicedName = currentFile?.name.split('.').slice(0, -1).join('.');

    setFieldValue('payload.fields.duration', duration);
    setFieldValue('payload.fields.key', uploadData?.Key);
    setFieldValue('payload.fields.size', currentFile?.size);
    setFieldValue('custom_preview.tmpKey', uploadData?.Key);
    setFieldValue('custom_preview.name', slicedName);
    if (isSize) {
      setIsLoading(false);
    } else {
      sizeCheck();
    }
  }, [
    uploadData,
    duration,
    currentFile?.size,
    setFieldValue,
    setIsLoading,
    isSize,
    sizeCheck,
    currentFile?.name,
    content,
    preview_content,
  ]);

  const deleteCurrentFileHandle = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault();
    filePicker.current.value = '';
    setCurrentFile(null);
    setDuration(0);
    setUploadData(null);
    setPercentage(0);
    setFieldValue('payload.fields.duration', 0);
    setFieldValue('payload.fields.key', '');
    setFieldValue('payload.fields.size', 0);
    setFieldValue('custom_preview', null);
    isEdit && setIsGettingClip(false);
  };

  return (
    <div className={cn(style.containerAudio, className)}>
      <span
        className={cn(style.titleAudio, { [style.errorTitle]: errors.audio })}
      >
        Аудиофайл:{uploadData ? '' : null}
      </span>

      {(uploadData && !isError) || (isEdit && isGettingClip) ? (
        <div className={style.wrapperAudio}>
          {!cancelLoading ? (
            <>
              <span className={style.fileName}>
                <TextWithTooltip
                  text={
                    (
                      currentFile?.name ||
                      content?.name ||
                      preview_content?.audio?.name
                    )?.length > 38
                      ? (
                          currentFile?.name ||
                          content?.name ||
                          preview_content?.audio?.name
                        )?.slice(0, 38) + '...mp3'
                      : (currentFile?.name ||
                          content?.name ||
                          preview_content?.audio?.name) + '.mp3'
                  }
                />
              </span>
              <button
                className={style.trashButton}
                onClick={(e) => deleteCurrentFileHandle(e)}
              >
                <Delete fill="#434343" />
              </button>
            </>
          ) : (
            <>
              <button className={style.img} onClick={(e) => pickHandle(e)}>
                <div className={style.audio}>
                  {currentFile && !isError ? (
                    <div className={style.progressBar}>
                      <div className={style.close}>
                        <Close />
                      </div>
                      <CircularProgressbar
                        value={percentage}
                        styles={{
                          path: {
                            stroke: '#6860F1',
                          },
                        }}
                      />
                    </div>
                  ) : (
                    <div className={style.status}>
                      <ClipUploadStatus isError={isError} />
                    </div>
                  )}
                </div>
              </button>
            </>
          )}
        </div>
      ) : (
        <button className={style.img} onClick={(e) => pickHandle(e)}>
          <div className={style.audio}>
            {currentFile && !isError ? (
              <div className={style.progressBar}>
                <div className={style.close}>
                  <Close />
                </div>
                <CircularProgressbar
                  value={percentage}
                  styles={{
                    path: {
                      stroke: '#6860F1',
                    },
                  }}
                />
              </div>
            ) : (
              <div className={style.status}>
                <ClipUploadStatus isError={isError} />
              </div>
            )}
          </div>
        </button>
      )}
      <input
        ref={filePicker}
        type="file"
        accept="audio/mpeg"
        className={style.hidden}
        onChange={changeHandle}
      />
      {isError && (
        <button onClick={(e) => pickHandle(e)} className={style.repeat}>
          Повторить
          <Repeat />
        </button>
      )}
    </div>
  );
};
