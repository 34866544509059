import { Suspense, lazy } from 'react';

import { Loader } from '../../../../../../widgets';

const CreateChannel = lazy(() => import('./CreateChannel'));

export const CreateChannel_Lazy = (): JSX.Element => (
  <>
    <Suspense fallback={<Loader />}>
      <CreateChannel />
    </Suspense>
  </>
);
