import React from 'react';

import { PlusInTheCircle } from '../../../../shared/icons';
import { Header } from '../../../../widgets';

import style from './HeaderCatalog.module.scss';

interface IProps {
  title: string;
  buttonText: string;
  onSubmit?: () => void;
}

export const HeaderCatalog = ({
  title,
  buttonText,
  onSubmit,
}: IProps): JSX.Element => {
  const handleAddCatalog = () => {
    onSubmit();
  };

  return (
    <div className={style.headingWrapper}>
      <Header label={`Каталог ${title}`} />
      <button
        className={style.createGroupTheme}
        type="button"
        onClick={handleAddCatalog}
      >
        <PlusInTheCircle />
        <span className={style.desc}>Создать {buttonText}</span>
      </button>
    </div>
  );
};
