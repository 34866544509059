import { Suspense, lazy } from 'react';

import { Loader } from '../../../../../widgets';

const Rates = lazy(() => import('./Rates'));

export const Rates_Lazy = (): JSX.Element => (
  <>
    <Suspense fallback={<Loader />}>
      <Rates />
    </Suspense>
  </>
);
