import cn from 'classnames';
import { useState } from 'react';
import { Cookies } from 'react-cookie';
import { Link } from 'react-router-dom';

import { COOKIE } from '../../../app/global/types';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { unSetTokens } from '../../../store/slices/authSlice';
import { IUserState } from '../../../store/slices/userSlice';
import { ClickShowContainer } from '../../../widgets/ClickShowContainer';
import { Roles } from '../roles';

import style from './PersonalButton.module.scss';

export const PersonalButton = (): JSX.Element => {
  const [dropdown, setDropdown] = useState(false);

  const onClickHandler = () => {
    setDropdown((prevState) => !prevState);
  };

  const dispatch = useAppDispatch();
  const handleLogOut = (): void => {
    dispatch(unSetTokens());

    const cookies = new Cookies();

    cookies.remove(COOKIE.ACCESS_TOKEN, { path: '/' });
    cookies.remove(COOKIE.LOGGED_IN, { path: '/' });
    cookies.remove(COOKIE.REFRESH_TOKEN, { path: '/' });
    history.pushState(null, null, '/login');
    window.location.reload();
  };

  const profile = useAppSelector((state) => state.userReducer as IUserState);

  return (
    <ClickShowContainer
      dropdown={dropdown}
      setDropdown={setDropdown}
      className={style.container}
    >
      <button className={style.button} onClick={onClickHandler}>
        {profile.isLoading ? (
          <div className={style.notavatar}></div>
        ) : (
          <img
            className={style.avatar}
            src="https://shapka-youtube.ru/wp-content/uploads/2021/02/prikolnaya-avatarka-dlya-patsanov.jpg"
            alt="Аватар пользователя"
          />
        )}

        <div className={style.wrapper}>
          <span className={cn(style.name, { [style.grey]: profile.isLoading })}>
            {profile.first_name}
          </span>
          <span className={cn(style.role, { [style.grey]: profile.isLoading })}>
            {Roles[profile.role]}
          </span>
        </div>
      </button>

      <div className={dropdown ? style.show : style.hide}>
        <Link to="/personalpage" className={style.personal}>
          Личные данные
        </Link>
        <button className={style.logout} onClick={handleLogOut}>
          Выйти
        </button>
      </div>
    </ClickShowContainer>
  );
};
