import { Form, Formik, FormikProps } from 'formik';
import { isEmpty } from 'lodash';
import React, { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from '../../../hooks';
import {
  IStateFilters,
  setFilters,
  setInInitialValue,
  setIsFiltred,
} from '../../../store/slices/filterSlice';
import { SearchEntity } from '../../../store/slices/selectSlice';
import { SaveButton } from '../../../widgets';
import { AddAttribute } from '../FilterSearchFormComponents';
import { disabledBtnToApplyFilter, returnedFilter } from '../helpers';
import { IFormikValues } from '../types';

import style from './FilterSearchForm.module.scss';

interface IProps {
  entity: SearchEntity;
  setModalActive: (val: boolean) => void;
}

export const FilterSearchForm = ({
  entity,
  setModalActive,
}: IProps): JSX.Element => {
  const colunms = ['Поисковый запрос', 'Исключения из поиска'];

  const dispatch = useAppDispatch();
  const [searchParams, setSearchParams] = useSearchParams();

  const { filters } = useAppSelector(
    (state) => state.filterReducer as IStateFilters
  );

  useEffect(() => {
    const params = {};
    const includes = {};
    const excludes = {};

    searchParams.forEach((value, key) => {
      params[key] = value;
    });

    Object.entries(params).forEach(([key, value]: [string, unknown]) => {
      let type: string;
      let search: string;

      if (typeof value === 'string') {
        type = value.split('|')[0];
        search = value.split('|')[1];
      }

      if (type === 'includes') {
        includes[key] = handleParams(key, search);
      } else if (type === 'excludes') {
        excludes[key] = handleParams(key, search);
      }
    });

    const searchFilters = {
      includes,
      excludes,
    };

    if (!isEmpty(includes) || !isEmpty(excludes)) {
      dispatch(setFilters(searchFilters));
      dispatch(setIsFiltred(true));
    } else {
      dispatch(setFilters({}));
      dispatch(setIsFiltred(false));
    }
  }, [dispatch, searchParams]);

  const handleParams = (key, value) => {
    switch (key) {
      case 'created_at':
      case 'release_date':
      case 'submit_date':
      case 'playlists_count':
      case 'clips_count':
      case 'channels_count':
        return {
          gte: value?.split('/')[0],
          lte: value?.split('/')[1],
        };
      case 'content_type':
      case 'owner':
      case 'channel':
      case 'level':
      case 'persons':
      case 'type':
      case 'feed':
      case 'feed_top':
      case 'feed_bottom':
      case 'playlist':
      case 'authors':
      case 'translators':
      case 'readers':
        return { id: value };
      case 'ages':
        return { name: value };
      case 'tags':
        return { tag_id: value };
      case 'is_active':
      case 'is_related':
        return value === 'true' ? true : false;
      default:
        return value;
    }
  };

  const handleSearchParams = (key, value, type) => {
    let params;

    switch (key) {
      case 'created_at':
      case 'release_date':
      case 'submit_date':
      case 'playlists_count':
      case 'clips_count':
      case 'channels_count':
        params = `${type}|${value.gte}/${value.lte}`;
        break;
      case 'content_type':
      case 'owner':
      case 'channel':
      case 'level':
      case 'type':
      case 'persons':
      case 'feed':
      case 'feed_top':
      case 'feed_bottom':
      case 'playlist':
      case 'authors':
      case 'translators':
      case 'readers':
        params = `${type}|${value.id}`;
        break;
      case 'ages':
        params = `${type}|${value.name}`;
        break;
      case 'tags':
        params = `${type}|${value.tag_id}`;
        break;
      default:
        params = `${type}|${value}`;
    }

    return params;
  };

  return (
    <Formik
      initialValues={filters}
      enableReinitialize
      onSubmit={(values) => {
        const includes =
          'includes' in values ? Object.entries(values.includes) : [];
        const excludes =
          'excludes' in values ? Object.entries(values.excludes) : [];

        includes.forEach(([key, value]) => {
          searchParams.set(key, handleSearchParams(key, value, 'includes'));
        });

        excludes.forEach(([key, value]) => {
          searchParams.set(key, handleSearchParams(key, value, 'excludes'));
        });

        if (!isEmpty(includes) || !isEmpty(excludes)) {
          dispatch(setFilters(values));
          dispatch(setIsFiltred(true));
          setSearchParams(searchParams);
          setModalActive(false);
        }
      }}
    >
      {(formik: FormikProps<IFormikValues>) => {
        const buttonsVisible =
          'includes' in formik.values || 'excludes' in formik.values;

        return (
          <Form
            className={style.form}
            onKeyDown={(event) =>
              event.key === 'Enter' && event.preventDefault()
            }
          >
            <div className={style.content}>
              {colunms.map((colunm) => {
                const columnType =
                  colunm === 'Поисковый запрос' ? 'includes' : 'excludes';

                return (
                  <div className={style.column} key={colunm}>
                    <h3 className={style.title}>{colunm}</h3>
                    <AddAttribute type={columnType} entity={entity} />
                    <div className={style.filtersList}>
                      {Object.keys(formik.values[columnType] || {}).map(
                        (attribute) => returnedFilter(attribute, columnType)
                      )}
                    </div>
                  </div>
                );
              })}
            </div>
            {buttonsVisible && (
              <div className={style.buttons}>
                <SaveButton
                  disabled={disabledBtnToApplyFilter(formik?.values)}
                  title="Применить фильтры"
                />
                <button
                  className={style.clearButton}
                  type="button"
                  onClick={() => {
                    setSearchParams();
                    dispatch(setInInitialValue(entity));
                    setModalActive(false);
                  }}
                >
                  Очистить все
                </button>
              </div>
            )}
          </Form>
        );
      }}
    </Formik>
  );
};
