import { Suspense, lazy } from 'react';

import { Loader } from '../../../../../../widgets';

const CreateRates = lazy(() => import('../ui/CreateRates'));

export const Create_Lazy = (): JSX.Element => (
  <>
    <Suspense fallback={<Loader />}>
      <CreateRates />
    </Suspense>
  </>
);
