import { Outlet, useLocation } from 'react-router-dom';

import { Header } from '../../Header';

import style from './Layout.module.scss';

export const Layout = (): JSX.Element => {
  const { pathname } = useLocation();

  const isCreatePage = pathname.split('/').at(-1) === 'create';
  const isEditPage = pathname.split('/').at(-2) === 'edit';

  return (
    <div className={isCreatePage || isEditPage ? '' : style.container}>
      <Header />
      <main>
        <Outlet />
      </main>
    </div>
  );
};
