import { paramsQueryByRelationId, paramsQueryFeedsByName } from './helpers';
import {
  IBody,
  IEdit,
  IParams,
  IParamsByName,
  IParamsByRelationId,
  IResponseCreate,
  IResponseFeedElemetTypes,
  IResponseFeedTypes,
  IResponseGet,
  IResponseGetById,
} from './types';
import { endpoints } from '../../../../shared/api/endpoints/endpoints';
import { tags } from '../../../../shared/api/tags/tags';
import { mainApi } from '../config';

export const feedsApiMutation = mainApi.injectEndpoints({
  endpoints: (builder) => ({
    createFeed: builder.mutation<IResponseCreate, IBody>({
      query: (body) => ({
        url: endpoints.feedCreate,
        method: 'POST',
        data: body,
      }),
      invalidatesTags: [tags.feed],
    }),

    editFeed: builder.mutation({
      query: (body: IEdit) => ({
        url: endpoints.feed,
        method: 'PUT',
        data: body,
      }),
      invalidatesTags: [tags.feed],
    }),

    deleteFeed: builder.mutation({
      query: (id) => ({
        url: `${endpoints.feed}?id=${id}`,
        method: 'DELETE',
        id,
      }),
      invalidatesTags: (_result, _error, arg) => [{ type: tags.feed, id: arg }],
    }),

    bindFeed: builder.mutation({
      query: (body: { ids: number[] }) => ({
        url: `${endpoints.feedRegistry}`,
        method: 'PUT',
        data: body,
      }),
      invalidatesTags: [tags.feed],
    }),
  }),
});

export const feedsApiByQuery = mainApi.injectEndpoints({
  endpoints: (builder) => ({
    getFeedByCondition: builder.query<IResponseGet, IParams>({
      query: ({ limit, offset, is_related }) => ({
        url: endpoints.feedGet,
        params: { limit, offset, is_related },
      }),
      serializeQueryArgs: ({ queryArgs }) => {
        const { is_related } = queryArgs;

        return is_related;
      },
      merge(currentCacheData, responseData) {
        const newResponses = responseData.response.filter(
          (newResponse) =>
            !currentCacheData.response.some(
              (currentResponse) => currentResponse.id === newResponse.id
            )
        );
        currentCacheData.response.push(...newResponses);
      },
      forceRefetch({ currentArg, previousArg }) {
        return currentArg !== previousArg;
      },
      providesTags: (result) => {
        if (result) {
          const { response } = result;

          return [
            ...response.map(({ id }) => ({ type: tags.feed, id } as const)),
            { type: tags.feed, id: 'FEEDS_LIST' },
          ];
        } else {
          return [{ type: tags.feed, id: 'FEEDS_LIST' }];
        }
      },
    }),
    getFeedsByName: builder.query<IResponseGet, IParamsByName>({
      query: ({ name, relation_id }) => ({
        url: `${endpoints.feedGetByName}?${paramsQueryFeedsByName(
          name,
          relation_id
        )}`,
      }),
      providesTags: [tags.feed],
    }),
    getFeedById: builder.query<IResponseGetById, number>({
      query: (id) => ({ url: `${endpoints.feed}?id=${id}` }),
      providesTags: [tags.feed],
    }),
  }),
});

export const feedsApiQuery = mainApi.injectEndpoints({
  endpoints: (builder) => ({
    getFeedType: builder.query<IResponseFeedTypes, void>({
      query: () => ({
        url: endpoints.feedType,
      }),
      providesTags: [tags.feed],
    }),

    getFeedElementType: builder.query<IResponseFeedElemetTypes, void>({
      query: () => ({
        url: endpoints.feedElementType,
      }),
      providesTags: [tags.feed],
    }),

    getFeedRelationType: builder.query<IResponseFeedElemetTypes, void>({
      query: () => ({
        url: endpoints.feedRelationType,
      }),
      providesTags: [tags.feed],
    }),

    getFeedByRelationId: builder.query<IResponseGet, IParamsByRelationId>({
      query: ({ id, is_shown_in_related_block }) => ({
        url: `${endpoints.feedGetByRelationId}?${paramsQueryByRelationId(
          id,
          is_shown_in_related_block
        )}`,
      }),
      providesTags: [tags.feed],
    }),
  }),
});

export const {
  useCreateFeedMutation,
  useDeleteFeedMutation,
  useEditFeedMutation,
  useBindFeedMutation,
} = feedsApiMutation;

export const {
  useGetFeedElementTypeQuery,
  useGetFeedTypeQuery,
  useGetFeedRelationTypeQuery,
  useGetFeedByRelationIdQuery,
} = feedsApiQuery;

export const {
  useGetFeedByIdQuery,
  useGetFeedByConditionQuery,
  useGetFeedsByNameQuery,
} = feedsApiByQuery;
