import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface ITokensPayload {
  accessToken: string | null;
  refreshToken: string | null;
}

export interface IAuthData {
  id: string | null;
  role: string | null;
  tokens: ITokensPayload;
}

export interface AuthState {
  user?: IAuthData | null;
}

const initialState: AuthState = {
  user: {
    id: null,
    role: null,
    tokens: {
      accessToken: null,
      refreshToken: null,
    },
  },
};

export const authSlice = createSlice({
  name: 'authReducer',
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<AuthState>) => {
      state.user.id = action.payload.user.id;
      state.user.role = action.payload.user.role;
    },
    unSetTokens: (state) => {
      state.user.tokens.accessToken = null;
      state.user.tokens.refreshToken = null;
    },
    setTokens: (state, action: PayloadAction<ITokensPayload>) => {
      state.user.tokens.accessToken = action.payload.accessToken;
      state.user.tokens.refreshToken = action.payload.refreshToken;
    },
  },
});

export const { setTokens, unSetTokens, setUser } = authSlice.actions;
export const actionsAuth = authSlice.actions;
export const authReducer = authSlice.reducer;
