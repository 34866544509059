import { Download } from 'shared/icons';
import { CreateButton } from 'widgets/CreateButton';

export const DownloadSpreadsheet = ({
  onClick,
  disabled,
  description,
}: {
  onClick: () => void;
  disabled?: boolean;
  description?: string;
}): JSX.Element => (
  <CreateButton
    description={description || 'Выгрузить таблицу'}
    icon={<Download />}
    onClick={onClick}
    disabled={disabled}
  />
);
