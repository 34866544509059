import { Suspense, lazy } from 'react';

import { Loader } from '../../../widgets';

const LoginPage = lazy(() => import('./LoginPage'));

export const LoginPage_Lazy = (): JSX.Element => (
  <>
    <Suspense fallback={<Loader />}>
      <LoginPage />
    </Suspense>
  </>
);
