import cn from 'classnames';
import React from 'react';

import style from './ButtonShowAll.module.scss';

interface IProps {
  showAll: boolean;
  setShowAll: (prev: boolean) => void;
  hideText?: boolean;
  className?: string;
}

export const ButtonShowAll = ({
  showAll,
  setShowAll,
  hideText = false,
  className,
}: IProps): JSX.Element => (
  <button
    className={cn(style.buttonShow, className)}
    type="button"
    onClick={() => setShowAll(!showAll)}
  >
    {hideText
      ? showAll
        ? ''
        : 'Показать все'
      : showAll
      ? 'Свернуть'
      : 'Показать все'}
  </button>
);
