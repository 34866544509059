import { Suspense, lazy } from 'react';

import { Loader } from '../../../../../widgets';

const Reader = lazy(() => import('./Reader'));

export const Reader_Lazy = (): JSX.Element => (
  <>
    <Suspense fallback={<Loader />}>
      <Reader />
    </Suspense>
  </>
);
