export const Ellipsis = (): JSX.Element => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="none"
  >
    <circle cx="15" cy="15" r="15" />
    <circle
      cx="9.23077"
      cy="14.6618"
      r="1.73077"
      transform="rotate(-90 9.23077 14.6618)"
      fill="#434343"
    />
    <circle
      cx="15.0003"
      cy="14.6247"
      r="1.73077"
      transform="rotate(-90 15.0003 14.6247)"
      fill="#434343"
    />
    <circle
      cx="20.7698"
      cy="14.5856"
      r="1.73077"
      transform="rotate(-90 20.7698 14.5856)"
      fill="#434343"
    />
  </svg>
);

export const EllipsisSmall = (): JSX.Element => (
  <svg
    width="13"
    height="4"
    viewBox="0 0 13 4"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      cx="1.5"
      cy="1.56299"
      r="1.5"
      transform="rotate(-90 1.5 1.56299)"
      fill="#6860F1"
    />
    <circle
      cx="6.49609"
      cy="1.53174"
      r="1.5"
      transform="rotate(-90 6.49609 1.53174)"
      fill="#6860F1"
    />
    <circle
      cx="11.5"
      cy="1.5"
      r="1.5"
      transform="rotate(-90 11.5 1.5)"
      fill="#6860F1"
    />
  </svg>
);
