import cn from 'classnames';

import style from './Separator.module.scss';

interface IProps {
  margin?: boolean;
  color?: 'purple' | 'grey';
  className?: string;
}

export const Separator = ({
  margin = true,
  color = 'purple',
  className,
}: IProps): JSX.Element => (
  <div
    data-testid="separator"
    className={cn(
      style.separator,
      {
        [style.margin]: margin,
        [style.grey]: color === 'grey',
      },
      className
    )}
  />
);
