import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type SearchEntity =
  | 'playlists'
  | 'persons'
  | 'channels'
  | 'authors'
  | 'readers'
  | 'translators'
  | 'owner'
  | 'owners'
  | 'rate'
  | 'rates'
  | 'counterparties'
  | 'clips'
  | 'feeds'
  | 'feed_groups'
  | 'feed-groups'
  | 'rubrics'
  | 'feeds_top'
  | 'feeds_bottom'
  | 'url'
  | 'tariff'
  | 'topics'
  | 'tags'
  | 'book_series'
  | 'book-series'
  | 'collection_playlists'
  | 'collection_clips'
  | 'themes'
  | 'contracts'
  | 'keyword' // ??
  | 'supermix' // ??
  | 'playlist_ids';

interface IPayload {
  searchEntity: SearchEntity;
  value: string;
}

export type Entity = {
  [key in SearchEntity]: string;
};

export interface ISelectState {
  isLoading: boolean;
  inputValueInSelect: Entity | null;
}

const initialState: ISelectState = {
  isLoading: false,
  inputValueInSelect: null,
};

export const selectSlice = createSlice({
  name: 'select',
  initialState,
  reducers: {
    setLoading(state, action) {
      state.isLoading = action.payload;
    },

    setSelectedValue(state, { payload }: PayloadAction<IPayload>) {
      state.inputValueInSelect = {
        ...state.inputValueInSelect,
        [payload.searchEntity]: payload.value,
      };
    },
  },
});

export const { setLoading, setSelectedValue } = selectSlice.actions;
export const selectSliceReducer = selectSlice.reducer;
