import React, { useState } from 'react';

import { useAppDispatch } from '../../../../hooks';
import {
  CatalogType,
  ICatalogMiddle,
  deleteElement,
  editName,
} from '../../../../store/slices/catalogSlice';
import { HangingInput, MenuEditOrOpen } from '../../../../widgets';
import { Router } from '../../../Router/routerEnum';

import style from './style.module.scss';

interface ITheme extends ICatalogMiddle {
  parentId: number;
}

interface IProps {
  item: ITheme;
  typeCatalog: CatalogType;
  onEdit?: (id: number, name: string) => void;
  onDelete?: (id: number) => void;
}

export const CardMiddleCategoryTheme = ({
  item,
  typeCatalog,
  onEdit,
  onDelete,
}: IProps): JSX.Element => {
  const [value, setValue] = useState<string>(item.name || '');
  const [showTitleInput, setShowTitleInput] = useState(false);
  const [showTitleMenu, setShowTitleMenu] = useState(false);
  const dispatch = useAppDispatch();

  const editTitle = (text: string) => {
    if (typeCatalog === 'theme') {
      onEdit(item.id, text);
    } else {
      dispatch(
        editName({
          type: typeCatalog,
          id: item.id,
          name: text,
        })
      );
    }
    setShowTitleInput(false);
  };

  const handleEditTitle = () => {
    setShowTitleInput(true);
    setValue(item?.name);
    setShowTitleMenu(false);
  };

  const handleDeleteTheme = () => {
    if (typeCatalog === 'theme') {
      onDelete(item.id);
    } else {
      dispatch(
        deleteElement({
          type: typeCatalog,
          parentId: item.parentId,
          id: item.id,
        })
      );
    }
    setShowTitleMenu(false);
  };

  return (
    <li className={style.listItemTheme}>
      <h4
        className={style.listItemThemeName}
        onClick={() => setShowTitleMenu(true)}
      >
        {item?.name}
      </h4>
      {showTitleMenu && (
        <MenuEditOrOpen
          dropdown={showTitleMenu}
          setDropdown={setShowTitleMenu}
          handleEdit={handleEditTitle}
          handleDelete={handleDeleteTheme}
          titleEdit="название"
          titleDelete="подтему"
          link={`/${typeCatalog === 'theme' ? Router.Theme : Router.Tag}/${
            Router.Edit
          }/${item?.id}`}
          className={style.contextMenu}
        />
      )}

      {showTitleInput && (
        <HangingInput
          value={value}
          position="left"
          placeholder="Введите название"
          showInput={showTitleInput}
          onChange={(e) => {
            setValue(e.target.value);
          }}
          onKeyDown={() => editTitle(value)}
          onBlur={() => {
            editTitle(value);
          }}
          className={style.inputWidth}
        />
      )}
    </li>
  );
};
