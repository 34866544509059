import { configureStore } from '@reduxjs/toolkit';

import { rootReducer } from './rootReduser';
import { mainAuthApi } from '../app/api/auth/config';
import { mainApi } from '../app/api/services/config';

const middleware = (getDefaultMiddleware) =>
  getDefaultMiddleware({}).concat([mainApi.middleware, mainAuthApi.middleware]);

export const store = configureStore({
  reducer: rootReducer,
  middleware,
  devTools: process.env.NODE_ENV !== 'production',
});
export type AppDispatch = typeof store.dispatch;
export type AppStore = typeof store;
export type RootState = ReturnType<typeof rootReducer>;
