import { lazy, Suspense } from 'react';

import { Loader } from '../../../widgets';

const TechnicalHealth = lazy(() => import('./TechnicalHealth'));

export const TechnicalHealth_Lazy = (): JSX.Element => (
  <>
    <Suspense fallback={<Loader />}>
      <TechnicalHealth />
    </Suspense>
  </>
);
