export const elementType = {
  name: 'playlists',
  id: 1,
};

export const AutoSectionFields = {
  Name: 'name',
  Element_type: 'element_type',
  Feed_type: 'feed_type',
  Algorithm: 'algorithm',
  Is_active: 'is_active',
  Elements: 'elements',
};

export interface IAutoSectionErrors {
  name: boolean;
  element_type: boolean;
  feed_type: boolean;
  algorithm: boolean;
  elements: number[];
}

export interface IElements {
  entity_id: number;
  end_date: string;
  screen_position: number;
  screen_element_position: number;
}

export interface IAutoSectionValues {
  name: string;
  element_type: string;
  feed_type: string;
  algorithm: string;
  is_active: boolean;
  elements: {
    screen1: IElements[];
    screen2: IElements[];
    screen3: IElements[];
    screen4: IElements[];
  };
}

export const validateCreateAutoSection = (
  values: IAutoSectionValues
): object => {
  const errors = {} as IAutoSectionErrors;

  let errElements = [];

  if (!values.name) {
    errors.name = true;
  }

  if (!values.element_type) {
    errors.element_type = true;
  }

  if (!values.feed_type) {
    errors.feed_type = true;
  }

  if (!values.algorithm) {
    errors.algorithm = true;
  }

  Object.entries(values.elements).forEach(([, value]) => {
    value.forEach((item) => {
      if (item.entity_id && !item.end_date) {
        errElements = [...errElements, item.entity_id];
      }
    });
  });

  if (errElements.length) {
    errors.elements = errElements;
  }

  return errors;
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const getElements = (values: {
  screen1: IElements[];
  screen2: IElements[];
  screen3: IElements[];
  screen4: IElements[];
}) => {
  const elements = [];

  Object.entries(values).forEach(([, value]) => {
    value.forEach((item) => {
      if (item.entity_id) {
        elements.push(item);
      }
    });
  });

  return elements;
};
