interface IProps {
  fill?: string;
}
export const Delete = ({ fill = '#E33D27' }: IProps): JSX.Element => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    fill="none"
    viewBox="0 0 16 16"
  >
    <path
      fill={fill}
      d="M6.421 2.733v.197H9.58v-.197a1.579 1.579 0 00-3.158 0zm-.987.197v-.197a2.566 2.566 0 115.132 0v.197h4.44a.494.494 0 010 .987h-1.15l-.79 9.417a2.862 2.862 0 01-2.852 2.622H5.786a2.862 2.862 0 01-2.851-2.622l-.79-9.417H.993a.493.493 0 010-.987h4.441zM3.918 13.25a1.875 1.875 0 001.868 1.718h4.428a1.876 1.876 0 001.868-1.718l.783-9.334h-9.73l.783 9.334zm3.095-6.67a.493.493 0 10-.987 0v5.724a.493.493 0 10.987 0V6.58zm2.467-.493c.273 0 .494.22.494.493v5.724a.493.493 0 11-.987 0V6.58c0-.272.22-.493.493-.493z"
    ></path>
  </svg>
);
