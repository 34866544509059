import { useFormikContext } from 'formik';
import React from 'react';

import { Switch } from '../../../../../widgets';
import { fieldSwitch } from '../../../helpers';
import { IPropsFilterAndColumn } from '../../../types';
import { AttributeItemWrapper } from '../../AttributeItemWrapper';

import style from './SwitchInFilter.module.scss';

export const SwitchInFilter = ({
  filter,
  columnType,
}: IPropsFilterAndColumn): JSX.Element => {
  const { values } = useFormikContext();

  return (
    <AttributeItemWrapper
      mode="withSwitch"
      filter={filter}
      columnType={columnType}
    >
      <Switch
        label={fieldSwitch[filter]}
        field={`${columnType}.${filter}`}
        value={values?.[columnType]?.[filter]}
        className={style.switch}
      />
    </AttributeItemWrapper>
  );
};
