export const getTheAudioDuration = async (file: File): Promise<number> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.readAsArrayBuffer(file);

    reader.onloadend = async (e) => {
      const ctx = new AudioContext();
      const audioArrayBuffer = e.target.result;
      await ctx.decodeAudioData(
        audioArrayBuffer as ArrayBuffer,
        (data) => {
          resolve(Math.round(data.duration));
        },
        (error) => reject(error)
      );
    };
  });
