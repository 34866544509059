import { lazy, Suspense } from 'react';

import { Loader } from '../../../../../widgets';

const ReportForCounterparty = lazy(() => import('./ReportForCounterparty'));

export const ReportForCounterparty_Lazy = (): JSX.Element => (
  <>
    <Suspense fallback={<Loader />}>
      <ReportForCounterparty />
    </Suspense>
  </>
);
