import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

import { FeedGroup } from '../../../../../../../../../app/api/services/feedGroups/types';
import { Feed } from '../../../../../../../../../app/api/services/feeds/types';
import { useAppDispatch } from '../../../../../../../../../hooks';
import { deleteRubric } from '../../../../../../../../../store/slices/rubricSlice';
import { MenuOption } from '../../../../../../../../../widgets';
import { EditRubricFrame } from '../../../AddRubricFrame';
import { FlatFeedGroupView } from '../../../FlatFeedGroupView';
import { FlatFeedViewInRubric } from '../../../FlatFeedViewInRubric';
import { Element } from 'app/api/services/rubrics/types';
import { DndIcon } from 'shared/icons';

import styles from '../../ui/RubricFrameElements.module.scss';

interface IProps {
  id: number;
  element: Element;
}

export const SortableItem = ({ id, element }: IProps): JSX.Element => {
  const dispatch = useAppDispatch();

  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition: transition || undefined,
  };

  return (
    <>
      {element?.entity_type === 'feed' ? (
        <>
          <div
            style={style}
            ref={setNodeRef}
            className={styles.inputWrapper}
            key={element.position}
          >
            <div className={styles.dnd} {...attributes} {...listeners}>
              <DndIcon />
            </div>
            <FlatFeedViewInRubric
              title="Лента:"
              feed={element.entity as Feed}
              className={styles.input}
            />

            <MenuOption
              onDelete={() => dispatch(deleteRubric(element.position))}
              position="right"
              isTransparent={true}
            >
              <EditRubricFrame elementToEdit={element} position="right" />
            </MenuOption>
          </div>
        </>
      ) : (
        <div
          style={style}
          ref={setNodeRef}
          key={element.position}
          className={styles.inputWrapper}
        >
          <div className={styles.dnd} {...attributes} {...listeners}>
            <DndIcon />
          </div>
          <FlatFeedGroupView
            title="Лодочка:"
            feedGroup={element.entity as FeedGroup}
            className={styles.input}
          />

          <MenuOption
            onDelete={() => dispatch(deleteRubric(element.position))}
            position="right"
            isTransparent={true}
          >
            <EditRubricFrame elementToEdit={element} position="right" />
          </MenuOption>
        </div>
      )}
    </>
  );
};
