import { useCallback } from 'react';
import { GroupBase } from 'react-select';
import { AsyncPaginate, LoadOptions } from 'react-select-async-paginate';

import { useAppDispatch } from '../../../hooks';
import {
  SearchEntity,
  setSelectedValue,
} from '../../../store/slices/selectSlice';
import './SelectWithOptionsAndPagination.scss';
import { IEntityMerging } from 'app/global/types';

interface IProps {
  searchEntity: SearchEntity;
  onChange?: (options) => void;
  menuIsOpen?: boolean;
  // TODO: сделать нормальные типы?
  loadOptions: LoadOptions<
    { name: string },
    GroupBase<{ name: string }>,
    { offset?: number; offsetSearch?: number }
  >;
  defaultOptions: IEntityMerging[];
  additional?: { offset?: number; offsetSearch?: number };
}

export const SelectWithOptionsAndPagination = ({
  searchEntity,
  onChange,
  menuIsOpen,
  loadOptions,
  defaultOptions,
  additional,
}: IProps): JSX.Element => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const dispatch = useAppDispatch();

  const onChangeHandle = useCallback(
    (value) => {
      if (!value) {
        return;
      }

      onChange(value);
      dispatch(setSelectedValue({ searchEntity, value: '' }));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [onChange]
  );

  return (
    <AsyncPaginate
      placeholder="Поиск"
      classNamePrefix="select"
      defaultOptions={defaultOptions}
      loadOptions={loadOptions}
      menuIsOpen={menuIsOpen}
      getOptionValue={(option: { name: string }) => option.name}
      getOptionLabel={(option: { name: string }) => option.name}
      onChange={(value) => onChangeHandle(value)}
      isSearchable
      isClearable
      openMenuOnFocus
      autoFocus
      defaultMenuIsOpen
      loadingMessage={() => 'Поиск'}
      noOptionsMessage={() => 'Ничего не найдено'}
      additional={additional}
      components={{
        IndicatorSeparator: () => null,
        DropdownIndicator: () => null,
        ClearIndicator: () => null,
        DownChevron: () => null,
      }}
    />
  );
};
