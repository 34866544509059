export const Copy = (): JSX.Element => (
  <svg
    width="13"
    height="13"
    viewBox="0 0 13 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.52941 13C1.10882 13 0.748648 12.8726 0.448883 12.6178C0.149119 12.363 -0.000508506 12.0571 1.29831e-06 11.7V2.6H1.52941V11.7H9.94118V13H1.52941ZM4.58824 10.4C4.16765 10.4 3.80747 10.2726 3.50771 10.0178C3.20794 9.763 3.05831 9.45707 3.05882 9.1V1.3C3.05882 0.942501 3.20871 0.636351 3.50847 0.381551C3.80824 0.126751 4.16816 -0.00043223 4.58824 1.10357e-06H11.4706C11.8912 1.10357e-06 12.2514 0.127401 12.5511 0.382201C12.8509 0.637001 13.0005 0.942934 13 1.3V9.1C13 9.4575 12.8501 9.76365 12.5504 10.0184C12.2506 10.2732 11.8907 10.4004 11.4706 10.4H4.58824ZM4.58824 9.1H11.4706V1.3H4.58824V9.1Z"
      fill="#6860F1"
    />
  </svg>
);
