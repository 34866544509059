import cn from 'classnames';
import { useFormikContext } from 'formik';
import React, { useState } from 'react';

import {
  useGetOwnerLevelsQuery,
  useGetOwnerTypesQuery,
} from '../../../../../app/api/services/owners/owners';
import { useGetContentTypeQuery } from '../../../../../app/api/services/playlists/playlists';
import { ArrowDown, Check } from '../../../../../shared/icons';
import { ClickShowContainer } from '../../../../../widgets';
import { fieldWithoutSearch } from '../../../helpers';
import { IPropsFilterAndColumn } from '../../../types';
import { AttributeItemWrapper } from '../../AttributeItemWrapper';

import style from './SelectPresent.module.scss';

export const SelectPresent = ({
  massAction,
  columnType,
}: IPropsFilterAndColumn): JSX.Element => {
  const { setFieldValue } = useFormikContext();

  //TODO добавить необходимые селекты для недостающих типов

  const { data: ownerTypesOptions } = useGetOwnerTypesQuery(undefined, {
    skip: massAction !== 'type_id',
  });

  const { data: ownerLevelsOptions } = useGetOwnerLevelsQuery(undefined, {
    skip: massAction !== 'level_id',
  });

  const { data: contentType } = useGetContentTypeQuery(undefined, {
    skip: massAction !== 'content_type_id',
  });

  const options =
    ownerTypesOptions?.response ||
    ownerLevelsOptions?.response ||
    contentType?.response ||
    [];

  const [dropdown, setDropdown] = useState(false);
  const [currentOption, setCurrentOption] = useState(null);

  const onClickHandler = (event) => {
    event.preventDefault();
    setDropdown((prevState) => !prevState);
  };

  return (
    <AttributeItemWrapper
      title={fieldWithoutSearch[massAction]}
      massAction={massAction}
      columnType={columnType}
    >
      <ClickShowContainer
        dropdown={dropdown}
        setDropdown={setDropdown}
        className={cn(style.container, { [style.active]: dropdown })}
        onClick={(event) => onClickHandler(event)}
      >
        <span
          className={cn(style.placeholder, {
            [style.option_choose]: !!currentOption,
          })}
        >
          {!currentOption ? 'Выберите' : currentOption?.name}
        </span>
        <ArrowDown color="#434343" />

        <div className={cn(dropdown ? style.list : style.hide)}>
          {options?.map((option) => (
            <div
              key={option.id}
              className={style.option}
              onClick={() => {
                setCurrentOption(option);
                setFieldValue(`${columnType}.${massAction}`, option.id);
              }}
            >
              {option?.name}

              {currentOption?.id === option?.id && (
                <Check height="18px" stroke="#434343" width="24px" />
              )}
            </div>
          ))}
        </div>
      </ClickShowContainer>
    </AttributeItemWrapper>
  );
};
