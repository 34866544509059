import cn from 'classnames';
import React, { PropsWithChildren } from 'react';

import { Delete } from '../../../shared/icons';

import style from './DeleteConfirmationModal.module.scss';

interface IProps {
  active: boolean;
  title?: string;
  onSubmit: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  onClose: () => void;
  className?: string;
  classNameBody?: string;
  classNameFooter?: string;
  type?: 'delete' | 'archive' | 'reset';
}

export const DeleteConfirmationModal = ({
  active,
  title,
  onSubmit,
  onClose,
  className,
  classNameBody,
  classNameFooter,
  children,
  type = 'delete',
}: PropsWithChildren<IProps>): JSX.Element => {
  if (!active) {
    return null;
  }

  return (
    <div className={cn(style.modal, className)}>
      <div className={style.modalContent}>
        {title && (
          <div className={style.modalIcon}>
            <Delete />
            <div className={style.modalHeader}>{title}</div>
          </div>
        )}
        <div className={cn(style.modalBody, classNameBody)}>{children}</div>
        <div className={cn(style.modalFooter, classNameFooter)}>
          <button onClick={onClose} className={style.button1}>
            Отмена
          </button>
          <button onClick={onSubmit} className={style.button2}>
            {type === 'delete' && `Удалить`}
            {type === 'archive' && 'Архивировать'}
            {type === 'reset' && 'Сбросить'}
          </button>
        </div>
      </div>
    </div>
  );
};

export default DeleteConfirmationModal;
