import { Suspense, lazy } from 'react';

import { Loader } from '../../../../../widgets';

const Feed = lazy(() => import('./Feed'));

export const Feed_Lazy = (): JSX.Element => (
  <>
    <Suspense fallback={<Loader />}>
      <Feed />
    </Suspense>
  </>
);
