import { ReactNode, useEffect, useRef } from 'react';

interface IProps {
  children: ReactNode;
  dropdown: boolean;
  className?: string;
  optionForNotHide?: boolean;
  setDropdown: (value: boolean) => void;
  onMouseOver?: () => void;
  onMouseLeave?: () => void;
  onClick?: (e: unknown) => void;
}

export const ClickShowContainer = ({
  dropdown,
  children,
  optionForNotHide,
  className,
  setDropdown,
  onMouseOver,
  onMouseLeave,
  onClick,
}: IProps): JSX.Element => {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (optionForNotHide) {
      return;
    }

    const checkIfClickedOutside = (e: Event) => {
      if (dropdown && ref.current && !ref.current.contains(e.target as Node)) {
        setDropdown(false);
      }
    };
    document.addEventListener('mousedown', checkIfClickedOutside);

    return () => {
      document.removeEventListener('mousedown', checkIfClickedOutside);
    };
  }, [dropdown, optionForNotHide, setDropdown]);

  return (
    <div
      className={className}
      ref={ref}
      onMouseOver={onMouseOver}
      onMouseLeave={onMouseLeave}
      onClick={onClick}
    >
      {children}
    </div>
  );
};
