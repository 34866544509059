import cn from 'classnames';
import React, { memo, ReactNode, useEffect, useRef } from 'react';

import { ArrowUp } from '../../../shared/icons';

import style from './ContentContainer.module.scss';

interface IProps {
  children: ReactNode;
  className?: string;
}

export const ContentContainer = memo(
  ({ children, className }: IProps): JSX.Element => {
    const divRef = useRef<HTMLDivElement | null>(null);
    const buttonRef = useRef<HTMLButtonElement | null>(null);

    useEffect(() => {
      divRef.current.addEventListener('scroll', () => {
        divRef.current.scrollTop >= 300
          ? buttonRef.current.classList.add(style.showButton)
          : buttonRef.current.classList.remove(style.showButton);
      });
    }, []);

    const executeScroll = () =>
      divRef.current.scrollTo({
        top: 0,
        behavior: 'smooth',
      });

    return (
      <div className={cn(style.container, className)} ref={divRef}>
        {children}

        <button
          onClick={executeScroll}
          className={style.scrollToTopButton}
          ref={buttonRef}
        >
          <ArrowUp />
        </button>
      </div>
    );
  }
);
