import React from 'react';
import { Link } from 'react-router-dom';

import { Feed } from '../../../../../app/api/services/feeds/types';
import { Edit } from '../../../../../shared/icons';
import { CopyButton, TextWithTooltip } from '../../../../../widgets';
import { Router } from '../../../../Router/routerEnum';

import style from './HeaderBlock.module.scss';

interface IProps {
  title: string;
  feed: Feed;
}

export const HeaderBlock = ({ title, feed }: IProps): JSX.Element => (
  <div className={style.headerFeed}>
    <div className={style.titleFeed}>
      <h3 className={style.title}>{`${title}:`}</h3>
      <div className={style.linkToFeed}>
        <Link
          to={`/${Router.RelatedFeed}/${Router.Edit}/${feed?.id}`}
          className={style.link}
          target="_blank"
        >
          {feed?.name}
          <button className={style.button} type="button">
            <Edit />
          </button>
        </Link>
      </div>
    </div>
    <div className={style.titleFeed}>
      <h3 className={style.title}>UUID ленты:</h3>
      <TextWithTooltip text={feed?.uuid} className={style.uuidNumber} />
      <CopyButton text={feed?.uuid} />
    </div>
  </div>
);
