import React from 'react';

interface ICheck {
  width: string;
  height: string;
  stroke: string;
}

export const Check = (props: ICheck): JSX.Element => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width}
    height={props.height}
    fill="none"
    viewBox="0 0 12 12"
  >
    <path
      stroke={props.stroke}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M2 6l3 3 5-6"
    ></path>
  </svg>
);
