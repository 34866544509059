/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import * as _ from 'lodash';
import * as yup from 'yup';

import { IBody } from '../../../../../app/api/services/authors/types';
import { IBody as IBodyBookSeries } from '../../../../../app/api/services/bookSeries/types';
import { IBody as IBodyChannel } from '../../../../../app/api/services/channels/types';
import { IBodyCreate as IBodyCollection } from '../../../../../app/api/services/collections/types';
import { ICreateCompilations } from '../../../../../app/api/services/compilations/types';
import { IPayloadEditContract } from '../../../../../app/api/services/contracts/types';
import { Payload as IBodyOwners } from '../../../../../app/api/services/owners/types';
import { IBody as IBodyReader } from '../../../../../app/api/services/readers/types';
import { ICreateBaseSection } from '../../../../../app/api/services/sections/baseSectionsTypes';
import { Payload as IBodyTranslator } from '../../../../../app/api/services/translators/types';

export enum AuthorFields {
  Name = 'name',
  Title = 'title',
  Description = 'description',
  Img_id = 'img_id',
  Images = 'images',
  Primary_image = 'primary_image_url',
  Primary_banner = 'primary_banner_url',
  Secondary_image = 'secondary_image_url',
  Secondary_banner = 'secondary_banner_url',
  Is_active = 'is_active',
  Weight = 'weight',
  UUID = 'uuid',
  Created_at = 'created_at',
  Updated_at = 'updated_at',
  Playlists_count = 'playlists_count',
}

export const titleListRelationFeed = [
  'Плейлисты с этим автором',
  'Подборка плейлистов',
  'Издатели с этим автором',
  'Редакторская лента',
];

type TValues =
  | IBody
  | IBodyReader
  | IBodyTranslator
  | IBodyOwners
  | IBodyChannel
  | IBodyCollection
  | IBodyBookSeries
  | IPayloadEditContract
  | ICreateCompilations
  | ICreateBaseSection;

export const createObjectToSend = (
  diffKeys: string[],
  oldValues: TValues,
  newValues: TValues
) => {
  const diffObject = {} as IBody;

  diffKeys.forEach((old_value) =>
    Object.keys(newValues).some((new_value) =>
      old_value === new_value
        ? (diffObject[old_value] = newValues[new_value])
        : null
    )
  );

  if (!_.isEmpty(newValues.images)) {
    diffObject.images = newValues.images;
  }

  // @ts-expect-error temp TODO: убрать когда починят бэк
  if (oldValues.elements) {
    // @ts-expect-error temp
    diffObject.elements = newValues.elements;
  }

  return diffObject;
};

export const validateAuthorFields = yup.object().shape({
  name: yup.string().min(1).required(),
  img_id: yup.number().required(),
  primary_image_url: yup.string().required(),
});
