import cn from 'classnames';
import { Field, useFormikContext } from 'formik';

import { ICreateBaseSection } from '../../../../../../../../app/api/services/sections/baseSectionsTypes';
import {
  ContainerWrapper,
  ImgUploader,
  Input,
  Separator,
} from '../../../../../../../../widgets';
import { IValidateSection, SectionFields } from '../../../SectionFields';

import style from './Description.module.scss';

export const Description = (): JSX.Element => {
  const { setFieldValue, values } = useFormikContext<ICreateBaseSection>();
  const { errors } = useFormikContext<IValidateSection>();

  return (
    <ContainerWrapper title="Описание">
      <div className={cn(style.input, style.inputWrapper)}>
        <Field
          name={SectionFields.Name}
          component={Input}
          isError={!!errors?.name}
        />
      </div>

      <Separator color="grey" />

      <p className={style.imageTitle}>Картинки:</p>
      <div className={style.imageContainer}>
        <div className={style.imagePadding}>
          <ImgUploader
            label="Картинка 1 (1х1)"
            mode="primary_image"
            imageUrl={values.primary_image_url}
            onChange={(image) => {
              setFieldValue(
                SectionFields.Primary_image,
                image?.url ? image?.url : ''
              );
              setFieldValue(
                `images.${SectionFields.Primary_image_id}`,
                image?.id ? image?.id : ''
              );
            }}
          />
        </div>

        <div className={style.imagePadding}>
          <ImgUploader
            label="Картинка 2 (1х1)"
            mode="secondary_image"
            imageUrl={values.secondary_image_url}
            onChange={(image) => {
              setFieldValue(
                SectionFields.Secondary_image,
                image?.url ? image?.url : ''
              );
              setFieldValue(
                `images.${SectionFields.Secondary_image_id}`,
                image?.id ? image?.id : ''
              );
            }}
          />
        </div>

        <div className={style.imagePadding}>
          <ImgUploader
            label="Вытянутый баннер"
            mode="primary_banner"
            imageUrl={values.primary_banner_url}
            onChange={(image) => {
              setFieldValue(
                SectionFields.Primary_banner,
                image?.url ? image?.url : ''
              );
              setFieldValue(
                `images.${SectionFields.Primary_banner_id}`,
                image?.id ? image?.id : ''
              );
            }}
          />
        </div>

        <div className={style.imagePadding}>
          <ImgUploader
            label="Баннер web"
            mode="secondary_banner"
            imageUrl={values.secondary_banner_url}
            onChange={(image) => {
              setFieldValue(
                SectionFields.Secondary_banner,
                image?.url ? image?.url : ''
              );
              setFieldValue(
                `images.${SectionFields.Secondary_banner_id}`,
                image?.id ? image?.id : ''
              );
            }}
          />
        </div>
      </div>
    </ContainerWrapper>
  );
};
