import { useFormikContext } from 'formik';
import React from 'react';

import { useAppDispatch } from '../../../../../hooks';
import { changeValueCommands } from '../../../../../store/slices/massActionsSlice';
import { ImgUploader } from '../../../../../widgets';
import { fieldText } from '../../../helpers';
import { IPropsFilterAndColumn } from '../../../types';
import { AttributeItemWrapper } from '../../AttributeItemWrapper';

import style from './InputImg.module.scss';

export const InputImg = ({
  massAction,
  columnType,
}: IPropsFilterAndColumn): JSX.Element => {
  const dispatch = useAppDispatch();
  const { setFieldValue } = useFormikContext();

  const handleChange = (e) => {
    setFieldValue(`${columnType}.${massAction}`, e.id);
    dispatch(changeValueCommands({ columnType, massAction, value: e.id }));
  };

  return (
    <AttributeItemWrapper
      title={fieldText[massAction]}
      massAction={massAction}
      columnType={columnType}
      classNameContent={style.container}
    >
      <ImgUploader
        mode="primary_image"
        label="Картинка"
        onChange={(image) => {
          handleChange(image);
        }}
        className={style.imgBlock}
      />
    </AttributeItemWrapper>
  );
};
