import { Suspense, lazy } from 'react';

import { Loader } from '../../../../../widgets';

const BookSeries = lazy(() => import('./BookSeries'));

export const BookSeries_Lazy = (): JSX.Element => (
  <>
    <Suspense fallback={<Loader />}>
      <BookSeries />
    </Suspense>
  </>
);
