import format from 'date-fns/format';
import React, { useEffect } from 'react';

import { getOwnersForSelect } from '../../../helpers';
import { AppleBank, TBank } from '../BanksComponents';
import { useGetOwnerByConditionQuery } from 'app/api/services/owners/owners';
import { Owner } from 'app/api/services/owners/types';
import {
  useLazyGetAppleBankReportQuery,
  useLazyGetTBankReportQuery,
} from 'app/api/services/reports';
import { useDownloadFile } from 'hooks/useDownloadFile';
import { errorToast } from 'utils';
import { Loader } from 'widgets';

import style from './Banks.module.scss';
interface IValuesTBank {
  end_date: Date;
  start_date: Date;
  owner: Owner;
}

interface IValuesAppleBank {
  end_month: Date;
  start_month: Date;
  owner: Owner;
}

const getTBankValuesToSend = (values: IValuesTBank) => ({
  start_date: format(values.start_date, 'yyyy-MM-dd'),
  end_date: format(values.end_date, 'yyyy-MM-dd'),
  owner_id: values.owner.id,
});

const getAppleBankValuesToSend = (values: IValuesAppleBank) => ({
  start_month: format(values.start_month, 'yyyy-MM'),
  end_month: format(values.end_month, 'yyyy-MM'),
  owner_id: values.owner.id,
});

export const Banks = (): JSX.Element => {
  const { data: owners } = useGetOwnerByConditionQuery({
    offset: 0,
    limit: 1000,
  });

  const [
    getAppleBankReport,
    {
      isLoading: isAppleBankLoading,
      isFetching: isAppleBankFetching,
      isError: IAppleBankError,
      data: appleReport,
    },
  ] = useLazyGetAppleBankReportQuery();

  const [
    getTBankReport,
    {
      isLoading: isTBankLoading,
      isFetching: isTBankFetching,
      isError: ITBankError,
      data: tbankReport,
    },
  ] = useLazyGetTBankReportQuery();

  const ownersOptions = getOwnersForSelect(owners?.response);

  const submitTBank = (values: IValuesTBank) => {
    getTBankReport(getTBankValuesToSend(values));
  };

  const submitAppleBank = (values: IValuesAppleBank) => {
    getAppleBankReport(getAppleBankValuesToSend(values));
  };

  const [downloadFile, isFileDownloading] = useDownloadFile();

  const downloadHandler = (url: string) => {
    downloadFile(url, 'xlsx');
  };

  const isLoading =
    isTBankLoading ||
    isTBankFetching ||
    isAppleBankLoading ||
    isAppleBankFetching ||
    isFileDownloading;

  const isError = ITBankError || IAppleBankError;

  useEffect(() => {
    if (isError) {
      errorToast(isError, 'Что то пошло не так.');
    }
  }, [isError]);

  return (
    <div className={style.wrapper}>
      {isLoading && <Loader />}
      <div className={style.header}>
        <h4>Отчёты банков</h4>
      </div>
      <div className={style.content}>
        <TBank
          downloadHandler={() =>
            downloadHandler(tbankReport?.response?.download_url)
          }
          onSubmit={submitTBank}
          owners={ownersOptions}
        />
        <AppleBank
          downloadHandler={() =>
            downloadHandler(appleReport?.response?.download_url)
          }
          onSubmit={submitAppleBank}
          owners={ownersOptions}
        />
      </div>
    </div>
  );
};
