import cn from 'classnames';
import {
  Dispatch,
  ReactNode,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Virtuoso, VirtuosoHandle } from 'react-virtuoso';

import { useExpand } from '../../../hooks';
import { ArrowUp } from '../../../shared/icons';
import { Loader } from '../../Loader';

import style from './VirtuosoContainer.module.scss';

interface IVirtuosoContainer<T> {
  entity: T[];
  perPage: number;
  totalCount: number;
  responseLength: number;
  limit: number;
  isFetching: boolean;
  itemContent: (item: T) => ReactNode;
  setOff?: Dispatch<SetStateAction<number>>;
}

export const VirtuosoContainer = <T,>({
  entity = [],
  perPage,
  limit,
  responseLength,
  isFetching,
  itemContent,
  setOff,
}: IVirtuosoContainer<T>): JSX.Element => {
  const [showButton, setShowButton] = useState(false);

  const ref = useRef<VirtuosoHandle>(null);

  const handleScroll = (event) => {
    const scrollTop = event.currentTarget.scrollTop;
    setShowButton(scrollTop > 400);
  };

  useEffect(() => {
    setShowButton(false);
  }, []);

  const handleScrollTop = () => {
    ref.current.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const expand = useExpand();

  const Footer = () => isFetching && <Loader />;

  return (
    <div className={style.container}>
      <Virtuoso
        data={entity}
        className={cn({ [style.expand]: expand }, style.virtuoso)}
        ref={ref}
        onScroll={handleScroll}
        components={{ Footer }}
        endReached={() => {
          if (entity?.length < perPage && responseLength > 0) {
            setOff && setOff((_off) => _off + limit);
          }
        }}
        itemContent={(index) => {
          if (!entity) {
            return;
          }
          const item = entity[index];

          return itemContent(item);
        }}
      />

      {showButton && (
        <button onClick={handleScrollTop} className={style.scrollToTopButton}>
          <ArrowUp />
        </button>
      )}
    </div>
  );
};
