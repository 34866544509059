import cn from 'classnames';
import ru from 'date-fns/locale/ru';
import { useFormikContext } from 'formik';
import { useState } from 'react';
import DatePicker from 'react-datepicker';
import MaskedInput from 'react-text-mask';
import 'react-datepicker/dist/react-datepicker.css';

import { InputDate } from '../../../../../widgets';
import { fieldDate } from '../../../helpers';
import { IPropsFilterAndColumn } from '../../../types';
import { AttributeItemWrapper } from '../../AttributeItemWrapper';

import style from './InputDateFilter.module.scss';

export const InputDateFilter = ({
  massAction,
  columnType,
}: IPropsFilterAndColumn): JSX.Element => {
  const term = 'Лицензионный срок' || 'Срок действия договора';
  const { values, setFieldValue } = useFormikContext();
  const [startDate, setStartDate] = useState<Date>(
    values?.[columnType]?.[massAction] &&
      new Date(values?.[columnType]?.[massAction])
  );

  const handleChange = (date) => {
    const dateCut = new Date(date).toLocaleDateString('en-ca');
    setStartDate(date);
    setFieldValue(
      `${columnType}.${massAction}`,
      date ? `${dateCut}T00:00:00` : null
    );
  };

  return (
    <AttributeItemWrapper
      title={fieldDate[massAction]}
      massAction={massAction}
      columnType={columnType}
    >
      {fieldDate[massAction] !== term ? (
        <div className={cn(style.dateBlock)} data-testid="wrapper">
          <td style={{ position: 'relative', zIndex: '10' }}>
            <DatePicker
              placeholderText="2020.10.25"
              selected={startDate}
              onChange={handleChange}
              className={style.input}
              dateFormat="yyyy.MM.dd"
              locale={ru}
              customInput={
                <MaskedInput
                  type="text"
                  mask={[
                    /\d/,
                    /\d/,
                    /\d/,
                    /\d/,
                    '.',
                    /\d/,
                    /\d/,
                    '.',
                    /\d/,
                    /\d/,
                  ]}
                />
              }
            />
          </td>
        </div>
      ) : (
        //TODO когда будет такое поле посмотреть как отправлять на бэк и поправить,
        // возможно заменить просто на DatePicker
        <div className={style.dateRange}>
          <InputDate
            field={`${columnType}.${massAction}`}
            className={style.date}
          />
          &ndash;
          <InputDate
            field={`${columnType}.${massAction}`}
            className={style.date}
          />
        </div>
      )}
    </AttributeItemWrapper>
  );
};
