import cn from 'classnames';
import { useState } from 'react';

import { DeleteConfirmationModal } from '../../../../../../../../../../widgets/DeleteConfirmationModal';

import style from './ResetModal.module.scss';

interface IProps {
  handleReset: () => void;
}

export const ResetModal = ({ handleReset }: IProps): JSX.Element => {
  const [showReset, setShowReset] = useState<boolean>(false);

  const openModal = () => {
    setShowReset(true);
  };

  const closeModal = () => {
    setShowReset(false);
  };

  const onReset = (e) => {
    e.preventDefault();
    setShowReset(false);
    handleReset();
  };

  return (
    <div className={style.container}>
      <span className={style.reset} onClick={() => openModal()}>
        Сбросить закреп
      </span>
      <DeleteConfirmationModal
        type="reset"
        active={showReset}
        onSubmit={(e) => {
          onReset(e);
        }}
        onClose={closeModal}
        className={cn(style.resetModal)}
        classNameBody={style.resetBody}
        classNameFooter={style.resetFooter}
      >
        Сбросить этот закреп?
      </DeleteConfirmationModal>
    </div>
  );
};
