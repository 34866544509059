import cn from 'classnames';
import { useEffect, useState } from 'react';

import { IEntityMerging } from '../../../app/global/types';
import { Edit, Plus, Trash } from '../../../shared/icons';
import { SearchEntity } from '../../../store/slices/selectSlice';
import { ClickShowContainer } from '../../ClickShowContainer';
import { Img } from '../../Img';
import { SelectWithOptions } from '../../SelectWithOptions';

import style from './AddEntity.module.scss';

interface IProps {
  searchEntity: SearchEntity;
  label?: string;
  mode: 'withTrash' | 'withEdit';
  position?: 'right' | 'left';
  showCurrentOptionName?: boolean;
  options: IEntityMerging[] | { id: number; name: string }[];
  withUUID?: boolean;
  items?: IEntityMerging[];
  className?: string;
  onChange: (item) => void;
}

export const AddEntity = ({
  searchEntity,
  label,
  position,
  showCurrentOptionName = true,
  onChange,
  options,
  withUUID,
  mode,
  items,
  className,
}: IProps): JSX.Element => {
  const [show, setShow] = useState<boolean>(false);
  const [showPlusButton, setShowPlusButton] = useState<boolean>(true);

  const [allSelectedOptions, setAllSelectedOptions] = useState(
    items?.[0] ? items : []
  );
  const handleTypeSelect = (option) => {
    setShow(false);
    const isInArray = !!allSelectedOptions.find(
      (item) => item?.id === option?.id
    );
    if (isInArray || option === null) {
      return;
    }

    setAllSelectedOptions([option]);
  };

  useEffect(() => {
    onChange(allSelectedOptions);

    if (mode === 'withEdit' && allSelectedOptions.length > 0) {
      setShowPlusButton(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allSelectedOptions, mode]);

  const clickHandle = (e) => {
    e.preventDefault();
    setShow((prevState) => !prevState);
  };

  const editClickHandle = (e) => {
    e.preventDefault();
    setShow(true);
  };

  return (
    <>
      <div className={cn(style.container, className)}>
        <span className={style.label}>{label}</span>

        <div className={style.buttonsWrapper}>
          {showPlusButton && (
            <button className={style.add} onClick={(e) => clickHandle(e)}>
              <Plus />
            </button>
          )}

          {mode === 'withTrash' ? (
            <button className={style.trash} onClick={(e) => e.preventDefault()}>
              <Trash />
            </button>
          ) : null}
        </div>

        {show && (
          <ClickShowContainer
            dropdown={show}
            setDropdown={setShow}
            className={cn(style.select, { [style.left]: position === 'left' })}
          >
            <SelectWithOptions
              searchEntity={searchEntity}
              options={options}
              onChange={handleTypeSelect}
              withUUID={withUUID}
            />
          </ClickShowContainer>
        )}

        {mode === 'withEdit' && allSelectedOptions.length > 0 ? (
          <div className={style.editWrapper}>
            {showCurrentOptionName && (
              <span className={style.editDesc}>
                {allSelectedOptions[0]?.name}
              </span>
            )}
            <button
              onClick={(e) => editClickHandle(e)}
              className={style.editButton}
            >
              <Edit />
            </button>
          </div>
        ) : null}
      </div>

      {mode === 'withTrash' ? (
        <div className={style.selectedOptions}>
          {allSelectedOptions.map((option) => (
            <div key={option.id} className={style.option}>
              <Img className={style.image} circle src={option?.image?.url} />
              <span className={style.name}>{option.name}</span>
            </div>
          ))}
        </div>
      ) : null}
    </>
  );
};
