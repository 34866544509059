import { Suspense, lazy } from 'react';

import { Loader } from '../../../../../widgets';

const Clip = lazy(() => import('./Clip'));

export const Clip_Lazy = (): JSX.Element => (
  <>
    <Suspense fallback={<Loader />}>
      <Clip />
    </Suspense>
  </>
);
