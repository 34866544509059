import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { useSearchParams } from 'react-router-dom';

import { TagsForCompilationsListHeader } from './TagsForCompilationsListHeader';
import { TagsForCompilationsListItem } from './TagsForCompilationsListItem';
import { mainApi } from '../../../../../../app/api/services/config';
import { useAppDispatch } from '../../../../../../hooks';
import { errorToast, getTitle } from '../../../../../../utils';
import {
  Loader,
  PaginationContainer,
  VirtuosoContainer,
} from '../../../../../../widgets';
import {
  useDeleteTagForCompilationMutationMutation,
  useGetAllTagsForCompilationsQuery,
} from 'app/api/services/tadForCompilations/tagForCompilations';
import { TagsCompilations } from 'app/api/services/tadForCompilations/types';

import style from '../../../PlaylistCompilation/ui/PlaylistCompilationList/PlaylistCompilationList.module.scss';

interface ITagsForCompilationsListProps {
  setTitle: Dispatch<SetStateAction<string>>;
}

const TagsForCompilationsList = ({
  setTitle,
}: ITagsForCompilationsListProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const [searchParams, setSearchParams] = useSearchParams();

  const currentPage = +searchParams.get('currentPage') || 1;
  const limit = +searchParams.get('limit') || 5;
  const pageSize = +searchParams.get('pageSize') || 20;

  const [selectedPage, setSelectedPage] = useState(currentPage);
  const [isToast, setIsToast] = useState(false);
  const [off, setOff] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [perPage] = useState(pageSize);
  const [query, setQuery] = useState({
    offset: off + perPage * (selectedPage - 1),
    limit,
  });

  const { data, isLoading, isError, isFetching } =
    useGetAllTagsForCompilationsQuery(
      { ...query },
      {
        refetchOnMountOrArgChange: true,
      }
    );

  const [
    deleteTagsForCompilation,
    { isLoading: isLoadingDelete, isError: isErrorDelete },
  ] = useDeleteTagForCompilationMutationMutation();

  useEffect(() => {
    setQuery({ offset: off + perPage * (selectedPage - 1), limit });
  }, [limit, off, perPage, selectedPage]);

  useEffect(() => {
    !isLoading && setTotalCount(data?.totalCount);
  }, [data, isLoading]);

  useEffect(() => {
    isError &&
      errorToast(
        isError,
        'Не удалось загрузить список тегов для подборок плейлистов. Обновите страницу',
        setIsToast
      );
  }, [isErrorDelete, isError]);

  const changePageHandle = useCallback(
    (page) => {
      setSelectedPage(page);
      if (selectedPage !== page) {
        setOff(0);
        setSearchParams((prevState) => {
          prevState.set('currentPage', page);
          prevState.set('pageSize', String(perPage));

          return prevState;
        });
        dispatch(mainApi.util.resetApiState());
      }
    },
    [dispatch, perPage, selectedPage, setSearchParams]
  );

  useEffect(() => {
    setTitle(getTitle(totalCount, ['тег', 'тега', 'тегов']));
  }, [totalCount, setTitle]);

  return (
    <>
      <div className={style.contentContainer}>
        <div className={style.content}>
          {/* заголовок таблицы */}
          <TagsForCompilationsListHeader />

          {/* content */}
          {isLoading && !isToast ? (
            <Loader />
          ) : (
            !isToast && (
              <VirtuosoContainer
                entity={data?.response as TagsCompilations[]}
                perPage={pageSize}
                totalCount={totalCount}
                limit={query.limit}
                setOff={setOff}
                responseLength={data?.response.length}
                isFetching={isFetching}
                itemContent={(tagsForCompilations) => (
                  <TagsForCompilationsListItem
                    tagsForCompilations={tagsForCompilations}
                    editEntity="подборки плейлистов"
                  />
                )}
              />
            )
          )}
        </div>
      </div>

      <PaginationContainer
        pointBy={pageSize}
        total={totalCount}
        selectedPage={selectedPage}
        onChangePage={(page) => changePageHandle(page)}
        className={style.footer}
      />
    </>
  );
};

export default TagsForCompilationsList;
