import { Component, ErrorInfo, ReactNode, ReactFragment } from 'react';

import { BoundaryToast } from './BoundaryToast';

interface Props {
  children: ReactNode;
}

interface State {
  hasError: boolean;
}

class ErrorBoundary extends Component<Props, State> {
  public state: State = {
    hasError: false,
  };

  public static getDerivedStateFromError(): State {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    // eslint-disable-next-line no-console
    console.error('Uncaught error:', error, errorInfo);
  }

  public render(): string | number | boolean | ReactFragment | JSX.Element {
    if (this.state.hasError) {
      return <BoundaryToast error={this.state.hasError} />;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
