import React from 'react';
import { useCookies } from 'react-cookie';

import { COOKIE } from '../../../app/global/types';
import { useAppDispatch } from '../../../hooks';
import { setTokens } from '../../../store/slices/authSlice';

interface IAuthMiddleware {
  children: React.ReactElement;
}

const AuthMiddleware: React.FC<IAuthMiddleware> = ({ children }) => {
  const dispatch = useAppDispatch();
  const [cookies, setCookie, removeCookie] = useCookies([
    COOKIE.ACCESS_TOKEN,
    COOKIE.REFRESH_TOKEN,
    COOKIE.LOGGED_IN,
  ]);
  const { access_token, refresh_token } = cookies;

  if (access_token && refresh_token) {
    setCookie(COOKIE.LOGGED_IN, true);
    dispatch(
      setTokens({
        accessToken: access_token || null,
        refreshToken: refresh_token || null,
      })
    );
  } else {
    setCookie(COOKIE.LOGGED_IN, false);
    removeCookie(COOKIE.ACCESS_TOKEN);
    removeCookie(COOKIE.REFRESH_TOKEN);
  }

  return children;
};

export default AuthMiddleware;
