import { useFormikContext } from 'formik';
import React, { useEffect } from 'react';

import { placeholderValue } from './helpers';
import { Switch } from '../../../../../widgets';
import { fieldQuantitativeIndicator } from '../../../helpers';
import { IPropsFilterAndColumn } from '../../../types';
import { AttributeItemWrapper } from '../../AttributeItemWrapper';

import style from './InputNumberOrRange.module.scss';

export const InputNumberOrRange = ({
  filter,
  columnType,
}: IPropsFilterAndColumn): JSX.Element => {
  const { values, setFieldValue } = useFormikContext();
  const title = fieldQuantitativeIndicator[filter];

  const minValue = values?.[columnType]?.[filter]?.['gte'];
  const maxValue = values?.[columnType]?.[filter]?.['lte'];

  useEffect(() => {
    if (minValue !== maxValue) {
      setFieldValue(`active.${columnType}.${filter}`, true);
    }
  }, []);

  useEffect(() => {
    if (
      typeof values?.['active']?.[columnType]?.[filter] === 'boolean' &&
      !values?.['active']?.[columnType]?.[filter]
    ) {
      setFieldValue(`${columnType}.${filter}.lte`, minValue);
    }

    if (
      typeof values?.['active']?.[columnType]?.[filter] === 'boolean' &&
      values?.['active']?.[columnType]?.[filter] &&
      minValue === maxValue
    ) {
      setFieldValue(`${columnType}.${filter}.lte`, '');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [columnType, filter, minValue, setFieldValue, values]);

  return (
    <AttributeItemWrapper
      filter={filter}
      columnType={columnType}
      title={title}
      mode="withSwitch"
      className={style.attributeWrapper}
    >
      <div className={style.content}>
        {!values?.['active']?.[columnType]?.[filter] ? (
          <input
            type="number"
            step="1"
            min={0}
            value={minValue ? minValue : ''}
            placeholder={placeholderValue(title, false)[0]}
            className={style.input}
            onChange={(e) => {
              setFieldValue(
                `${columnType}.${filter}.gte`,
                e.target.value ? Number(e.target.value) : null
              );
              setFieldValue(
                `${columnType}.${filter}.lte`,
                e.target.value ? Number(e.target.value) : null
              );
            }}
          />
        ) : (
          <div className={style.rangeInput}>
            <input
              type="number"
              step="1"
              min={0}
              max={maxValue}
              value={minValue || minValue === 0 ? minValue : ''}
              placeholder={`от ${placeholderValue(title, true)[0]}`}
              className={style.input}
              onChange={(e) => {
                if (e.target.value > maxValue) {
                  setFieldValue(
                    `${columnType}.${filter}.gte`,
                    e.target.value ? Number(e.target.value) : null
                  );
                  setFieldValue(
                    `${columnType}.${filter}.lte`,
                    e.target.value ? Number(e.target.value) : null
                  );
                } else {
                  setFieldValue(
                    `${columnType}.${filter}.gte`,
                    e.target.value
                      ? Number(
                          e.target.value > maxValue ? maxValue : e.target.value
                        )
                      : null
                  );
                }
              }}
            />
            &mdash;
            <input
              type="number"
              step="1"
              min={minValue}
              value={maxValue ? maxValue : ''}
              placeholder={`до ${placeholderValue(title, true)[1]}`}
              className={style.input}
              onChange={(e) => {
                if (e.target.value < minValue) {
                  setFieldValue(
                    `${columnType}.${filter}.gte`,
                    e.target.value ? Number(e.target.value) : null
                  );
                  setFieldValue(
                    `${columnType}.${filter}.lte`,
                    e.target.value ? Number(e.target.value) : null
                  );
                } else {
                  setFieldValue(
                    `${columnType}.${filter}.lte`,
                    e.target.value
                      ? Number(
                          e.target.value < minValue ? minValue : e.target.value
                        )
                      : null
                  );
                }
              }}
            />
          </div>
        )}
        <Switch
          label={`Задать диапазон`}
          field={`active.${columnType}.${filter}`}
          value={values?.['active']?.[columnType]?.[filter]}
          className={style.switch}
        />
      </div>
    </AttributeItemWrapper>
  );
};
