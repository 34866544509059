export const REGISTRATION_PASSWORD = /^(?=.*[a-z])(?=.*\d).{5,}/;

export const ONE_CHAR_IN_LOWERCASE = /[a-z]+/;
export const ONE_CHAR_IN_UPPERCASE = /[A-Z]+/;
export const ONE_CHAR_DIGIT = /\d+/;
export const ONLY_EN_CHAR_AND_DIGIT = /^[A-Za-z\d-]+$/;

export const RU_CHAR = /[а-яА-Я]/g;

export const DIGIT = /\d/g;
