import { Error } from '../../../shared/icons';

import style from './ErrorAlert.module.scss';

interface IErrorAler {
  text: string;
}

export const ErrorAlert = ({ text }: IErrorAler): JSX.Element => (
  <div className={style.container}>
    <Error />
    {text}
  </div>
);
