import { Suspense, lazy } from 'react';

import { Loader } from '../../../../../../widgets';

const EditTag = lazy(() => import('./EditTag'));

export const EditTag_Lazy = (): JSX.Element => (
  <>
    <Suspense fallback={<Loader />}>
      <EditTag />
    </Suspense>
  </>
);
