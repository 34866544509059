import { lazy, Suspense } from 'react';

import { Loader } from 'widgets';

const Content = lazy(() => import('./Content'));

export const Content_Lazy = (): JSX.Element => (
  <>
    <Suspense fallback={<Loader />}>
      <Content />
    </Suspense>
  </>
);
