import { useFormikContext } from 'formik';
import { useCallback, useEffect, useRef } from 'react';
import AsyncSelect from 'react-select';

import { IBody, IFilters } from '../../../../../app/api/services/filters/types';
import { useAppDispatch } from '../../../../../hooks';
import { Close } from '../../../../../shared/icons';
import { addFilter } from '../../../../../store/slices/filterSlice';

import './SelectFilter.module.scss';

interface IProps {
  filters: IFilters;
  setShowInput?: (options: boolean) => void;
  type: 'includes' | 'excludes';
  isLoading: boolean;
}

interface IOptions {
  title: string;
  description: string;
  reference: string | undefined;
}

export const SelectFilter = ({
  filters,
  setShowInput,
  type,
  isLoading,
}: IProps): JSX.Element => {
  const { values, setFieldValue } = useFormikContext<IBody>();
  const dispatch = useAppDispatch();

  const options: IOptions[] = (
    filters?.properties ? Object.values(filters.properties) : []
  )
    ?.map(
      (i) =>
        (i = {
          title: i.title,
          description: i.description,
          reference: i.reference,
        })
    )
    ?.filter(
      (i) => !Object.keys(values[type] ?? {}).some((el) => el === i.title)
    );
  const selectRef = useRef(null);

  const onChangeHandle = useCallback(
    (value: IOptions) => {
      if (!value) {
        return;
      }

      setFieldValue(type, {
        ...values?.[type],
        [value.title]: value.title !== 'is_active' ? null : false,
      });
      dispatch(addFilter({ type, title: value.title }));
      value.reference !== undefined &&
        setFieldValue('references', {
          ...values?.['references'],
          [value.title]: value.reference,
        });
      setShowInput(false);
    },
    [setFieldValue, setShowInput, type, values, dispatch]
  );

  const ClearIndicator = () => (
    <div
      onClick={() => {
        selectRef.current.clearValue();
      }}
    >
      <Close />
    </div>
  );

  useEffect(() => {
    selectRef.current.clearValue();
  }, [onChangeHandle]);

  return (
    <AsyncSelect
      ref={selectRef}
      placeholder="Поиск"
      classNamePrefix="select"
      autoFocus
      openMenuOnFocus
      isClearable
      isSearchable
      options={options}
      getOptionValue={(val) => val.title}
      getOptionLabel={(val) => val.description}
      onChange={(value) => onChangeHandle(value)}
      noOptionsMessage={() => 'Ничего не найдено'}
      loadingMessage={() => 'Поиск'}
      isLoading={isLoading}
      components={{
        IndicatorSeparator: () => null,
        DropdownIndicator: () => <ClearIndicator />,
        ClearIndicator: () => null,
      }}
    />
  );
};
