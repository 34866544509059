import cn from 'classnames';

import { TextWithTooltip } from '../../TextWithTooltip';
import { ObligatoryField } from 'widgets/ObligatoryField';

import style from './TitleWithDescription.module.scss';

interface IProps {
  title: string;
  description: string | number;
  className?: string;
  withTooltip?: boolean;
  withTooltipAlt?: boolean;
  isObligatory?: boolean;
}

export const TitleWithDescription = ({
  description,
  title,
  className,
  withTooltip,
  withTooltipAlt,
  isObligatory = false,
}: IProps): JSX.Element => (
  <h3 className={cn(style.count, className)} data-testid="title">
    {isObligatory ? (
      <span className={style.label}>
        {title} {isObligatory && <ObligatoryField />}
      </span>
    ) : (
      <span>{title}</span>
    )}
    &nbsp;
    {withTooltipAlt ? (
      <TextWithTooltip
        text={description}
        className={style.entity}
        mode="normalAlt"
      />
    ) : withTooltip ? (
      <TextWithTooltip text={description} className={style.entity} />
    ) : (
      <span className={style.entity} data-testid="description">
        {description}
      </span>
    )}
  </h3>
);
