import cn from 'classnames';
import React from 'react';
import { Link } from 'react-router-dom';

import { ClickShowContainer } from '../../ClickShowContainer';

import style from './MenuEditOrOpen.module.scss';

interface IProps {
  dropdown: boolean;
  setDropdown: (value: boolean) => void;
  handleEdit: () => void;
  handleDelete: () => void;
  titleEdit: string;
  titleDelete: string;
  className?: string;
  link?: string;
}

export const MenuEditOrOpen = ({
  dropdown,
  setDropdown,
  handleEdit,
  handleDelete,
  titleEdit,
  titleDelete,
  link,
  className,
}: IProps): JSX.Element => (
  <ClickShowContainer dropdown={dropdown} setDropdown={setDropdown}>
    <div className={cn(style.frames, className)}>
      <button
        data-button="menu_edit_button"
        onClick={handleEdit}
        className={style.option_button}
        type="button"
      >
        {`Редактировать ${titleEdit}`}
      </button>
      {link && (
        <button
          data-button="menu_link_button"
          className={style.option_button}
          type="button"
        >
          <Link
            to={link}
            target="_blank"
            className={style.link}
            onClick={() => setDropdown(false)}
          >
            Перейти на страницу редактирования
          </Link>
        </button>
      )}
      <button
        data-button="menu_delete_button"
        onClick={handleDelete}
        className={style.option_button}
        type="button"
      >
        {`Удалить ${titleDelete}`}
      </button>
    </div>
  </ClickShowContainer>
);
