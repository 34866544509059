import { lazy, Suspense } from 'react';

import { Loader } from '../../../widgets';

const ThemeCatalog = lazy(() => import('./ThemeCatalog'));

export const ThemeCatalog_Lazy = (): JSX.Element => (
  <>
    <Suspense fallback={<Loader />}>
      <ThemeCatalog />
    </Suspense>
  </>
);
