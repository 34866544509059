import { Suspense, lazy } from 'react';

import { Loader } from '../../../widgets';

const PersonalPage = lazy(() => import('./PersonalPage'));

export const PersonalPage_Lazy = (): JSX.Element => (
  <>
    <Suspense fallback={<Loader />}>
      <PersonalPage />
    </Suspense>
  </>
);
