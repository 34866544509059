import { Suspense, lazy } from 'react';

import { Loader } from '../../../../../widgets';

const Owner = lazy(() => import('./Owner'));

export const Owner_Lazy = (): JSX.Element => (
  <>
    <Suspense fallback={<Loader />}>
      <Owner />
    </Suspense>
  </>
);
