export const Edit = (): JSX.Element => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="14"
    height="13"
    fill="none"
    viewBox="0 0 14 13"
  >
    <path
      fill="#6860F1"
      d="M0 12h14v1H0v-1zm11.7-8.5c.4-.4.4-1 0-1.4L9.9.3c-.4-.4-1-.4-1.4 0L1 7.8V11h3.2l7.5-7.5zM9.2 1L11 2.8 9.5 4.3 7.7 2.5 9.2 1zM2 10V8.2l5-5L8.8 5l-5 5H2z"
    ></path>
  </svg>
);
