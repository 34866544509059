import { Suspense, lazy } from 'react';

import { Loader } from '../../../../../../widgets';

const CreateAuthor = lazy(() => import('./CreateAuthor'));

export const CreateAuthor_Lazy = (): JSX.Element => (
  <>
    <Suspense fallback={<Loader />}>
      <CreateAuthor />
    </Suspense>
  </>
);
