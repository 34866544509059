import {
  DndContext,
  DragEndEvent,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors,
  closestCenter,
} from '@dnd-kit/core';
import {
  SortableContext,
  arrayMove,
  rectSortingStrategy,
} from '@dnd-kit/sortable';
import { useEffect, useState } from 'react';

import CoversImg from './Covers.png';
import SortableItem from './SortableItem';
import {
  useEditTopicMutation,
  useLazyGetTopicsCatalogQuery,
} from '../../../app/api/services/topicCatalog/topic';
import { errorToast } from '../../../utils';
import { Header, Loader } from '../../../widgets';
import { MobileBackground } from '../ThemeOrderComponents/MobileBackground';

import style from './ThemeOrder.module.scss';

const ThemeOrder = (): JSX.Element => {
  const [topics, setTopics] = useState([]);

  const sensors = useSensors(useSensor(MouseSensor), useSensor(TouchSensor));

  const [getData, { isLoading, data, isSuccess, isError }] =
    useLazyGetTopicsCatalogQuery();

  const [editTopic, { isLoading: isLoadingEdit, isError: isErrorEdit }] =
    useEditTopicMutation();

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;
    if (active.id !== over?.id) {
      const oldIndex = topics.findIndex((x) => x.id === active.id);
      const newIndex = topics.findIndex((x) => x.id === over.id);
      const newTopics = arrayMove(topics, oldIndex, newIndex);

      setTopics(newTopics);

      newTopics.forEach((item, index) => {
        const payload = {
          name: item.name,
          is_active: item.is_active,
          image_url_1: item.image_url_1,
          description: item.description,
          position: index + 1,
        };
        editTopic({
          id: item.id,
          payload: payload,
        });
      });
    }
  };

  useEffect(() => {
    getData({
      limit: 1000,
      offset: 0,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isSuccess) {
      setTopics(
        [...data.response.topics].sort((a, b) => a.position - b.position)
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess]);

  useEffect(() => {
    if (isError) {
      errorToast(isError, 'Не удалось получить данные.');
    }
    if (isErrorEdit) {
      errorToast(isErrorEdit, 'Не удалось сохранить измененияю.');
    }
  }, [isErrorEdit, isError]);

  return (
    <div className={style.container}>
      {(isLoading || isLoadingEdit) && <Loader />}
      <div className={style.innerContainer}>
        <div className={style.headingWrapper}>
          <Header className={style.heading} label="Очередность тем" />
          <div className={style.headingText}>
            Для изменения очередности тем, зажмите тему и перетащите в нужное
            место
          </div>
        </div>
        <MobileBackground>
          <DndContext
            sensors={sensors}
            collisionDetection={closestCenter}
            onDragEnd={handleDragEnd}
          >
            <SortableContext items={topics} strategy={rectSortingStrategy}>
              {topics.map((topic) => (
                <SortableItem
                  name={topic.name}
                  key={topic.id}
                  id={topic.id}
                  img={CoversImg} // TODO: передлать картинки приходят с бэка
                />
              ))}
            </SortableContext>
          </DndContext>
        </MobileBackground>
      </div>
    </div>
  );
};

export default ThemeOrder;
