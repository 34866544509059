import React from 'react';

import style from './LastRefreshed.module.scss';

export const LastRefreshed = ({ date }: { date: string }): JSX.Element => (
  <div>
    <div className={style.dateTitle}>Последнее обновление базы</div>
    <div className={style.date}>{date}</div>
  </div>
);
