import { useGoogleLogin } from '@react-oauth/google';
import { useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Id, toast } from 'react-toastify';

import { COOKIE, IApi } from '../../../app/global/types';
import { Router } from '../../../components/Router/routerEnum';
import { useAppDispatch } from '../../../hooks';
import { ISuccess } from '../../../pages/LoginPage/types/types';
import { GoogleIcon } from '../../../shared/icons';
import { useLoginWithGoogleMutation } from '../../../store/actions/users';
import { setTokens } from '../../../store/slices/authSlice';

import style from './GoogleButton.module.scss';

type TCookies = COOKIE.ACCESS_TOKEN | COOKIE.REFRESH_TOKEN | COOKIE.LOGGED_IN;

export const GoogleButton = (props: {
  setCookies: (cookie: TCookies, value: string) => void;
}): JSX.Element => {
  const { setCookies } = props;
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [loginWithGoogle, { data, error, isError, isSuccess }] =
    useLoginWithGoogleMutation();

  const login = useGoogleLogin({
    onSuccess: (codeResponse) => {
      loginWithGoogle(codeResponse.access_token);
    },
  });

  const handleSuccessLogin = useCallback(
    (data: ISuccess): void => {
      setCookies(COOKIE.ACCESS_TOKEN, data.response.access_token);
      setCookies(COOKIE.REFRESH_TOKEN, data.response.refresh_token);
      setCookies(COOKIE.LOGGED_IN, 'true');
      dispatch(
        setTokens({
          accessToken: data.response.access_token,
          refreshToken: data.response.refresh_token,
        })
      );

      return navigate(Router.MainPage, { state: 'isLogin' });
    },
    [dispatch, navigate, setCookies]
  );

  useEffect(() => {
    if (isSuccess) {
      handleSuccessLogin(data);
    }
  }, [isSuccess, handleSuccessLogin, data, setCookies]);

  useEffect(() => {
    if (isError) {
      handleErrorLogin(error as IApi);
    }
  }, [error, isError]);

  const handleErrorLogin = (error: IApi): Id =>
    error?.data?.message
      ? toast.error(error?.data?.message)
      : toast.error('Что то пошло не так');

  return (
    <button className={style.container} onClick={() => login()}>
      <GoogleIcon />
      <span className={style.desc}>Войти с помощью Google</span>
    </button>
  );
};
