import classNames from 'classnames';
import React from 'react';

interface IProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  children: React.ReactNode;
  variant?: 'reverse' | 'normal';
  size?: 'small' | 'normal';
  icon?: React.ReactNode;
}

import style from './Button.module.scss';

export const Button = ({
  children,
  variant = 'normal',
  size = 'normal',
  icon,
  ...props
}: IProps): JSX.Element => (
  <button
    className={classNames(
      style.button,
      style[`variant-${variant}`],
      style[`size-${size}`]
    )}
    {...props}
  >
    {icon && <span className={style.icon}>{icon}</span>}
    {children}
  </button>
);
