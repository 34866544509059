import { lazy, Suspense } from 'react';

import TagDirectoryForCompilations from './TagDirectoryForCompilations';
import { Loader } from '../../../widgets';

export const TagDirectoryForCompilations_Lazy = lazy(
  () => import('./TagDirectoryForCompilations')
);

export const TagDirectory_Lazy = (): JSX.Element => (
  <>
    <Suspense fallback={<Loader />}>
      <TagDirectoryForCompilations />
    </Suspense>
  </>
);
