import cn from 'classnames';
import { useEffect, useState } from 'react';

import { IContent } from '../../../app/global/types';
import { ArrowDown, Check } from '../../../shared/icons';
import { typeOptions } from '../../../shared/types';
import { ClickShowContainer } from '../../ClickShowContainer';
import { ObligatoryField } from 'widgets/ObligatoryField';

import style from './Select.module.scss';

export interface IOption {
  id: number;
  name: string;
  uuid?: string;
}

interface IProps {
  title: string;
  options?: IContent[] | IOption[];
  defaultOption?: number;
  placeholder?: string;
  className?: string;
  classNameDropdown?: string;
  onChange?: (option) => void;
  disabled?: boolean;
  isError?: boolean;
  isObligatory?: boolean;
}

export const Select = ({
  title,
  options,
  className,
  classNameDropdown,
  onChange,
  placeholder,
  defaultOption,
  disabled = false,
  isError = false,
  isObligatory = false,
}: IProps): JSX.Element => {
  const optionList = options
    ? options.map((option) => ({
        id: option.id,
        name: typeOptions[option?.name]
          ? typeOptions[option?.name]
          : option.name,
        disabled: option.disabled ? option.disabled : false,
      }))
    : [];

  const [dropdown, setDropdown] = useState(false);
  const [currentOption, setCurrentOption] = useState(
    optionList?.find((option) => option.id === defaultOption)
  );

  useEffect(() => {
    setCurrentOption(optionList?.find((option) => option.id === defaultOption));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options]);

  const onClickHandler = (event) => {
    event.preventDefault();
    setDropdown((prevState) => !prevState);
  };

  useEffect(() => {
    const changeOption = options?.find(
      (option) => option?.id === currentOption?.id
    );

    onChange(changeOption);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentOption]);

  return (
    <div className={cn(style.wrapper, className)}>
      <h3 className={cn(style.title, { [style.errorTitle]: isError })}>
        {title} {isObligatory && <ObligatoryField />}
      </h3>

      <ClickShowContainer
        dropdown={dropdown}
        setDropdown={setDropdown}
        className={cn(style.container, {
          [style.active]: dropdown,
          [style.disabled]: disabled,
          [style.errorContainer]: isError,
        })}
        onClick={(event) => !disabled && onClickHandler(event)}
      >
        <span
          className={cn(style.placeholder, {
            [style.option_choose]: !!currentOption,
            [style.placeholderError]: isError,
          })}
        >
          {placeholder && !currentOption ? placeholder : currentOption?.name}
        </span>

        <ArrowDown color="#434343" />

        <div
          className={cn(dropdown ? style.list : style.hide, classNameDropdown)}
        >
          {optionList.map((option) => (
            <div
              key={option.id}
              className={cn(style.option, {
                [style.disabledOption]: option.disabled,
              })}
              onClick={() => !option.disabled && setCurrentOption(option)}
            >
              {option?.name}
              {currentOption?.id === option?.id && (
                <Check height="18px" stroke="#434343" width="24px" />
              )}
            </div>
          ))}
        </div>
      </ClickShowContainer>
    </div>
  );
};
