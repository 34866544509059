import { Form, Formik, FormikProps } from 'formik';
import React, { useEffect } from 'react';

import { useUpdateMassActionsByEntityMutation } from '../../../app/api/services/massActions/massActions';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import {
  clearMassActions,
  IStateMassActions,
} from '../../../store/slices/massActionsSlice';
import { SearchEntity } from '../../../store/slices/selectSlice';
import { errorToast, successToast } from '../../../utils';
import { Loader, SaveButton } from '../../../widgets';
import { disabledBtnToApplyFilter, returnedFilter } from '../helpers';
import { AddAttribute } from '../MassActionsFormComponents';
import { IFormikValues } from '../types';

import style from './MassActionsForm.module.scss';

interface IProps {
  entity: SearchEntity;
  setModalActive: (val: boolean) => void;
}

export const MassActionsForm = ({
  entity,
  setModalActive,
}: IProps): JSX.Element => {
  const dispatch = useAppDispatch();

  const { massActions, chekedElements } = useAppSelector(
    (state) => state.massActionsReducer as IStateMassActions
  );

  const [updateMassActionsByEntity, { isLoading, isSuccess, isError }] =
    useUpdateMassActionsByEntityMutation();

  useEffect(() => {
    errorToast(isError, 'Не удалось внести изменения');
  }, [isError]);

  useEffect(() => {
    successToast({
      success: isSuccess,
      message: 'Изменения внесены успешно',
    });

    if (isSuccess) {
      dispatch(clearMassActions());
      setModalActive(false);
    }
  }, [dispatch, isSuccess, setModalActive]);

  useEffect(() => {
    dispatch(clearMassActions());
  }, [dispatch, entity]);

  return (
    <>
      {isLoading && <Loader />}
      <Formik
        initialValues={massActions}
        enableReinitialize
        onSubmit={(values) => {
          updateMassActionsByEntity({
            entity,
            body: {
              update: { id: chekedElements, fields: values.commands },
            },
          });
        }}
      >
        {(formik: FormikProps<IFormikValues>) => {
          const buttonsVisible = 'commands' in formik.values;

          return (
            <Form
              className={style.form}
              onKeyDown={(event) =>
                event.key === 'Enter' && event.preventDefault()
              }
            >
              <div className={style.content}>
                <div className={style.column}>
                  <h3 className={style.title}>Команды</h3>
                  <AddAttribute type="commands" entity={entity} />
                  <div className={style.filtersList}>
                    {Object.keys(formik.values.commands || {}).map(
                      (attribute) => returnedFilter(attribute, 'commands')
                    )}
                  </div>
                </div>
              </div>
              {buttonsVisible && (
                <div className={style.buttons}>
                  <SaveButton
                    disabled={disabledBtnToApplyFilter(formik?.values)}
                    title="Применить"
                  />
                </div>
              )}
            </Form>
          );
        }}
      </Formik>
    </>
  );
};
