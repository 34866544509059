import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  IPayloadAddGroup,
  IPayloadAddElement,
  IPayloadDeleteHightElement,
  ISelectState,
  ITypeAndId,
  findChooseElement,
  ICatalogMiddle,
  IPayloadEditName,
} from './typesAndFunctions';

const initialState: ISelectState = {
  catalogType: { theme: [], tag: [] },
};

export const catalogSlice = createSlice({
  name: 'select',
  initialState,
  reducers: {
    // Добавление
    addNewGroup(state, { payload }: PayloadAction<IPayloadAddGroup>) {
      state.catalogType[payload.type] = [
        ...state.catalogType[payload.type],
        payload.value,
      ];
    },
    addHightElement(state, { payload }: PayloadAction<IPayloadAddElement>) {
      findChooseElement(
        state.catalogType[payload.type],
        payload.id
      ).elements.push(payload.element);
    },
    addMiddleElement(state, { payload }: PayloadAction<IPayloadAddElement>) {
      const el = findChooseElement(state.catalogType[payload.type], payload.id);
      if (el && 'elements' in el && Array.isArray(el.elements)) {
        (el as ICatalogMiddle).elements.push(payload.element);
      }
    },
    // Редактирование названия
    editName(state, { payload }: PayloadAction<IPayloadEditName>) {
      findChooseElement(state.catalogType[payload.type], payload.id).name =
        payload.name;
    },
    // Удаление
    deleteGroup(state, { payload }: PayloadAction<ITypeAndId>) {
      state.catalogType[payload.type] = [
        ...state.catalogType[payload.type].filter((el) => el.id !== payload.id),
      ];
    },
    deleteElement(
      state,
      { payload }: PayloadAction<IPayloadDeleteHightElement>
    ) {
      const parentElement = findChooseElement(
        state.catalogType[payload.type],
        payload.parentId
      );

      if (parentElement && parentElement.elements) {
        parentElement.elements = [
          ...parentElement.elements.filter((el) => el.id !== payload.id),
        ];
      }
    },
  },
});

export const {
  addNewGroup,
  addHightElement,
  addMiddleElement,
  editName,
  deleteGroup,
  deleteElement,
} = catalogSlice.actions;
export const catalogSliceReducer = catalogSlice.reducer;
