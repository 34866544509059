import cn from 'classnames';
import { useFormikContext } from 'formik';
import { useEffect, useState } from 'react';
import { GroupBase } from 'react-select';
import { LoadOptions } from 'react-select-async-paginate';

import { IBody } from '../../../app/api/services/feeds/types';
import { IEntityMerging } from '../../../app/global/types';
import { SearchEntity } from '../../../store/slices/selectSlice';
import { getTheArrayForFeeds } from '../../../utils/getTheIdArray/getTheIdArray';
import { KitItem } from '../../KitItem';

import style from './AccessoriesKit.module.scss';

interface IProps {
  searchEntity: SearchEntity;
  count: number;
  options: Array<IEntityMerging>;
  items: IEntityMerging[];
  elementType: IElementType;
  onChange?: (elements) => void;
  label?: string;
  isError?: boolean;
  withPagination?: boolean;
  loadOptions: LoadOptions<
    { name: string },
    GroupBase<{ name: string }>,
    { offset?: number; offsetSearch?: number }
  >;
  additional?: { offset?: number; offsetSearch?: number };
}

interface IElementType {
  id: number;
  name: string;
}

export const AccessoriesKit = ({
  count,
  options,
  elementType,
  items,
  searchEntity,
  label = 'Набор элементов *',
  isError = false,
  withPagination,
  loadOptions,
  additional,
}: IProps): JSX.Element => {
  const { setFieldValue } = useFormikContext<IBody>();

  const [elements, setElements] = useState(
    items.length ? [...items] : [...Array(count)]
  );

  const elementName = elementType?.name;

  useEffect(() => {
    if (!count) {
      return;
    }

    if (count > elements.length) {
      setElements([
        ...elements,
        ...Array(count - elements.length).fill(undefined),
      ]);
    } else if (count < elements.length) {
      setElements(elements.slice(0, count));
    }
  }, [count, elements]);

  useEffect(() => {
    if (elementName) {
      setFieldValue('elements', {});
      setFieldValue(`elements.${elementName}`, getTheArrayForFeeds(elements));
    }
  }, [elementName, elements, setFieldValue]);

  const clickHandle = (option, selectedIndex) => {
    if (selectedIndex !== null) {
      const newElements = [...elements];
      newElements[selectedIndex] = option;
      setElements(newElements);
    }
  };

  return (
    <div className={style.container}>
      <h3 className={cn(style.label, { [style.errorLabel]: isError })}>
        {label}
      </h3>
      <div className={style.wrapper}>
        {elements?.map(
          (element, index): JSX.Element => (
            <KitItem
              key={element?.name || index}
              searchEntity={searchEntity}
              options={options}
              elements={elements}
              item={element}
              withPagination={withPagination}
              onClick={(option) => {
                clickHandle(option, index);
              }}
              loadOptions={loadOptions}
              additional={additional}
            />
          )
        )}
      </div>
    </div>
  );
};
