import cn from 'classnames';
import { Field } from 'formik';
import React from 'react';

import { Author } from '../../../app/api/services/authors/types';
import { BookSeries } from '../../../app/api/services/bookSeries/types';
import { Channel } from '../../../app/api/services/channels/types';
import {
  Collection,
  ITagsForCompilations,
} from '../../../app/api/services/collections/types';
import { Person } from '../../../app/api/services/persons/types';
import { Reader } from '../../../app/api/services/readers/types';
import { ISupermix } from '../../../app/api/services/supermix/types';
import { Translator } from '../../../app/api/services/translators/types';
import { ContainerWrapper, Input, TextArea } from '../../../widgets';
import { ImgUploaderBlock } from '../../ImgUploaderBlock';
import { Fields } from '../Field';
import { ICompilation } from 'app/api/services/compilations/types';

import style from './Description.module.scss';

interface IProps {
  entity:
    | Author
    | Reader
    | Translator
    | Person
    | Channel
    | Collection
    | BookSeries
    | ISupermix
    | ITagsForCompilations
    | ICompilation;
  titleInList?: boolean;
  children?: React.ReactNode;
  className?: string;
  classNameInputBlock?: string;
  isErrorName?: boolean;
  isErrorImg?: boolean;
  isErrorBanner?: boolean;
  isBannerRequired?: boolean;
  isErrorTitle?: boolean;
  isDescriptionRequired?: boolean;
  isErrorDescription?: boolean;
  titleObligatory?: boolean;
  descriptionObligatory?: boolean;
  hasImg?: boolean;
  hasName?: boolean;
  hasTheme?: boolean;
  hasLinkToTagsCatalog?: boolean;
  hasNameInput?: boolean;
  containerWrapperTitle?: string;
}

export const DescriptionBlock = ({
  entity,
  titleInList,
  children,
  className,
  classNameInputBlock,
  isErrorName,
  isErrorImg,
  isErrorBanner,
  isErrorTitle,
  isDescriptionRequired = false,
  isErrorDescription = false,
  isBannerRequired = false,
  titleObligatory = false,
  descriptionObligatory = false,
  hasImg = true,
  hasName = false,
  hasNameInput = true,
  hasTheme = false,
  hasLinkToTagsCatalog = false,
  containerWrapperTitle = 'Описание',
}: IProps): JSX.Element => (
  <ContainerWrapper title={containerWrapperTitle}>
    <div className={cn(style.wrapper, className)}>
      <div
        className={cn(
          style.blockWrapper,
          {
            [style.secondInput]: !titleInList,
          },
          classNameInputBlock
        )}
      >
        <div className={style.input}>
          {hasName && (
            <h3 className={style.name}>
              Название: <span className={style.entityName}>{entity.name}</span>
            </h3>
          )}
          {hasNameInput && (
            <Field
              name={Fields.Name}
              placeholder={titleInList ? 'Имя Фамилия' : 'Введите название'}
              label={titleInList ? 'Название в лентах' : 'Название'}
              component={Input}
              isError={isErrorName}
              isObligatory={titleObligatory}
            />
          )}
        </div>
        {titleInList && (
          <div className={style.input}>
            <Field
              name={Fields.Title}
              placeholder="Полное название"
              label="Название в списках"
              component={Input}
              isError={isErrorTitle}
              isObligatory={titleObligatory}
            />
          </div>
        )}
        <div className={style.inputTextArea}>
          <Field
            name={Fields.Description}
            component={TextArea}
            placeholder="Введите описание"
            title={isDescriptionRequired ? 'Описание' : 'Описание'}
            isError={isErrorDescription}
            isObligatory={descriptionObligatory}
          />
        </div>
      </div>
      {hasImg && (
        <ImgUploaderBlock
          entity={entity}
          isError={isErrorImg}
          isErrorBanner={isErrorBanner}
          isBannerRequired={isBannerRequired}
        />
      )}
      {hasTheme && (
        <div>
          <h3 className={style.name}>
            Тема: <span className={style.entityName}>{entity.name}</span>
          </h3>
        </div>
      )}
      {hasLinkToTagsCatalog && (
        <a className={style.btnSetPlaylistOrder}>
          Перейти в каталог тегов для подборок
        </a>
      )}
    </div>
    {children}
  </ContainerWrapper>
);
