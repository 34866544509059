import { lazy, Suspense } from 'react';

import { Loader } from '../../../widgets';

const License = lazy(() => import('./License'));

export const License_Lazy = (): JSX.Element => (
  <Suspense fallback={<Loader />}>
    <License />
  </Suspense>
);
