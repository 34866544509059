import S3 from 'aws-sdk/clients/s3';
import cn from 'classnames';
import React, { useRef, useState, useEffect } from 'react';
import { toast } from 'react-toastify';

import { IImage } from '../../../app/global/types';
import { Delete, UploadImage } from '../../../shared/icons';
import { generateUniqNameFile } from '../../../utils/';
import { Img } from '../../Img';
import { Loader } from '../../Loader';
import { useUploadImageMutation } from 'store/slices/uploadFileSlice';
import { ObligatoryField } from 'widgets/ObligatoryField';

import style from './ImgUploader.module.scss';

const config = {
  accessKeyId: process.env.REACT_APP_S3_PRIMARY_ACCESS_KEY,
  secretAccessKey: process.env.REACT_APP_S3_PRIMARY_SECRET_KEY,
  endpoint: process.env.REACT_APP_S3_ENDPOINT_URL,
};

const S3Client = new S3(config);

interface IProps {
  imageUrl?: string;
  mode?:
    | 'primary_image'
    | 'secondary_image'
    | 'primary_banner'
    | 'secondary_banner'
    | 'primary_image_url'
    | 'secondary_image_url'
    | 'primary_banner_url'
    | 'compilation_banner'
    | 'secondary_banner_url';

  className?: string;
  label: string;
  isError?: boolean;
  onChange: (image: IImage) => void;
  isObligatory?: boolean;
}

export const ImgUploader = ({
  imageUrl,
  mode = 'primary_image',
  className,
  onChange,
  label,
  isError = false,
  isObligatory = false,
}: IProps): JSX.Element => {
  const [currentFile, setCurrentFile] = useState<File>(null);

  const [currentUrlFile, setCurrentUrlFile] = useState<string>(imageUrl);

  const filePicker = useRef(null);

  const [uploadImg, { isLoading }] = useUploadImageMutation();

  const pickHandle = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    filePicker.current.click();
  };

  const changeHandle = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const [file] = event.target.files;
    const { name } = file;

    setCurrentFile(file);

    const paramsUpload = {
      Bucket: `${process.env.REACT_APP_TMP_HOT_STORAGE_BUCKET}/${process.env.REACT_APP_TMP_STORAGE_IMAGES_FOLDER}`,
      ACL: 'private',
      ContentType: file.type,
      ContentDisposition: 'inline',
    };

    const ext = name.split('.').at(-1);
    const key = `${generateUniqNameFile(name)}.${ext}`;

    const uploadData = await S3Client.upload({
      ...paramsUpload,
      Key: key,
      Body: file,
    }).promise();

    if (uploadData) {
      uploadImg({
        body: {
          key: key,
          name,
        },
        ext,
      })
        .then((data) => {
          setValue(data);
        })
        .catch(() => toast.error('Что-то пошло не так'));
    }
  };

  const setValue = (data) => {
    if (data.data.response) {
      setCurrentUrlFile(data.data.response.url);
      onChange(data.data.response);
    }
  };

  const deleteCurrentImageHandle = (e) => {
    e.preventDefault();
    filePicker.current.value = null;
    onChange(null);
    setCurrentUrlFile(null);
    setCurrentFile(null);
  };

  useEffect(() => {
    setCurrentUrlFile(imageUrl);
  }, [imageUrl]);

  return (
    <div className={cn(style.wrapper, className)}>
      {isLoading && <Loader />}
      <div className={style.container}>
        <h3 className={cn(style.label, { [style.errorLabel]: isError })}>
          {label} {isObligatory && <ObligatoryField />}
        </h3>

        <button className={style.img} onClick={(e) => pickHandle(e)}>
          <div
            className={cn(style.img, {
              [style.primaryImage]: mode === 'primary_image',
              [style.secondaryImage]: mode === 'secondary_image',
              [style.primaryBanner]: mode === 'primary_banner',
              [style.secondaryBanner]: mode === 'secondary_banner',
              [style.compilationPrimaryAndSecondaryBanner]:
                mode === 'compilation_banner',
            })}
          >
            {currentUrlFile || currentFile ? (
              <Img
                src={currentUrlFile}
                className={cn({
                  [style.primaryImage]: mode === 'primary_image',
                  [style.secondaryImage]: mode === 'secondary_image',
                  [style.primaryBanner]: mode === 'primary_banner',
                  [style.secondaryBanner]: mode === 'secondary_banner',
                  [style.compilationPrimaryAndSecondaryBanner]:
                    mode === 'compilation_banner',
                })}
              />
            ) : (
              <UploadImage />
            )}
          </div>
        </button>

        <input
          ref={filePicker}
          type="file"
          accept="image/*,.png,.jpg,.gif"
          className={style.hidden}
          onChange={changeHandle}
        />
      </div>

      {(currentUrlFile || currentFile) && (
        <button
          className={cn(style.trashButton, {
            [style.primaryImage]: mode === 'primary_image',
            [style.secondaryImage]: mode === 'secondary_image',
            [style.primaryBanner]: mode === 'primary_banner',
            [style.secondaryBanner]: mode === 'secondary_banner',
            [style.compilationBannerTrash]: mode === 'compilation_banner',
          })}
          onClick={(e) => deleteCurrentImageHandle(e)}
        >
          <Delete />
        </button>
      )}
    </div>
  );
};
