import {
  DndContext,
  DragEndEvent,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors,
  closestCorners,
  UniqueIdentifier,
  DragStartEvent,
  PointerSensor,
} from '@dnd-kit/core';
import {
  SortableContext,
  arrayMove,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { useState } from 'react';

import { useAppDispatch, useAppSelector } from '../../../../../../../../hooks';
import {
  IRubricState,
  setRubricList,
} from '../../../../../../../../store/slices/rubricSlice';
import { SortableItem } from '../SortableItem';

import style from './RubricFrameElements.module.scss';

export const RubricFrameElements = (): JSX.Element => {
  const elements = useAppSelector(
    (state) => (state.rubricReducer as IRubricState).list
  );
  const dispatch = useAppDispatch();

  const [, setActiveId] = useState<UniqueIdentifier>(null);

  const sensors = useSensors(
    useSensor(MouseSensor),
    useSensor(TouchSensor),
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 8,
      },
    })
  );

  const handleDragStart = (event: DragStartEvent) => {
    setActiveId(event.active.id);
  };

  const handleDragEnd = async (event: DragEndEvent) => {
    const { active, over } = event;

    if (active.id !== over?.id) {
      const oldIndex = elements.findIndex(
        (section) => section.id === active?.id
      );
      const newIndex = elements.findIndex((section) => section.id === over?.id);
      const newTopics = arrayMove(elements, oldIndex, newIndex);

      const newPositionTopics = newTopics.map((item, index) => ({
        ...item,
        position: ++index,
      }));

      dispatch(setRubricList(newPositionTopics));
    }
    setActiveId(null);
  };

  return (
    <div className={style.wrapper}>
      {elements.length && (
        <DndContext
          sensors={sensors}
          collisionDetection={closestCorners}
          onDragEnd={handleDragEnd}
          onDragStart={handleDragStart}
        >
          <SortableContext
            items={elements}
            strategy={verticalListSortingStrategy}
          >
            {elements?.map((element, index) => (
              <SortableItem id={element.id} key={index} element={element} />
            ))}
          </SortableContext>
        </DndContext>
      )}
    </div>
  );
};
