import cn from 'classnames';
import { useFormikContext } from 'formik';
import React, { useState, useEffect } from 'react';

import { IBody } from '../../../app/api/services/playlists/types';
import { useGetAllTopicsQuery } from '../../../app/api/services/topicCatalog/topic';
import { TopicCatalog } from '../../../app/api/services/topicCatalog/types';
import { useAppDispatch, useAppSelector, useDebounce } from '../../../hooks';
import { ISubmitCreatePlaylist } from '../../../pages/Navbar/Entity/PlayList/CreatePlaylist/ui/helpers';
import { ISelectState, setLoading } from '../../../store/slices/selectSlice';
import { ContainerWrapper, ObligatoryField, Separator } from '../../../widgets';
import { Modal } from '../../Modal';
import { AddTopic } from '../AddTopic';
import { ChangeTopic } from '../ChangeTopic';

import style from './Topics.module.scss';

interface IProps {
  required: boolean;
  topics?: TopicCatalog[][];
}

interface ITopic {
  name?: string;
  level?: number;
  id?: number;
}

export const Topics = ({ topics }: IProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const { setFieldValue, values } = useFormikContext<IBody>();
  const { errors } = useFormikContext<ISubmitCreatePlaylist>();

  const [actualTopics, setActualTopics] = useState<ITopic[][]>(topics || []);
  const [isShowAll, setIsShowAll] = useState<boolean>(false);

  const selectedValue = useAppSelector(
    (state) => (state.selectSliceReducer as ISelectState).inputValueInSelect
  );

  const debounceValueThemesSelect = useDebounce(selectedValue?.themes, 500);

  const { data: allTopics, isLoading } = useGetAllTopicsQuery(
    {
      limit: 5,
      offset: 0,
      name: debounceValueThemesSelect,
      childrenExist: false,
    },
    { refetchOnMountOrArgChange: true }
  );

  useEffect(() => {
    dispatch(setLoading(isLoading));
  }, [dispatch, isLoading]);

  const handleAdd = (option: TopicCatalog) => {
    if (!values.topic_ids.find((item) => item === option.id)) {
      setActualTopics((prev) => [
        [
          option.level === 3 ? option : { level: 3 },
          option.level === 2 ? option : { level: 2 },
          option.level === 1 ? option : { level: 1 },
        ],
        ...prev,
      ]);

      setFieldValue('topic_ids', [...values.topic_ids, option.id]);
    }
  };

  const handleChange = (option: TopicCatalog, index: number) => {
    if (!values.topic_ids.find((item) => item === option.id)) {
      const changeTopics = [...actualTopics];
      const changeTopicsIds = [...values.topic_ids];

      changeTopics.splice(index, 1, [
        option.level === 3 ? option : { level: 3 },
        option.level === 2 ? option : { level: 2 },
        option.level === 1 ? option : { level: 1 },
      ]);
      changeTopicsIds.splice(index, 1, option.id);

      setActualTopics(changeTopics);

      setFieldValue('topic_ids', changeTopicsIds);
    }
  };

  const handleDelete = (index: number) => {
    const changeTopics = [...actualTopics];
    const changeTopicsIds = [...values.topic_ids];

    changeTopics.splice(index, 1);
    changeTopicsIds.splice(index, 1);

    setActualTopics(changeTopics);

    setFieldValue('topic_ids', changeTopicsIds);
  };

  return (
    <ContainerWrapper
      title={
        <div className={style.title}>
          <span className={cn({ [style.errorLabel]: !!errors.topic })}>
            Темы <ObligatoryField className={style.star} />
          </span>
          <AddTopic
            searchEntity="themes"
            label={`Добавить ветку`}
            options={allTopics?.response}
            onChange={(option) => handleAdd(option)}
            isError={!!errors.topic}
          />
        </div>
      }
      className={style.wrapper}
    >
      <div className={style.content}>
        {actualTopics.map(
          (item, index) =>
            index < 2 && (
              <div key={index}>
                <div className={style.block}>
                  <ChangeTopic
                    searchEntity="themes"
                    label={`Добавить ветку`}
                    options={allTopics?.response}
                    onChange={(option) => handleChange(option, index)}
                    onDelete={() => handleDelete(index)}
                    isError={!!errors.topic}
                  />
                  {item.map((topic, index) => (
                    <div className={style.topic} key={index}>
                      <h4>
                        {topic.level === 1 && 'Группа тем:'}
                        {topic.level === 2 && 'Тема:'}
                        {topic.level === 3 && 'Подтема:'}
                      </h4>
                      <span>{topic.name ? topic.name : ''}</span>
                    </div>
                  ))}
                </div>
                <Separator className={style.separator} />
              </div>
            )
        )}
        {actualTopics.length > 2 && (
          <span className={style.showAll} onClick={() => setIsShowAll(true)}>
            Показать все
          </span>
        )}
      </div>
      <Modal active={isShowAll} setActive={setIsShowAll} title="Темы">
        <div className={style.content}>
          {actualTopics.map((item, index) => (
            <div key={index}>
              <div className={style.block}>
                <ChangeTopic
                  searchEntity="themes"
                  label={`Добавить ветку`}
                  options={allTopics?.response}
                  onChange={(option) => handleChange(option, index)}
                  onDelete={() => handleDelete(index)}
                  isError={!!errors.topic}
                />
                {item.map((topic, index) => (
                  <div className={style.topic} key={index}>
                    <h4>
                      {topic.level === 1 && 'Группа тем:'}
                      {topic.level === 2 && 'Тема:'}
                      {topic.level === 3 && 'Подтема:'}
                    </h4>
                    <span>{topic.name ? topic.name : ''}</span>
                  </div>
                ))}
              </div>
              <Separator className={style.separator} />
            </div>
          ))}
        </div>
      </Modal>
    </ContainerWrapper>
  );
};
