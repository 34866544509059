import { useFormikContext } from 'formik';
import React from 'react';

import { placeholderValue } from './helpers';
import { Switch } from '../../../../../widgets';
import { fieldQuantitativeIndicator } from '../../../helpers';
import { IPropsFilterAndColumn } from '../../../types';
import { AttributeItemWrapper } from '../../AttributeItemWrapper';

import style from './InputNumberOrRange.module.scss';

export const InputNumberOrRange = ({
  massAction,
  columnType,
}: IPropsFilterAndColumn): JSX.Element => {
  const { values, setFieldValue } = useFormikContext();
  const title = fieldQuantitativeIndicator[massAction];

  const minValue = values?.[columnType]?.[massAction]?.['gte'];
  const maxValue = values?.[columnType]?.[massAction]?.['lte'];

  return (
    <AttributeItemWrapper
      massAction={massAction}
      columnType={columnType}
      title={title}
      mode="withSwitch"
      className={style.attributeWrapper}
    >
      <div className={style.content}>
        {!values?.['active']?.[columnType]?.[massAction] ? (
          <input
            type="number"
            step="1"
            min={0}
            placeholder={placeholderValue(title, false)[0]}
            className={style.input}
            onChange={(e) =>
              setFieldValue(
                `${columnType}.${massAction}`,
                e.target.value ? Number(e.target.value) : null
              )
            }
          />
        ) : (
          <div className={style.rangeInput}>
            <input
              type="number"
              step="1"
              min={0}
              max={maxValue}
              value={minValue || minValue === 0 ? minValue : ''}
              placeholder={`от ${placeholderValue(title, true)[0]}`}
              className={style.input}
              onChange={(e) =>
                setFieldValue(
                  `${columnType}.${massAction}.gte`,
                  e.target.value
                    ? Number(
                        e.target.value > maxValue ? maxValue : e.target.value
                      )
                    : null
                )
              }
            />
            &mdash;
            <input
              type="number"
              step="1"
              min={minValue}
              value={maxValue ? maxValue : ''}
              placeholder={`до ${placeholderValue(title, true)[1]}`}
              className={style.input}
              onChange={(e) =>
                setFieldValue(
                  `${columnType}.${massAction}.lte`,
                  e.target.value
                    ? Number(
                        e.target.value < minValue ? minValue : e.target.value
                      )
                    : null
                )
              }
            />
          </div>
        )}
        <Switch
          label={`Задать диапазон`}
          field={`active.${columnType}.${massAction}`}
          value={values?.['active']?.[columnType]?.[massAction]}
          className={style.switch}
        />
      </div>
    </AttributeItemWrapper>
  );
};
