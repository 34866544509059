import { lazy, Suspense } from 'react';

import { Loader } from '../../../widgets';

const NotificationsForClients = lazy(() => import('./NotificationsForClients'));

export const NotificationsForClients_Lazy = (): JSX.Element => (
  <>
    <Suspense fallback={<Loader />}>
      <NotificationsForClients />
    </Suspense>
  </>
);
