export const DesktopIcon = (): JSX.Element => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.1756 21.5999H6.82518C6.65416 21.5998 6.48952 21.5368 6.36455 21.4236C6.23958 21.3104 6.16359 21.1554 6.15193 20.99C6.14028 20.8245 6.19382 20.6609 6.30175 20.5323C6.40968 20.4036 6.56395 20.3195 6.73338 20.2969L6.82518 20.2908H8.40025V18.1107H3.22504C2.68795 18.1107 2.17286 17.9039 1.79308 17.5356C1.41331 17.1673 1.19995 16.6679 1.19995 16.1471V4.36354C1.19995 3.84275 1.41331 3.34329 1.79308 2.97504C2.17286 2.60678 2.68795 2.3999 3.22504 2.3999H20.7749C21.312 2.3999 21.827 2.60678 22.2068 2.97504C22.5866 3.34329 22.7999 3.84275 22.7999 4.36354V16.1471C22.7999 16.6679 22.5866 17.1673 22.2068 17.5356C21.827 17.9039 21.312 18.1107 20.7749 18.1107H15.5987V20.2908H17.1756C17.3389 20.2908 17.4966 20.3482 17.6195 20.4524C17.7425 20.5565 17.8224 20.7003 17.8443 20.8572L17.8506 20.9454C17.8509 21.1037 17.7919 21.2567 17.6846 21.3761C17.5774 21.4955 17.4292 21.5731 17.2674 21.5947L17.1756 21.5999ZM14.2487 18.1107H9.75031V20.2908H14.2496L14.2487 18.1107ZM20.774 3.70899H3.22504C3.04601 3.70899 2.87431 3.77795 2.74772 3.9007C2.62113 4.02346 2.55001 4.18994 2.55001 4.36354V16.1471C2.55001 16.5093 2.85242 16.8016 3.22504 16.8016L14.9246 16.7999L14.9498 16.8016H20.7749C20.9539 16.8016 21.1256 16.7327 21.2522 16.6099C21.3788 16.4872 21.4499 16.3207 21.4499 16.1471V4.36354C21.4499 4.18994 21.3788 4.02346 21.2522 3.9007C21.1256 3.77795 20.9539 3.70899 20.7749 3.70899"
      fill="#434343"
    />
  </svg>
);

export const PhoneIcon = (): JSX.Element => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.16667 2.3999C7.59203 2.3999 7.04093 2.6347 6.6346 3.05264C6.22827 3.47057 6 4.03742 6 4.62847V19.3713C6 19.9624 6.22827 20.5292 6.6346 20.9472C7.04093 21.3651 7.59203 21.5999 8.16667 21.5999H15.8333C16.408 21.5999 16.9591 21.3651 17.3654 20.9472C17.7717 20.5292 18 19.9624 18 19.3713V4.62847C18 4.03742 17.7717 3.47057 17.3654 3.05264C16.9591 2.6347 16.408 2.3999 15.8333 2.3999H8.16667ZM7.33333 4.62847C7.33333 4.15533 7.70667 3.77133 8.16667 3.77133H15.8333C16.2933 3.77133 16.6667 4.15533 16.6667 4.62847V19.3713C16.6667 19.8445 16.2933 20.2285 15.8333 20.2285H8.16667C7.70667 20.2285 7.33333 19.8445 7.33333 19.3713V4.62847ZM10.6667 17.4856C10.4899 17.4856 10.3203 17.5579 10.1953 17.6865C10.0702 17.8151 10 17.9895 10 18.1713C10 18.3532 10.0702 18.5276 10.1953 18.6562C10.3203 18.7848 10.4899 18.857 10.6667 18.857H13.3333C13.5101 18.857 13.6797 18.7848 13.8047 18.6562C13.9298 18.5276 14 18.3532 14 18.1713C14 17.9895 13.9298 17.8151 13.8047 17.6865C13.6797 17.5579 13.5101 17.4856 13.3333 17.4856H10.6667Z"
      fill="#434343"
    />
  </svg>
);
