import cn from 'classnames';
import { useFormikContext } from 'formik';
import { useState } from 'react';

import { ChangeEntity } from './ChangeEntity';
import { IEntityMerging } from '../../../app/global/types';
import { ChannelFields } from '../../../pages/Navbar/Entity/Channel/CreateChannel/ChannelFields';
import { Plus } from '../../../shared/icons';
import { SearchEntity } from '../../../store/slices/selectSlice';
import { ClickShowContainer } from '../../ClickShowContainer';
import { MenuOption } from '../../MenuOption';
import { SelectWithOptions } from '../../SelectWithOptions';
import { TextWithTooltip } from '../../TextWithTooltip';
import { ObligatoryField } from 'widgets/ObligatoryField';

import style from './AddSingleChangeableEntity.module.scss';

interface IProps {
  searchEntity: SearchEntity;
  label: string;
  options: { id: number; name: string }[];
  field: string;
  className?: string;
  entity?: IEntityMerging;
  isEdit?: boolean;
  isError?: boolean;
  isObligatory?: boolean;
}

export const AddSingleChangeableEntity = ({
  searchEntity,
  label,
  options,
  field,
  className,
  entity,
  isEdit = false,
  isError = false,
  isObligatory = false,
}: IProps): JSX.Element => {
  const { setFieldValue } = useFormikContext();

  const [showSelect, setShowSelect] = useState(false);
  const [currentOption, setCurrentOption] = useState(entity || null);

  const newOptions = currentOption
    ? options?.filter((option) => option.id !== currentOption.id)
    : options;

  const clickHandle = (e) => {
    e.preventDefault();
    setShowSelect(true);
  };

  const handleTypeSelect = (option) => {
    const { id, level, type } = option;

    setCurrentOption(option);

    setFieldValue(field, id);
    setShowSelect(false);
    changeCopyrightHolder(level, type);
  };

  const changeCopyrightHolder = (level, type) => {
    setFieldValue(ChannelFields.Owner_level, level?.name);
    setFieldValue(ChannelFields.Owner_type, type?.name);
  };

  return (
    <div className={cn(style.container, className)}>
      <h3 className={cn(style.label, { [style.errorLabel]: isError })}>
        {currentOption === null ? label : `${label}`}{' '}
        {isObligatory && <ObligatoryField />}
      </h3>

      <div className={style.selectWrapper}>
        {!currentOption && (
          <button className={style.add} onClick={(e) => clickHandle(e)}>
            <Plus />
          </button>
        )}

        {showSelect && (
          <ClickShowContainer
            dropdown={showSelect}
            setDropdown={setShowSelect}
            className={style.select}
          >
            <SelectWithOptions
              searchEntity={searchEntity}
              options={options}
              onChange={handleTypeSelect}
            />
          </ClickShowContainer>
        )}
      </div>

      {currentOption && (
        <div className={style.editWrapper}>
          <TextWithTooltip
            text={currentOption.name}
            className={style.optionDescription}
          />
          {!isEdit && (
            <MenuOption
              position="left"
              onDelete={() => {
                setCurrentOption(null);
              }}
            >
              <ChangeEntity
                currentOption={currentOption}
                handleTypeSelect={handleTypeSelect}
                options={newOptions}
                searchEntity={searchEntity}
              />
            </MenuOption>
          )}
        </div>
      )}
    </div>
  );
};
