import { TopicCatalog } from '../../app/api/services/topicCatalog/types';
import { IEntityMerging } from '../../app/global/types';

interface IEntityTest {
  name: string;
  id?: number;
}

export const getTheIdArray = (
  entity: IEntityMerging[] | IEntityTest[] | undefined
): number[] => entity?.map((item) => item?.id) || [];

export const getTheIdTopics = (
  entity: TopicCatalog[][] | undefined
): number[] => entity?.map((item) => item[0].id) || [];

export const getTheArrayForFeeds = (
  entity: IEntityMerging[] | IEntityTest[] | undefined
): Array<{ id: number; position: number }> =>
  entity?.map((item, index) => ({ position: index + 1, id: item?.id })) || [];

export const getTheArrayForSection = (
  entity: IEntityMerging[] | IEntityTest[] | undefined
): Array<{
  entity_id: number;
  screen_element_position: number;
  screen_position: number;
  end_date: string;
}> =>
  entity?.map((item, index) => ({
    screen_element_position: index + 1,
    entity_id: item?.id,
    screen_position: item?.screen_position || item?.screenPosition,
    end_date: item?.end_date,
  })) || [];
