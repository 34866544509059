import cn from 'classnames';
import React, { useState } from 'react';

import { Delete } from '../../../shared/icons';
import { DeleteConfirmationModal } from '../../DeleteConfirmationModal';

import style from './DeleteButton.module.scss';

interface IProps {
  mode: 'circle' | 'flat';
  modalMode: 'modalCircle' | 'modalFlat';
  color?: 'grey';
  entity?: string;
  modalEntity?: string;
  onClick: (e) => void;
  customLabel?: string;
  type?: 'archive' | 'delete';
}

export const DeleteButton = React.memo(
  ({
    mode,
    modalMode,
    entity,
    modalEntity,
    color,
    onClick,
    customLabel,
    type = 'delete',
  }: IProps): JSX.Element => {
    const [showModal, setShowModal] = useState<boolean>(false);

    const closeModal = () => {
      setShowModal(false);
    };

    const openModal = () => {
      setShowModal(true);
    };

    const deleteButtonHandle = (
      e: React.MouseEvent<HTMLButtonElement, MouseEvent>
    ) => {
      e.preventDefault();
      onClick(e);
    };

    const label =
      type === 'delete'
        ? `Удалить ${entity ? entity : ''}`
        : `Архивировать ${entity ? entity : ''}`;

    return (
      <>
        <button
          type="button"
          className={cn(mode === 'circle' ? style.circle : style.flat, {
            [style.grey]: color === 'grey',
          })}
          onClick={openModal}
        >
          {mode === 'circle' ? (
            <Delete />
          ) : (
            <>{customLabel ? customLabel : label}</>
          )}
        </button>
        <DeleteConfirmationModal
          type={type}
          active={showModal}
          title={
            type === 'delete'
              ? `Удаление ${modalEntity}`
              : `Архивирование ${modalEntity}`
          }
          onSubmit={deleteButtonHandle}
          onClose={closeModal}
          className={cn(
            modalMode === 'modalCircle' ? style.modalCircle : style.modalFlat
          )}
        >
          <div>{`Вы уверены, что хотите ${
            type === 'delete' ? 'удалить' : 'архивировать'
          } ${entity}?`}</div>
          <div className={style.text}>
            {`Вы не сможете восстановить данную сущность, и все связи с другими сущностями будут утеряны`}
          </div>
        </DeleteConfirmationModal>
      </>
    );
  }
);
