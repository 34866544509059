export const Plus = (): JSX.Element => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="13"
    height="13"
    fill="none"
    viewBox="0 0 13 13"
  >
    <path
      fill="#6860F1"
      d="M11.774 5.746h-4.52v-4.52a.753.753 0 10-1.507 0v4.52H1.226a.753.753 0 000 1.507h4.52v4.521a.753.753 0 001.507 0v-4.52h4.521a.754.754 0 000-1.508z"
    ></path>
  </svg>
);
