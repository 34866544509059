import { MENU } from './menu';
import { useExpand } from '../../../hooks';
import { NavItemWithDropdown } from '../../../widgets';
import { PersonalButton } from '../../PersonalButton';

import style from './Header.module.scss';

export const Header = (): JSX.Element => {
  const expand = useExpand();

  return (
    <>
      {!expand && (
        <header>
          <nav className={style.navbar}>
            <div className={style.wrapper}>
              {MENU.map((item) => (
                <NavItemWithDropdown
                  key={item.title}
                  title={item.title}
                  subtitle={item.subtitle}
                  css={item.css}
                />
              ))}
            </div>

            <PersonalButton />
          </nav>
        </header>
      )}
    </>
  );
};
