import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { Feed } from '../../../app/api/services/feeds/types';
import { useLazyGetRelatedFeedElementsQuery } from '../../../app/api/services/relatedFeeds/relatedFeed';
import { ContainerWrapper } from '../../../widgets';
import { ExpandedViewFeed } from '../../ExpandedViewFeed';

import style from './RelatedFeedInEntity.module.scss';

interface IProps {
  feeds: Feed[];
  titleList: string[];
  classNameWrapper?: string;
  relatedFeeds?: Element[];
}

export const RelatedFeedInEntity = ({
  feeds,
  classNameWrapper,
}: IProps): JSX.Element => {
  const [relatedFeeds, setRelatedFeeds] = useState([]);

  const { pathname } = useLocation();

  const [getReletedFeedElements] = useLazyGetRelatedFeedElementsQuery();

  useEffect(() => {
    const getFullRelatedFeeds = async (related_feeds) => {
      related_feeds.forEach(async (el) => {
        const relatedFeedElements = await getReletedFeedElements({
          collection_id: el?.collection?.id,
          offset: 0,
          limit: 1000,
        }).unwrap();

        setRelatedFeeds((prev) => {
          const collection = {
            ...el.collection,
            elements: relatedFeedElements?.response,
          };
          const newEl = { ...el, collection };

          const newFeeds = [...prev, newEl];

          return newFeeds;
        });
      });
    };

    if (feeds) {
      getFullRelatedFeeds(feeds);
    }
  }, [feeds, getReletedFeedElements]);

  return (
    <ContainerWrapper
      title={`Связанные ленты ${
        pathname.includes('book-series') ? 'на странице' : 'в лодочке'
      }`}
      className={classNameWrapper}
    >
      {relatedFeeds?.length
        ? relatedFeeds?.map(
            (feed) =>
              feed && (
                // !!feed.collection.elements.length && (
                <ExpandedViewFeed
                  feed={feed}
                  key={feed.id}
                  className={style.hiddeSeparator}
                />
              )
            // )
          )
        : 'Здесь будут связанные ленты'}
    </ContainerWrapper>
  );
};
