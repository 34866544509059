import { Form, Formik, FormikHelpers } from 'formik';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import {
  fieldDescription,
  initialValues,
  validationSchema,
} from './formDescription';
import { useRegisterUserMutation } from '../../../store/actions/users';
import { DIGIT, RU_CHAR } from '../../../utils/regExp/regExp';
import { FieldRenderer } from '../../FieldRenderer';
import { Loader } from '../../Loader';
import { PrimaryButton } from '../../PrimaryButton';
import { IRegisterFormValues } from '../types';

export const RegisterForm = (): JSX.Element => {
  const [registerUser, { isError, isSuccess, isLoading }] =
    useRegisterUserMutation();

  const navigate = useNavigate();

  const handleSubmit = (
    body: IRegisterFormValues,
    actions: FormikHelpers<IRegisterFormValues>
  ): void => {
    registerUser(body);

    if (isSuccess) {
      actions.resetForm();
    }
  };

  useEffect(() => {
    if (isSuccess) {
      window.location.href = '/login';
    }
  }, [isSuccess, navigate]);

  useEffect(() => {
    if (isError) {
      toast.error('Что то пошло не так');
    }
  }, [isError]);

  return (
    <>
      {isLoading && <Loader />}

      <Formik
        initialValues={initialValues}
        validateOnBlur
        validationSchema={validationSchema}
        onSubmit={(
          values: IRegisterFormValues,
          actions: FormikHelpers<IRegisterFormValues>
        ): void => {
          handleSubmit(values, actions);
        }}
      >
        {(formik): JSX.Element => {
          formik.values.email = formik.values.email.replace(RU_CHAR, '');
          formik.values.first_name = formik.values.first_name.replace(
            DIGIT,
            ''
          );
          formik.values.last_name = formik.values.last_name.replace(DIGIT, '');

          return (
            <Form>
              <FieldRenderer fieldDescription={fieldDescription} />

              <PrimaryButton
                label="Зарегистрироваться"
                disabled={
                  !formik.isValid ||
                  formik.values.email.length === 0 ||
                  formik.values.password.length === 0 ||
                  formik.values.first_name.length === 0 ||
                  formik.values.last_name.length === 0
                }
              />
            </Form>
          );
        }}
      </Formik>
    </>
  );
};
