import { IResponseFilters } from './types';
import { mainApi } from '../config';
import { IResponseStats } from '../reports/types';
import { endpoints } from 'shared/api/endpoints/endpoints';

export const supermixResportApiQuery = mainApi.injectEndpoints({
  endpoints: (builder) => ({
    getStatsBySupermix: builder.query<
      IResponseStats,
      { month: string; supermix_id: string }
    >({
      query: (params) => ({
        url: `${endpoints.getStatsBySupermix}`,
        params: params,
      }),
    }),

    getSupermixDetails: builder.query<
      IResponseStats,
      { month: string; supermix_id: string; owner_id: number }
    >({
      query: (params) => ({
        url: `${endpoints.getSupermixDetails}`,
        params: params,
      }),
    }),

    getSupermixDetailsFilters: builder.query<IResponseFilters, void>({
      query: () => ({
        url: `${endpoints.getSupermixFilters}`,
      }),
    }),
  }),
});

export const {
  useLazyGetStatsBySupermixQuery,
  useGetSupermixDetailsFiltersQuery,
  useLazyGetSupermixDetailsQuery,
} = supermixResportApiQuery;
