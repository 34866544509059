import React, { useState } from 'react';

import TagsForCompilationsList from './TagsForCompilationsList/TagsForCompilationsList';
import { ListManagement } from '../../../../../components/ListManagement/ListManagement';

const TagsForCompilations = (): JSX.Element => {
  const [title, setTitle] = useState('');

  return (
    <>
      <ListManagement header="Теги для подборок" countEntityLabel={title} />

      <TagsForCompilationsList setTitle={setTitle} />
    </>
  );
};

export default TagsForCompilations;
