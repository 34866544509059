import { skipToken } from '@reduxjs/toolkit/query';
import { Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import {
  useCreateInfoSectionMutation,
  useDeleteInfoSectionMutation,
  useGetInfoSectionByIdQuery,
  useGetSectionListsQuery,
  useUpdateInfoSectionMutation,
} from '../../../../../../app/api/services/sections/sections';
import { useGetSupermixByConditionSearchQuery } from '../../../../../../app/api/services/supermix/supermix';
import { HeaderBlock, Router } from '../../../../../../components';
import { errorToast, successToast } from '../../../../../../utils';
import { ErrorAlert, Loader } from '../../../../../../widgets';
import { Description, MainParameters } from '../CreateInfoSectionComponents';
import {
  IInfoSectionValues,
  InfoSectionFields,
  validateCreateInfoSection,
} from '../helpers';

import style from './CreateInfoSection.module.scss';

export interface IInfoSection {
  description: string;
  name: string;
}

export const CreateInfoSection = (): JSX.Element => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [isToast, setIsToast] = useState(false);

  const {
    data: sectionLists,
    isLoading: isLoadingSectionLists,
    isError: errorSectionLists,
  } = useGetSectionListsQuery();

  const {
    data: section,
    isLoading: isLoadingGetById,
    isError: isErrorSectionById,
  } = useGetInfoSectionByIdQuery(id ? id : skipToken);

  const [
    createInfoSection,
    {
      isLoading: isLoadingCreate,
      isError: isCreateSectionError,
      isSuccess: isSuccessCreate,
      data,
    },
  ] = useCreateInfoSectionMutation();

  const [
    updateInfoSection,
    {
      isLoading: isLoadingUpdate,
      isError: isErrorUpdate,
      isSuccess: isSuccessUpdate,
    },
  ] = useUpdateInfoSectionMutation();

  const [
    deleteInfoSection,
    {
      isLoading: isLoadingDelete,
      isError: isErrorDelete,
      isSuccess: isSuccessDelete,
    },
  ] = useDeleteInfoSectionMutation();

  const isEdit = !!id;

  const sectionNames = sectionLists?.response?.info_section.names.map(
    (el, index) => ({
      name: el,
      id: index,
    })
  );

  const buttonNames = sectionLists?.response?.info_section?.block_texts.map(
    (el, index) => ({ name: el, id: index })
  );

  const [isValidateCheked, setIsValidateCheked] = useState(false);

  const { data: supermixes } = useGetSupermixByConditionSearchQuery({
    limit: 50, // TODO: переделать селект чтобы он поддерживал пагинацию если супермиксов станет больше 50
    offset: 0,
    enrichments: 'product',
  });

  const initialValues = {
    [InfoSectionFields.Description]: section?.response?.description,
    [InfoSectionFields.Name]: isEdit ? section?.response?.name : '',
    [InfoSectionFields.Is_active]: section?.response?.is_active,
    [InfoSectionFields.Blocks]: isEdit ? section?.response?.blocks : [],
  };

  const onSubmit = (values) => {
    isEdit ? updateInfoSection({ values, id: +id }) : createInfoSection(values);
  };

  useEffect(() => {
    successToast({
      success: isSuccessCreate,
      message: 'Раздел успешно создан',
      setIsToast,
      navigate,
      path: `/${Router.Section}/info/${Router.Edit}/${data?.response?.id}`,
    });

    successToast({
      success: isSuccessUpdate,
      message: 'Раздел успешно изменен',
      setIsToast,
    });

    successToast({
      success: isSuccessDelete,
      message: 'Раздел успешно удален',
      setIsToast,
      navigate,
      path: `/${Router.Section}`,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccessCreate, isSuccessDelete, isSuccessUpdate]);

  useEffect(() => {
    errorToast(isErrorDelete, 'Не удалось удалить раздел', setIsToast);
    errorToast(isErrorUpdate, 'Не удалось обновить раздел', setIsToast);
    errorToast(isErrorSectionById, 'Не удалось загрузить раздел', setIsToast);
    errorToast(isCreateSectionError, 'Не удалось создать раздел', setIsToast);
    errorToast(
      errorSectionLists,
      'Не удалось загрузить справочники',
      setIsToast
    );
  }, [
    isErrorDelete,
    isErrorUpdate,
    isErrorSectionById,
    isCreateSectionError,
    errorSectionLists,
  ]);

  return (
    <div className={style.container}>
      {(isLoadingCreate ||
        isLoadingGetById ||
        isLoadingDelete ||
        isLoadingUpdate ||
        isLoadingSectionLists) &&
      !isToast ? (
        <Loader />
      ) : null}
      {!isLoadingGetById && (
        <Formik
          initialValues={initialValues}
          enableReinitialize
          onSubmit={onSubmit}
          validateOnBlur={isValidateCheked}
          validateOnChange={isValidateCheked}
          validate={(e) =>
            validateCreateInfoSection(e as unknown as IInfoSectionValues)
          }
        >
          {(formik) => {
            if (Object.keys(formik.errors).length) {
              setIsValidateCheked(true);
            }

            return (
              <Form
                className={style.form}
                onKeyDown={(event) =>
                  event.key === 'Enter' && event.preventDefault()
                }
              >
                <HeaderBlock
                  deleteEntity={() => deleteInfoSection(+id)}
                  deleteBtnEntity="раздел"
                  editEntity="информационного раздела"
                />
                <Description
                  sectionName={section?.response?.name}
                  elementNames={supermixes?.response}
                  sectionNames={sectionNames}
                  buttonNames={buttonNames}
                  blocks={section?.response?.blocks}
                />
                <MainParameters date={section?.response?.created_at} />
                {Object.keys(formik.errors).length > 0 && (
                  <ErrorAlert text="Пожалуйста, заполните все обязательные поля" />
                )}
              </Form>
            );
          }}
        </Formik>
      )}
    </div>
  );
};
