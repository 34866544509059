import * as yup from 'yup';

export enum TagFields {
  Name = 'name',
  Description = 'description',
  IsActive = 'is_active',
  Level = 'level',
}

export const titleListRelationFeed = [
  'Плейлисты',
  'Подборка плейлистов',
  'Редакторская лента',
];

export const validateTagFields = yup.object().shape({
  description: yup.string().required(),
});

export interface IValidateTag {
  name: boolean;
  description: boolean;
  level: boolean;
}

export interface IInitialValues {
  description: string;
  is_active: boolean;
  name: string;
  level: string | number;
}

// eslint-disable-next-line complexity, max-statements
export const validateCreateTag = (values: IInitialValues): object => {
  const errors = {} as IValidateTag;

  if (!values.name) {
    errors.name = true;
  }

  if (!values.description) {
    errors.description = true;
  }

  if (!values.level) {
    errors.level = true;
  }

  return errors;
};
