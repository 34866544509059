import cn from 'classnames';
import { memo, useState } from 'react';
import { GroupBase } from 'react-select';
import { LoadOptions } from 'react-select-async-paginate';

import { IEntityMerging } from '../../../app/global/types';
import { Edit, Plus } from '../../../shared/icons';
import { SearchEntity } from '../../../store/slices/selectSlice';
import { ClickShowContainer } from '../../ClickShowContainer';
import { Img } from '../../Img';
import { SelectWithOptions } from '../../SelectWithOptions';
import { TextWithTooltip } from '../../TextWithTooltip';
import { SelectWithOptionsAndPagination } from 'widgets/SelectWithOptionsAndPagination';

import style from './KitItem.module.scss';

interface IProps {
  searchEntity: SearchEntity;
  options: Array<IEntityMerging>;
  item: IEntityMerging;
  elements: IEntityMerging[];
  onClick: (option) => void;
  withPagination?: boolean;
  loadOptions?: LoadOptions<
    { name: string },
    GroupBase<{ name: string }>,
    { offset?: number; offsetSearch?: number }
  >;
  additional?: { offset?: number; offsetSearch?: number };
}

export const KitItem = memo(
  ({
    searchEntity,
    options,
    item,
    elements,
    onClick,
    withPagination,
    loadOptions,
    additional,
  }: IProps): JSX.Element => {
    const [show, setShow] = useState<boolean>(false);

    const selectedOption = null;

    const [move, setMove] = useState(false);

    const [currentOption] = useState(item || null);
    const handleTypeSelect = (option) => {
      if (!option) {
        return;
      }
      setShow(false);

      addOption(option);
    };

    const addOption = (option) => {
      const isInArray = !!elements?.find(
        (element) => element?.id === option?.id
      );

      if (isInArray) {
        return;
      }
      onClick(option);
    };

    return (
      <div className={style.container}>
        <div
          className={style.block}
          onClick={() => setShow((prevState) => !prevState)}
          onMouseMove={() => setMove(true)}
          onMouseLeave={() => setMove(false)}
        >
          {currentOption ? (
            <Img
              className={style.image}
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              //TODO исправить, когда бэк сделает единую сущность для картинок
              src={
                currentOption?.image?.url ||
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                currentOption?.primary_image ||
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                currentOption?.primary_image_url ||
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                currentOption?.image_url
              }
            />
          ) : (
            <div className={style.plusButton}>
              <Plus />
            </div>
          )}

          {move && currentOption && (
            <div className={style.editHover}>
              <Edit />
            </div>
          )}
        </div>

        {show && (
          <ClickShowContainer
            dropdown={show}
            setDropdown={setShow}
            className={style.select}
          >
            {withPagination ? (
              <SelectWithOptionsAndPagination
                searchEntity={searchEntity}
                onChange={handleTypeSelect}
                loadOptions={loadOptions}
                defaultOptions={options}
                additional={additional}
              />
            ) : (
              <SelectWithOptions
                searchEntity={searchEntity}
                options={options}
                onChange={handleTypeSelect}
                value={selectedOption}
              />
            )}
          </ClickShowContainer>
        )}
        <div
          className={cn(
            style.label,
            currentOption?.name ? style.hasName : style.noName
          )}
        >
          <div className={style.titleDescription}>
            {currentOption ? (
              <TextWithTooltip
                className={style.hint}
                text={currentOption?.name}
              />
            ) : (
              'Название'
            )}
          </div>
        </div>
      </div>
    );
  }
);
