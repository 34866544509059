import cn from 'classnames';
import { Field, useField } from 'formik';
import { FC, memo } from 'react';

import { IFieldDescription } from '../../../app/global/types';

import style from './InputForTheLoginForm.module.scss';

export const InputForTheLoginForm: FC<IFieldDescription> = memo(
  (props): JSX.Element => {
    const [, meta] = useField(props);

    const errorText = meta.error && meta.touched ? meta.error : null;

    return (
      <div className={cn(style.inputContainer)}>
        <Field
          type={props.type}
          name={props.name}
          className={cn(style.input, {
            [style.invalid]: errorText,
            [style.valid_input]: !errorText,
          })}
          placeholder=" "
          autoComplete="off"
        />
        <label htmlFor={props.name} className={style.label}>
          {props.label}
        </label>
        <span className={style.errorText}>{errorText}</span>
      </div>
    );
  }
);
