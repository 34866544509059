import React, { useState } from 'react';

import { Copy, CopyActive } from '../../../shared/icons';

import style from './CopyButton.module.scss';

interface IProps {
  text: string;
  disabled?: boolean;
}

export const CopyButton = React.memo(
  ({ text, disabled }: IProps): JSX.Element => {
    const [btnClicked, setBtnClicked] = useState(false);

    const copyText = () => {
      navigator.clipboard.writeText(text);
      setBtnClicked(true);
    };

    return (
      <button
        disabled={disabled}
        className={style.copyButton}
        type="button"
        onClick={copyText}
      >
        {btnClicked ? <CopyActive /> : <Copy />}
      </button>
    );
  }
);
