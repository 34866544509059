import { lazy, Suspense } from 'react';

import { Loader } from '../../../widgets';

const CounterpartyCandidates = lazy(() => import('./CounterpartyCandidates'));

export const CounterpartyCandidates_Lazy = (): JSX.Element => (
  <>
    <Suspense fallback={<Loader />}>
      <CounterpartyCandidates />
    </Suspense>
  </>
);
