import React, { Suspense } from 'react';

import TagsForCompilations from './TagsForCompilations';
import { Loader } from '../../../../../widgets';

export const TagsForCompilations_Lazy = (): JSX.Element => (
  <>
    <Suspense fallback={<Loader />}>
      <TagsForCompilations />
    </Suspense>
  </>
);
