import moment from 'moment';
import React from 'react';

import { TagCatalog } from '../../../../../../../../app/api/services/tagCatalog/types';
import {
  ContainerWrapper,
  CopyButton,
  TextWithTooltip,
  TitleWithDescription,
} from '../../../../../../../../widgets';

import style from './Characteristics.module.scss';
interface IProps {
  entity: TagCatalog;
}

export const Characteristics = ({ entity }: IProps): JSX.Element => (
  <div className={style.gridItem3}>
    <div className={style.headerWrapper}>
      <ContainerWrapper title="Характеристики" className={style.wrapper}>
        <div className={style.characteristics}>
          <h3 className={style.count}>
            {'UUID:'}
            <TextWithTooltip text={entity?.uuid} className={style.entity} />
            {entity?.uuid && <CopyButton text={entity?.uuid} />}
          </h3>
          <TitleWithDescription
            title="Дата создания:"
            description={moment(entity?.created_at).format('YYYY/MM/DD')}
          />
        </div>
      </ContainerWrapper>
    </div>
  </div>
);
