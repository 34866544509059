import React, { forwardRef, HTMLAttributes } from 'react';

import style from './ThemeOrderCard.module.scss';
type ItemProps = HTMLAttributes<HTMLDivElement> & {
  img: string;
  name: string;
};

export const ThemeOrderCard = forwardRef<HTMLDivElement, ItemProps>(
  ({ name, img, ...props }, ref) => (
    <div ref={ref} className={style.wrapper} {...props}>
      <h4
        className={
          name.split(' ')[0].length > 13 ? style.titleLong : style.title
        }
      >
        {name}
      </h4>
      <img className={style.img} src={img} alt="" />
    </div>
  )
);
