import { Suspense, lazy } from 'react';

import { Loader } from '../../../../../widgets';

const RegistryRelatedFeeds = lazy(() => import('./RegistryRelatedFeeds'));

export const RegistryRelatedFeeds_Lazy = (): JSX.Element => (
  <>
    <Suspense fallback={<Loader />}>
      <RegistryRelatedFeeds />
    </Suspense>
  </>
);
