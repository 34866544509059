import React, { memo } from 'react';

import { ColumnTitle } from '../../../../../../widgets';

import style from './SectionListHeader.module.scss';

export const SectionListHeader = memo(
  (): JSX.Element => (
    <div className={style.listHeader}>
      <ColumnTitle text="Название" />
      <ColumnTitle text="Картинка" />
      <ColumnTitle text="Вес" />
      <ColumnTitle text="Кол-во лент" />
      <ColumnTitle text="Дата создания" />
    </div>
  )
);
