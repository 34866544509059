import React, { Suspense } from 'react';

import { Loader } from '../../../../../../widgets';

const CreateRelatedFeed = React.lazy(() => import('./CreateRelatedFeed'));

export const CreateRelatedFeed_Lazy = (): JSX.Element => (
  <>
    <Suspense fallback={<Loader />}>
      <CreateRelatedFeed />
    </Suspense>
  </>
);
