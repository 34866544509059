import cn from 'classnames';
import { useFormikContext } from 'formik';

import style from './InputNumber.module.scss';

interface TProps {
  field: string;
  value: number;
  title: string;
  className?: string;
  classNameWrapper?: string;
  placeholder?: string;
  maxValue?: number;
  showPercentage?: boolean;
}

export const InputNumber = ({
  field,
  value,
  title,
  maxValue,
  className,
  placeholder,
  classNameWrapper,
  showPercentage = false,
}: TProps): JSX.Element => {
  const { setFieldValue } = useFormikContext();

  return (
    <div className={cn(style.wrapper, classNameWrapper)}>
      <h3 className={style.title} data-testid="span-title">
        {title}
      </h3>
      <input
        type="number"
        step="1"
        min="0"
        max={maxValue}
        placeholder={placeholder}
        aria-label="input-title"
        className={cn(
          style.countElement,
          style.placeholder,
          {
            [style.option_choose]: !!value,
          },
          className
        )}
        value={value ? value : ''}
        onChange={(e) => {
          setFieldValue(
            field,
            Number(e.target.value.substring(0, String(maxValue).length))
          );
        }}
      />
      {showPercentage && <span className={style.percentage}>%</span>}
    </div>
  );
};
