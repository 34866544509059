import cn from 'classnames';
import { ReactNode } from 'react';

import { Separator } from '../../Separator';

import style from './ContainerWrapper.module.scss';

interface IProps {
  title: ReactNode | string;
  className?: string;
  children?: ReactNode;
  isError?: boolean;
}

export const ContainerWrapper = ({
  title,
  className,
  children,
  isError = false,
}: IProps): JSX.Element => (
  <div className={cn(style.containerWrapper, className)}>
    <h2
      className={cn(style.blockTitle, {
        [style.error]: isError,
      })}
    >
      {title}
    </h2>
    <Separator />
    {children}
  </div>
);
