import { IRoot, TagCatalog, newTagCatalog } from './types';

export interface IResponseGetTagCatalogAdapter extends IRoot {
  response: TagCatalog[];
}

export interface IResponseGetTagCatalog extends IRoot {
  response: {
    tags: newTagCatalog[];
    subTags: newTagCatalog[];
  };
}

export const getTagCatalogAdapter = (
  data: IResponseGetTagCatalogAdapter
): IResponseGetTagCatalog => {
  const { response } = data;

  const getChildrenTags = (id) =>
    response.filter((item) => item.ancestor_id === id);

  const getParentTitle = (id) => {
    const tag = response.filter((item) => item.id === id);

    return tag[0]?.name;
  };

  const tagLevelOne: newTagCatalog[] = data.response
    .filter((item) => item.level === 1)
    .map((item) => ({ ...item, elements: getChildrenTags(item.id) }));

  const tagLevelTwo: newTagCatalog[] = data.response
    .filter((item) => item.level === 2)
    .map((item) => ({
      ...item,
      parentTitle: getParentTitle(item.ancestor_id),
      elements: [
        ...getChildrenTags(item.id).map((item) => ({
          ...item,
          elements: getChildrenTags(item.id),
        })),
      ],
    }));

  return {
    ...data,
    response: {
      tags: tagLevelOne,
      subTags: tagLevelTwo,
    },
  };
};
