export const placeholderValue = (
  title: string,
  switchValue: boolean
): string[] => {
  const quantitativeIndicator = [
    'Кол-во проигрываний',
    'скачан ролик',
    'Отложен (в избранное)',
    'Одобрение (лайки)',
    'Одобрение (дизлайки)',
    'Поделились роликом',
    'Комментарий',
    'Жалоба',
    'Кол-во роликов',
    'Подписчиков',
    'Кол-во плейлистов',
    'Кол-во подписчиков',
    'Кол-во каналов',
    'Проигрывания всех роликов',
    'Кол-во элементов в ленте',
    'Купили тариф за месяц',
    'Арендовали тариф за месяц',
    'Подписались на тариф за месяц',
    'Количество сессий пользователя',
    'LTV',
    'CAC',
    'gross profit',
    'Просмотров (для историй)',
    'Вес',
  ];

  const raiting = ['Рейтинг ленты', 'Рейтинг автора'];

  const indicatorWithPercent = [
    'Комиссия площадки (%)',
    'Скидки (%)',
    'НДС (%)',
  ];

  if (quantitativeIndicator.includes(title)) {
    return switchValue ? ['100', '100 000 000'] : ['100'];
  } else if (raiting.includes(title)) {
    return switchValue ? ['1', '5'] : ['5'];
  } else if (indicatorWithPercent.includes(title)) {
    return switchValue ? ['10', '50'] : ['30'];
  } else {
    return ['0'];
  }
};
