interface IProps {
  color: string;
  className?: string;
}

export const ArrowDown = ({ color, className }: IProps): JSX.Element => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="14"
    height="10"
    fill="none"
    viewBox="0 0 10 6"
  >
    <path
      fill={color}
      d="M9.424 1.688L5.39 5.493a.422.422 0 01-.156.096.558.558 0 01-.18.028.558.558 0 01-.18-.028.422.422 0 01-.156-.096L.672 1.688a.52.52 0 01-.168-.395c0-.158.06-.294.18-.407.12-.113.26-.169.42-.169.16 0 .3.056.42.17l3.53 3.319 3.53-3.32a.581.581 0 01.413-.158c.165 0 .307.057.427.17.12.113.18.244.18.395 0 .15-.06.282-.18.395z"
    ></path>
  </svg>
);
