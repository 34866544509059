import { useEffect } from 'react';
import { toast, ToastContainer, Zoom } from 'react-toastify';

interface IProps {
  error: boolean;
}

export const BoundaryToast = ({ error }: IProps): JSX.Element => {
  useEffect(() => {
    if (error) {
      toast.error('Что-то пошло не так. Обновите страницу');
    }
  }, [error]);

  return (
    <ToastContainer
      position="top-center"
      autoClose={2000}
      hideProgressBar
      newestOnTop={false}
      transition={Zoom}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss={false}
      draggable
      pauseOnHover={false}
      theme="colored"
    />
  );
};
