// Оставлю на случай если придется возвращаться к   исходному решению
export const parseDateMonthYear = (date: string | undefined): string => {
  if (date === 'invalid') {
    return '';
  }

  return date ? new Date(date).toLocaleDateString('en-ca') : '';
};

export const dateConverter = (date: string | undefined): string => {
  if (date === 'invalid' || !date) {
    return '';
  }

  const dateObj = new Date(Date.parse(date));
  const year = dateObj.getFullYear();
  const month = ('0' + (dateObj.getMonth() + 1)).slice(-2);
  const day = ('0' + dateObj.getDate()).slice(-2);
  const formattedDate = `${day}.${month}.${year}`;

  return formattedDate;
};
