interface IProps {
  color?: string;
}

export const Trash = ({ color = '#7d7d7d' }: IProps): JSX.Element => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="17"
    height="18"
    fill="none"
    viewBox="0 0 17 18"
  >
    <path
      fill={color}
      d="M6.868 3.54v.204h3.264V3.54a1.632 1.632 0 00-3.264 0zm-1.02.204V3.54a2.651 2.651 0 015.303 0v.204h4.59a.51.51 0 010 1.02h-1.19l-.816 9.73a2.957 2.957 0 01-2.947 2.71H6.212a2.958 2.958 0 01-2.947-2.71l-.816-9.73H1.26a.51.51 0 110-1.02h4.589zM4.283 14.41a1.937 1.937 0 001.93 1.776h4.575a1.938 1.938 0 001.931-1.776l.809-9.645H3.473l.809 9.645zM7.48 7.517a.51.51 0 10-1.02 0v5.915a.51.51 0 001.02 0V7.517zm2.55-.51a.51.51 0 01.51.51v5.915a.51.51 0 11-1.02 0V7.517a.51.51 0 01.51-.51z"
    ></path>
  </svg>
);
