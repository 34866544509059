import { Suspense, lazy } from 'react';

import { Loader } from '../../../../../../widgets';

const CreateReader = lazy(() => import('./CreateReader'));

export const CreateReader_Lazy = (): JSX.Element => (
  <>
    <Suspense fallback={<Loader />}>
      <CreateReader />
    </Suspense>
  </>
);
