export const DragAndDrop = (): JSX.Element => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="15"
    height="20"
    fill="none"
    viewBox="0 0 20 25"
  >
    <path
      fill="#6860F1"
      d="M10 0l4.33 4.5H5.67L10 0zM10 25l4.33-4.5H5.67L10 25zM0 8h20v1.1H0V8zM0 12h20v1.1H0V12zM0 16h20v1.1H0V16z"
    ></path>
  </svg>
);

export const DndIcon = (): JSX.Element => (
  <svg
    width="20"
    height="10"
    viewBox="0 0 20 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <line
      x1="0.000585937"
      y1="0.5"
      x2="20.0006"
      y2="0.523438"
      stroke="#222222"
    />
    <line
      x1="0.000585937"
      y1="4.5"
      x2="20.0006"
      y2="4.52344"
      stroke="#222222"
    />
    <line
      x1="0.000585937"
      y1="8.5"
      x2="20.0006"
      y2="8.52344"
      stroke="#222222"
    />
  </svg>
);

export const DndIconActive = (): JSX.Element => (
  <svg
    width="44"
    height="44"
    viewBox="0 0 44 44"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g filter="url(#filter0_d_12009_22452)">
      <circle cx="22" cy="22" r="18" fill="white" />
    </g>
    <path d="M22 10L26.3301 14.5H17.6699L22 10Z" fill="#6860F1" />
    <path d="M22 35L26.3301 30.5H17.6699L22 35Z" fill="#6860F1" />
    <path d="M12 18H32V19.1H12V18Z" fill="#6860F1" />
    <path d="M12 22H32V23.1H12V22Z" fill="#6860F1" />
    <path d="M12 26H32V27.1H12V26Z" fill="#6860F1" />
    <defs>
      <filter
        id="filter0_d_12009_22452"
        x="0"
        y="0"
        width="44"
        height="44"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset />
        <feGaussianBlur stdDeviation="2" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.395833 0 0 0 0 0.395833 0 0 0 0 0.395833 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_12009_22452"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_12009_22452"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);
