import style from './EntityCount.module.scss';

interface IEntityCount {
  entity: string;
  count: number;
}

export const EntityCount = ({ entity, count }: IEntityCount): JSX.Element => (
  <h3 className={style.count} data-testid="entity">
    Кол-во {entity}: <span className={style.countText}>{count}</span>
  </h3>
);
