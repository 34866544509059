export const typeTheme = {
  I: 'группа тем',
  II: 'тема',
  III: 'подтема',
};

export const level = {
  '1': 'I',
  '2': 'II',
  '3': 'III',
};
