export const Close = (): JSX.Element => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="14"
    height="14"
    fill="none"
    viewBox="0 0 14 14"
  >
    <path
      fill="#434343"
      d="M11.928 1.191L7 6.12 2.073 1.19a.624.624 0 00-.882.882L6.116 7 1.19 11.926a.623.623 0 10.881.883L7 7.883l4.928 4.927a.623.623 0 00.88-.881L7.882 7l4.928-4.928a.625.625 0 10-.881-.883v.001z"
    ></path>
  </svg>
);
