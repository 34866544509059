import cn from 'classnames';
import { isEmpty } from 'lodash';
import { useSearchParams } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from '../../../hooks';
import { Close, Edit } from '../../../shared/icons';
import {
  IStateFilters,
  setInInitialValue,
} from '../../../store/slices/filterSlice';
import icon from '../assets/filter.svg';

import style from './Filter.module.scss';

interface IProps {
  text: string;
  onChange: (title: string) => void;
  type: 'massActions' | 'filter';
  isMassActions?: boolean;
  openModal?: () => void;
}
export const Filter = ({
  text,
  onChange,
  type,
  isMassActions = false,
  openModal,
}: IProps): JSX.Element => {
  const [, setSearchParams] = useSearchParams();
  const { entity, isLoading, isLoadingEntity, filters } = useAppSelector(
    (state) => state.filterReducer as IStateFilters
  );

  const dispatch = useAppDispatch();
  const isSearch =
    (!isEmpty(filters.includes) && type === 'filter') ||
    (!isEmpty(filters.excludes) && type === 'filter');

  const handleClear = () => {
    setSearchParams();
    dispatch(setInInitialValue(entity));
  };

  return (
    <div className={cn(style.filter, { [style.filterSearch]: isSearch })}>
      {!isSearch ? (
        <button
          type="button"
          onClick={() => onChange(text)}
          className={style.buttonOpen}
          disabled={isLoading || isLoadingEntity}
        >
          <img className={style.icon} src={icon} alt="icon" />
          {text}
        </button>
      ) : (
        <>
          <button
            type="button"
            className={style.buttonClear}
            onClick={handleClear}
            disabled={isLoading}
          >
            <Close />
          </button>
          <p onClick={() => onChange(text)} className={style.text}>
            {text}
          </p>
        </>
      )}
      {type === 'massActions' && isMassActions && (
        <button
          type="button"
          className={style.buttonEdit}
          onClick={openModal}
          disabled={isLoading}
        >
          <Edit />
        </button>
      )}
    </div>
  );
};
