import cn from 'classnames';
import React, { useState } from 'react';
import { Tooltip } from 'react-tooltip';

import { newTagCatalog } from '../../../../app/api/services/tagCatalog/types';
import { newTopicCatalog } from '../../../../app/api/services/topicCatalog/types';
import { useAppDispatch } from '../../../../hooks';
import { PlusInTheCircle } from '../../../../shared/icons';
import {
  CatalogType,
  ICatalogHight,
  deleteGroup,
} from '../../../../store/slices/catalogSlice';
import { HangingInput, MenuEditOrOpen, Separator } from '../../../../widgets';
import { Router } from '../../../Router/routerEnum';
import { CatalogWarningModal } from '../../CatalogWarningModal';

import style from './CardCatalogGroup.module.scss';

interface IProps {
  item: ICatalogHight | newTopicCatalog | newTagCatalog;
  typeCatalog: CatalogType;
  catalogNames?: string[];
  onEdit?: (id: number, name: string) => void;
  onCreate?: (id: number, name: string) => void;
  onDelete?: (id: number) => void;
}

export const CardCatalogGroup = ({
  item,
  typeCatalog,
  onEdit,
  onCreate,
  onDelete,
  catalogNames,
}: IProps): JSX.Element => {
  const [value, setValue] = useState<string>(item.name);
  const [, setSubValue] = useState<string>('');
  const [showInput, setShowInput] = useState(false);
  const [showTitleMenu, setShowTitleMenu] = useState(false);
  const [isEditName, setIsEditName] = useState(!item?.name);
  const [showWarning, setShowWarning] = useState<boolean>(false);

  const dispatch = useAppDispatch();

  const closeWarning = () => {
    setShowWarning(false);
  };

  const openWarning = () => {
    setShowWarning(true);
  };

  const dispatchEditTitle = (text: string) => {
    const isDuplicate = catalogNames.includes(text.toLowerCase().trim());
    if (isDuplicate) {
      openWarning();
      setTimeout(() => {
        closeWarning();
      }, 5000);
    } else {
      onEdit(item.id, text.trim());
      setIsEditName(false);
    }
  };

  const handleAddElement = (name) => {
    const isDuplicate = catalogNames.includes(name.toLowerCase().trim());
    if (item.id === null) {
      if (isDuplicate) {
        openWarning();
        setTimeout(() => {
          closeWarning();
        }, 5000);
      } else {
        onCreate(item.id, name.trim());
        setShowInput(false);
      }
    } else {
      onCreate(item.id, name.trim());
      setShowInput(false);
    }
  };

  const handleEditTitle = () => {
    setIsEditName(true);
    setShowTitleMenu(false);
  };

  const handleDeleteGroup = () => {
    if (typeCatalog === 'theme' || typeCatalog === 'tag') {
      onDelete(item.id);
    } else {
      dispatch(deleteGroup({ type: typeCatalog, id: item.id }));
    }
  };

  return (
    <>
      <li className={style.wrapper}>
        <div className={style.titleBlock}>
          {!isEditName ? (
            <div className={style.titleWrapper}>
              <h4
                className={cn(style.input, style.name)}
                onClick={() => setShowTitleMenu(true)}
              >
                {item?.name}
              </h4>
              {showTitleMenu && (
                <MenuEditOrOpen
                  dropdown={showTitleMenu}
                  setDropdown={setShowTitleMenu}
                  handleEdit={handleEditTitle}
                  handleDelete={handleDeleteGroup}
                  titleEdit="название"
                  titleDelete={
                    typeCatalog === 'tag' ? 'высшую категорию' : 'группу тем'
                  }
                  link={`/${
                    typeCatalog === 'theme' ? Router.Theme : Router.Tag
                  }/${Router.Edit}/${item?.id}`}
                  className={style.contextMenu}
                />
              )}
            </div>
          ) : (
            <input
              value={value}
              autoFocus
              placeholder="Введите название"
              className={style.input}
              onChange={(e) => {
                setValue(e.target.value);
              }}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  e.preventDefault();
                  item.id === null
                    ? handleAddElement(value)
                    : dispatchEditTitle(value);
                }
              }}
              onBlur={() =>
                item.id === null
                  ? handleAddElement(value)
                  : dispatchEditTitle(value)
              }
            />
          )}
          <div className={style.addButtonWrapper}>
            <button
              type="button"
              className={style.buttonPlusMiddle}
              onClick={() => setShowInput(true)}
              disabled={!item.name?.length}
              data-tooltip-id="plusBtn"
              data-tooltip-content={
                typeCatalog === 'tag'
                  ? 'Добавить высокочастотник'
                  : 'Добавить тему'
              }
            >
              <PlusInTheCircle />
            </button>
            <Tooltip id="plusBtn" place="bottom" />
            {showInput && (
              <HangingInput
                position="right"
                placeholder="Введите название"
                showInput={showInput}
                onChange={(e) => {
                  setSubValue(e.target.value);
                }}
                onKeyDown={(e) => handleAddElement(e.target.value)}
                onBlur={(e) => handleAddElement(e.target.value)}
                className={style.inputWidth}
              />
            )}
          </div>
        </div>
        <Separator color="grey" className={style.separator} />
        <ul className={style.listBlock}>
          {item?.elements?.map((item) => (
            <li key={item.id} className={style.listItem}>
              <a href={`#${item.name}-${item.id}`}>{item.name}</a>
            </li>
          ))}
        </ul>
      </li>
      <CatalogWarningModal
        active={showWarning}
        titleWarning={typeCatalog === 'tag' ? 'Тег' : 'Тема'}
        textWarning={typeCatalog === 'tag' ? 'этого тега' : 'этой темы'}
      />
    </>
  );
};
