import React from 'react';

import { Router } from 'components';
import { StatsLinkList } from 'widgets';

import style from './AllTransactions.module.scss';

const links = [
  { name: 'Отчёты банков', href: Router.Banks },
  { name: 'Сводная таблица', href: Router.Summary },
  { name: 'Итоговая таблица', href: Router.Total },
];

export const AllTransactions = (): JSX.Element => (
  <div className={style.wrapper}>
    <h2 className={style.title}>Все транзакции</h2>
    <StatsLinkList links={links} />
  </div>
);
