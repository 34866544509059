import { Suspense, lazy } from 'react';

import { Loader } from '../../../../../widgets';

const Translator = lazy(() => import('./Translator'));

export const Translator_Lazy = (): JSX.Element => (
  <>
    <Suspense fallback={<Loader />}>
      <Translator />
    </Suspense>
  </>
);
