import cn from 'classnames';

import style from './Checkbox.module.scss';

interface IProps {
  checked: boolean;
  handleChek: () => void;
  name: string;
  className: string;
}

export const CheckboxMass = ({
  checked,
  handleChek,
  name,
  className,
}: IProps): JSX.Element => (
  <>
    <input
      id={name}
      className={cn(className, style.checkbox)}
      type="checkbox"
      checked={checked}
      onChange={handleChek}
    />
    <label htmlFor={name} />
  </>
);
