import { Suspense, lazy } from 'react';

import { Loader } from '../../../../../widgets';

const ClipCompilation = lazy(() => import('./ClipCompilation'));

export const ClipCompilation_Lazy = (): JSX.Element => (
  <>
    <Suspense fallback={<Loader />}>
      <ClipCompilation />
    </Suspense>
  </>
);
