import { ToastContainer, Zoom } from 'react-toastify';

import './Toast.scss';

export const Toast = (): JSX.Element => (
  <ToastContainer
    position="top-center"
    autoClose={2000}
    hideProgressBar
    newestOnTop={false}
    transition={Zoom}
    closeOnClick
    rtl={false}
    pauseOnFocusLoss={false}
    draggable
    pauseOnHover={false}
    theme="colored"
  />
);
