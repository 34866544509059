import { useFormikContext } from 'formik';
import moment from 'moment';

import { Collection } from '../../../../../../../../app/api/services/collections/types';
import {
  ContainerWrapper,
  Switch,
  TitleWithDescription,
} from '../../../../../../../../widgets';
import { AutoSectionFields } from '../../../helpers';

import style from './MainParameters.module.scss';

export const MainParameters = ({ date }: { date: string }): JSX.Element => {
  const { values } = useFormikContext<Collection>();

  return (
    <ContainerWrapper title="Главные параметры">
      <div className={style.mainParametersWrapper}>
        <Switch
          label="Активность"
          field={AutoSectionFields.Is_active}
          value={values.is_active}
        />
        <TitleWithDescription
          title="Дата создания:"
          description={date && moment(date).format('YYYY/MM/DD HH:mm')}
        />
      </div>
    </ContainerWrapper>
  );
};
