import cn from 'classnames';

import { DOTS, usePagination } from '../../../hooks';
import { LessSign, PlusSign } from '../../../shared/icons';

import style from './Pagination.module.scss';

interface IProps {
  totalCount: number;
  siblingCount?: number;
  currentPage: number;
  pageSize: number;
  onPageChange: (page: number | string) => void;
}

const Pagination = (props: IProps): JSX.Element => {
  const {
    totalCount,
    siblingCount = 1,
    currentPage,
    pageSize,
    onPageChange,
  } = props;

  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize,
  });

  // if (currentPage === 0 || paginationRange.length < 2) {
  //   return null;
  // }

  const lastPage = paginationRange[paginationRange.length - 1];

  const onNext = () => {
    if (currentPage === lastPage) {
      return;
    }
    onPageChange(currentPage + 1);
  };

  const onPrevious = () => {
    if (currentPage - 1 === 0) {
      return;
    }
    onPageChange(currentPage - 1);
  };

  return (
    <div className={cn(style.container)}>
      <div
        className={cn(style.buttonContainer, style.rightButton, {
          [style.disabled]: currentPage === 1,
        })}
        onClick={onPrevious}
      >
        <div className={style.buttonWrapper}>
          <LessSign color={currentPage === 1 ? '#7d7d7d' : '#434343'} />
          <span className={style.leftDesc}>Назад</span>
        </div>
      </div>
      <ul className={cn(style.paginationContainer)}>
        {paginationRange.map((pageNumber) => {
          if (pageNumber === DOTS) {
            return (
              <li
                key={`${Math.random()}2`}
                className={cn(style.paginationItem, style.dots)}
              >
                &#8230;
              </li>
            );
          }

          return (
            <li
              key={`${Math.random()}2`}
              className={cn(style.paginationItem, {
                [style.selected]: pageNumber === currentPage,
              })}
              onClick={() => onPageChange(pageNumber)}
            >
              {pageNumber}
            </li>
          );
        })}
      </ul>
      <div
        className={cn(style.buttonContainer, style.leftButton, {
          [style.disabled]: currentPage === 1,
        })}
        onClick={onNext}
      >
        <div className={style.buttonWrapper}>
          <span className={style.rightDesc}>Вперед</span>
          <PlusSign color={currentPage === lastPage ? '#7d7d7d' : '#434343'} />
        </div>
      </div>
    </div>
  );
};

export default Pagination;
