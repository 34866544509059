import React from 'react';
import { useParams } from 'react-router-dom';

import { getLabel } from '../../../utils';
import { DeleteButton, Header, SaveButton } from '../../../widgets';

import style from './HeaderBlock.module.scss';

interface IProps {
  deleteEntity: () => void;
  disabled?: boolean;
  editEntity: string;
  deleteBtnEntity: string;
  isLoadClip?: boolean;
}

export const HeaderBlock = ({
  deleteEntity,
  disabled = false,
  editEntity,
  deleteBtnEntity,
  isLoadClip = false,
}: IProps): JSX.Element => {
  const { id } = useParams();
  const isEdit = !!id;

  return (
    <div className={style.headerWrapper}>
      <Header label={getLabel(isEdit, editEntity)} />
      <div className={style.btns}>
        {isEdit && (
          <DeleteButton
            mode="flat"
            modalMode="modalFlat"
            entity={deleteBtnEntity}
            modalEntity={editEntity}
            onClick={deleteEntity}
          />
        )}
        <SaveButton disabled={disabled} isLoading={isLoadClip} />
      </div>
    </div>
  );
};
