import { LoaderMini } from '../../Loader';

import style from './SaveButton.module.scss';

interface IProps {
  disabled: boolean;
  title?: string;
  isLoading?: boolean;
}

export const SaveButton = ({
  disabled,
  title,
  isLoading,
}: IProps): JSX.Element => (
  <button
    type="submit"
    className={style.button}
    disabled={disabled}
    id="Сохранить"
  >
    {title ? title : 'Сохранить'}
    {isLoading && <LoaderMini />}
  </button>
);
