import * as _ from 'lodash';

export const getDiffObjects = (
  a: Record<string, unknown>,
  b: Record<string, unknown>
): Array<string> =>
  _.reduce(
    a,
    (result, value, key) =>
      _.isEqual(value, b[key]) ? result : result.concat(key),
    []
  );

export const getEmptyElemnts = (a: Record<string, unknown>): Array<string> =>
  _.reduce(
    a,
    (result, value, key) =>
      _.isUndefined(value) ? result.concat(key) : result,
    []
  );
