import {
  IBody,
  IContentType,
  IEdit,
  IParams,
  ITagsType,
  IResponseCreate,
  IResponseGet,
  IResponseGetById,
} from './types';
import { endpoints } from '../../../../shared/api/endpoints/endpoints';
import { tags } from '../../../../shared/api/tags/tags';
import { mainApi } from '../config';

export const playlistsApiQuery = mainApi.injectEndpoints({
  // eslint-disable-next-line max-lines-per-function
  endpoints: (builder) => ({
    getPlaylistByCondition: builder.query<IResponseGet, IParams>({
      query: ({ offset, limit }) => ({
        url: endpoints.playlistGet,
        params: { limit, offset },
      }),
      serializeQueryArgs: ({ endpointName }) => endpointName,
      merge(currentCacheData, responseData) {
        const newResponses = responseData.response.filter(
          (newResponse) =>
            !currentCacheData.response.some(
              (currentResponse) => currentResponse.id === newResponse.id
            )
        );
        currentCacheData.response.push(...newResponses);
      },
      forceRefetch({ currentArg, previousArg }) {
        return currentArg !== previousArg;
      },
      providesTags: (result) => {
        if (result) {
          const { response } = result;

          return [
            ...response.map(({ id }) => ({ type: tags.playlist, id } as const)),
            { type: tags.playlist, id: 'PLAYLISTS_LIST' },
          ];
        } else {
          return [{ type: tags.playlist, id: 'PLAYLISTS_LIST' }];
        }
      },
    }),
    getPlaylistByName: builder.query<IResponseGet, string>({
      query: (name) => ({
        url: `${endpoints.playlistGetByName}?name=${name}`,
        params: {
          limit: 5,
        },
      }),
      providesTags: [tags.playlist],
    }),
    getPlaylistById: builder.query<IResponseGetById, number>({
      query: (id) => ({ url: `${endpoints.playlist}?id=${id}&cache=true` }),
      providesTags: [tags.playlist],
    }),
    getContentType: builder.query<IContentType, void>({
      query: () => ({ url: endpoints.content_type }),
      providesTags: [tags.playlist],
    }),
    getTags: builder.query<ITagsType, void>({
      query: () => ({ url: endpoints.tags }),
      providesTags: [tags.playlist],
    }),
  }),
});

export const playlistsApiMutation = mainApi.injectEndpoints({
  endpoints: (builder) => ({
    createPlaylist: builder.mutation<
      IResponseCreate,
      Omit<IBody, 'product_name' | 'rate_name' | 'keywords'>
    >({
      query: (body) => ({
        url: endpoints.playlistCreate,
        method: 'POST',
        data: body,
      }),
      invalidatesTags: [tags.playlist, tags.rss],
    }),

    editPlaylist: builder.mutation({
      query: (body: IEdit) => ({
        url: endpoints.playlist,
        method: 'PUT',
        data: body,
      }),
      invalidatesTags: [tags.playlist],
    }),

    deletePlaylist: builder.mutation({
      query: (id) => ({
        url: `${endpoints.playlist}?id=${id}`,
        method: 'DELETE',
        id,
      }),
      invalidatesTags: (_result, _error, arg) => [
        { type: tags.playlist, id: arg },
      ],
    }),
  }),
});

export const {
  useCreatePlaylistMutation,
  useDeletePlaylistMutation,
  useEditPlaylistMutation,
} = playlistsApiMutation;

export const {
  useGetPlaylistByIdQuery,
  useGetPlaylistByConditionQuery,
  useGetPlaylistByNameQuery,
  usePrefetch,
  useGetContentTypeQuery,
  useGetTagsQuery,
} = playlistsApiQuery;
