import { Owner } from 'app/api/services/owners/types';
import { IOption } from 'widgets/Select/ui/Select';

export const errorMessages = {
  end_before_start: 'Конец периода должен позже начала',
  future_time: 'Выберите время не из будущего',
  end_required: 'Выберите конец',
  start_required: 'Выберите начало',
};

export const getOwnersForSelect = (owners: Owner[]): IOption[] => {
  const res =
    owners?.map((owner) => ({
      id: owner.id,
      name: owner.name,
    })) || [];

  const all = { id: null, name: 'Все правообладатели' };

  return [all, ...res];
};
