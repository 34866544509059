import React from 'react';

export const EyeHide = (): JSX.Element => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    fill="none"
    viewBox="0 0 20 20"
  >
    <path
      fill="#8179FE"
      d="M10.216 3c-6.997 0-9.1 6.066-9.119 6.127L1 9.417l.096.29c.02.06 2.123 6.126 9.12 6.126 6.997 0 9.1-6.065 9.12-6.127l.096-.29-.096-.289C19.316 9.066 17.213 3 10.216 3zm0 10.083A3.67 3.67 0 016.55 9.417a3.67 3.67 0 013.666-3.667 3.67 3.67 0 013.667 3.667 3.67 3.67 0 01-3.667 3.666z"
      opacity="0.6"
    ></path>
    <path
      fill="#8179FE"
      d="M10.216 7.582c-.994 0-1.833.84-1.833 1.833 0 .994.84 1.834 1.833 1.834.994 0 1.834-.84 1.834-1.834 0-.993-.84-1.833-1.834-1.833z"
      opacity="0.6"
    ></path>
    <path
      stroke="#434343"
      strokeLinecap="round"
      d="M2.289 15.713L17.513 3.343"
    ></path>
  </svg>
);
