import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { SearchEntity } from './selectSlice';
import { IParams, IResp } from '../../app/api/services/filters/types';

export interface IStateFilters {
  entity: SearchEntity | null;
  filters: {
    limit?: number;
    offset?: number;
    includes?: IParams;
    excludes?: IParams;
  };
  filtredList: IResp[SearchEntity][] | null;
  totalCount: number | null;
  pageSize: number;
  page: number;
  isLoading: boolean;
  isLoadingEntity: boolean;
  isFiltred: boolean;
}

interface IPayloadList {
  list: IResp[SearchEntity][];
  count: number;
}

const initialState: IStateFilters = {
  entity: null,
  filters: {},
  filtredList: null,
  totalCount: null,
  pageSize: 20,
  page: 1,
  isLoading: false,
  isLoadingEntity: false,
  isFiltred: false,
};

export const filterSlice = createSlice({
  name: 'filterSlice',
  initialState,
  reducers: {
    setInInitialValue(state, action) {
      state.entity = action.payload;
      state.filters = {};
      state.filtredList = null;
      state.totalCount = null;
      state.page = 1;
      state.isFiltred = false;
    },

    setIsFiltred(state, { payload }: PayloadAction<boolean>) {
      state.isFiltred = payload;
    },

    setLoading(state, { payload }: PayloadAction<boolean>) {
      state.isLoading = payload;
    },

    setLoadingEntity(state, { payload }: PayloadAction<boolean>) {
      state.isLoadingEntity = payload;
    },

    addFilter(
      state,
      { payload }: PayloadAction<{ type: string; title: string }>
    ) {
      state.filters = {
        ...state.filters,
        [payload.type]: {
          ...state.filters[payload.type],
          [payload.title]: payload.title !== 'is_active' ? null : false,
        },
      };
    },
    setFilters(state, action) {
      state.filters = action.payload;
    },

    changeValueFilter(
      state,
      {
        payload,
      }: PayloadAction<{ columnType: string; filter: string; value: any }>
    ) {
      state.filters = {
        ...state.filters,
        [payload.columnType]: {
          ...state.filters[payload.columnType],
          [payload.filter]: payload.value,
        },
      };
    },

    deleteFilter(
      state,
      { payload }: PayloadAction<{ columnType: string; filter: string }>
    ) {
      const updatedValues = { ...state.filters };
      delete updatedValues?.[payload?.columnType]?.[payload?.filter];
      !Object.keys(updatedValues?.[payload?.columnType]).length &&
        delete updatedValues?.[payload?.columnType];
      state.filters = updatedValues;
    },

    setList(state, { payload }: PayloadAction<IPayloadList>) {
      const newList = payload.list.filter(
        (item) => !state.filtredList?.some((element) => element.id === item.id)
      );

      state.filtredList =
        state.filtredList !== null
          ? [...state.filtredList, ...newList]
          : [...newList];
      state.totalCount = payload.count;
    },
    setPage(state, { payload }: PayloadAction<number>) {
      state.page = payload;
      // state.filters.offset = state.pageSize * (state.page - 1);
    },
    resetList(state) {
      state.filtredList = null;
    },
  },
});

export const {
  setInInitialValue,
  setFilters,
  setList,
  setPage,
  setLoading,
  setLoadingEntity,
  deleteFilter,
  resetList,
  addFilter,
  setIsFiltred,
  changeValueFilter,
} = filterSlice.actions;
export const filterReducer = filterSlice.reducer;
