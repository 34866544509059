import classNames from 'classnames';
import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from 'react';

import { ArrowDown } from '../../../shared/icons';

import style from './SelectWrapper.module.scss';

interface IProps {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  children: React.ReactElement;
  title: string;
  value: string;
  isValueVisible: boolean;
  isDate?: boolean;
  disabled?: boolean;
  className?: string;
  isError?: boolean;
}

export const SelectWrapper = ({
  isOpen,
  setIsOpen,
  children,
  title,
  value,
  isValueVisible,
  isDate = false,
  disabled = false,
  className,
  isError,
}: IProps): JSX.Element => {
  const [clickedOutside, setClickedoutside] = useState(false);
  const wrapperRef = useRef(null);

  const handleClick = () => {
    setIsOpen((prev: boolean) => !prev);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setClickedoutside(true);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [wrapperRef]);

  useEffect(() => {
    if (clickedOutside) {
      setIsOpen(false);
      setClickedoutside(false);
    }
  }, [clickedOutside, setIsOpen]);

  return (
    <div
      className={classNames(className, style.selectWrapper, {
        [style.errorSelect]: isError,
      })}
      ref={wrapperRef}
    >
      <button onClick={handleClick} className={style.select} type="button">
        <div className={style.selectInner}>
          {title}
          <div className={style.selectDate}>{isValueVisible && value}</div>
        </div>
        <ArrowDown
          className={isOpen ? style.iconArrowDown : undefined}
          color="#434343"
        />
      </button>
      <div
        className={
          isOpen
            ? isDate
              ? style.dateSelectOpen
              : style.dateSelectOpenNoBorder
            : style.dateSelect
        }
      >
        {!disabled && children}
        {disabled && (
          <div className={style.error}>
            Вначале необходимо выбрать контрагента
          </div>
        )}
      </div>
    </div>
  );
};
