export const Point = (): JSX.Element => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="6"
    height="6"
    fill="none"
    viewBox="0 0 6 6"
  >
    <circle cx="3" cy="3" r="3" fill="#8179FE"></circle>
  </svg>
);
