import cn from 'classnames';
import { useState } from 'react';

import { IEntityMerging } from '../../../../app/global/types';
import { SearchEntity } from '../../../../store/slices/selectSlice';
import { MenuOption } from '../../../../widgets';
import { ClickShowContainer } from '../../../../widgets/ClickShowContainer';
import { SelectWithOptions } from '../../../../widgets/SelectWithOptions';

import style from './ChangeTopic.module.scss';

interface IProps {
  searchEntity?: SearchEntity;
  label: string;
  options: IEntityMerging[] | { id: number; name: string }[];
  className?: string;
  onChange?: (option) => void;
  onDelete?: () => void;
  isError?: boolean;
}

export const ChangeTopic = ({
  searchEntity,
  options,
  className,
  onChange,
  onDelete,
}: IProps): JSX.Element => {
  const [showSelect, setShowSelect] = useState(false);

  const clickHandle = (e) => {
    e.preventDefault();
    setShowSelect(true);
  };

  const handleTypeSelect = (option) => {
    setShowSelect(false);

    onChange(option);
  };

  return (
    <div className={cn(style.container, className)}>
      {showSelect && (
        <ClickShowContainer
          dropdown={showSelect}
          setDropdown={setShowSelect}
          className={style.select}
        >
          <SelectWithOptions
            searchEntity={searchEntity}
            options={options}
            onChange={handleTypeSelect}
          />
        </ClickShowContainer>
      )}

      <MenuOption
        clickHandle={clickHandle}
        onDelete={() => {
          onDelete();
        }}
      />
    </div>
  );
};
