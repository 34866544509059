/* eslint-disable max-len */
import cn from 'classnames';
import { useFormikContext } from 'formik';
import React from 'react';

import { Fields } from './Fields';
import { Author } from '../../../app/api/services/authors/types';
import { BookSeries } from '../../../app/api/services/bookSeries/types';
import { Channel } from '../../../app/api/services/channels/types';
import { Clip } from '../../../app/api/services/clips/types';
import {
  Collection,
  ITagsForCompilations,
} from '../../../app/api/services/collections/types';
import { Person } from '../../../app/api/services/persons/types';
import { Reader } from '../../../app/api/services/readers/types';
import { ISupermix } from '../../../app/api/services/supermix/types';
import { Translator } from '../../../app/api/services/translators/types';
import { ImgUploader } from '../../../widgets';
import { ICompilation } from 'app/api/services/compilations/types';

import style from './ImgUploaderBlock.module.scss';

interface IProps {
  entity:
    | Author
    | Reader
    | Translator
    | Person
    | Channel
    | Clip
    | Collection
    | BookSeries
    | ISupermix
    | ITagsForCompilations
    | ICompilation;
  className?: string;
  isError?: boolean;
  isErrorBanner?: boolean;
  isBannerRequired?: boolean;
}

export const ImgUploaderBlock = ({
  entity,
  className,
  isError = false,
  isErrorBanner = false,
}: IProps): JSX.Element => {
  const { setFieldValue } = useFormikContext();

  return (
    <div className={cn(style.imageContainer, className)}>
      <ImgUploader
        mode="primary_image"
        label="Картинка"
        imageUrl={entity?.primary_image_url}
        onChange={(image) => {
          setFieldValue(Fields.Img_id, image?.id);
          setFieldValue(Fields.Img_url, image?.url);
          setFieldValue(Fields.Primary_image, image?.url || null);
          setFieldValue(`images.${Fields.Primary_image_id}`, image?.id);
        }}
        isError={isError}
        isObligatory={true}
      />
      <ImgUploader
        mode="secondary_image"
        label="Баннер 1х1"
        imageUrl={entity?.secondary_image_url}
        onChange={(image) => {
          setFieldValue(Fields.Secondary_image, image?.url || null);
          setFieldValue(`images.${Fields.Secondary_image_id}`, image?.id);
        }}
      />
      <ImgUploader
        mode="compilation_banner"
        label="Баннер (мобилка)"
        imageUrl={entity?.primary_banner_url}
        onChange={(image) => {
          setFieldValue(Fields.Primary_banner, image?.url || null);
          setFieldValue(`images.${Fields.Primary_banner_id}`, image?.id);
        }}
        isError={isErrorBanner}
      />
      <ImgUploader
        mode="compilation_banner"
        label="Баннер (десктоп)"
        imageUrl={entity?.secondary_banner_url}
        onChange={(image) => {
          setFieldValue(Fields.Secondary_banner, image?.url || null);
          setFieldValue(`images.${Fields.Secondary_banner_url}`, image?.id);
        }}
        isError={isErrorBanner}
      />
    </div>
  );
};
