import cn from 'classnames';

import style from './SeparatorVertical.module.scss';

interface IProps {
  className?: string;
}

export const SeparatorVertical = ({ className }: IProps): JSX.Element => (
  <>
    <div className={cn(style.verticalSeparator, className)}></div>
  </>
);
