import cn from 'classnames';
import { useFormikContext } from 'formik';
import React from 'react';

import { Switch } from '../../../../../widgets';

import style from './SwitchBlock.module.scss';

interface IProps {
  feedListLength: number;
}

export const SwitchBlock = ({ feedListLength }: IProps): JSX.Element => {
  // TODO временная типизация, пока не приходит бэк
  // Переключатель заблокирован, пока эта функциональность
  //  не подключена, потом нужно пересмотреть работу
  //  этого мини-компонента и доработать его
  const { values, setFieldValue } = useFormikContext<{
    isFix: boolean;
    toFix: number;
  }>();

  return (
    <div
      className={cn(style.switchBlock, {
        [style.switchActive]: values.isFix,
      })}
    >
      <Switch
        field="relatedFeed"
        value={values.isFix}
        label="Закрепить"
        className={style.switch}
        isToggle={false}
      />

      <input
        className={values.isFix ? style.input : style.inputHidden}
        type="number"
        value={values.toFix}
        onChange={(e) => {
          setFieldValue(
            'input',
            Number(e.target.value) <= feedListLength &&
              Number(e.target.value) !== 0
              ? Number(e.target.value)
              : feedListLength
          );
        }}
      />
    </div>
  );
};
