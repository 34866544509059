import { Suspense, lazy } from 'react';

import { Loader } from '../../../../../../widgets';

const CreatePerson = lazy(() => import('./CreatePerson'));

export const CreatePerson_Lazy = (): JSX.Element => (
  <>
    <Suspense fallback={<Loader />}>
      <CreatePerson />
    </Suspense>
  </>
);
