import { FC } from 'react';

import { IFieldDescription } from '../../../app/global/types';
import { InputForTheLoginForm } from '../../InputForTheLoginForm';
import { PasswordForTheLoginForm } from '../../PasswordForTheLoginForm';

export interface IFieldRenderer {
  fieldDescription: IFieldDescription[];
}

export const FieldRenderer: FC<IFieldRenderer> = ({ fieldDescription }) => (
  <>
    {fieldDescription.map((item) => {
      if (['email', 'text'].includes(item.type)) {
        return (
          <InputForTheLoginForm
            key={item.name}
            type={item.type}
            name={item.name}
            placeholder={item.placeholder}
            label={item.label}
            blur={true}
          />
        );
      }
      if (['password', 'passwordWithTooltip'].includes(item.type)) {
        return (
          <PasswordForTheLoginForm
            key={item.name}
            type={item.type}
            name={item.name}
            placeholder={item.placeholder}
            label={item.label}
          />
        );
      }
    })}
  </>
);
