import { object, string } from 'yup';

import { IFieldDescription } from '../../../app/global/types';

export const validationSchema = object({
  login: string().email('Невалидный email').required('Требуется email'),
  password: string().required('Требуется пароль'),
});

export const fieldDescription: IFieldDescription[] = [
  {
    type: 'email',
    name: 'login',
    label: 'Email',
    blur: true,
  },
  {
    type: 'password',
    name: 'password',
    label: 'Пароль',
  },
];
