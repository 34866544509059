import { Suspense, lazy } from 'react';

import { Loader } from '../../../widgets';

const QuestionOrder = lazy(() => import('./QuestionOrder'));

export const QuestionOrder_Lazy = (): JSX.Element => (
  <>
    <Suspense fallback={<Loader />}>
      <QuestionOrder />
    </Suspense>
  </>
);
