/* eslint-disable max-lines-per-function */
import { IGetAutoSection } from './autoSectionsTypes';
import { ICreateBaseSection, IGetBaseSection } from './baseSectionsTypes';
import {
  ICreateAutoSection,
  ICreateInfoSection,
  IGetByConditionParams,
  IGetSectionsByCondition,
  IResUpdateSectionPosition,
  IResponseCreate,
  ISectionLists,
  IUpdateSectionPosition,
} from './types';
import { endpoints } from '../../../../shared/api/endpoints/endpoints';
import { tags } from '../../../../shared/api/tags/tags';
import { mainApi } from '../config';

export const sectionsApiQuery = mainApi.injectEndpoints({
  endpoints: (builder) => ({
    getSectionsByCondition: builder.query<
      IGetSectionsByCondition,
      IGetByConditionParams
    >({
      query: (params) => ({
        url: `${endpoints.sections}`,
        params,
      }),
      providesTags: [tags.section],
    }),

    getSectionLists: builder.query<ISectionLists, void>({
      query: () => ({
        url: `${endpoints.getSectionLists}`,
      }),
    }),
    updateSectionPosition: builder.mutation<
      IResUpdateSectionPosition,
      IUpdateSectionPosition
    >({
      query: (body) => ({
        url: endpoints.sectionPosition,
        method: 'PATCH',
        data: body,
      }),
      invalidatesTags: [tags.section],
    }),
  }),
});

export const autoSections = mainApi.injectEndpoints({
  endpoints: (builder) => ({
    getAutoSectionById: builder.query<IGetAutoSection, string>({
      query: (id) => ({
        url: `${endpoints.autoSection}`,
        params: { id },
      }),
      providesTags: [tags.section],
    }),

    createAutoSection: builder.mutation<IResponseCreate, ICreateAutoSection>({
      query: (body) => ({
        url: endpoints.autoSection,
        method: 'POST',
        data: body,
      }),
      invalidatesTags: [tags.section],
    }),

    updateAutoSection: builder.mutation<
      IResponseCreate,
      { values: ICreateAutoSection; id: number }
    >({
      query: ({ values, id }) => ({
        url: endpoints.autoSection,
        method: 'PUT',
        params: { id },
        data: { payload: values },
      }),
      invalidatesTags: [tags.section],
    }),

    deleteAutoSection: builder.mutation<IResponseCreate, number>({
      query: (id) => ({
        url: endpoints.autoSection,
        method: 'DELETE',
        params: { id },
      }),
      invalidatesTags: [tags.section],
    }),
  }),
});

export const infoSections = mainApi.injectEndpoints({
  endpoints: (builder) => ({
    getInfoSectionById: builder.query({
      query: (id) => ({
        url: `${endpoints.infoSection}`,
        params: { id },
      }),
      providesTags: [tags.section],
    }),

    createInfoSection: builder.mutation<IResponseCreate, ICreateInfoSection>({
      query: (body) => ({
        url: endpoints.infoSection,
        method: 'POST',
        data: body,
      }),
      invalidatesTags: [tags.section],
    }),

    updateInfoSection: builder.mutation<
      IResponseCreate,
      { values: IResponseCreate; id: number }
    >({
      query: ({ values, id }) => ({
        url: endpoints.infoSection,
        method: 'PUT',
        params: { id },
        data: { payload: values },
      }),
      invalidatesTags: [tags.section],
    }),

    deleteInfoSection: builder.mutation<IResponseCreate, number>({
      query: (id) => ({
        url: endpoints.infoSection,
        method: 'DELETE',
        params: { id },
      }),
      invalidatesTags: [tags.section],
    }),
  }),
});

export const baseSections = mainApi.injectEndpoints({
  endpoints: (builder) => ({
    getBaseSectionById: builder.query<IGetBaseSection, number>({
      query: (id) => ({
        url: `${endpoints.baseSection}`,
        params: { id },
      }),
      providesTags: [tags.section],
    }),
    createBaseSection: builder.mutation<IResponseCreate, ICreateBaseSection>({
      query: (body) => ({
        url: endpoints.baseSection,
        method: 'POST',
        data: body,
      }),
      invalidatesTags: [tags.section],
    }),

    updateBaseSection: builder.mutation<
      IResponseCreate,
      { values: ICreateBaseSection; id: number }
    >({
      query: ({ values, id }) => ({
        url: endpoints.baseSection,
        method: 'PUT',
        params: { id },
        data: { payload: values },
      }),
      invalidatesTags: [tags.section],
    }),

    deleteBaseSection: builder.mutation<IResponseCreate, number>({
      query: (id) => ({
        url: endpoints.baseSection,
        method: 'DELETE',
        params: { id },
      }),
      invalidatesTags: [tags.section],
    }),
  }),
});

export const {
  useGetSectionListsQuery,
  useGetSectionsByConditionQuery,
  useUpdateSectionPositionMutation,
} = sectionsApiQuery;

export const {
  useGetInfoSectionByIdQuery,
  useCreateInfoSectionMutation,
  useDeleteInfoSectionMutation,
  useUpdateInfoSectionMutation,
} = infoSections;

export const {
  useCreateBaseSectionMutation,
  useDeleteBaseSectionMutation,
  useUpdateBaseSectionMutation,
  useGetBaseSectionByIdQuery,
} = baseSections;

export const {
  useDeleteAutoSectionMutation,
  useCreateAutoSectionMutation,
  useUpdateAutoSectionMutation,
  useGetAutoSectionByIdQuery,
} = autoSections;
