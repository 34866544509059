import React from 'react';

import { LeftIcons, RightIcons, TimeIcon } from './icons';
import { ArrowLeft } from '../../../../../shared/icons/ArrowLeft';

import style from './MobileBackground.module.scss';

interface IProps {
  children: React.ReactNode;
}

export const MobileBackground = ({ children }: IProps): JSX.Element => (
  <div className={style.container}>
    <div className={style.wrapper}>
      <div className={style.header}>
        <div className={style.headerTop}>
          <LeftIcons />
          <TimeIcon />
          <RightIcons />
        </div>
        <div className={style.headerTitle}>
          <ArrowLeft color="white" />
          <div className={style.headerTitleText}>Категории</div>
        </div>
      </div>
      <div className={style.childrenWrapper}>{children}</div>
    </div>
  </div>
);
