import cn from 'classnames';
import React from 'react';
import { Link } from 'react-router-dom';

import { TextWithTooltip } from '../../TextWithTooltip';

import style from './TitleWithLink.module.scss';

interface IProps {
  title: string;
  link: string;
  width?: number;
  text?: string | number;
  className?: string;
}

export const TitleWithLink = ({
  title,
  link,
  width,
  text,
  className,
}: IProps): JSX.Element => (
  <div className={cn(style.container, className)}>
    <h3 className={style.title}>{title}</h3>
    {!link.includes('http') ? (
      <Link
        target="_blank"
        to={link}
        style={{
          width: `${width}px`,
        }}
      >
        <TextWithTooltip text={text} className={style.linkText} />
      </Link>
    ) : (
      <a
        href={link}
        style={{
          width: `${width}px`,
        }}
        target="_blank"
      >
        <TextWithTooltip text={text} className={style.linkText} />
      </a>
    )}
  </div>
);
