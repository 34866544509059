import { ReactComponent as ClipUploadImage } from '../assets/icon_add_audio.svg';
import { ReactComponent as ClipAddMaterialImage } from '../assets/icon_add_material.svg';
import { ReactComponent as ClipErrorUploadImage } from '../assets/icon_alert.svg';

import style from './ClipUploadStatus.module.scss';

interface IProps {
  isError: boolean;
  isMaterialImg?: boolean;
}

export const ClipUploadStatus = ({
  isError,
  isMaterialImg,
}: IProps): JSX.Element => (
  <>
    {!isError ? (
      <div className={style.success}>
        {isMaterialImg ? <ClipAddMaterialImage /> : <ClipUploadImage />}
      </div>
    ) : (
      <div className={style.error}>
        <ClipErrorUploadImage />
        <p className={style.errorText}>Сбой загрузки</p>
      </div>
    )}
  </>
);
