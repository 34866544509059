import cn from 'classnames';
import React from 'react';

import { TextWithTooltip } from '../../TextWithTooltip';

import style from './Tag.module.scss';

enum Size {
  Mini = 'mini',
  Normal = 'normal',
}

enum Category {
  TagGroup = 'tagGroup',
  Superior = 'superior',
  High = 'high',
  Medium = 'medium',
  Low = 'low',
}

interface IProps extends React.HTMLAttributes<HTMLDivElement> {
  value: string;
  category?: 'tagGroup' | 'superior' | 'high' | 'medium' | 'low';
  size?: 'mini' | 'normal';
  border?: boolean;
}

export const Tag = ({
  value,
  category = 'medium',
  size = 'normal',
  border = false,
  ...props
}: IProps): JSX.Element => (
  <div
    {...props}
    className={cn(style.container, {
      [style.mini]: size === Size.Mini,
      [style.normal]: size === Size.Normal,
      [style.low]: category === Category.Low,
      [style.medium]: category === Category.Medium,
      [style.high]: category === Category.High,
      [style.superior]: category === Category.Superior,
      [style.tagGroup]: category === Category.TagGroup,
      [style.noborder]: !border,
    })}
  >
    {value?.length > 31 ? (
      <TextWithTooltip className={style.text} text={value} />
    ) : (
      value
    )}

    <div className={style.corner}></div>
  </div>
);
