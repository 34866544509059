import { Suspense, lazy } from 'react';

import { Loader } from '../../../../../widgets';

const Source = lazy(() => import('./Source'));

export const Source_Lazy = (): JSX.Element => (
  <>
    <Suspense fallback={<Loader />}>
      <Source />
    </Suspense>
  </>
);
