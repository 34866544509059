import {
  IRequestToList,
  IResponseGetList,
  IResponseMassActions,
} from './types';
import { endpoints } from '../../../../shared/api/endpoints/endpoints';
import { tags } from '../../../../shared/api/tags/tags';
import { mainApi } from '../config';

export const massActionsApi = mainApi.injectEndpoints({
  endpoints: (builder) => ({
    getMassActionForEntity: builder.query<IResponseMassActions, string>({
      query: (entity) => ({
        url: `${endpoints.massActions}?entity=${entity.replace('_', '-')}`,
        method: 'GET',
      }),
    }),
    updateMassActionsByEntity: builder.mutation<
      IResponseGetList,
      IRequestToList
    >({
      query: ({ entity, body }) => ({
        url: `${endpoints.massActions}?entity=${entity.replace('_', '-')}`,
        method: 'POST',
        data: body,
      }),
      invalidatesTags: [tags.massActions],
    }),
  }),
});

export const {
  useGetMassActionForEntityQuery,
  useUpdateMassActionsByEntityMutation,
} = massActionsApi;
