import { object, string } from 'yup';

import { IFieldDescription } from '../../../app/global/types';
import { REGISTRATION_PASSWORD } from '../../../utils/regExp/regExp';
import { IRegisterFormValues } from '../types';

export const fieldDescription: IFieldDescription[] = [
  {
    type: 'email',
    name: 'email',
    label: 'Email',
  },
  {
    type: 'text',
    name: 'first_name',
    label: 'Имя',
  },
  {
    type: 'text',
    name: 'last_name',
    label: 'Фамилия',
  },
  {
    type: 'passwordWithTooltip',
    name: 'password',
    label: 'Пароль',
  },
];

export const initialValues: IRegisterFormValues = {
  email: '',
  password: '',
  first_name: '',
  last_name: '',
};

export const validationSchema = object({
  email: string().email('Неверный email').required('Введите email'),
  first_name: string().required('Введите имя'),
  last_name: string().required('Введите фамилию'),
  password: string()
    .required('Введите пароль')
    .matches(REGISTRATION_PASSWORD, 'Пароль не соответствует требованиям'),
});
