import cn from 'classnames';
import { useFormikContext } from 'formik';
import { useEffect, useState } from 'react';

import style from './Checkbox.module.scss';

interface IProps {
  value: string;
  label?: string;
  className?: string;
  isChecked?: boolean;
  onChange?: () => void;
}

export const Checkbox = ({
  label,
  value,
  className,
  isChecked: checked,
  onChange,
}: IProps): JSX.Element => {
  const { setFieldValue } = useFormikContext();

  const [isChecked, setChecked] = useState(checked || false);

  const handler = () => {
    onChange && onChange();
    setChecked((prev) => !prev);
  };

  useEffect(() => {
    setFieldValue(value, isChecked);
  }, [setFieldValue, value, isChecked]);

  return (
    <label className={style.container}>
      <input
        type="checkbox"
        className={cn(className, style.checkbox, {
          [style.selected]: isChecked,
        })}
        checked={isChecked}
        onChange={handler}
      />
      <span className={style.label}>{label}</span>
    </label>
  );
};
