import { startOfDay, isSameDay } from 'date-fns';
import format from 'date-fns/format';
import { useFormikContext } from 'formik';
import React, { useState } from 'react';

import { DateInput } from '../../DateInput';
import { SelectWrapper } from 'widgets/SelectWrapper';

import style from './DateSelect.module.scss';

interface ITotalValues {
  start_date: Date;
  end_date: Date;
}
interface IProps {
  showTimeSelect?: boolean;
  dateFormat?: string;
}

export const DateSelect = ({
  showTimeSelect,
  dateFormat = 'yyyy.MM.dd HH:mm',
}: IProps): JSX.Element => {
  const [isValueVisible, setIsValueVisible] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedDates, setSelectedDates] = useState<string>('');

  const { setFieldValue, values } = useFormikContext<ITotalValues>();
  const { errors } = useFormikContext<{
    start_date: string;
    end_date: string;
  }>();

  const now = new Date();
  const sod = new Date(startOfDay(now).getTime());

  return (
    <SelectWrapper
      title="Период"
      value={`${selectedDates}`}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      isValueVisible={isValueVisible}
      isDate
      className={style.select}
      isError={!!(errors.end_date || errors?.start_date)}
    >
      <div className={style.inner}>
        <div className={style.inputs}>
          <DateInput
            onChange={(date) => setFieldValue('start_date', date)}
            selected={values.start_date}
            title="Начало"
            dateFormat={dateFormat}
            showTimeSelect={showTimeSelect}
            placeholderText="Выберите"
            minTime={isSameDay(now, values.start_date) ? sod : null}
            maxTime={isSameDay(now, values.start_date) ? now : null}
            error={errors.start_date}
          />
          <DateInput
            onChange={(date) => setFieldValue('end_date', date)}
            selected={values.end_date}
            title="Конец"
            dateFormat={dateFormat}
            showTimeSelect={showTimeSelect}
            placeholderText="Выберите"
            minTime={isSameDay(now, values.end_date) ? sod : null}
            maxTime={isSameDay(now, values.end_date) ? now : null}
            error={errors.end_date}
          />
        </div>
        <button
          className={style.button}
          type="submit"
          onClick={() => {
            setIsValueVisible(true);
            setIsOpen(false);
            if (values.start_date && values.end_date) {
              setSelectedDates(
                `${format(values.start_date, dateFormat)} - ${format(
                  values.end_date,
                  dateFormat
                )}`
              );
            }
          }}
        >
          Применить
        </button>
      </div>
    </SelectWrapper>
  );
};
