export interface ISupermixProfitValues {
  supermix_id: string;
  period: string;
}
export interface IValidateSupermixProfit {
  period: boolean;
  supermix_id: boolean;
}

export const getDate = (date: string): string =>
  new Date(date)
    .toLocaleDateString('ru-RU', {
      year: 'numeric',
      month: 'numeric',
    })
    .split('.')
    .reverse()
    .join('-');

export const validateGetSupermixProfit = (
  values: ISupermixProfitValues
): object => {
  const errors = {} as IValidateSupermixProfit;

  if (!values.supermix_id) {
    errors.supermix_id = true;
  }

  if (!values.period) {
    errors.period = true;
  }

  return errors;
};

export const SupermixProfitFields = {
  supermix_id: 'supermix_id',
  period: 'period',
};

export const initialValues = {
  [SupermixProfitFields.supermix_id]: '',
  [SupermixProfitFields.period]: '',
} as unknown as ISupermixProfitValues;
// TOOD: убрать as, посмотреть что не так с типами
