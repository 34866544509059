interface IProps {
  color?: string;
}

export const PlayIcon = (): JSX.Element => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="none"
  >
    <circle cx="15" cy="15" r="14.5" fill="#8179FE" stroke="#8179FE" />
    <path
      d="M21.5 15.866C22.1667 15.4811 22.1667 14.5189 21.5 14.134L12.5 8.93782C11.8333 8.55292 11 9.03405 11 9.80385V20.1962C11 20.966 11.8333 21.4471 12.5 21.0622L21.5 15.866Z"
      fill="white"
    />
  </svg>
);

export const InversionPlayIcon = ({
  color = '#8179FE',
}: IProps): JSX.Element => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
  >
    <circle cx="10" cy="10" r="10" fill="white" />
    <path
      d="M13.5 10.866C14.1667 10.4811 14.1667 9.51887 13.5 9.13397L9 6.5359C8.33333 6.151 7.5 6.63212 7.5 7.40192L7.5 12.5981C7.5 13.3679 8.33333 13.849 9 13.4641L13.5 10.866Z"
      fill={color}
    />
  </svg>
);
