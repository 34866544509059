import { useFormikContext } from 'formik';

import { ContainerWrapper, SelectNew } from '../../../../../../../../widgets';
import { IOption } from '../../../../../../../../widgets/Select/ui/Select';
import {
  AutoSectionFields,
  IAutoSectionErrors,
  IAutoSectionValues,
} from '../../../helpers';

import style from './Description.module.scss';

export const Description = ({
  sectionName,
  sectionElement,
  sectionFeed,
  sectionAlgorithm,
  sectionNames,
  sectionElements,
  sectionFeeds,
  sectionAlgorithms,
}: {
  sectionName: string;
  sectionElement: string;
  sectionFeed: string;
  sectionAlgorithm: string;
  sectionNames: IOption[];
  sectionElements: IOption[];
  sectionFeeds: IOption[];
  sectionAlgorithms: IOption[];
}): JSX.Element => {
  const { setFieldValue } = useFormikContext<IAutoSectionValues>();
  const { errors } = useFormikContext<IAutoSectionErrors>();

  return (
    <ContainerWrapper title="Описательные параметры">
      <div className={style.topWrapper}>
        <SelectNew
          placeholder="Название"
          title="Название"
          options={sectionNames}
          onChange={(option) => {
            setFieldValue(AutoSectionFields.Name, option?.name);
          }}
          defaultOption={
            sectionName &&
            sectionNames?.find((el) => el.name === sectionName)?.id
          }
          isError={!!errors.name}
          isObligatory={true}
        />
        <SelectNew
          placeholder="Выберите тип"
          title="Тип элемента"
          options={sectionElements}
          onChange={(option) => {
            setFieldValue(AutoSectionFields.Element_type, option?.name);
          }}
          defaultOption={
            sectionElement &&
            sectionElements?.find((el) => el.name === sectionElement)?.id
          }
          isError={!!errors.element_type}
          isObligatory={true}
        />
        <SelectNew
          placeholder="Выберите вид"
          title="Вид ленты"
          options={sectionFeeds}
          onChange={(option) => {
            setFieldValue(AutoSectionFields.Feed_type, option?.name);
          }}
          defaultOption={
            sectionFeed &&
            sectionFeeds?.find((el) => el.name === sectionFeed)?.id
          }
          isError={!!errors.feed_type}
          isObligatory={true}
        />
        <SelectNew
          placeholder="Выберите алгоритм"
          title="Алгоритм формирования списка"
          options={sectionAlgorithms}
          onChange={(option) => {
            setFieldValue(AutoSectionFields.Algorithm, option?.name);
          }}
          defaultOption={
            sectionAlgorithm &&
            sectionAlgorithms?.find((el) => el.name === sectionAlgorithm)?.id
          }
          isError={!!errors.algorithm}
          isObligatory={true}
        />
      </div>
    </ContainerWrapper>
  );
};
