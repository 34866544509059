import { Dispatch, SetStateAction } from 'react';
import { toast } from 'react-toastify';

export const errorToast = (
  error: boolean,
  message: string,
  setIsToast?: Dispatch<SetStateAction<boolean>>
): void => {
  if (error) {
    toast.error(message, {
      onOpen: () => setIsToast && setIsToast(true),
      onClose: () => setIsToast && setIsToast(false),
    });
  }
};
