import { createApi } from '@reduxjs/toolkit/dist/query/react';

import { tags } from '../../../shared/api/tags/tags';
import { axiosMainApi } from '../../network/axiosQueryInstance';

export const mainApi = createApi({
  reducerPath: 'mainApiReducer',
  baseQuery: axiosMainApi,
  endpoints: () => ({}),
  tagTypes: Object.values(tags),
});

export const mainApiReducer = mainApi.reducer;
