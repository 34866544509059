import cn from 'classnames';
import { useState } from 'react';
import { Link } from 'react-router-dom';

import { ClickShowContainer } from '../../ClickShowContainer';

import style from './NavItemWithDropdown.module.scss';

interface ISubtitle {
  title: string;
  path: string;
}

interface IProps {
  title: string;
  subtitle: ISubtitle[];
  css: string;
}

export const NavItemWithDropdown = ({
  title,
  subtitle,
  css,
}: IProps): JSX.Element => {
  const [dropdown, setDropdown] = useState(false);

  const clickHandle = () => setDropdown((prevState) => !prevState);

  const clickItemHandle = () => setDropdown(false);

  return (
    <ClickShowContainer
      className={style.container}
      dropdown={dropdown}
      setDropdown={setDropdown}
    >
      <button
        id={title}
        className={cn(style.button, style[css], {
          [style.active]: dropdown,
        })}
        onClick={clickHandle}
      >
        {title}
      </button>
      <ul className={dropdown ? style.show : style.hide}>
        {subtitle.map((item) => (
          <Link
            to={item.path}
            key={item.title}
            className={style.item}
            onClick={clickItemHandle}
          >
            {item.title}
          </Link>
        ))}
      </ul>
    </ClickShowContainer>
  );
};
