import { Router } from '../../Router/routerEnum';

export const MENU = [
  {
    title: 'Сущности',
    css: 'entity',
    subtitle: [
      { title: 'Ролик', path: Router.Clip },
      { title: 'Плейлист', path: Router.Playlist },
      { title: 'Канал', path: Router.Channel },
      { title: 'Правообладатель', path: Router.Owner },
      { title: 'Источник', path: Router.Source },
      { title: 'Тег', path: Router.Tag },
      { title: 'Теги для подборок', path: Router.TagsForCompilations },
      { title: 'Темы', path: Router.Theme },
      { title: 'Автор', path: Router.Author },
      { title: 'Переводчик', path: Router.Translator },
      { title: 'Чтец', path: Router.Reader },
      { title: 'Персона', path: Router.Person },
      { title: 'Классификации', path: Router.Classifications },
      { title: 'Супермикс', path: Router.Supermix },
      { title: 'Подборка плейлистов', path: Router.PlaylistCompilation },
    ],
  },
  {
    title: 'Система рекомендаций',
    css: 'system',
    subtitle: [
      { title: 'Каталог тем', path: Router.ThemeCatalog },
      { title: 'Очередность тем', path: Router.ThemeOrder },
      { title: 'Каталог тегов', path: Router.TagDirectory },
      {
        title: 'Каталог тегов для подборок',
        path: Router.TagDirectoryForCompilations,
      },
      { title: 'Семантическое поле', path: Router.SemanticField },
      { title: 'Ключевые слова', path: Router.Keywords },
      { title: 'Книжная серия', path: Router.BookSeries },
      { title: 'Ленты', path: Router.Feed },
      { title: 'Лодочки', path: Router.FeedsGroups },
      { title: 'Рубрика', path: Router.Rubric },
      { title: 'Раздел', path: Router.Section },
      { title: 'Реестр связанных лент', path: Router.RegistryRelatedFeeds },
      { title: 'Связанные ленты', path: Router.RelatedFeed },
      { title: 'Фильтры для лент', path: Router.RibbonFilters },
      { title: 'Контексты', path: Router.Contexts },
    ],
  },
  {
    title: 'Пользователи',
    css: 'users',
    subtitle: [
      { title: 'Профиль клиента', path: Router.ClientProfile },
      { title: 'Внешние клиенты', path: Router.ExternalCustomers },
      { title: 'Внутренние клиенты', path: Router.InternalCustomers },
      {
        title: 'Характеристика пользователя',
        path: Router.CharacteristicsOfTheUser,
      },
    ],
  },
  {
    title: 'Бизнес',
    css: 'busines',
    subtitle: [
      { title: 'Тарифы', path: Router.Rates },
      { title: 'Покупки', path: Router.Purchases },
      { title: 'Договор', path: Router.Contract },
      { title: 'Контрагенты', path: Router.Counterparties },
      { title: 'Кандидаты в контрагенты', path: Router.CounterpartyCandidates },
      {
        title: 'Уведомления для клиентов',
        path: Router.NotificationsForClients,
      },
      { title: 'Спецпроекты', path: Router.SpecialProjects },
    ],
  },
  {
    title: 'Дашборды',
    css: 'dashboards',
    subtitle: [
      { title: 'Бизнес статистика', path: Router.BusinessStatistics },
      { title: 'Техническое здоровье', path: Router.TechnicalHealth },
    ],
  },
  {
    title: 'Поддержка',
    css: 'support',
    subtitle: [
      { title: 'FAQ', path: Router.FAQ },
      { title: 'Очередность вопросов', path: Router.QuestionOrder },
    ],
  },
];
