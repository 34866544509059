import cn from 'classnames';
import { ChangeEvent, useState, KeyboardEvent, useEffect } from 'react';

import Pagination from '../../Pagination/ui/Pagination';

import style from './PaginationContainer.module.scss';

interface IProps {
  pointBy?: number;
  total?: number;
  selectedPage?: number;
  className?: string;
  onChangePage?: (page: number) => void;
}

export const PaginationContainer = ({
  pointBy,
  total,
  selectedPage,
  className,
  onChangePage,
}: IProps): JSX.Element => {
  const [currentPage, setCurrentPage] = useState<number | string>(selectedPage);
  const [inputValue, setInputValue] = useState('1');

  const lastPage = Math.ceil(total / pointBy);

  const changeInputValueHandler = (event: ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    if (event.target.value.match(/\D/g)) {
      event.target.value = event.target.value.replace(/\D/g, '');
    }
    setInputValue(event.target.value);
  };

  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      if (Number(inputValue) > lastPage) {
        setCurrentPage(lastPage);
      } else if (Number(inputValue) === 0) {
        setCurrentPage(1);
      } else {
        setCurrentPage(Number(inputValue));
      }
    }
  };

  useEffect(() => {
    onChangePage && onChangePage(currentPage as number);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  return (
    <div className={cn(style.footerWrapper, className)}>
      <input
        className={style.input}
        value={inputValue}
        onChange={(e: ChangeEvent<HTMLInputElement>) =>
          changeInputValueHandler(e)
        }
        onKeyDown={handleKeyDown}
        placeholder={inputValue}
      />

      <Pagination
        currentPage={currentPage as number}
        totalCount={total || pointBy}
        pageSize={pointBy}
        onPageChange={(page) => setCurrentPage(page)}
      />
    </div>
  );
};
