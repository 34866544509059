import { OverlayScrollbars } from 'overlayscrollbars';
import { MutableRefObject, useEffect } from 'react';

export const useScrollbar = (
  root: MutableRefObject<HTMLDivElement>,
  hasScroll: boolean
): void => {
  useEffect(() => {
    let scrollbars;

    if (root.current && hasScroll) {
      scrollbars = OverlayScrollbars(root.current, {});
    }

    return () => {
      if (scrollbars) {
        scrollbars.destroy();
      }
    };
  }, [root, hasScroll]);
};
