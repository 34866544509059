import { S3 } from 'aws-sdk';

import { generateUniqNameFile } from '../generateUniqNameFile';

const config = {
  accessKeyId: process.env.REACT_APP_S3_PRIMARY_ACCESS_KEY,
  secretAccessKey: process.env.REACT_APP_S3_PRIMARY_SECRET_KEY,
  endpoint: process.env.REACT_APP_S3_ENDPOINT_URL,
};

const S3Client = new S3(config);

export const uploadAudioFileInBucket = async (file: File): Promise<unknown> => {
  const paramsUpload = {
    Bucket: `${process.env.REACT_APP_TMP_HOT_STORAGE_BUCKET}/${process.env.REACT_APP_TMP_STORAGE_AUDIO_FOLDER}`,
    ACL: 'private',
    ContentType: file.type,
    ContentDisposition: 'inline',
  };

  const ext = file.name.split('.').at(-1);
  const key = `${generateUniqNameFile(file.name)}.${ext}`;

  return await S3Client.upload({
    ...paramsUpload,
    Key: key,
    Body: file,
  })
    .on('httpUploadProgress', (evt) => {
      Math.round((evt.loaded / evt.total) * 100);
    })
    .promise();
};
