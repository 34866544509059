export const timeMsInHMS = (x: number | string | undefined): string => {
  if (x === undefined) {
    return '';
  }

  const duration = typeof x === 'string' ? Number(x) : x;
  const hours = Math.floor(duration / 3600);
  let minutes = Math.floor(duration / 60) - hours * 60 + '';
  minutes = minutes.length < 2 ? 0 + minutes : minutes;
  let seconds = (duration % 60) + '';
  seconds = seconds.length < 2 ? 0 + seconds : seconds;

  return `${hours}:${minutes}:${seconds}`;
};
