import { Form, Formik } from 'formik';
import { useCallback, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Id, toast } from 'react-toastify';

import { fieldDescription, validationSchema } from './formDescription';
import { COOKIE, IApi } from '../../../app/global/types';
import { Router } from '../../../components/Router/routerEnum';
import { useAppDispatch } from '../../../hooks';
import { ISuccess } from '../../../pages/LoginPage/types/types';
import { useLoginUserMutation } from '../../../store/actions/users';
import { setTokens } from '../../../store/slices/authSlice';
import { RU_CHAR } from '../../../utils/regExp/regExp';
import { FieldRenderer } from '../../FieldRenderer';
import { Loader } from '../../Loader';
import { PrimaryButton } from '../../PrimaryButton';
import { ILoginFormValues } from '../types';

type TCookies = COOKIE.ACCESS_TOKEN | COOKIE.REFRESH_TOKEN | COOKIE.LOGGED_IN;

export const LoginForm = (props: {
  setCookies: (cookie: TCookies, value: string) => void;
}): JSX.Element => {
  const { setCookies } = props;
  const navigate = useNavigate();
  const location = useLocation();

  const initialValues: ILoginFormValues = {
    login: location?.state?.email || '',
    password: '',
  };

  const [loginUser, { data, error, isError, isSuccess, isLoading }] =
    useLoginUserMutation();

  const dispatch = useAppDispatch();

  const handleSubmit = (values: ILoginFormValues): void => {
    loginUser(values);
  };

  const handleSuccessLogin = useCallback(
    (data: ISuccess): void => {
      setCookies(COOKIE.ACCESS_TOKEN, data.response.access_token);
      setCookies(COOKIE.REFRESH_TOKEN, data.response.refresh_token);
      setCookies(COOKIE.LOGGED_IN, 'true');
      dispatch(
        setTokens({
          accessToken: data.response.access_token,
          refreshToken: data.response.refresh_token,
        })
      );

      return navigate(Router.MainPage, { state: 'isLogin' });
    },
    [dispatch, navigate, setCookies]
  );

  useEffect(() => {
    if (isSuccess) {
      if (data.statusCode === 403) {
        const error = {
          data: {
            message: data.response,
          },
        };

        handleErrorLogin(error as IApi);
      } else {
        handleSuccessLogin(data);
      }
    }
  }, [isSuccess, handleSuccessLogin, data, setCookies]);

  useEffect(() => {
    if (isError) {
      handleErrorLogin(error as IApi);
    }
  }, [error, isError]);

  const handleErrorLogin = (error: IApi): Id =>
    error?.data?.message
      ? toast.error(error?.data?.message)
      : toast.error('Что то пошло не так');

  return (
    <>
      {isLoading && <Loader />}

      <Formik
        initialValues={initialValues}
        validateOnBlur
        autoComplete="off"
        validationSchema={validationSchema}
        onSubmit={(values: ILoginFormValues): void => {
          handleSubmit(values);
        }}
      >
        {(formik): JSX.Element => {
          formik.values.login = formik.values.login.replace(RU_CHAR, '');
          if (formik.values.login.length > 0) {
            sessionStorage.setItem('login', formik.values.login);
          }

          return (
            <Form>
              <FieldRenderer fieldDescription={fieldDescription} />

              <PrimaryButton
                label="Войти"
                disabled={
                  isLoading ||
                  !formik.isValid ||
                  formik.values.login.length === 0 ||
                  formik.values.password.length === 0
                }
              />
            </Form>
          );
        }}
      </Formik>
    </>
  );
};
