interface IProps {
  color: string;
}

export const PlusSign = ({ color }: IProps): JSX.Element => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="6"
    height="11"
    fill="none"
    viewBox="0 0 6 11"
  >
    <path
      fill={color}
      d="M1.094.76l4.077 4.322a.452.452 0 01.102.167c.02.06.03.125.03.193 0 .069-.01.133-.03.193a.452.452 0 01-.102.167l-4.077 4.335a.557.557 0 01-.423.18.577.577 0 01-.436-.193.636.636 0 01-.181-.45c0-.172.06-.322.181-.45l3.557-3.782L.235 1.66a.623.623 0 01-.17-.443c0-.176.061-.328.182-.457A.564.564 0 01.671.567c.16 0 .302.064.423.193z"
    ></path>
  </svg>
);
