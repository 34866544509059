import { Suspense, lazy } from 'react';

import { Loader } from '../../../../../widgets';

const Author = lazy(() => import('./Author'));

export const Author_Lazy = (): JSX.Element => (
  <>
    <Suspense fallback={<Loader />}>
      <Author />
    </Suspense>
  </>
);
