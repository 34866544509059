import { withProfiler } from '@sentry/react';
import { useEffect } from 'react';

import { useGetMeStatusQuery, useGetProfileQuery } from './api/auth/users';
import { AppRouter } from '../components/Router';
import { Toast } from '../components/Toast';
import 'react-toastify/dist/ReactToastify.css';
import { useAppDispatch, useAppSelector } from '../hooks';
import { AuthState } from '../store/slices/authSlice';
import { setProfile } from '../store/slices/userSlice';

const App = (): JSX.Element => {
  const dispatch = useAppDispatch();

  const accessToken = useAppSelector(
    (state) => (state.authReducer as AuthState).user.tokens.accessToken
  );

  const {
    data: user,
    isLoading: isLoadingUser,
    isFetching: isFetchingUser,
  } = useGetMeStatusQuery(accessToken, { skip: !accessToken });

  const id = user?.response?.user?.id;

  const {
    data: person,
    isLoading: isLoadingPerson,
    isFetching: isFetchingPerson,
  } = useGetProfileQuery(id, {
    skip: !id,
  });

  const isLoading =
    isFetchingPerson || isFetchingUser || isLoadingPerson || isLoadingUser;

  useEffect(() => {
    dispatch(
      setProfile({
        role: user?.response?.role || null,
        first_name: person?.response?.first_name || null,
        last_name: person?.response?.last_name || null,
        isLoading,
      })
    );
  }, [
    dispatch,
    user?.response?.role,
    person?.response?.first_name,
    person?.response?.last_name,
    isLoading,
  ]);

  return (
    <>
      <Toast />
      <AppRouter />
    </>
  );
};

export default withProfiler(App);
