export enum Fields {
  Img_id = 'img_id',
  Img_url = 'image_url',
  Primary_image = 'primary_image_url',
  Primary_banner = 'primary_banner_url',
  Secondary_image = 'secondary_image_url',
  Secondary_banner = 'secondary_banner_url',
  Primary_image_id = 'primary_image_id',
  Primary_banner_id = 'primary_banner_id',
  Secondary_image_id = 'secondary_image_id',
  Secondary_banner_url = 'secondary_banner_id',
}
