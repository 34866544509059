import { lazy, Suspense } from 'react';

import { Loader } from '../../../../../../widgets';

const CreateClipCompilation = lazy(() => import('./CreateClipCompilation'));

export const CreateClipCompilation_Lazy = (): JSX.Element => (
  <>
    <Suspense fallback={<Loader />}>
      <CreateClipCompilation />
    </Suspense>
  </>
);
