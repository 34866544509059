import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface IPointState {
  value: number;
}

const initialState: IPointState = {
  value: 15,
};

export const pointBySlice = createSlice({
  name: 'pointBy',
  initialState,
  reducers: {
    setPointBy(state, action: PayloadAction<number>) {
      state.value = action.payload;
    },
  },
});

export const { setPointBy } = pointBySlice.actions;

export const pointByReducer = pointBySlice.reducer;
