import { lazy, Suspense } from 'react';

import { Loader } from 'widgets';

const PlaylistTheme = lazy(() => import('./PlaylistTheme'));

export const PlaylistTheme_Lazy = (): JSX.Element => (
  <>
    <Suspense fallback={<Loader />}>
      <PlaylistTheme />
    </Suspense>
  </>
);
