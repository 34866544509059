import { lazy, Suspense } from 'react';

import { Loader } from '../../../widgets';

const Classifications = lazy(() => import('./Classifications'));

export const Classifications_Lazy = (): JSX.Element => (
  <>
    <Suspense fallback={<Loader />}>
      <Classifications />
    </Suspense>
  </>
);
