import React from 'react';

import { ContainerWrapper } from '../../../../../../../../widgets';

import style from './RelatedFeeds.module.scss';

export const RelatedFeeds = (): JSX.Element => (
  <div className={style.feedListContainer}>
    <div className={style.headerWrapper}>
      <ContainerWrapper title="Связанные ленты в лодочке">
        <div className={style.feedList}>Здесь будут связанные ленты</div>
      </ContainerWrapper>
    </div>
  </div>
);
