// первые три обязательные, остальные опциональные
export enum UTM_Metrics_Enum {
  utmSource = 'utm_source',
  utmMedium = 'utm_medium',
  utmCampaign = 'utm_campaign',
  utmContent = 'utm_content',
  utmTerm = 'utm_term',
}

export const validateUrlWithUtm = (url: string): boolean => {
  try {
    const parsedUrl = new URL(url);
    const searchParams = new URLSearchParams(parsedUrl.search);

    if (
      !searchParams.has(UTM_Metrics_Enum.utmSource) ||
      !searchParams.has(UTM_Metrics_Enum.utmMedium) ||
      !searchParams.has(UTM_Metrics_Enum.utmCampaign)
    ) {
      return false;
    }

    const optionalParams = Object.values(UTM_Metrics_Enum).slice(3);

    for (const param of optionalParams) {
      if (searchParams.has(param)) {
        if (!searchParams.get(param)) {
          return false;
        }
      }
    }

    return true;
  } catch (error) {
    return false;
  }
};
