import { memo } from 'react';

import { ColumnTitle, ExpandButton } from '../../../../../../widgets';

import style from './TagsForCompilations.module.scss';

export const TagsForCompilationsListHeader = memo(
  (): JSX.Element => (
    <div className={style.titleWrapper}>
      <div className={style.titleColumn}>
        <ColumnTitle text="Название" />
      </div>
      <div className={style.themeColumn}>
        <ColumnTitle text="Тема" />
      </div>
      <div className={style.compilationCountColumn}>
        <ColumnTitle text="Кол-во подборок" />
      </div>
      <div className={style.dataCreatedColumn}>
        <ColumnTitle text="Дата создания" />
      </div>
      <div className={style.buttonsColumn}>
        <div className={style.buttonContainer}>
          <ExpandButton />
        </div>
      </div>
    </div>
  )
);
