import { Suspense, lazy } from 'react';

import { Loader } from '../../../../widgets';

const CreateQuestion = lazy(() => import('./CreateQuestion'));

export const CreateQuestion_Lazy = (): JSX.Element => (
  <>
    <Suspense fallback={<Loader />}>
      <CreateQuestion />
    </Suspense>
  </>
);
