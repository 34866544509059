import { useFormikContext } from 'formik';

import { ICreateBaseSection } from '../../../../../../../../app/api/services/sections/baseSectionsTypes';
import {
  ContainerWrapper,
  InputDate,
  InputNumber,
  Switch,
  TitleWithDescription,
} from '../../../../../../../../widgets';
import { SectionFields } from '../../../SectionFields';

import style from './MainParameters.module.scss';

export const MainParameters = (): JSX.Element => {
  const { values } = useFormikContext<ICreateBaseSection>();

  return (
    <ContainerWrapper
      title="Главные параметры"
      className={style.contentWrapper}
    >
      <div className={style.mainParametersWrapper}>
        <Switch
          label="Активность"
          field={SectionFields.Is_active}
          value={values.is_active}
          className={style.featureMargin}
        />
        <TitleWithDescription
          title="Статус неактивности раздела:"
          description=""
          className={style.featureMargin}
        />
        {/* //TODO переделать под input, когда станет больше таких сущностей */}
        <div className={style.inputDate}>
          <h3
          // className={cn({
          //   [style.disabledLabel]: disabled,
          //   [style.errorLabel]: !!errors?.pubDate,
          // })}
          >
            Дата публикации раздела
          </h3>
          <div className={style.licenseDateInput}>
            <InputDate
              field={SectionFields.Published_at}
              value={values.published_at}
              className={style.publicationDate}
              dateFormat="yyyy.MM.dd     p"
              placeholder="2020.10.25    12:30"
              showTimeSelect
            />
          </div>
        </div>
        <InputNumber
          title="Вес"
          value={values.weight}
          field={SectionFields.Weight}
          maxValue={999}
          placeholder="0"
        />
      </div>
    </ContainerWrapper>
  );
};
