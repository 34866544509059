import {
  InputDateFilter,
  InputNumberOrRange,
  InputText,
  SelectPresent,
  SelectWithSearch,
  SwitchInFilter,
  InputImg,
} from './MassActionsFormComponents';
import { IFormikValues, TColumn } from './types';
import { IBody } from '../../app/api/services/filters/types';

//TODO добавлять поля по мере готовности фильтров на бэке
export const fieldText = {
  name: 'Название',
  uuid: 'UUID',
  id: 'ID',
  title: 'Название в списках',
  description: 'Опиcание',
  full_description: 'Опиcание',
  ISBN: 'ISBN',
  idbook: 'idbook',
};

export const fieldWithSearch = {
  channel_id: 'Канал',
  playlist_id: 'Плейлист',
  owner_id: 'Правообладатель',
  pesron_id: 'Персона',
  author_id: 'Автор',
  reader_id: 'Чтец',
  translator_id: 'Переводчик',
  feed_id: 'Лента',
  counterparty_id: 'Контрагент',
};

export const fieldWithoutSearch = {
  type_id: 'Вид правообладателя',
  level_id: 'Уровень авторства правообладателя',
  content_type_id: 'Вид контента',
  format_id: 'Формат плейлиста',
};

export const fieldSwitch = {
  is_active: 'Активность',
};

export const fieldDate = {
  created_at: 'Дата создания',
  updated_at: 'Дата обновления',
  release_date: 'Дата релиза',
  published_at: 'Дата публикации',
};

export const fieldQuantitativeIndicator = {
  playlists_count: 'Кол-во плейлистов',
  clips_count: 'Кол-во роликов',
};

export const fieldImg = {
  img_id: 'Картинка',
  primary_image_id: 'Основной id картинки',
  primary_image_url: 'Основной url картинки',
};

export const returnedFilter = (
  property: string,
  columnType: TColumn
): React.ReactElement => {
  if (property in fieldText) {
    return (
      <InputText massAction={property} key={property} columnType={columnType} />
    );
  } else if (property in fieldWithSearch) {
    return (
      <SelectWithSearch
        massAction={property}
        key={property}
        columnType={columnType}
      />
    );
  } else if (property in fieldWithoutSearch) {
    return (
      <SelectPresent
        massAction={property}
        key={property}
        columnType={columnType}
      />
    );
  } else if (property in fieldSwitch) {
    return (
      <SwitchInFilter
        massAction={property}
        key={property}
        columnType={columnType}
      />
    );
  } else if (property in fieldDate) {
    return (
      <InputDateFilter
        massAction={property}
        key={property}
        columnType={columnType}
      />
    );
  } else if (property in fieldQuantitativeIndicator) {
    return (
      <InputNumberOrRange
        massAction={property}
        key={property}
        columnType={columnType}
      />
    );
  } else if (property in fieldImg) {
    return (
      <InputImg massAction={property} key={property} columnType={columnType} />
    );
  }
};

export const disabledBtnToApplyFilter = (
  values: IFormikValues | IBody
): boolean => {
  const allProperties = [
    ...Object.values(values?.['includes'] || {}),
    ...Object.values(values?.['excludes'] || {}),
  ]
    .map((i) =>
      i !== null && i.constructor === Object ? Object.values(i || {}) : i
    )
    .flat();

  return (
    allProperties.includes(null) ||
    allProperties.includes('') ||
    allProperties.includes(Object.values({ gte: null, lte: null }))
  );
};
