interface IProps {
  color?: string;
}

export const StopIcon = (): JSX.Element => (
  <svg
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="15" cy="15" r="14.5" fill="#8179FE" stroke="#8179FE" />
    <rect
      x="10.7144"
      y="8.57153"
      width="3.21429"
      height="12.8571"
      rx="1"
      fill="white"
    />
    <rect
      x="16.0085"
      y="8.57153"
      width="3.21429"
      height="12.8571"
      rx="1"
      fill="white"
    />
  </svg>
);

export const InversionStopIcon = ({
  color = '#8179FE',
}: IProps): JSX.Element => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
  >
    <circle cx="10" cy="10" r="10" fill="white" />
    <rect x="7" y="5" width="2.26668" height="10" rx="0.5" fill={color} />
    <rect x="10.7334" y="5" width="2.26668" height="10" rx="0.5" fill={color} />
  </svg>
);
