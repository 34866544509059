import { Form, FormikProvider, useFormik } from 'formik';
import React, { useEffect, useState } from 'react';

import { Bank } from '../../Bank/ui/Bank';
import { fields, validationSchema } from '../helpres';
import { TBankIcon } from 'shared/icons';
import { TOption } from 'shared/types/typesOptionsSelect';
import { ErrorAlert } from 'widgets';

const initialValues = {
  [fields.StartDate]: null,
  [fields.EndDate]: null,
  [fields.Owner]: null,
};

export const TBank = ({
  owners,
  onSubmit,
  downloadHandler,
  downloadDisabled,
}: {
  owners: TOption;
  onSubmit: (values) => void;
  downloadHandler: () => void;
  downloadDisabled?: boolean;
}): JSX.Element => {
  const [isValidateCheked, setIsValidateCheked] = useState(false);

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: onSubmit,
    validateOnBlur: isValidateCheked,
    validateOnChange: isValidateCheked,
  });

  useEffect(() => {
    if (Object.keys(formik.errors).length) {
      setIsValidateCheked(true);
    }
  }, [formik.errors]);

  return (
    <FormikProvider value={formik}>
      <Form
        onKeyDown={(event) => event.key === 'Enter' && event.preventDefault()}
      >
        <Bank
          field={fields.Owner}
          icon={<TBankIcon />}
          title="Т-Банк"
          owners={owners}
          downloadHandler={downloadHandler}
          downloadDisabled={downloadDisabled}
          bank="tbank"
        />
      </Form>
      {Object.keys(formik.errors).length > 0 && (
        <ErrorAlert text="Пожалуйста, заполните все обязательные поля" />
      )}
    </FormikProvider>
  );
};
