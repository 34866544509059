import cn from 'classnames';
import { useFormikContext } from 'formik';
import { useState } from 'react';

import { EditURL } from './EditURL';
import { Plus } from '../../../shared/icons';
import { MenuOption } from '../../MenuOption';
import { TextWithTooltip } from '../../TextWithTooltip';
import { ObligatoryField } from 'widgets';

import style from './AddURL.module.scss';

interface IProps {
  label: string;
  field: string;
  className?: string;
  value: string;
  isError?: boolean;
  isPlaylistUrl?: boolean;
  isObligatory?: boolean;
}

export const AddURL = ({
  label,
  field,
  className,
  value,
  isError = false,
  isPlaylistUrl = false,
  isObligatory,
}: IProps): JSX.Element => {
  const { setFieldValue } = useFormikContext();
  const [showInput, setShowInput] = useState(false);
  const [valueURL, setValueURL] = useState('');

  const clickHandle = (e) => {
    e.preventDefault();
    setShowInput(true);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValueURL(event.target.value);
  };

  return (
    <div className={cn(style.container, className)}>
      <h3 className={cn(style.label, { [style.errorLabel]: isError })}>
        {valueURL === '' ? label : `${label}`}{' '}
        {isObligatory && <ObligatoryField />}
      </h3>

      {!value && (
        <button className={style.add} onClick={(e) => clickHandle(e)}>
          <Plus />
        </button>
      )}

      {showInput && (
        <div className={style.selectWrapper}>
          <div className={style.select}>
            <input
              className={style.input}
              placeholder="Добавьте URL"
              autoFocus={showInput}
              onChange={handleChange}
              onKeyDown={(event) => {
                if (event.key === 'Enter') {
                  event.preventDefault();
                  setFieldValue(field, valueURL);
                  setShowInput(false);
                }
              }}
              onBlur={() => {
                setFieldValue(field, valueURL);
                setShowInput(false);
              }}
            />
          </div>
        </div>
      )}

      {value && (
        <div
          className={
            isPlaylistUrl ? style.editPlaylistWrapper : style.editWrapper
          }
        >
          <a
            className={style.optionDescription}
            href={value}
            target="_blank"
            rel="noreferrer"
          >
            <TextWithTooltip text={value} />
          </a>
          <MenuOption
            position="left"
            onDelete={() => {
              setFieldValue(field, '');
              setValueURL('');
            }}
          >
            <EditURL field={field} value={value} />
          </MenuOption>
        </div>
      )}
    </div>
  );
};
