export const ArrowUp = (): JSX.Element => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="14"
    height="17"
    fill="none"
    viewBox="0 0 14 17"
  >
    <path
      fill="#fff"
      d="M6.1 16a.9.9 0 101.8 0H6.1zM7.636.364a.9.9 0 00-1.272 0L.636 6.09A.9.9 0 101.91 7.364L7 2.273l5.091 5.091a.9.9 0 101.273-1.273L7.636.364zM7.9 16V1H6.1v15h1.8z"
    ></path>
  </svg>
);
