import axios, {
  AxiosRequestHeaders,
  CreateAxiosDefaults,
  HeadersDefaults,
  RawAxiosRequestHeaders,
} from 'axios';
import Cookies from 'universal-cookie';

import { API_PATH } from '../../../global/Api';
import { clearCookies, getAccessTokenCookie } from '../../../utils/cookies';

interface CommonHeaderProperties extends AxiosRequestHeaders {
  Authorization: string;
}

const baseHeaders: RawAxiosRequestHeaders | Partial<HeadersDefaults> = {
  'Content-Type': 'application/json',
};

const configAuthApi: CreateAxiosDefaults = {
  baseURL: API_PATH,
};

export const instanceAuthApi = axios.create(configAuthApi);

instanceAuthApi.interceptors.request.use(
  async (request) => {
    const cookies = new Cookies();
    try {
      const accessToken = getAccessTokenCookie(cookies);

      if (accessToken) {
        request.headers = {
          ...baseHeaders,
          Authorization: `Bearer ${accessToken}`,
        } as unknown as CommonHeaderProperties;
      } else {
        request.headers = {
          ...baseHeaders,
        } as unknown as CommonHeaderProperties;
      }

      return request;
    } catch (e) {
      clearCookies(cookies);
      window.location.href = '/login';
    }
  },
  (error) => Promise.reject(error)
);
