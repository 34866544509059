import cn from 'classnames';
import { useState } from 'react';

import { IEntityMerging } from '../../../../app/global/types';
import { SearchEntity } from '../../../../store/slices/selectSlice';
import { ClickShowContainer } from '../../../../widgets/ClickShowContainer';
import { SelectWithOptions } from '../../../../widgets/SelectWithOptions';

import style from './AddTopic.module.scss';

interface IProps {
  searchEntity?: SearchEntity;
  label: string;
  options: IEntityMerging[] | { id: number; name: string }[];
  className?: string;
  onChange?: (option) => void;
  isError?: boolean;
}

export const AddTopic = ({
  searchEntity,
  label,
  options,
  className,
  onChange,
  isError = false,
}: IProps): JSX.Element => {
  const [showSelect, setShowSelect] = useState(false);

  const clickHandle = (e) => {
    e.preventDefault();
    setShowSelect(true);
  };

  const handleTypeSelect = (option) => {
    setShowSelect(false);

    onChange(option);
  };

  return (
    <div className={cn(style.container, className)}>
      <h3
        className={cn(style.label, { [style.errorLabel]: isError })}
        onClick={(e) => clickHandle(e)}
      >
        {label}
      </h3>
      {showSelect && (
        <ClickShowContainer
          dropdown={showSelect}
          setDropdown={setShowSelect}
          className={style.select}
        >
          <SelectWithOptions
            searchEntity={searchEntity}
            options={options}
            onChange={handleTypeSelect}
          />
        </ClickShowContainer>
      )}
    </div>
  );
};
