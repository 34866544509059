/* eslint-disable @typescript-eslint/no-explicit-any */
import cn from 'classnames';
import { useFormikContext } from 'formik';
import { useEffect, useState } from 'react';

import { IEntityMerging } from '../../../../../../../../../../app/global/types';
import { SearchEntity } from '../../../../../../../../../../store/slices/selectSlice';
import { getTheArrayForSection } from '../../../../../../../../../../utils/getTheIdArray/getTheIdArray';
import { InputDate } from '../../../../../../../../../../widgets';
import { KitItem } from '../../../../../../../../../../widgets/KitItem';
import {
  IAutoSectionErrors,
  IAutoSectionValues,
  getElements,
} from '../../../../../helpers';
import { ResetModal } from '../../ResetModal';

import style from './AccessoriesKit.module.scss';

interface IProps {
  searchEntity: SearchEntity;
  options: Array<IEntityMerging>;
  items: any[];
  onChange?: (elements) => void;
  label?: string;
  isError?: boolean;
  screenPosition: number;
}

export const AccessoriesKit = ({
  options,
  items,
  searchEntity,
  label = 'Набор элементов *',
  isError = false,
  screenPosition = 1,
}: IProps): JSX.Element => {
  const { values, setFieldValue } = useFormikContext<IAutoSectionValues>();
  const { errors } = useFormikContext<IAutoSectionErrors>();

  const [elements, setElements] = useState(
    items?.length ? [...items] : [...Array(3)]
  );

  useEffect(() => {
    setFieldValue(
      `elements.screen${screenPosition}`,
      getTheArrayForSection(elements)
    );
  }, [elements, screenPosition, setFieldValue]);

  const clickHandle = (option, selectedIndex) => {
    const elementsbr = getElements(values.elements);

    if (!elementsbr.some((item) => item.entity_id === option.id)) {
      if (selectedIndex !== null) {
        const newElements = [...elements];
        newElements[selectedIndex] = { ...option, screenPosition };

        setElements(newElements);
      }
    }
  };

  const handleReset = (selectedIndex) => {
    const newElements = [...elements];
    newElements[selectedIndex] = undefined;

    setElements(newElements);
  };

  const handleChangeDate = (date, selectedIndex) => {
    const newElements = [...elements];
    newElements[selectedIndex] = {
      ...newElements[selectedIndex],
      end_date: date,
    };

    setElements(newElements);
  };

  return (
    <div className={style.container}>
      <div>
        <h3 className={cn(style.label, { [style.errorLabel]: isError })}>
          {label}
        </h3>
        <div className={style.wrapper}>
          {elements?.map(
            (element, index): JSX.Element => (
              <KitItem
                key={element?.name || index}
                searchEntity={searchEntity}
                options={options}
                elements={elements}
                item={element}
                onClick={(option) => {
                  clickHandle(option, index);
                }}
              />
            )
          )}
        </div>
      </div>
      <div className={style.dates}>
        <h3 className={cn(style.label, { [style.errorLabel]: isError })}>
          Дата и время окончания закрепа
        </h3>
        <div className={style.wrapper}>
          {elements?.map(
            (element, index): JSX.Element => (
              <div className={style.inputDate} key={index}>
                <h3
                  className={cn(style.labelDate, {
                    [style.activeLabel]: elements[index] && !element?.end_date,
                    [style.errorLabel]:
                      errors?.elements?.includes(element?.id) &&
                      !element?.end_date,
                  })}
                >
                  {index + 1}
                </h3>
                <div className={style.licenseDateInput}>
                  <InputDate
                    field={`elements.screen${screenPosition}.${index}.end_date`}
                    value={element?.end_date}
                    className={cn(style.publicationDate, {
                      [style.activeDate]: elements[index] && !element?.end_date,
                      [style.errorDate]:
                        errors?.elements?.includes(element?.id) &&
                        !element?.end_date,
                    })}
                    dateFormat="yyyy.MM.dd    p"
                    placeholder="2020.10.25   12:30"
                    showTimeSelect
                    handleChangeDate={(date) => handleChangeDate(date, index)}
                    isError={
                      errors?.elements?.includes(element?.id) &&
                      !element?.end_date
                    }
                  />
                  {elements[index] && !element?.end_date && (
                    <span className={style.activePlaceholder}>
                      Введите дату и время окончания закрепа
                    </span>
                  )}
                  {elements[index] && element?.end_date && (
                    <ResetModal
                      handleReset={() => {
                        handleReset(index);
                      }}
                    />
                  )}
                </div>
              </div>
            )
          )}
        </div>
      </div>
    </div>
  );
};
