import { useFormikContext } from 'formik';
import { useEffect, useState } from 'react';

import { DateInput } from 'pages/Navbar/Dashboards/DashboradsComponents/DateInput';
import { ISupermixProfitValues } from 'pages/Navbar/Dashboards/SupermixProfitGeneral/helpers';
import { SelectWrapper } from 'widgets/SelectWrapper';

import style from './MonthSelect.module.scss';

// сделать один компонент?
export const MonthSelect = ({
  field,
  isError,
  includeDates,
  maxDate,
  disabled,
}: {
  field: string;
  isError?: boolean;
  includeDates?: Date[];
  maxDate?: Date;
  disabled?: boolean;
}): JSX.Element => {
  const [isOpen, setIsOpen] = useState(false);
  const [isValueVisible, setIsValueVisible] = useState(false);
  const [selectedDate, setSelectedDate] = useState<string>('');

  const { setFieldValue, values } = useFormikContext<ISupermixProfitValues>();

  useEffect(() => {
    if (values[field]) {
      setSelectedDate(
        `${values[field]?.getFullYear()}, ${values[field]?.toLocaleString(
          'ru-RU',
          {
            month: 'long',
          }
        )}`
      );
    } else {
      setSelectedDate('');
    }
  }, [values, field]);

  return (
    <SelectWrapper
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      isValueVisible={isValueVisible}
      title="Период"
      isError={isError}
      value={selectedDate}
      className={style.select}
    >
      <div className={style.datePickerInner}>
        <DateInput
          onChange={(date) => {
            setFieldValue(field, date);
            setIsValueVisible(true);
            setIsOpen(false);
          }}
          selected={values[field]}
          title="Выберите месяц"
          dateFormat="yyyy, MMM"
          showMonthYearPicker
          placeholderText="Месяц"
          maxDate={maxDate}
          includeDates={includeDates}
          disabled={disabled}
        />
      </div>
    </SelectWrapper>
  );
};
