import cn from 'classnames';
import { useFormikContext } from 'formik';
import { useEffect, useState } from 'react';

import { EditChangeEntity } from './EditChangeEntity';
import { IEntityMerging } from '../../../app/global/types';
import { Plus } from '../../../shared/icons';
import { SearchEntity } from '../../../store/slices/selectSlice';
import { ClickShowContainer } from '../../ClickShowContainer';
import { MenuOption } from '../../MenuOption';
import { SelectWithOptions } from '../../SelectWithOptions';
import { TextWithTooltip } from '../../TextWithTooltip';
import { ObligatoryField } from 'widgets/ObligatoryField';

import style from './AddManyChangeEntity.module.scss';

interface IProps {
  searchEntity: SearchEntity;
  options: { id: number; name: string }[];
  field: string;
  items?: IEntityMerging[] | { id: number; name: string }[];
  label: string;
  isEdit?: boolean;
  isError?: boolean;
  className?: string;
  isObligatory?: boolean;
}

export const AddManyChangeEntity = ({
  searchEntity,
  items,
  options,
  field,
  label,
  isError,
  className,
  isObligatory = false,
}: IProps): JSX.Element => {
  const { setFieldValue } = useFormikContext();

  const [plusIsOpen] = useState(true);
  const [showSelect, setShowSelect] = useState(false);
  const [allSelectedOptions, setAllSelectedOptions] = useState(items ?? []);

  const newArr = options?.filter(
    (option) => !allSelectedOptions.some((entity) => entity.id === option.id)
  );

  const clickHandle = (e) => {
    e.preventDefault();
    setShowSelect(true);
  };

  const handleTypeSelect = (option) => {
    const isInArray = !!allSelectedOptions.find(
      (item) => item.id === option?.id
    );

    if (isInArray || option === null) {
      return;
    } else {
      setAllSelectedOptions((prevState) => [...prevState, option]);
      setShowSelect(false);
    }
  };

  const handleDeleteOption = (option) => {
    setAllSelectedOptions((prevState) => [
      ...prevState.filter((element) => element.id !== option.id),
    ]);
    setShowSelect(false);
  };

  useEffect(() => {
    setFieldValue(
      field,
      allSelectedOptions?.map((el) => el.id)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allSelectedOptions]);

  return (
    <div className={cn(style.container, className)}>
      <h3 className={cn(style.label, { [style.errorLabel]: isError })}>
        {label} {isObligatory && <ObligatoryField />}
      </h3>
      <div className={style.buttonPlusContainer}>
        {plusIsOpen && (
          <button className={style.add} onClick={(e) => clickHandle(e)}>
            <Plus />
          </button>
        )}

        {showSelect && (
          <ClickShowContainer
            dropdown={showSelect}
            setDropdown={setShowSelect}
            className={style.select}
          >
            <SelectWithOptions
              searchEntity={searchEntity}
              options={newArr}
              onChange={handleTypeSelect}
            />
          </ClickShowContainer>
        )}
      </div>

      <div className={style.selectedOptions}>
        {allSelectedOptions.map((option) => (
          <div key={option.id} className={cn(style.option)}>
            <TextWithTooltip text={option.name} className={style.tooltipText} />

            <MenuOption
              position="left"
              onDelete={() => {
                handleDeleteOption(option);
              }}
            >
              <EditChangeEntity
                elementToEdit={option}
                options={newArr}
                searchEntity={searchEntity}
                allSelectedOptions={allSelectedOptions}
                setAllSelectedOptions={setAllSelectedOptions}
              />
            </MenuOption>
          </div>
        ))}
      </div>
    </div>
  );
};
