import cn from 'classnames';
import { Link } from 'react-router-dom';

import { Router } from '../../../components/Router/routerEnum';
import { PlusInTheCircle } from '../../../shared/icons';

import style from './CreateButton.module.scss';

interface IProps {
  description: string;
  icon?: React.ReactElement;
  link?: string;
  onClick?: () => void;
  type?: 'reverse' | 'normal';
  disabled?: boolean;
}

export const CreateButton = ({
  description,
  icon = <PlusInTheCircle />,
  link,
  onClick,
  type = 'normal',
  disabled = false,
}: IProps): JSX.Element => {
  if (onClick) {
    return (
      <button
        className={cn(style.container, style[type], {
          [style.disabled]: disabled,
        })}
        onClick={onClick}
        type="button"
      >
        {icon}
        <span className={style.desc}>{description}</span>
      </button>
    );
  } else {
    return (
      <Link
        className={cn(style.container, style[type], {
          [style.disabled]: disabled,
        })}
        to={link || Router.Create}
        target="_blank"
      >
        {icon}
        <span className={style.desc}>{description}</span>
      </Link>
    );
  }
};
