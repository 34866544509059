import cn from 'classnames';
import { ReactNode } from 'react';

import { Close } from '../../../shared/icons';
import { Separator } from '../../../widgets';

import style from './Modal.module.scss';

interface IPropsModal {
  active: boolean;
  setActive: (active: boolean) => void;
  children: ReactNode;
  className?: string;
  classNameWrapper?: string;
  title?: string;
  id?: string;
}

export const Modal = ({
  active,
  setActive,
  children,
  className,
  classNameWrapper,
  title,
  id,
}: IPropsModal): JSX.Element => {
  const clickHandle = (e) => {
    e.preventDefault();
    setActive(false);
  };

  return (
    <div
      id={id}
      className={
        active ? cn(style.modal, style.active, classNameWrapper) : style.modal
      }
      onClick={clickHandle}
    >
      <div
        className={cn(style.modalContent, className)}
        onClick={(e) => e.stopPropagation()}
      >
        {title && (
          <div className={style.header}>
            <h3 className={style.title}>{title}</h3>
            <Separator className={style.separator} />
          </div>
        )}
        <button className={style.closeButton} onClick={clickHandle}>
          <Close />
        </button>
        {children}
      </div>
    </div>
  );
};
