import cn from 'classnames';
import AsyncSelect from 'react-select';

import { IContent } from '../../../app/global/types';
import { ObligatoryField } from 'widgets/ObligatoryField';

import style from './SelectSearch.module.scss';

interface Options {
  id: number;
  name?: string;
  amount?: string;
  uuid?: string;
}

interface IProps {
  options: IContent[] | Options[];
  isLoading: boolean;
  valueInput: string;
  handleChangeInput: (e) => void;
  onChangeHandle: (e, ...rest) => void;
  name?: string;
  defaultOption: IContent | Options;
  isError?: boolean;
  title?: string;
  className?: string;
  isObligatory?: boolean;
}

export const SelectSearch = ({
  name,
  options,
  isLoading,
  valueInput,
  handleChangeInput,
  onChangeHandle,
  defaultOption,
  isError = false,
  title,
  className,
  isObligatory = false,
}: IProps): JSX.Element => (
  <label className={cn(style.wrapper, className)}>
    {title && (
      <h3 className={cn(style.title, { [style.errorTitle]: isError })}>
        {title} {isObligatory && <ObligatoryField />}
      </h3>
    )}
    <AsyncSelect
      defaultValue={defaultOption}
      className={cn(style.select, { [style.errorSelect]: isError })}
      placeholder="Выберите"
      inputValue={valueInput}
      isSearchable
      options={options}
      getOptionValue={(option) => String(option.id)}
      getOptionLabel={(option) => (option.name ? option.name : option.amount)}
      onChange={(value, ...rest) => onChangeHandle(value, ...rest)}
      noOptionsMessage={() => 'Ничего не найдено'}
      loadingMessage={() => 'Поиск'}
      onInputChange={(value) => handleChangeInput(value)}
      isLoading={isLoading}
      name={name}
    />
  </label>
);
