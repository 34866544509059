import { Dispatch, SetStateAction } from 'react';
import { NavigateFunction } from 'react-router-dom';
import { toast } from 'react-toastify';

interface IProps {
  success: boolean;
  message: string;
  onOpen?: () => void;
  onClose?: () => void;
  setIsToast?: Dispatch<SetStateAction<boolean>>;
  navigate?: NavigateFunction;
  path?: string;
  autoClose?: number;
}

export const successToast = ({
  message,
  success,
  onOpen,
  onClose,
  setIsToast,
  navigate,
  path,
  autoClose,
}: IProps): void => {
  if (success) {
    toast.success(message, {
      onOpen: onOpen
        ? onOpen
        : () => {
            setIsToast && setIsToast(true);
            navigate &&
              path &&
              navigate(path, {
                replace: true,
              });
          },
      onClose: onClose
        ? onClose
        : () => {
            setIsToast && setIsToast(false);
          },
      autoClose,
    });
  }
};
