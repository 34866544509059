import cn from 'classnames';
import { useLayoutEffect, useState } from 'react';

import imagePlaceholder from '../assets/imagePlaceholder.png';
import imagePlaceholderCircle from '../assets/ImgPlaceholderCircle.png';

import style from './Img.module.scss';

interface IProps {
  src: string;
  className?: string;
  circle?: boolean;
}

export const Img = ({ src, circle, className }: IProps): JSX.Element => {
  const [isValidImg, setIsValidImg] = useState(true);

  const checkImgSrc = (src: string) => {
    const img = new Image();
    img.onload = () => {
      setIsValidImg(true);
    };
    img.onerror = () => {
      setIsValidImg(false);
    };
    img.src = src;
  };

  useLayoutEffect(() => {
    checkImgSrc(src);
  }, [src]);

  return (
    <img
      src={
        isValidImg ? src : circle ? imagePlaceholderCircle : imagePlaceholder
      }
      alt="Картинка"
      className={cn(style.img, className, { [style.circle]: circle })}
    />
  );
};
