import {
  IBodyCreate,
  IEdit,
  IOwnersGet,
  IOwnersGetById,
  IResponseOwnerTypesAndLevels,
  Params,
} from './types';
import { endpoints } from '../../../../shared/api/endpoints/endpoints';
import { tags } from '../../../../shared/api/tags/tags';
import { mainApi } from '../config';

export const ownersApiQuery = mainApi.injectEndpoints({
  endpoints: (builder) => ({
    getOwnerByCondition: builder.query<IOwnersGet, Params>({
      query: ({ limit, offset, for_contract, counterparty_id }) => ({
        url: endpoints.ownerGet,
        params: { limit, offset, for_contract, counterparty_id },
      }),
      serializeQueryArgs: ({ endpointName }) => endpointName,
      merge(currentCacheData, responseData) {
        const newResponses = responseData.response.filter(
          (newResponse) =>
            !currentCacheData.response.some(
              (currentResponse) => currentResponse.id === newResponse.id
            )
        );
        currentCacheData.response.push(...newResponses);
      },
      forceRefetch({ currentArg, previousArg }) {
        return currentArg !== previousArg;
      },
      providesTags: (result) => {
        if (result) {
          const { response } = result;

          return [
            ...response.map(({ id }) => ({ type: tags.owner, id } as const)),
            { type: tags.owner, id: 'OWNER_LIST' },
          ];
        } else {
          return [{ type: tags.owner, id: 'OWNER_LIST' }];
        }
      },
    }),
    getOwnerById: builder.query<IOwnersGetById, number>({
      query: (id) => ({
        url: `${endpoints.owner}?id=${id}`,
      }),
      providesTags: [tags.owner],
    }),
    getOwnersByName: builder.query<IOwnersGet, string>({
      query: (name: string) => ({
        url: `${endpoints.ownerGetByName}?name=${name}`,
        params: {
          limit: 5,
        },
      }),
      providesTags: [tags.owner],
    }),
  }),
});

export const ownersApiMutation = mainApi.injectEndpoints({
  endpoints: (builder) => ({
    createOwner: builder.mutation({
      query: (body: IBodyCreate) => ({
        url: endpoints.ownerCreate,
        method: 'POST',
        data: body,
      }),
      invalidatesTags: [tags.owner],
    }),

    editOwner: builder.mutation({
      query: (body: IEdit) => ({
        url: endpoints.owner,
        method: 'PUT',
        data: body,
      }),
      invalidatesTags: [tags.owner],
    }),

    deleteOwner: builder.mutation({
      query: (id) => ({
        url: `${endpoints.owner}?id=${id}`,
        method: 'DELETE',
        id,
      }),
      invalidatesTags: (_result, _error, arg) => [
        { type: tags.owner, id: arg },
      ],
    }),

    getOwnerTypes: builder.query<IResponseOwnerTypesAndLevels, void>({
      query: () => ({
        url: endpoints.ownerTypes,
      }),
      providesTags: [tags.owner],
    }),
    getOwnerLevels: builder.query<IResponseOwnerTypesAndLevels, void>({
      query: () => ({
        url: endpoints.ownerLevels,
      }),
      providesTags: [tags.owner],
    }),
  }),
});

export const {
  useGetOwnerByConditionQuery,
  useGetOwnerByIdQuery,
  useGetOwnersByNameQuery,
  usePrefetch,
} = ownersApiQuery;

export const {
  useCreateOwnerMutation,
  useDeleteOwnerMutation,
  useEditOwnerMutation,
  useGetOwnerTypesQuery,
  useGetOwnerLevelsQuery,
} = ownersApiMutation;
