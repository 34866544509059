import cn from 'classnames';

import style from './ObligatoryField.module.scss';

interface IProps {
  className?: string;
}

export const ObligatoryField = ({ className }: IProps): JSX.Element => (
  <div className={cn(style.star, className)}>*</div>
);
