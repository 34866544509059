import { lazy, Suspense } from 'react';

import { Loader } from '../../../widgets';

const Contexts = lazy(() => import('./Contexts'));

export const Contexts_Lazy = (): JSX.Element => (
  <>
    <Suspense fallback={<Loader />}>
      <Contexts />
    </Suspense>
  </>
);
