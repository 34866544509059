import { Suspense, lazy } from 'react';

import { Loader } from '../../../../../../widgets';

const CreateContract = lazy(() => import('./CreateContract'));

export const CreateContract_Lazy = (): JSX.Element => (
  <>
    <Suspense fallback={<Loader />}>
      <CreateContract />
    </Suspense>
  </>
);
