import cn from 'classnames';
import moment from 'moment';
import React from 'react';

import { formatDuration } from '../../../utils/formatDuration/formatDuration';
import {
  ContainerWrapper,
  CopyButton,
  Separator,
  TextWithTooltip,
  TitleWithDescription,
} from '../../../widgets';

import style from './Characteristisc.module.scss';

interface IProps {
  children?: React.ReactNode;
  titleUUID: string;
  titleSKU?: string;
  titleDateCreated: string;
  titleDuration?: string;
  UUIDDescription: string;
  SKUDescription?: string;
  dateCreatedDescription: string;
  durationOfAudio?: number;
  className?: string;
  classNameWrapper?: string;
  isForClip?: boolean;
  isForPlaylist?: boolean;
  titleEvent?: string;
  dateEvent?: string;
  showSeparator?: boolean;
}

export const Characteristics = ({
  children,
  titleUUID,
  titleSKU,
  titleDateCreated,
  titleDuration,
  UUIDDescription,
  SKUDescription,
  dateCreatedDescription,
  durationOfAudio,
  className,
  classNameWrapper,
  isForClip = false,
  isForPlaylist = false,
  showSeparator = false,
}: IProps): JSX.Element => (
  <ContainerWrapper title="Характеристики" className={classNameWrapper}>
    <div
      className={cn(isForClip ? style.clipWrapper : style.wrapper, className)}
    >
      <div
        className={
          isForPlaylist
            ? style.contentPlaylist
            : isForClip
            ? style.contentClip
            : style.content
        }
      >
        {isForPlaylist && (
          <TitleWithDescription
            title={titleDuration}
            description={formatDuration(durationOfAudio)}
            className={style.duration}
          />
        )}
        <h3 className={style.count}>
          {titleUUID}
          <TextWithTooltip text={UUIDDescription} className={style.entity} />
          {UUIDDescription && <CopyButton text={UUIDDescription} />}
        </h3>
        <h3 className={style.count}>
          {titleSKU}
          <TextWithTooltip text={SKUDescription} className={style.sku} />
          {SKUDescription && <CopyButton text={SKUDescription} />}
        </h3>
        <div className={style.dateEvents}>
          <TitleWithDescription
            title={titleDateCreated}
            description={moment(dateCreatedDescription).format(
              isForClip ? 'YYYY/MM/DD HH:mm' : 'YYYY.MM.DD HH:mm'
            )}
            className={style.date}
          />
        </div>
        {isForClip && (
          <TitleWithDescription
            title={titleDuration}
            description={formatDuration(durationOfAudio)}
            className={style.duration}
          />
        )}
      </div>
      {showSeparator && <Separator className={style.separator} />}
      {children}
    </div>
  </ContainerWrapper>
);
