export const UploadImage = (): JSX.Element => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="62"
    height="62"
    fill="none"
    viewBox="0 0 62 62"
  >
    <g filter="url(#filter0_d_2508_25377)">
      <circle cx="31" cy="31" r="18" fill="#F4F5F8"></circle>
    </g>
    <path
      fill="#6860F1"
      d="M33.475 30.956a2.475 2.475 0 11-4.95 0 2.475 2.475 0 014.95 0z"
    ></path>
    <path
      fill="#6860F1"
      d="M25.06 26.5a1.98 1.98 0 00-1.98 1.98v5.94a1.98 1.98 0 001.98 1.98h11.88a1.98 1.98 0 001.98-1.98v-5.94a1.98 1.98 0 00-1.98-1.98h-1.16a1.98 1.98 0 01-1.4-.58l-.82-.82a1.98 1.98 0 00-1.4-.58h-2.32a1.98 1.98 0 00-1.4.58l-.82.82a1.98 1.98 0 01-1.4.58h-1.16zm.495 1.98a.495.495 0 110-.989.495.495 0 010 .99zm8.91 2.476a3.465 3.465 0 11-6.93 0 3.465 3.465 0 016.93 0z"
    ></path>
    <defs>
      <filter
        id="filter0_d_2508_25377"
        width="62"
        height="62"
        x="0"
        y="0"
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        ></feColorMatrix>
        <feMorphology
          in="SourceAlpha"
          operator="dilate"
          radius="3"
          result="effect1_dropShadow_2508_25377"
        ></feMorphology>
        <feOffset></feOffset>
        <feGaussianBlur stdDeviation="5"></feGaussianBlur>
        <feComposite in2="hardAlpha" operator="out"></feComposite>
        <feColorMatrix values="0 0 0 0 0.670833 0 0 0 0 0.670833 0 0 0 0 0.670833 0 0 0 0.15 0"></feColorMatrix>
        <feBlend
          in2="BackgroundImageFix"
          result="effect1_dropShadow_2508_25377"
        ></feBlend>
        <feBlend
          in="SourceGraphic"
          in2="effect1_dropShadow_2508_25377"
          result="shape"
        ></feBlend>
      </filter>
    </defs>
  </svg>
);
