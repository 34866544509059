interface IProps {
  color: string;
}

export const ArrowLeft = ({ color }: IProps): JSX.Element => (
  <svg
    width="9"
    height="16"
    viewBox="0 0 9 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.8875 14.8238L0.217708 8.52383C0.138542 8.44883 0.0825972 8.36758 0.049875 8.28008C0.016625 8.19258 0 8.09883 0 7.99883C0 7.89883 0.016625 7.80508 0.049875 7.71758C0.0825972 7.63008 0.138542 7.54883 0.217708 7.47383L6.8875 1.15508C7.07222 0.980078 7.30312 0.892578 7.58021 0.892578C7.85729 0.892578 8.09479 0.986328 8.29271 1.17383C8.49062 1.36133 8.58958 1.58008 8.58958 1.83008C8.58958 2.08008 8.49062 2.29883 8.29271 2.48633L2.47396 7.99883L8.29271 13.5113C8.47743 13.6863 8.56979 13.9018 8.56979 14.1578C8.56979 14.4143 8.47083 14.6363 8.27292 14.8238C8.075 15.0113 7.8441 15.1051 7.58021 15.1051C7.31632 15.1051 7.08542 15.0113 6.8875 14.8238Z"
      fill={color}
    />
  </svg>
);
