import React, { useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { useNavigate } from 'react-router-dom';

import { COOKIE } from '../../../../app/global/types';
import { Layout } from '../../../Layout';
import { Router } from '../../routerEnum';

export const PrivateRoute: React.FC = () => {
  const navigate = useNavigate();
  const [cookies] = useCookies([COOKIE.LOGGED_IN]);
  const { logged_in } = cookies as { logged_in: string };
  const loggedInIsTrue = logged_in === 'true';

  useEffect(() => {
    !loggedInIsTrue &&
      navigate(Router.Login, {
        replace: true,
      });
  }, [loggedInIsTrue, navigate]);

  return <Layout />;
};
