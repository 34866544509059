import cn from 'classnames';
import { memo } from 'react';

import { dateConverter } from '../../../../../../utils';
import { EditButton, Text, TextWithTooltip } from '../../../../../../widgets';
import { ActivityStatus } from '../../../../../../widgets/ActivityStatus';
import { TagsCompilations } from 'app/api/services/tadForCompilations/types';

import style from './TagsForCompilations.module.scss';

interface ITagsForCompilationsListProps {
  tagsForCompilations: TagsCompilations;
  editEntity: string;
}

export const TagsForCompilationsListItem = memo(
  ({ tagsForCompilations }: ITagsForCompilationsListProps): JSX.Element => (
    <div className={style.contentItem} key={tagsForCompilations.id}>
      <div className={cn(style.titleColumnList, style.titleDesc)}>
        <ActivityStatus isActive={tagsForCompilations?.is_active} />
        <TextWithTooltip text={tagsForCompilations?.name} />
      </div>

      <div className={style.themeDesc}>
        <Text text={tagsForCompilations?.theme} />
      </div>

      <div className={style.compilationCountDesc}>
        <Text text={tagsForCompilations?.compilations_count} />
      </div>

      <div className={style.dataCreatedDesc}>
        <Text text={dateConverter(tagsForCompilations?.created_at)} />
      </div>

      <div className={style.buttons}>
        <EditButton id={tagsForCompilations?.id} circle />
      </div>
    </div>
  )
);
