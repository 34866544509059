import { lazy, Suspense } from 'react';

import { Loader } from '../../../widgets';

const CharacteristicsOfTheUser = lazy(
  () => import('./CharacteristicsOfTheUser')
);

export const CharacteristicsOfTheUser_Lazy = (): JSX.Element => (
  <>
    <Suspense fallback={<Loader />}>
      <CharacteristicsOfTheUser />
    </Suspense>
  </>
);
