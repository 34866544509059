import cn from 'classnames';
import { useState, createContext } from 'react';

import { Ellipsis } from '../../../shared/icons';
import { ClickShowContainer } from '../../ClickShowContainer';

import style from './MenuOption.module.scss';

interface ButtonShowProps {
  onDelete?: (e) => void;
  children?: React.ReactNode;
  position?: 'right' | 'left';
  className?: string;
  isTransparent?: boolean;
  clickHandle?: (e: MouseEvent) => void;
}

export interface IStore {
  contextOpened: boolean;
  setContextOpened: (val: boolean) => void;
}

export const FrameContext = createContext<IStore>(null);

export const MenuOption = ({
  onDelete,
  children,
  position,
  className,
  isTransparent = false,
  clickHandle,
}: ButtonShowProps): JSX.Element => {
  const [isOpen, setIsOpen] = useState(false);
  const [contextOpened, setContextOpened] = useState<boolean>(false);
  const value = { contextOpened, setContextOpened };

  const handleEdit = (e) => {
    setIsOpen(false);
    setContextOpened(true);
    clickHandle && clickHandle(e);
  };

  const handleDelete = (e) => {
    setIsOpen(false);
    onDelete(e);
  };

  return (
    <div
      className={cn(style.inputWrapper, { [style.inputWrapperClose]: !isOpen })}
    >
      <button
        data-button="menu_open_button"
        onClick={() => setIsOpen((prev) => !prev)}
        type="button"
        className={cn(
          isTransparent ? style.button : style.buttonTransparent,
          className,
          (isOpen || contextOpened) && style.open_menu
        )}
      >
        <Ellipsis />
      </button>
      {isOpen && (
        <ClickShowContainer
          dropdown={isOpen}
          setDropdown={setIsOpen}
          className={cn(style.select, {
            [style.left]: position === 'left',
          })}
        >
          <div className={style.frames}>
            <button
              data-button="menu_edit_button"
              onClick={(e) => handleEdit(e)}
              className={style.option_button}
              type="button"
            >
              Редактировать
            </button>
            <button
              data-button="menu_delete_button"
              onClick={handleDelete}
              className={style.option_button}
              type="button"
            >
              Удалить
            </button>
          </div>
        </ClickShowContainer>
      )}
      <FrameContext.Provider value={value}>{children}</FrameContext.Provider>
    </div>
  );
};
