import { lazy, Suspense } from 'react';

import { Loader } from '../../../../../../widgets';

const CreatePlaylistCompilation = lazy(
  () => import('./CreatePlaylistCompilation')
);

export const CreatePlaylistCompilation_Lazy = (): JSX.Element => (
  <>
    <Suspense fallback={<Loader />}>
      <CreatePlaylistCompilation />
    </Suspense>
  </>
);
