import { Suspense, lazy } from 'react';

import { Loader } from '../../../../../widgets';

const PlaylistCompilation = lazy(() => import('./PlaylistCompilation'));

export const PlaylistCompilation_Lazy = (): JSX.Element => (
  <>
    <Suspense fallback={<Loader />}>
      <PlaylistCompilation />
    </Suspense>
  </>
);
