import { Author } from '../api/services/authors/types';
import { BookSeries } from '../api/services/bookSeries/types';
import { Channel } from '../api/services/channels/types';
import { Collection } from '../api/services/collections/types';
import { Counterparties } from '../api/services/counterparties/types';
import {
  FeedBottom,
  FeedGroup,
  FeedTop,
} from '../api/services/feedGroups/types';
import { Feed } from '../api/services/feeds/types';
import { Owner } from '../api/services/owners/types';
import { Person } from '../api/services/persons/types';
import { Playlist } from '../api/services/playlists/types';
import { Reader } from '../api/services/readers/types';
import { Translator } from '../api/services/translators/types';

export interface IFieldDescription {
  type: 'text' | 'email' | 'password' | 'tel' | 'passwordWithTooltip';
  name: string;
  placeholder?: string;
  mask?: string;
  label?: string;
  blur?: boolean;
}

export interface IApi {
  status: number;
  data: IDataApi;
}

export interface IDataApi {
  response: unknown;
  message: string;
  statusCode: number;
  timestamp: string;
}

export enum COOKIE {
  ACCESS_TOKEN = 'access_token',
  REFRESH_TOKEN = 'refresh_token',
  LOGGED_IN = 'logged_in',
}

export interface IImage {
  name: string;
  url: string;
  uniqueKey: string;
  ext: string;
  id: number;
  uuid: string;
  created_at: Date;
  updated_at: Date;
}

export type IEntityMerging =
  | Owner
  | Counterparties
  | Channel
  | Person
  | Author
  | Reader
  | Translator
  | Feed
  | FeedGroup
  | FeedTop
  | FeedBottom
  | Playlist
  | Collection
  | BookSeries;

export interface IItemEntity {
  entity: IEntityMerging;
  order?: number;
}

export interface IContent {
  id: number;
  name: string;
  amount?: string;
  uuid: string;
}

export interface ITags {
  id: number;
  name: string;
  uuid: string;
}
