import { lazy, Suspense } from 'react';

import { Loader } from '../../../widgets';

const BusinessStatistics = lazy(() => import('./BusinessStatistics'));

export const BusinessStatistics_Lazy = (): JSX.Element => (
  <>
    <Suspense fallback={<Loader />}>
      <BusinessStatistics />
    </Suspense>
  </>
);
