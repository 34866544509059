import { Suspense, lazy } from 'react';

import { Loader } from '../../../../../../widgets';

const CreateCounterparties = lazy(() => import('./CreateCounterparties'));

export const CreateCounterparties_Lazy = (): JSX.Element => (
  <>
    <Suspense fallback={<Loader />}>
      <CreateCounterparties />
    </Suspense>
  </>
);
