/* eslint-disable max-lines-per-function */
import {
  IParams,
  IResponseGet,
  IResponseGetById,
  IAlgorithms,
  IEdit,
  IBody,
  IResponseCreate,
  IGetRelatedFeedElements,
  IGetAlgorithmScreens,
  IParamsRelatedFeedsByCondition,
  IParamsChangeRelatedFeedsPosition,
} from './types';
import { endpoints } from '../../../../shared/api/endpoints/endpoints';
import { tags } from '../../../../shared/api/tags/tags';
import { mainApi } from '../config';

export const feedsApiMutation = mainApi.injectEndpoints({
  endpoints: (builder) => ({
    createRelatedFeed: builder.mutation<IResponseCreate, IBody>({
      query: (body) => ({
        url: endpoints.relatedFeed,
        method: 'POST',
        data: body,
      }),
      invalidatesTags: [tags.feedRelated],
    }),

    editRelatedFeed: builder.mutation({
      query: (body: IEdit) => ({
        url: `${endpoints.relatedFeed}?id=${body.id}`,
        method: 'PUT',
        data: { payload: body.payload },
      }),
      invalidatesTags: [tags.feedRelated],
    }),

    deleteRelatedFeed: builder.mutation({
      query: (id) => ({
        url: `${endpoints.relatedFeed}?id=${id}`,
        method: 'DELETE',
        id,
      }),
      invalidatesTags: (_result, _error, arg) => [
        { type: tags.feedRelated, id: arg },
      ],
    }),

    changeRelatedFeedsPosition: builder.mutation<
      unknown,
      IParamsChangeRelatedFeedsPosition
    >({
      query: (body) => ({
        url: `${endpoints.relatedFeedsPositions}`,
        method: 'PATCH',
        data: body,
      }),
      invalidatesTags: (_result, _error, arg) => [
        { type: tags.relatedFeedsByCondition },
      ],
    }),
  }),
});

export const feedsApiByQuery = mainApi.injectEndpoints({
  endpoints: (builder) => ({
    getRelatedFeed: builder.query<IResponseGet, IParams>({
      query: ({ limit, offset }) => ({
        url: endpoints.relatedFeedsGet,
        params: { limit, offset },
      }),
      serializeQueryArgs: ({ endpointName }) => endpointName,
      merge(currentCacheData, responseData) {
        const newResponses = responseData.response.filter(
          (newResponse) =>
            !currentCacheData.response.some(
              (currentResponse) => currentResponse.id === newResponse.id
            )
        );
        currentCacheData.response.push(...newResponses);
      },
      forceRefetch({ currentArg, previousArg }) {
        return currentArg !== previousArg;
      },
      providesTags: (result) => {
        if (result) {
          const { response } = result;

          return [
            ...response.map(
              ({ id }) => ({ type: tags.feedRelated, id } as const)
            ),
            { type: tags.feedRelated, id: 'RELATED_FEEDS_LIST' },
          ];
        } else {
          return [{ type: tags.feedRelated, id: 'RELATED_FEEDS_LIST' }];
        }
      },
    }),

    getRelatedFeedById: builder.query<IResponseGetById, number>({
      query: (id) => ({ url: `${endpoints.relatedFeed}?id=${id}` }),
      providesTags: [tags.feedRelated],
    }),

    getRelatedFeedElements: builder.query<
      IGetRelatedFeedElements,
      { collection_id: number; limit: number; offset: number }
    >({
      query: (params) => ({
        url: `${endpoints.relatedFeedElements}`,
        params: params,
      }),
      providesTags: [tags.feedRelated],
    }),

    getAlgorithms: builder.query<IAlgorithms, unknown>({
      query: () => ({ url: `${endpoints.algorithms}` }),
      providesTags: [tags.algorithms],
    }),

    getAlgorithmScreens: builder.query<IGetAlgorithmScreens, void>({
      query: () => ({ url: `${endpoints.algorithmScreens}` }),
    }),

    getRelatedFeedsByCondition: builder.query<
      IResponseGet,
      IParamsRelatedFeedsByCondition
    >({
      query: (params) => ({
        url: `${endpoints.relatedFeedByCondition}`,
        params: params,
      }),
      providesTags: [tags.relatedFeedsByCondition],
    }),
  }),
});

export const {
  useGetRelatedFeedQuery,
  useGetRelatedFeedByIdQuery,
  useGetAlgorithmsQuery,
  useGetRelatedFeedElementsQuery,
  useLazyGetRelatedFeedElementsQuery,
  useGetAlgorithmScreensQuery,
  useGetRelatedFeedsByConditionQuery,
} = feedsApiByQuery;

export const {
  useDeleteRelatedFeedMutation,
  useCreateRelatedFeedMutation,
  useEditRelatedFeedMutation,
  useChangeRelatedFeedsPositionMutation,
} = feedsApiMutation;
