import _ from 'lodash';
import { memo, useState } from 'react';

import { useAppDispatch, useAppSelector, useExpand } from '../../hooks';
import {
  IStateMassActions,
  setIsMassAction,
  setChekedElements,
} from '../../store/slices/massActionsSlice';
import { SearchEntity } from '../../store/slices/selectSlice';
import {
  CreateButton,
  Filter,
  Header,
  Separator,
  TextCount,
} from '../../widgets';
import { FilterSearchForm } from '../FilterSearchForm';
import { MassActionsForm } from '../MassActionsForm';
import { Modal } from '../Modal';

import style from './ListManagement.module.scss';

interface ListManagementProps {
  header: string;
  countEntityLabel: string;
  createButtonLabel?: string;
  entity?: SearchEntity;
}

export const ListManagement = memo(
  ({
    countEntityLabel,
    header,
    createButtonLabel,
    entity,
  }: ListManagementProps): JSX.Element => {
    const expand = useExpand();
    const dispatch = useAppDispatch();

    const { chekedElements, isMassActions } = useAppSelector(
      (state) => state.massActionsReducer as IStateMassActions
    );

    const [modalActive, setModalActive] = useState(false);
    const [openMassModal, setOpenMassModal] = useState(false);
    const [titleModal, setTitle] = useState('');

    const handleModal = (title: string) => {
      setModalActive(true);
      setTitle(title);
    };

    const handleMassAction = () => {
      if (isMassActions) {
        dispatch(setIsMassAction(false));
        dispatch(setChekedElements([]));
      } else dispatch(setIsMassAction(true));
    };

    return (
      <>
        {!expand && (
          <>
            <div className={style.headerContainer}>
              <div className={style.headingWrapper}>
                <Header className={style.headerClip} label={header} />
                <TextCount text={countEntityLabel} />
              </div>
              {entity && (
                <div className={style.filters}>
                  <Filter
                    text="Фильтр для поиска"
                    onChange={handleModal}
                    type="filter"
                  />
                  <Filter
                    text="Фильтр для команд"
                    onChange={handleMassAction}
                    type="massActions"
                    isMassActions={!_.isEmpty(chekedElements)}
                    openModal={() => setOpenMassModal(!openMassModal)}
                  />
                </div>
              )}
              {createButtonLabel ? (
                <CreateButton description={createButtonLabel} />
              ) : (
                <p className={style.emptyButton} />
              )}
            </div>
            {entity && (
              <div className={style.separator}>
                <Separator />
              </div>
            )}
            <Modal
              title={titleModal}
              active={modalActive}
              setActive={setModalActive}
            >
              <FilterSearchForm
                entity={entity}
                setModalActive={setModalActive}
              />
            </Modal>

            <Modal
              title="Фильтр для команд"
              active={openMassModal}
              setActive={setOpenMassModal}
            >
              <MassActionsForm
                entity={entity}
                setModalActive={setOpenMassModal}
              />
            </Modal>
          </>
        )}
      </>
    );
  }
);
