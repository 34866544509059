import { ICreateBaseSection } from '../../../../../app/api/services/sections/baseSectionsTypes';

export enum SectionFields {
  Name = 'name',
  Is_active = 'is_active',
  Weight = 'weight',
  Published_at = 'published_at',
  Primary_image = 'primary_image_url',
  Secondary_image = 'secondary_image_url',
  Primary_banner = 'primary_banner_url',
  Secondary_banner = 'secondary_banner_url',
  Primary_image_id = 'primary_image_id',
  Secondary_image_id = 'secondary_image_id',
  Primary_banner_id = 'primary_banner_id',
  Secondary_banner_id = 'secondary_banner_id',
  // Feed_bottom = 'feed_bottom',
  Elements = 'elements',
  // UUID = 'uuid',
  // Created_at = 'created_at',
  // Updated_at = 'updated_at',
  Images = 'images',
}

// export interface IValueFields {
//   name: string;
//   is_active: boolean;
//   weight: number;
//   published_at: string;
//   primary_image_url: string;
//   secondary_image_url: string;
//   primary_banner_url: string;
//   secondary_banner_url: string;
//   images: any;
//   elements: Frame[];
//   // feed_bottom: number;
//   // uuid: string;
//   // created_at: string;
//   // updated_at: string;
// }

export interface IValidateSection {
  name: boolean;
  elements: boolean;
  feedBootomIsFeedGroup: boolean;
}

// eslint-disable-next-line complexity, max-statements, @typescript-eslint/explicit-module-boundary-types
export const validateCreateSection = (values: ICreateBaseSection): object => {
  const errors = {} as IValidateSection;

  if (!values.name) {
    errors.name = true;
  }

  if (values.elements?.length === 0) {
    errors.elements = true;
  }

  if (values.elements?.at(-1).entity_type === 'feed_group') {
    errors.feedBootomIsFeedGroup = true;
  }

  return errors;
};
