import { lazy, Suspense } from 'react';

import { Loader } from '../../../widgets';

const RibbonFilters = lazy(() => import('./RibbonFilters'));

export const RibbonFilters_Lazy = (): JSX.Element => (
  <>
    <Suspense fallback={<Loader />}>
      <RibbonFilters />
    </Suspense>
  </>
);
