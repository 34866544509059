/* eslint-disable complexity */
import { IBody } from '../../../../../app/api/services/playlists/types';

const convertData = (
  diffKeys: Array<string>,
  oldValues: Record<string, unknown>,
  newValues: Record<string, unknown>,
  key: string
): unknown => {
  if (key === 'price') {
    return [{ amount: newValues[key], currency_id: 1 }];
  }
  if (key === 'date_start_license' || key === 'date_end_license') {
    const dateStart = diffKeys.find((elm) => elm === 'date_start_license');
    const dateEnd = diffKeys.find((elm) => elm === 'date_end_license');

    return {
      start_date: dateStart
        ? newValues.date_start_license
        : oldValues.date_start_license,
      end_date: dateEnd
        ? newValues.date_end_license
        : oldValues.date_end_license,
    };
  }

  if (key === 'rate_id') {
    const rate_id = diffKeys.find((elm) => elm === 'rate_id');
    const entity_id = diffKeys.find((elm) => elm === 'entity_id');
    const entity_type = diffKeys.find((elm) => elm === 'entity_type');

    return {
      rate_id: rate_id ? newValues.rate_id : oldValues.rate_id,
      entity_id: entity_id ? newValues.entity_id : oldValues.entity_id,
      entity_type: entity_type ? newValues.entity_type : oldValues.entity_type,
    };
  }

  return newValues[key];
};

const convertKey = (key: string): string => {
  if (key === 'price') {
    return 'prices';
  }

  if (key === 'date_start_license' || key === 'date_end_license') {
    return 'licence_period';
  }

  if (key === 'rate_id') {
    return 'priority_product';
  }

  return key;
};

export const createObjectForUpdatePlaylist = (
  diffKeys: Array<string>,
  oldValues: Omit<IBody, 'ISBN'>,
  newValues: Omit<IBody, 'ISBN'>
): Omit<IBody, 'ISBN' | 'product_name'> => {
  const diffObject = {} as Omit<IBody, 'ISBN' | 'product_name'>;
  diffKeys.forEach((v) =>
    Object.keys(newValues).some((c) =>
      v === c
        ? (diffObject[convertKey(v)] = convertData(
            diffKeys,
            oldValues,
            newValues,
            c
          ))
        : null
    )
  );

  return diffObject;
};
