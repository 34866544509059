import { useAppDispatch, useExpand } from '../../../hooks';
import { Collapse, Expand } from '../../../shared/icons';
import { setExpand } from '../../../store/slices/expandSlice';

import style from './ExpandButton.module.scss';

export const ExpandButton = (): JSX.Element => {
  const dispatch = useAppDispatch();

  const expand = useExpand();

  const clickHandle = () => {
    dispatch(setExpand());
  };

  return (
    <button
      className={style.button}
      onClick={clickHandle}
      data-testid="buttonExpand"
    >
      {expand ? <Collapse /> : <Expand />}
    </button>
  );
};
