import cn from 'classnames';
import ru from 'date-fns/locale/ru';
import { useFormikContext } from 'formik';
import { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import style from './InputDate.module.scss';

interface TProps {
  value?: string | Date;
  field: string;
  className?: string;
  dateFormat?: string;
  placeholder?: string;
  showTimeSelect?: boolean;
  isError?: boolean;
  disabled?: boolean;
  handleChangeDate?: (value: string | number | Date) => void;
}

export const InputDate = ({
  field,
  className,
  value = null,
  dateFormat = 'yyyy.MM.dd',
  placeholder = '2020.10.25',
  showTimeSelect = false,
  isError = false,
  disabled = false,
  handleChangeDate,
}: TProps): JSX.Element => {
  const { setFieldValue } = useFormikContext();
  const [startDate, setStartDate] = useState<Date>(
    value ? new Date(value) : null
  );

  useEffect(() => {
    setStartDate(value ? new Date(value) : null);
  }, [value]);

  const handleChange = (date) => {
    setStartDate(date);

    setFieldValue(field, new Date(date).toISOString());
    if (!date) {
      setFieldValue(field, null);
    }

    handleChangeDate && handleChangeDate(new Date(date).toISOString());
  };

  return (
    <div className={cn(style.dateBlock, className)} data-testid="wrapper">
      <DatePicker
        placeholderText={disabled ? '' : placeholder}
        selected={startDate}
        onChange={handleChange}
        className={cn(style.input, { [style.errorInput]: isError })}
        dateFormat={dateFormat}
        locale={ru}
        showTimeSelect={showTimeSelect}
        disabled={disabled}
      />
    </div>
  );
};
