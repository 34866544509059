import cn from 'classnames';
import { useEffect } from 'react';

import { useGetFeedGroupByNameQuery } from '../../../../../../../../app/api/services/feedGroups/feedGroups';
import { IResponseGet as IResponseGetFeedGroup } from '../../../../../../../../app/api/services/feedGroups/types';
import { useGetFeedsByNameQuery } from '../../../../../../../../app/api/services/feeds/feeds';
import { IResponseGet as IResponseGetFeed } from '../../../../../../../../app/api/services/feeds/types';
import { useAppDispatch, useAppSelector } from '../../../../../../../../hooks';
import { useDebounce } from '../../../../../../../../hooks/useDebounce';
import {
  ISelectState,
  setLoading,
} from '../../../../../../../../store/slices/selectSlice';
import { ColumnTitle, ContainerWrapper } from '../../../../../../../../widgets';
import { AddRubricFrame } from '../../AddRubricFrame';

import style from './Content.module.scss';

interface IProps {
  feeds: IResponseGetFeed;
  feedsGroup: IResponseGetFeedGroup;
}

export const Content = ({ feedsGroup, feeds }: IProps): JSX.Element => {
  const dispatch = useAppDispatch();

  const selectedValue = useAppSelector(
    (state) => (state.selectSliceReducer as ISelectState).inputValueInSelect
  );
  const debounceValueFeedsSelect = useDebounce(selectedValue?.feeds, 500);

  const { data: feedsByName, isLoading: isLoadingFeedsByName } =
    useGetFeedsByNameQuery(
      { name: debounceValueFeedsSelect },
      {
        skip: !debounceValueFeedsSelect?.length,
        refetchOnMountOrArgChange: true,
      }
    );

  const debounceValueFeedGroupsSelect = useDebounce(
    selectedValue?.feed_groups,
    500
  );

  const { data: feedsGroupByName, isLoading: isLoadingFeedGroupsByName } =
    useGetFeedGroupByNameQuery(debounceValueFeedGroupsSelect, {
      skip: !debounceValueFeedGroupsSelect?.length,
      refetchOnMountOrArgChange: true,
    });

  useEffect(() => {
    dispatch(setLoading(isLoadingFeedsByName || isLoadingFeedGroupsByName));
  }, [dispatch, isLoadingFeedsByName, isLoadingFeedGroupsByName]);

  return (
    <ContainerWrapper title="Контент" className={style.contentWrapper}>
      <div className={style.title}>
        <p className={style.title_entity}>
          <ColumnTitle text="Сущность" />
        </p>
        <p className={style.title_name}>
          <ColumnTitle text="Название" />
        </p>
        <p className={style.title_composition}>
          <ColumnTitle text="Состав" />
        </p>
        <p className={style.title_type}>
          <ColumnTitle text="Тип элемента" />
        </p>
        <p className={style.title_kind}>
          <ColumnTitle text="Вид ленты" />
        </p>
        <p className={style.title_uuid}>
          <ColumnTitle text="UUID" />
        </p>
      </div>

      <div className={cn(style.feedWrapper, style.input)}>
        <AddRubricFrame
          feeds={selectedValue?.feeds ? feedsByName?.response : feeds?.response}
          feedsGroup={
            selectedValue?.feed_groups
              ? feedsGroupByName?.response
              : feedsGroup?.response
          }
        />
      </div>
    </ContainerWrapper>
  );
};
