import { Suspense, lazy } from 'react';

import { Loader } from '../../../../../../widgets';

const CreateClip = lazy(() => import('./CreateClip'));

export const CreateClip_Lazy = (): JSX.Element => (
  <>
    <Suspense fallback={<Loader />}>
      <CreateClip />
    </Suspense>
  </>
);
