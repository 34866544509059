import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface IUserState {
  email: string | null;
  accessToken: string | null;
  first_name: string | null;
  last_name: string | null;
  role: string | null;
  isLoading: boolean;
}

const initialState: IUserState = {
  email: null,
  accessToken: null,
  first_name: null,
  last_name: null,
  role: null,
  isLoading: false,
};

export interface IProfile {
  first_name: string;
  last_name: string;
  role: string;
  isLoading: boolean;
}

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser(state, action: PayloadAction<string>) {
      state.email = action.payload;
      localStorage.setItem('user', action.payload);
    },
    setProfile(state, action: PayloadAction<IProfile>) {
      state.first_name = action.payload.first_name || null;
      state.last_name = action.payload.last_name || null;
      state.role = action.payload.role || null;
      state.isLoading = action.payload.isLoading;
    },
  },
});

export const { setUser, setProfile } = userSlice.actions;

export const userReducer = userSlice.reducer;
