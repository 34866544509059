import { lazy, Suspense } from 'react';

import { Loader } from '../../../widgets';

const Purchases = lazy(() => import('./Purchases'));

export const Purchases_Lazy = (): JSX.Element => (
  <>
    <Suspense fallback={<Loader />}>
      <Purchases />
    </Suspense>
  </>
);
