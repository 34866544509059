import { Suspense, lazy } from 'react';

import { Loader } from '../../../widgets';

const Faq = lazy(() => import('./Faq'));

export const Faq_Lazy = (): JSX.Element => (
  <>
    <Suspense fallback={<Loader />}>
      <Faq />
    </Suspense>
  </>
);
