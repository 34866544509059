import cn from 'classnames';
import React from 'react';

import style from './Header.module.scss';

interface IProps {
  label: string;
  className?: string;
}

export const Header = React.memo(
  ({ label, className }: IProps): JSX.Element => (
    <h2 className={cn(style.heading, className)}>{label}</h2>
  )
);
