import { Suspense, lazy } from 'react';

import { Loader } from '../../../../../../widgets';

const EditTheme = lazy(() => import('./EditTheme'));

export const EditTheme_Lazy = (): JSX.Element => (
  <>
    <Suspense fallback={<Loader />}>
      <EditTheme />
    </Suspense>
  </>
);
