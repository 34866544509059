import { useFormikContext } from 'formik';
import React, { useEffect } from 'react';

import { useAppDispatch } from '../../../../../hooks';
import { changeValueCommands } from '../../../../../store/slices/massActionsSlice';
import { Switch } from '../../../../../widgets';
import { IPropsFilterAndColumn } from '../../../types';
import { AttributeItemWrapper } from '../../AttributeItemWrapper';

import style from './SwitchInFilter.module.scss';

export const SwitchInFilter = ({
  massAction,
  columnType,
}: IPropsFilterAndColumn): JSX.Element => {
  const { values } = useFormikContext();
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(
      changeValueCommands({
        columnType,
        massAction,
        value: values?.[columnType]?.[massAction],
      })
    );
  }, [values?.[columnType]?.[massAction]]);

  return (
    <AttributeItemWrapper
      mode="withSwitch"
      massAction={massAction}
      columnType={columnType}
    >
      <Switch
        label="Активность"
        field={`${columnType}.${massAction}`}
        value={values?.[columnType]?.[massAction]}
        className={style.switch}
      />
    </AttributeItemWrapper>
  );
};
