import {
  DndContext,
  DragEndEvent,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors,
  closestCorners,
  UniqueIdentifier,
  DragStartEvent,
  PointerSensor,
} from '@dnd-kit/core';
import {
  SortableContext,
  arrayMove,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';

import { SectionListHeader } from './SectionListHeader';
import SortableItem from './SortableItem';
import { useUpdateSectionPositionMutation } from '../../../../../../app/api/services/sections/sections';
import { ISectionByCondition } from '../../../../../../app/api/services/sections/types';

import style from './SectionList.module.scss';

interface ISectionByConditionWithId extends ISectionByCondition {
  id: number;
}

export const SectionList = ({
  sections,
  deleteInfoSection,
  deleteAutoSection,
  deleteBaseSection,
  setIsError,
}: {
  sections: ISectionByCondition[];
  deleteInfoSection: (id: number) => void;
  deleteAutoSection: (id: number) => void;
  deleteBaseSection: (id: number) => void;
  setIsError: Dispatch<SetStateAction<boolean>>;
}): JSX.Element => {
  const [topics, setTopics] = useState<ISectionByConditionWithId[] | []>([]);
  const [activeId, setActiveId] = useState<UniqueIdentifier>(null);

  const sensors = useSensors(
    useSensor(MouseSensor),
    useSensor(TouchSensor),
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 8,
      },
    })
  );

  const [updateSectionPosition] = useUpdateSectionPositionMutation();

  const handleDragStart = (event: DragStartEvent) => {
    setActiveId(event.active.id);
  };

  const handleDragEnd = async (event: DragEndEvent) => {
    const { active, over } = event;

    if (active.id !== over?.id) {
      const oldIndex = topics.findIndex((section) => section.id === active?.id);
      const newIndex = topics.findIndex((section) => section.id === over?.id);
      const newTopics = arrayMove(topics, oldIndex, newIndex);
      const activeSection = topics.find((section) => section.id === active.id);

      setTopics(newTopics);

      try {
        await updateSectionPosition({
          new_position: newIndex + 1, // тк индекс с 0
          section_id: activeSection.section.id,
          section_type: activeSection.section_type,
        }).unwrap();
      } catch (error) {
        setIsError(true);
      }
    }
    setActiveId(null);
  };

  useEffect(() => {
    setTopics(sections.map((el) => ({ ...el, id: el.position })));
  }, [sections]);

  return (
    <div>
      <div className={style.list}>
        <SectionListHeader />
        <DndContext
          sensors={sensors}
          collisionDetection={closestCorners}
          onDragEnd={handleDragEnd}
          onDragStart={handleDragStart}
        >
          <SortableContext
            items={topics}
            strategy={verticalListSortingStrategy}
          >
            {topics?.map((section) => (
              <SortableItem
                id={section.id}
                key={section.id}
                section={section}
                deleteInfoSection={deleteInfoSection}
                deleteAutoSection={deleteAutoSection}
                deleteBaseSection={deleteBaseSection}
                isActive={activeId === +section.id}
              />
            ))}
          </SortableContext>
        </DndContext>
      </div>
    </div>
  );
};
