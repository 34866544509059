export const Collapse = (): JSX.Element => (
  <svg
    data-testid="collapse-icon"
    xmlns="http://www.w3.org/2000/svg"
    width="21"
    height="21"
    fill="none"
    viewBox="0 0 21 21"
  >
    <rect
      width="20"
      height="20"
      x="0.5"
      y="0.5"
      stroke="#6860F1"
      rx="2.5"
    ></rect>
    <path
      fill="#6860F1"
      d="M9.617 12.107a.5.5 0 00-.5-.5h-4.5a.5.5 0 000 1h4v4a.5.5 0 101 0v-4.5zm-5.233 5.44l2.543-2.543-.707-.707-2.543 2.543.707.707zm2.543-2.543L9.47 12.46l-.707-.707-2.543 2.543.707.707zM10.822 9.442a.5.5 0 00.525.473l4.494-.235a.5.5 0 00-.052-.999l-3.995.21-.21-3.995a.5.5 0 10-.998.052l.236 4.494zm4.94-5.706L10.95 9.08l.744.67 4.813-5.346-.743-.67z"
    ></path>
  </svg>
);
