import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import React, { HTMLAttributes } from 'react';

import { SectionListItem } from './SectionListItem';
import { ISectionByCondition } from '../../../../../../app/api/services/sections/types';

type ItemProps = HTMLAttributes<HTMLDivElement> & {
  deleteInfoSection: (id: number) => void;
  deleteAutoSection: (id: number) => void;
  deleteBaseSection: (id: number) => void;
  section: ISectionByCondition;
  isActive: boolean;
};

const SortableItem = (props: ItemProps): JSX.Element => {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: props.id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <SectionListItem
      deleteAutoSection={props.deleteAutoSection}
      deleteInfoSection={props.deleteInfoSection}
      deleteBaseSection={props.deleteBaseSection}
      ref={setNodeRef}
      style={style}
      id={props.id}
      isActive={props.isActive}
      section={props.section}
      {...props}
      {...attributes}
      {...listeners}
    />
  );
};

export default SortableItem;
