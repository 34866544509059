import { useFormikContext } from 'formik';
import React, { useState } from 'react';

import { useAppDispatch } from '../../../../../hooks';
import { Close } from '../../../../../shared/icons';
import { changeValueCommands } from '../../../../../store/slices/massActionsSlice';
import { fieldText } from '../../../helpers';
import { IPropsFilterAndColumn } from '../../../types';
import { AttributeItemWrapper } from '../../AttributeItemWrapper';

import style from './InputText.module.scss';

export const InputText = ({
  massAction,
  columnType,
}: IPropsFilterAndColumn): JSX.Element => {
  const dispatch = useAppDispatch();
  const { values, setFieldValue } = useFormikContext();
  const [value, setValue] = useState<string>(
    values?.[columnType]?.[massAction] || ''
  );

  const handleChange = (e) => {
    setValue(e.target.value);
    setFieldValue(`${columnType}.${massAction}`, e.target.value);
    dispatch(
      changeValueCommands({ columnType, massAction, value: e.target.value })
    );
  };

  return (
    <AttributeItemWrapper
      title={fieldText[massAction]}
      massAction={massAction}
      columnType={columnType}
    >
      <label className={style.inputBlock}>
        <input
          placeholder="Поиск"
          className={style.input}
          value={value}
          onChange={(e) => {
            handleChange(e);
          }}
        />
        {values?.[columnType]?.[massAction] && (
          <button
            className={style.clearInput}
            type="button"
            onClick={() => {
              setValue('');
              setFieldValue(`${columnType}.${massAction}`, null);
              dispatch(
                changeValueCommands({ columnType, massAction, value: null })
              );
            }}
          >
            <Close />
          </button>
        )}
      </label>
    </AttributeItemWrapper>
  );
};
