import React, { useContext, useEffect } from 'react';

import { IEntityMerging } from '../../../app/global/types';
import { SearchEntity } from '../../../store/slices/selectSlice';
import { ClickShowContainer } from '../../ClickShowContainer';
import { FrameContext } from '../../MenuOption/ui/MenuOptions';
import { SelectWithOptions } from '../../SelectWithOptions';

import style from './AddSingleChangeableEntity.module.scss';

export interface IProps {
  searchEntity: SearchEntity;
  options: IEntityMerging[] | { id: number; name: string }[];
  handleTypeSelect: (option) => void;
  currentOption?: IEntityMerging;
}

export const ChangeEntity = ({
  searchEntity,
  options,
  handleTypeSelect,
  currentOption,
}: IProps): JSX.Element => {
  const { contextOpened, setContextOpened } = useContext(FrameContext);

  useEffect(() => {
    setContextOpened(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentOption]);

  return (
    <div className={style.container_select}>
      {contextOpened && (
        <ClickShowContainer
          dropdown={contextOpened}
          setDropdown={setContextOpened}
        >
          <SelectWithOptions
            searchEntity={searchEntity}
            options={options}
            onChange={handleTypeSelect}
          />
        </ClickShowContainer>
      )}
    </div>
  );
};
