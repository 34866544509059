import axios, {
  AxiosRequestHeaders,
  CreateAxiosDefaults,
  HeadersDefaults,
  RawAxiosRequestHeaders,
} from 'axios';
import { Buffer } from 'buffer';
import Cookies from 'universal-cookie';

import { API_PATH } from '../../../global/Api';
import {
  clearCookies,
  getAccessTokenCookie,
  getRefreshTokenCookie,
  setCookies,
} from '../../../utils/cookies';

interface CommonHeaderProperties extends AxiosRequestHeaders {
  Authorization: string;
}

const baseHeaders: RawAxiosRequestHeaders | Partial<HeadersDefaults> = {
  'Content-Type': 'application/json',
};

const configMainApi: CreateAxiosDefaults = {
  baseURL: API_PATH,
  headers: baseHeaders,
};
const instanceRefresh = axios.create(configMainApi);

export const instanceMainApi = axios.create(configMainApi);

instanceMainApi.interceptors.request.use(
  async (request) => {
    const cookies = new Cookies();
    try {
      const accessToken = getAccessTokenCookie(cookies);
      const refreshToken = getRefreshTokenCookie(cookies);

      request.headers = {
        ...baseHeaders,
        authorization: `Bearer ${accessToken}`,
      } as unknown as CommonHeaderProperties;

      const tokenPayload =
        accessToken &&
        JSON.parse(Buffer.from(accessToken.split('.')[1], 'base64').toString());

      const isExpiredToken =
        tokenPayload &&
        tokenPayload.exp < Math.floor((new Date().getTime() + 1) / 1000);

      if (isExpiredToken) {
        const response = await instanceRefresh.request({
          url: '/api/main/auth/refresh',
          method: 'POST',
          headers: {
            ...baseHeaders,
            authorization: `Bearer ${accessToken}`,
          },
          data: { refresh_token: String(refreshToken) },
        });

        if (response?.data) {
          const { response: refreshResponse } = response.data as {
            response: { access_token: string; refresh_token: string };
          };

          const { access_token, refresh_token } = refreshResponse;

          request.headers = {
            ...baseHeaders,
            authorization: `Bearer ${access_token}`,
          } as unknown as CommonHeaderProperties;

          setCookies(cookies, access_token, refresh_token, true);

          return request;
        }
      }

      return request;
    } catch (e) {
      clearCookies(cookies);
      window.location.href = '/login';
    }
  },
  (error) => Promise.reject(error)
);
