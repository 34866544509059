/* eslint-disable max-lines-per-function */
import { endpoints } from './../../../../shared/api/endpoints/endpoints';
import {
  ISupermixResponse,
  IGetSupermixesByCondition,
  IParams,
  IParamsSupermixPlaylists,
  ISupermixPlaylistsResponse,
  ISupermixEdit,
  IDeleteSupermixPlaylist,
  ISupermixPayload,
  IGetSupermixesByPlaylist,
} from './types';
import { tags } from '../../../../shared/api/tags/tags';
import { mainApi } from '../config';

export const SupermixApiQuery = mainApi.injectEndpoints({
  endpoints: (builder) => ({
    getSupermixByCondition: builder.query<IGetSupermixesByCondition, IParams>({
      query: ({ limit, offset }) => ({
        url: endpoints.supermixes,
        params: {
          limit,
          offset,
        },
      }),
      serializeQueryArgs: ({ endpointName }) => endpointName,

      merge(currentCacheData, responseData) {
        const newResponses = responseData.response.filter(
          (newResponse) =>
            !currentCacheData.response.some(
              (currentResponse) => currentResponse.id === newResponse.id
            )
        );
        currentCacheData.response.push(...newResponses);
      },

      forceRefetch({ currentArg, previousArg }) {
        return currentArg !== previousArg;
      },

      providesTags: (result) => {
        if (result) {
          const { response } = result;

          return [
            ...response.map(({ id }) => ({ type: tags.supermix, id } as const)),
            { type: tags.supermix, id: 'SUPERMIX_LIST' },
          ];
        } else {
          return [{ type: tags.supermix, id: 'SUPERMIX_LIST' }];
        }
      },
    }),

    getSupermixByConditionSearch: builder.query<
      IGetSupermixesByCondition,
      IParams
    >({
      query: ({ limit, offset, name, enrichments }) => ({
        url: endpoints.supermixes,
        params: {
          limit,
          offset,
          name,
          enrichments,
        },
      }),
    }),

    getSupermixPlaylistsByCondition: builder.query<
      ISupermixPlaylistsResponse,
      IParamsSupermixPlaylists
    >({
      query: (params) => ({
        url: endpoints.supermixPlaylists,
        params,
      }),
    }),

    getSupermixesByPlaylist: builder.query<IGetSupermixesByPlaylist, number>({
      query: (playlist_id) => ({
        url: endpoints.supermixesByPlaylistId,
        method: 'GET',
        params: { playlist_id },
      }),
    }),

    getSupermix: builder.query<ISupermixResponse, number>({
      query: (id) => ({
        url: endpoints.supermix,
        method: 'GET',
        params: { id },
      }),
    }),
  }),
});

export const SupermixApiMutation = mainApi.injectEndpoints({
  endpoints: (builder) => ({
    createSupermix: builder.mutation<ISupermixResponse, ISupermixPayload>({
      query: (body) => ({
        url: endpoints.supermix,
        method: 'POST',
        data: body,
      }),
      invalidatesTags: [tags.supermix],
    }),

    deleteSupermix: builder.mutation<unknown, number>({
      query: (id) => ({
        url: endpoints.supermix,
        method: 'DELETE',
        params: { id },
      }),
      invalidatesTags: [tags.supermix],
    }),

    editSupermix: builder.mutation<unknown, ISupermixEdit>({
      query: ({ id, payload }) => ({
        url: endpoints.supermix,
        method: 'PUT',
        data: { payload },
        params: { id },
      }),
      invalidatesTags: [tags.supermix],
    }),

    deleteSupermixPlaylists: builder.mutation<unknown, IDeleteSupermixPlaylist>(
      {
        query: ({ supermix_id, payload }) => ({
          url: endpoints.supermixPlaylists,
          method: 'DELETE',
          data: payload,
          params: { supermix_id },
        }),
        invalidatesTags: [tags.supermix],
      }
    ),

    addSupermixPlaylists: builder.mutation<unknown, IDeleteSupermixPlaylist>({
      query: ({ supermix_id, payload }) => ({
        url: endpoints.supermixPlaylists,
        method: 'POST',
        data: payload,
        params: { supermix_id },
      }),
      invalidatesTags: [tags.supermix],
    }),
  }),
});

export const {
  useGetSupermixByConditionQuery,
  useGetSupermixPlaylistsByConditionQuery,
  useGetSupermixQuery,
  useLazyGetSupermixQuery,
  useGetSupermixesByPlaylistQuery,
  useGetSupermixByConditionSearchQuery,
} = SupermixApiQuery;

export const {
  useCreateSupermixMutation,
  useDeleteSupermixMutation,
  useEditSupermixMutation,
  useDeleteSupermixPlaylistsMutation,
  useAddSupermixPlaylistsMutation,
} = SupermixApiMutation;
