import { useFormikContext } from 'formik';

import {
  useGetPlaylistByConditionQuery,
  useGetPlaylistByNameQuery,
} from '../../../../../../../../app/api/services/playlists/playlists';
import { useAppSelector, useDebounce } from '../../../../../../../../hooks';
import { ISelectState } from '../../../../../../../../store/slices/selectSlice';
import { ContainerWrapper, Separator } from '../../../../../../../../widgets';
import { IAutoSectionValues } from '../../../helpers';
import { AccessoriesKit } from '../commponents/AccessoriesKit';

import style from './ListOfPlaylist.module.scss';

export const ListOfPlaylist = (): JSX.Element => {
  const { values } = useFormikContext<IAutoSectionValues>();

  const selectedValue = useAppSelector(
    (state) => (state.selectSliceReducer as ISelectState).inputValueInSelect
  );

  const debounceValuePlaylistSelect = useDebounce(
    selectedValue?.playlists,
    500
  );

  const { data: playlist } = useGetPlaylistByConditionQuery({
    limit: 5,
    offset: 0,
  });

  const { data: playlistByName } = useGetPlaylistByNameQuery(
    debounceValuePlaylistSelect,
    {
      skip: !debounceValuePlaylistSelect?.length,
      refetchOnMountOrArgChange: true,
    }
  );

  return (
    <ContainerWrapper title="Список плейлистов">
      <div className={style.topWrapper}>
        <AccessoriesKit
          label="Первый экран"
          searchEntity={'playlists'}
          items={values?.elements?.screen1}
          options={
            selectedValue?.playlists
              ? playlistByName?.response
              : playlist?.response
          }
          screenPosition={1}
          //   isError={!!errors?.elementsCount}
        />
        <Separator />
        <AccessoriesKit
          label="Второй экран"
          searchEntity={'playlists'}
          items={values?.elements?.screen2}
          options={
            selectedValue?.playlists
              ? playlistByName?.response
              : playlist?.response
          }
          screenPosition={2}
          //   isError={!!errors?.elementsCount}
        />
        <Separator />
        <AccessoriesKit
          label="Третий экран"
          searchEntity={'playlists'}
          items={values?.elements?.screen3}
          options={
            selectedValue?.playlists
              ? playlistByName?.response
              : playlist?.response
          }
          screenPosition={3}
          //   isError={!!errors?.elementsCount}
        />
        <Separator />
        <AccessoriesKit
          label="Четвертый экран"
          searchEntity={'playlists'}
          items={values?.elements?.screen4}
          options={
            selectedValue?.playlists
              ? playlistByName?.response
              : playlist?.response
          }
          screenPosition={4}
          //   isError={!!errors?.elementsCount}
        />
      </div>
    </ContainerWrapper>
  );
};
