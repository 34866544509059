import { Field, useFormikContext } from 'formik';
import _ from 'lodash';
import { useEffect, useState } from 'react';

import { IBlock } from '../../../../../../../../app/api/services/sections/types';
import { Delete, Plus } from '../../../../../../../../shared/icons';
import {
  ContainerWrapper,
  Input,
  Select,
  SelectNew,
  SelectSearch,
  Separator,
  TitleWithDescription,
} from '../../../../../../../../widgets';
import { IOption } from '../../../../../../../../widgets/Select/ui/Select';
import {
  IInfoSectionErrors,
  IInfoSectionValues,
  InfoSectionFields,
} from '../../../helpers';

import style from './Description.module.scss';

export const Description = ({
  sectionNames,
  elementNames,
  sectionName,
  buttonNames,
  blocks,
}: {
  sectionNames: IOption[];
  elementNames: IOption[];
  sectionName: string;
  buttonNames: IOption[];
  blocks: IBlock[];
}): JSX.Element => {
  const { setFieldValue } = useFormikContext<IInfoSectionValues>();
  const { errors } = useFormikContext<IInfoSectionErrors>();

  const [currentBlocks, setCurrentBlocks] = useState<any[]>(
    (blocks ? blocks.map((el) => ({ ...el, id: crypto.randomUUID() })) : []) ||
      []
  );
  const [value, setValue] = useState('');

  const onChangeHandle = (value, position) => {
    if (!value) {
      return;
    }

    setCurrentBlocks((prev) => {
      const newBlocks = _.cloneDeep(prev);
      newBlocks.find((el) => el.position === position).supermix_id = value.id;
      newBlocks.find((el) => el.position === position).supermix = value;

      return newBlocks;
    });
  };

  const onChangeButton = (option, position) => {
    if (!option) {
      return;
    }

    setCurrentBlocks((prev) => {
      const newBlocks = _.cloneDeep(prev);

      newBlocks.find((el) => el.position === position).button_text =
        option.name;

      return newBlocks;
    });
  };

  const onDelete = (position: number) => {
    setCurrentBlocks((prev) => {
      const newBlocks = prev?.filter((el) => el?.position !== position);

      return newBlocks.map((el, i) => {
        const newEl = { ...el };
        newEl.position = i + 1;

        return newEl;
      });
    });
  };

  const onAddBlock = () => {
    const newPosition =
      currentBlocks.length > 0
        ? currentBlocks[currentBlocks.length - 1].position + 1
        : 1;
    setCurrentBlocks([
      ...currentBlocks,
      {
        position: newPosition,
        id: crypto.randomUUID(),
      },
    ]);
  };

  useEffect(() => {
    setFieldValue(InfoSectionFields.Blocks, currentBlocks);
  }, [currentBlocks, setFieldValue]);

  return (
    <ContainerWrapper title="Описание">
      <div className={style.topWrapper}>
        <SelectNew
          placeholder="Выберите название"
          title="Название раздела"
          options={sectionNames}
          onChange={(option) => {
            setFieldValue(InfoSectionFields.Name, option?.name);
          }}
          defaultOption={
            sectionName &&
            sectionNames?.find((el) => el.name === sectionName)?.id
          }
          isError={!!errors.name}
          isObligatory={true}
        />
        <Field
          name={InfoSectionFields.Description}
          component={Input}
          isError={!!errors?.description}
          label="Описание раздела"
          isObligatory={true}
        />
      </div>
      <Separator color="grey" />

      <div>
        <div>
          {currentBlocks?.map((block, index) => (
            <div className={style.blockElement} key={block.id}>
              <h4
                className={
                  errors.blocks ? style.blockTitleError : style.blockTitle
                }
              >
                {`Блок ${block.position} *`}
              </h4>
              <div className={style.blockRow}>
                <SelectSearch
                  options={elementNames}
                  isLoading={false}
                  valueInput={value}
                  handleChangeInput={setValue}
                  onChangeHandle={(value) =>
                    onChangeHandle(value, block.position)
                  }
                  title="Выберите название"
                  defaultOption={{
                    id: block.supermix?.id,
                    name: block.supermix?.name,
                  }}
                  isError={!!errors[`blocks[${index}].supermix_id`]}
                  isObligatory={true}
                />
                <Select
                  className={style.selectBlock}
                  placeholder="Выберите текст"
                  title="Текст в кнопке"
                  options={buttonNames}
                  onChange={(option) => onChangeButton(option, block.position)}
                  defaultOption={
                    buttonNames?.find((el) => el.name === block.button_text)?.id
                  }
                  isError={!!errors[`blocks[${index}].button_text`]}
                  isObligatory={true}
                />
                <TitleWithDescription
                  className={style.description}
                  title="Цена:"
                  description={block?.supermix?.product?.rate?.name}
                />
                <TitleWithDescription
                  className={style.description}
                  title="Описание:"
                  description={block?.supermix?.description}
                />
                {index !== 0 && (
                  <button
                    type="button"
                    className={style.delete_button}
                    onClick={() => onDelete(block.position)}
                  >
                    <Delete fill="#434343" />
                  </button>
                )}
              </div>
              <Separator className={style.separator} color="grey" />
            </div>
          ))}
        </div>
      </div>

      <button type="button" className={style.button} onClick={onAddBlock}>
        <span className={style.plus}>
          <Plus />
        </span>
        Добавить блок
      </button>
    </ContainerWrapper>
  );
};
