import { IProfileInfo, IUserInfo } from './types';
import { endpoints } from '../../../../shared/api/endpoints/endpoints';
import { mainAuthApi } from '../config';

export const usersApi = mainAuthApi.injectEndpoints({
  endpoints: (builder) => ({
    getMeStatus: builder.query<IUserInfo, string>({
      query(token) {
        return {
          url: endpoints.user,
          method: 'POST',
          headers: { authorization: token ? `Bearer ${token}` : '' },
        };
      },
    }),
    getProfile: builder.query<IProfileInfo, number>({
      query: (id) => ({ url: `${endpoints.profile}?user_id=${id}` }),
    }),
    loginUser: builder.mutation({
      query: (body: { login: string; password: string }) => ({
        url: endpoints.login,
        method: 'POST',
        data: body,
      }),
    }),
    loginWithGoogle: builder.mutation({
      query: (token) => ({
        url: `${endpoints.googleLogin}/${token}`,
        method: 'GET',
      }),
    }),
    registerUser: builder.mutation({
      query: (body: {
        email: string;
        password: string;
        first_name: string;
        last_name: string;
      }) => ({
        url: endpoints.register,
        method: 'post',
        data: body,
      }),
    }),
  }),
});

export const { useGetMeStatusQuery, useGetProfileQuery } = usersApi;
