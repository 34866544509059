import { Suspense, lazy } from 'react';

import { Loader } from '../../../../../widgets';

const Contract = lazy(() => import('./Contract'));

export const Contract_Lazy = (): JSX.Element => (
  <>
    <Suspense fallback={<Loader />}>
      <Contract />
    </Suspense>
  </>
);
