import {
  InputDateFilter,
  InputNumberOrRange,
  InputText,
  ReleaseDate,
  SelectPresent,
  SelectWithSearch,
  SwitchInFilter,
} from './FilterSearchFormComponents';
import { IFormikValues, TColumn } from './types';
import { IBody } from '../../app/api/services/filters/types';

//TODO добавлять поля по мере готовности фильтров на бэке
export const fieldText = {
  name: 'Название',
  uuid: 'UUID',
  id: 'ID',
  title: 'Название в списках',
  description: 'Опиcание',
  full_description: 'Опиcание',
  ISBN: 'ISBN',
  idbook: 'idbook',
};

export const fieldWithSearch = {
  channel: 'Канал',
  playlist: 'Плейлист',
  owner: 'Правообладатель',
  persons: 'Персона',
  authors: 'Автор',
  readers: 'Чтец',
  translators: 'Переводчик',
  feed: 'Лента (сверху или снизу)',
  feed_bottom: 'Лента (снизу)',
  feed_top: 'Лента (сверху)',
  counterparty: 'Контрагент',
  tags: 'Теги',
  rates: 'Тарифы',
  topic_group: 'Группа тем',
  topic: 'Тема',
  sub_topic: 'Подтема',
};

export const fieldWithoutSearch = {
  type: 'Вид правообладателя',
  level: 'Уровень авторства правообладателя' || 'Категория',
  content_type: 'Вид контента',
  format: 'Формат плейлиста',
  ages: 'Возрастное ограничение',
};

export const fieldSwitch = {
  is_active: 'Активность',
  is_related: 'Связанная лента',
};

export const fieldDate = {
  created_at: 'Дата создания',
  updated_at: 'Дата обновления',
  published_at: 'Дата публикации',
  publication_date: 'Дата публикации',
};

export const releaseDate = {
  release_date: 'Дата релиза',
};

export const fieldQuantitativeIndicator = {
  playlists_count: 'Кол-во плейлистов',
  subscribers_count: 'Кол-во подписчиков',
  clips_count: 'Кол-во роликов',
  channels_count: 'Кол-во каналов',
  feeds_count: 'Кол-во лент',
  weight: 'Вес',
};

export const returnedFilter = (
  property: string,
  columnType: TColumn
): React.ReactElement => {
  if (property in fieldText) {
    return (
      <InputText filter={property} key={property} columnType={columnType} />
    );
  } else if (property in fieldWithSearch) {
    return (
      <SelectWithSearch
        filter={property}
        key={property}
        columnType={columnType}
      />
    );
  } else if (property in fieldWithoutSearch) {
    return (
      <SelectPresent filter={property} key={property} columnType={columnType} />
    );
  } else if (property in fieldSwitch) {
    return (
      <SwitchInFilter
        filter={property}
        key={property}
        columnType={columnType}
      />
    );
  } else if (property in fieldDate) {
    return (
      <InputDateFilter
        filter={property}
        key={property}
        columnType={columnType}
      />
    );
  } else if (property in releaseDate) {
    return (
      <ReleaseDate filter={property} key={property} columnType={columnType} />
    );
  } else if (property in fieldQuantitativeIndicator) {
    return (
      <InputNumberOrRange
        filter={property}
        key={property}
        columnType={columnType}
      />
    );
  }
};

export const disabledBtnToApplyFilter = (
  values: IFormikValues | IBody
): boolean => {
  const allProperties = [
    ...Object.values(values?.['includes'] || {}),
    ...Object.values(values?.['excludes'] || {}),
  ]
    .map((i) =>
      i !== null && i.constructor === Object ? Object.values(i || {}) : i
    )
    .flat();

  return (
    allProperties.includes(null) ||
    allProperties.includes('') ||
    allProperties.includes(Object.values({ gte: null, lte: null }))
  );
};
