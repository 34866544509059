import { createApi, retry } from '@reduxjs/toolkit/dist/query/react';

import { axiosAuthApi } from '../../network/axiosQueryInstance';

export const mainAuthApi = createApi({
  reducerPath: 'authApiReducer',
  baseQuery: retry(axiosAuthApi, { maxRetries: 3 }),
  endpoints: () => ({}),
  tagTypes: [],
});

export const authApiReducer = mainAuthApi.reducer;
