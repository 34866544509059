import { combineReducers } from '@reduxjs/toolkit';

import { authReducer } from './slices/authSlice';
import { catalogSliceReducer } from './slices/catalogSlice/catalogSlice';
import { expandReducer } from './slices/expandSlice';
import { filterReducer } from './slices/filterSlice';
import { massActionsReducer } from './slices/massActionsSlice';
import { pointByReducer } from './slices/pointBySlice';
import { rubricReducer } from './slices/rubricSlice';
import { selectSliceReducer } from './slices/selectSlice';
import { userReducer } from './slices/userSlice';
import { authApiReducer } from '../app/api/auth/config';
import { mainApiReducer } from '../app/api/services/config';

const reducers = {
  userReducer,
  authReducer,
  pointByReducer,
  expandReducer,
  selectSliceReducer,
  rubricReducer,
  mainApiReducer,
  authApiReducer,
  catalogSliceReducer,
  filterReducer,
  massActionsReducer,
};

export const rootReducer = combineReducers<{
  userReducer;
  authReducer;
  pointByReducer;
  expandReducer;
  selectSliceReducer;
  rubricReducer;
  mainApiReducer;
  authApiReducer;
  catalogSliceReducer;
  filterReducer;
  massActionsReducer;
}>(reducers);
