interface IProps {
  color: string;
}

export const LessSign = ({ color }: IProps): JSX.Element => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="6"
    height="11"
    fill="none"
    viewBox="0 0 6 11"
  >
    <path
      fill={color}
      d="M4.906 10.24L.829 5.918a.452.452 0 01-.102-.167.598.598 0 01-.031-.193c0-.069.01-.133.03-.193a.452.452 0 01.103-.167L4.906.863a.557.557 0 01.423-.18c.17 0 .315.064.436.193a.636.636 0 01.181.45c0 .172-.06.322-.181.45L2.208 5.558 5.765 9.34c.113.12.169.267.169.443s-.06.328-.181.457a.564.564 0 01-.424.193.564.564 0 01-.423-.193z"
    ></path>
  </svg>
);
