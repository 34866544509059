import type { BaseQueryFn } from '@reduxjs/toolkit/query';
import type { AxiosError, AxiosInstance, AxiosRequestConfig } from 'axios';

import { instanceMainApi } from './interseptors/JwtAxiosRefresh';
import { instanceAuthApi } from './interseptors/SetToken';

const baseQueryFn =
  (
    instance: AxiosInstance
  ): BaseQueryFn<
    {
      url: string;
      method?: AxiosRequestConfig['method'];
      data?: AxiosRequestConfig['data'];
      params?: AxiosRequestConfig['params'];
    },
    unknown,
    unknown
  > =>
  async ({ url, method = 'get', data, params }) => {
    try {
      const result = await instance({
        url,
        method,
        data,
        params,
      });

      return { data: result.data };
    } catch (axiosError) {
      const err = axiosError as AxiosError;

      return {
        error: {
          status: err.response?.status,
          data: err.response?.data || err.message,
        },
      };
    }
  };

export const axiosMainApi = baseQueryFn(instanceMainApi);
export const axiosAuthApi = baseQueryFn(instanceAuthApi);
