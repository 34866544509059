import React from 'react';
import { Link } from 'react-router-dom';

import style from './StatsLinkList.module.scss';

interface IProps {
  links: { name: string; href: string }[];
}

export const StatsLinkList = ({ links }: IProps): JSX.Element => (
  <nav>
    <ul className={style.navWrapper}>
      {links.map((link, i) => (
        <li key={i}>
          <Link className={style.navItem} to={link.href}>
            {link.name}
          </Link>
        </li>
      ))}
    </ul>
  </nav>
);
