import cn from 'classnames';
import React, { useState } from 'react';
import { Tooltip } from 'react-tooltip';

import { newTagCatalog } from '../../../../app/api/services/tagCatalog/types';
import { newTopicCatalog } from '../../../../app/api/services/topicCatalog/types';
import { CardLowCategoryTag } from '../../../../components';
import { CatalogWarningModal } from '../../../../components/CatalogComponents/CatalogWarningModal';
import { Modal } from '../../../../components/Modal';
import { useAppDispatch } from '../../../../hooks';
import { PlusInTheCircle } from '../../../../shared/icons';
import {
  CatalogType,
  deleteGroup,
  ICatalogHight,
} from '../../../../store/slices/catalogSlice';
import {
  ButtonShowAll,
  HangingInput,
  MenuEditOrOpen,
  Separator,
} from '../../../../widgets';

import style from '../../../../components/CatalogComponents/CardCatalogGroup/ui/CardCatalogGroup.module.scss';

interface IProps {
  item: ICatalogHight | newTopicCatalog | newTagCatalog;
  typeCatalog: CatalogType;
  catalogNames?: string[];
  onEdit?: (id: number, name: string) => void;
  onCreate?: (id: number, name: string) => void;
  onDelete?: (id: number) => void;
}

export const TagDirectoryCardForCompilations = ({
  item,
  typeCatalog,
  catalogNames,
  onEdit,
  onCreate,
  onDelete,
}: IProps): JSX.Element => {
  const [value, setValue] = useState<string>(item.name);
  const [, setSubValue] = useState<string>('');
  const [showInput, setShowInput] = useState(false);
  const [showTitleMenu, setShowTitleMenu] = useState(false);
  const [isEditName, setIsEditName] = useState(!item?.name);
  const [showWarning, setShowWarning] = useState<boolean>(false);
  const [showAll, setShowAll] = useState(false);

  const itemCount = 9;
  const isTagTitleWarning = typeCatalog === 'tag' ? 'Тема' : 'Тема';
  const isTagTextWarning = typeCatalog === 'tag' ? 'этой темы' : 'этой темы';

  const dispatch = useAppDispatch();

  const duplicateCheck = (text: string) => {
    const isDuplicate = catalogNames.includes(text.toLowerCase().trim());
    if (isDuplicate) {
      setShowWarning(true);
      setTimeout(() => {
        setShowWarning(false);
      }, 5000);
    } else {
      onEdit(item.id, text.trim());
      setIsEditName(false);
    }
  };

  const handleAddElement = (name: string) => {
    const isDuplicate = catalogNames.includes(name.toLowerCase().trim());
    if (item.id === null) {
      if (isDuplicate) {
        setShowWarning(true);
        setTimeout(() => {
          setShowWarning(false);
        }, 5000);
      } else {
        onCreate(item.id, name.trim());
        setShowInput(false);
      }
    } else {
      onCreate(item.id, name.trim());
      setShowInput(false);
    }
  };

  const handleEditTitle = () => {
    setIsEditName(true);
    setShowTitleMenu(false);
  };

  const handleDeleteGroup = () => {
    if (typeCatalog === 'theme' || typeCatalog === 'tag') {
      onDelete(item.id);
    } else {
      dispatch(deleteGroup({ type: typeCatalog, id: item.id }));
    }
  };

  const listBlock = () => (
    <ul className={cn(style.listBlock, { [style.listBlockShow]: showAll })}>
      {item?.elements?.map((item, index) =>
        index < itemCount || showAll ? (
          <CardLowCategoryTag
            typeCatalog={typeCatalog}
            key={item.id}
            item={item}
            onEdit={(id, name) => onEdit(id, name)}
            onDelete={(id) => onDelete(id)}
            className={(showAll && style.modalTagName) || style.tagName}
          />
        ) : null
      )}
    </ul>
  );

  return (
    <>
      <li className={style.wrapper}>
        <div className={style.titleBlock}>
          {!isEditName ? (
            <div className={style.titleWrapper}>
              <h4
                className={cn(style.input, style.name)}
                onClick={() => setShowTitleMenu(true)}
              >
                {item?.name}
              </h4>
              {showTitleMenu && (
                <MenuEditOrOpen
                  dropdown={showTitleMenu}
                  setDropdown={setShowTitleMenu}
                  handleEdit={handleEditTitle}
                  handleDelete={handleDeleteGroup}
                  titleEdit="название"
                  titleDelete={typeCatalog === 'tag' ? 'тему' : 'тему'}
                  className={style.contextMenu}
                />
              )}
            </div>
          ) : (
            <input
              value={value}
              autoFocus
              placeholder="Введите название"
              className={style.input}
              onChange={(e) => {
                setValue(e.target.value);
              }}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  e.preventDefault();
                  item.id === null
                    ? handleAddElement(value)
                    : duplicateCheck(value);
                }
              }}
              onBlur={() =>
                item.id === null
                  ? handleAddElement(value)
                  : duplicateCheck(value)
              }
            />
          )}
          <div className={style.addButtonWrapper}>
            <button
              type="button"
              className={style.buttonPlusMiddle}
              onClick={() => setShowInput(true)}
              disabled={!item.name?.length}
              data-tooltip-id="plusBtn"
              data-tooltip-content={
                typeCatalog === 'tag' ? 'Добавить тег' : 'Добавить тему'
              }
            >
              <PlusInTheCircle />
            </button>
            <Tooltip id="plusBtn" place="bottom" />
            {showInput && (
              <HangingInput
                position="right"
                placeholder="Введите название"
                showInput={showInput}
                onChange={(e) => {
                  setSubValue(e.target.value);
                }}
                onKeyDown={(e) => handleAddElement(e.target.value)}
                onBlur={(e) => handleAddElement(e.target.value)}
                className={style.inputWidth}
              />
            )}
          </div>
        </div>
        <Separator color="grey" className={style.separator} />
        <div className={style.listTag}>
          {showAll && (
            <Modal active={showAll} setActive={setShowAll}>
              <div className={style.modalWrapper}>{item.name}</div>
              <Separator color="grey" className={style.separator} />
              <div>{listBlock()}</div>
            </Modal>
          )}
          {!showAll && listBlock()}
          {item?.elements?.length > 9 && (
            <ButtonShowAll
              showAll={showAll}
              setShowAll={setShowAll}
              hideText={true}
              className={style.marginLeft}
            />
          )}
        </div>
      </li>
      <CatalogWarningModal
        active={showWarning}
        titleWarning={isTagTitleWarning}
        textWarning={isTagTextWarning}
      />
    </>
  );
};
