import { lazy, Suspense } from 'react';

import { Loader } from '../../../../../widgets';

const Statistics = lazy(() => import('./Statistics'));

export const Statistics_Lazy = (): JSX.Element => (
  <>
    <Suspense fallback={<Loader />}>
      <Statistics />
    </Suspense>
  </>
);
