export const Error = (): JSX.Element => (
  <svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="icon_alert">
      <path
        id="Vector"
        d="M11 2.0625C6.07191 2.0625 2.0625 6.07191 2.0625 11C2.0625 15.9281 6.07191 19.9375 11 19.9375C15.9281 19.9375 19.9375 15.9281 19.9375 11C19.9375 6.07191 15.9281 2.0625 11 2.0625ZM11 15.8086C10.83 15.8086 10.6639 15.7582 10.5226 15.6638C10.3812 15.5694 10.2711 15.4352 10.206 15.2781C10.141 15.1211 10.124 14.9483 10.1571 14.7816C10.1903 14.6149 10.2721 14.4618 10.3923 14.3416C10.5125 14.2214 10.6656 14.1396 10.8323 14.1064C10.999 14.0732 11.1718 14.0903 11.3289 14.1553C11.4859 14.2203 11.6201 14.3305 11.7145 14.4718C11.809 14.6131 11.8594 14.7793 11.8594 14.9493C11.8594 15.1772 11.7688 15.3958 11.6077 15.5569C11.4465 15.7181 11.2279 15.8086 11 15.8086ZM11.9333 7.16547L11.6866 12.4077C11.6866 12.59 11.6142 12.7649 11.4853 12.8938C11.3563 13.0227 11.1815 13.0952 10.9991 13.0952C10.8168 13.0952 10.6419 13.0227 10.513 12.8938C10.3841 12.7649 10.3116 12.59 10.3116 12.4077L10.065 7.16805V7.1659C10.0596 7.03997 10.0797 6.91425 10.1242 6.7963C10.1687 6.67836 10.2365 6.57064 10.3237 6.47962C10.4109 6.3886 10.5156 6.31617 10.6316 6.26669C10.7475 6.21721 10.8722 6.1917 10.9983 6.1917C11.1243 6.1917 11.2491 6.21721 11.365 6.26669C11.4809 6.31617 11.5856 6.3886 11.6728 6.47962C11.76 6.57064 11.8279 6.67836 11.8724 6.7963C11.9168 6.91425 11.937 7.03997 11.9316 7.1659L11.9333 7.16547Z"
        fill="#E33D27"
      />
    </g>
  </svg>
);
