import cn from 'classnames';
import React from 'react';

import { useAppDispatch } from '../../../../../hooks';
import { Delete } from '../../../../../shared/icons';
import { deleteMassAction } from '../../../../../store/slices/massActionsSlice';
import { IPropsFilterAndColumn } from '../../../types';

import style from './AttributeItemWrapper.module.scss';

interface IProps extends IPropsFilterAndColumn {
  children: React.ReactNode;
  mode?: 'normal' | 'withSwitch';
  title?: string;
  className?: string;
  classNameContent?: string;
}

export const AttributeItemWrapper = ({
  massAction,
  columnType,
  children,
  mode = 'normal',
  title,
  className,
  classNameContent,
}: IProps): JSX.Element => {
  const dispatch = useAppDispatch();

  const handleDelete = () => {
    dispatch(deleteMassAction({ columnType, massAction }));
  };

  return (
    <div className={cn(style.attributeItem, className)}>
      <div
        className={cn(
          style.content,
          {
            [style.contentWithSwitch]: mode === 'withSwitch',
          },
          classNameContent
        )}
      >
        {(mode === 'normal' || title) && (
          <h4
            className={cn(style.title, {
              [style.titleSwitch]: mode === 'withSwitch',
            })}
          >
            {title}
          </h4>
        )}
        {children}
      </div>
      <button
        className={style.deleteAttribute}
        type="button"
        onClick={handleDelete}
      >
        <Delete />
      </button>
    </div>
  );
};
