import { getWordEnding } from '../getWordEnding';

export const getTitle = (
  entity: number | undefined,
  description: string[] | undefined
): string => {
  const length = entity ? entity : 0;
  if (!entity && !description) return '';

  return `${length} ${getWordEnding(length, description)}`;
};
