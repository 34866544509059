import { lazy, Suspense } from 'react';

import { Loader } from '../../../widgets';

const TagDirectory = lazy(() => import('./TagDirectory'));

export const TagDirectory_Lazy = (): JSX.Element => (
  <>
    <Suspense fallback={<Loader />}>
      <TagDirectory />
    </Suspense>
  </>
);
