import { useFormikContext } from 'formik';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import AsyncSelect from 'react-select';

import { useGetFiltredListByReferenceQuery } from '../../../../../app/api/services/filters/filters';
import { IContent } from '../../../../../app/global/types';
import { Close } from '../../../../../shared/icons';
import { fieldWithSearch } from '../../../helpers';
import { IPropsFilterAndColumn } from '../../../types';
import { AttributeItemWrapper } from '../../AttributeItemWrapper';

import style from './SelectWithSearch.module.scss';
//TODO проверить работоспособность на реальном фильтре, заменить options на реальные данные
export const SelectWithSearch = ({
  massAction,
  columnType,
}: IPropsFilterAndColumn): JSX.Element => {
  const { values, setFieldValue } = useFormikContext();

  const [options, setOptions] = useState<IContent[]>([]);
  const [reference] = useState(values?.['references']?.[massAction]);

  const [valueInput, setInputValue] = useState<string>();

  const selectRef = useRef(null);

  const params = valueInput ? { reference, name: valueInput } : { reference };

  const { data, isLoading } = useGetFiltredListByReferenceQuery(
    { ...params },
    { skip: !reference, refetchOnMountOrArgChange: true }
  );

  const handleClear = () => {
    selectRef.current.clearValue();
  };

  const ClearIndicator = () => (
    <button type="button" onClick={handleClear}>
      <Close />
    </button>
  );

  const onChangeHandle = useCallback(
    (value) => {
      if (!value) {
        return;
      }

      setFieldValue(`${columnType}.${massAction}`, value.id);
    },
    [setFieldValue, columnType, massAction]
  );

  useEffect(() => {
    if (!isLoading) {
      setOptions(data?.response?.[reference?.split('=')[1]]);
    }
  }, [data?.response, isLoading, reference, values]);

  useEffect(() => {
    selectRef.current.clearValue();
  }, [onChangeHandle]);

  return (
    <AttributeItemWrapper
      title={fieldWithSearch[massAction]}
      massAction={massAction}
      columnType={columnType}
      classNameContent={style.wrapper}
    >
      <AsyncSelect
        ref={selectRef}
        placeholder="Поиск"
        className={style.selectFilter}
        inputValue={valueInput}
        isClearable
        isSearchable
        options={options}
        getOptionValue={(option) => String(option.id)}
        getOptionLabel={(option) => option.name}
        onChange={(value) => onChangeHandle(value)}
        noOptionsMessage={() => 'Ничего не найдено'}
        loadingMessage={() => 'Поиск'}
        onInputChange={(value) => setInputValue(value)}
        isLoading={isLoading}
        components={{
          IndicatorSeparator: () => null,
          DropdownIndicator: () => (valueInput ? <ClearIndicator /> : null),
          ClearIndicator: () => null,
        }}
      />
    </AttributeItemWrapper>
  );
};
