import cn from 'classnames';
import React, { HTMLAttributes, forwardRef } from 'react';

import { ISectionByCondition } from '../../../../../../app/api/services/sections/types';
import { Router } from '../../../../../../components';
import { DndIcon, DndIconActive } from '../../../../../../shared/icons';
import { dateConverter } from '../../../../../../utils';
import { DeleteButton, EditButton, Img, Text } from '../../../../../../widgets';

import style from './SectionListItem.module.scss';

type ItemProps = HTMLAttributes<HTMLDivElement> & {
  section: ISectionByCondition;
  deleteInfoSection: (id: number) => void;
  deleteAutoSection: (id: number) => void;
  deleteBaseSection: (id: number) => void;
  isActive: boolean;
};

export const SectionListItem = forwardRef<HTMLDivElement, ItemProps>(
  (
    {
      section,
      deleteInfoSection,
      deleteAutoSection,
      deleteBaseSection,
      isActive,
      ...props
    },
    ref
  ): JSX.Element => (
    <div
      ref={ref}
      className={cn({
        [style.listItem]: section.section_type,
        [style.listItem__info]: section.section_type === 'info',
        [style.listItem__auto]: section.section_type === 'auto',
      })}
      {...props}
    >
      {/* днд иконка */}
      {isActive ? (
        <button className={style.dndButtonActive} type="button">
          <DndIconActive />
        </button>
      ) : (
        <button className={style.dndButton} type="button">
          <DndIcon />
        </button>
      )}
      {/* Название */}
      <div>
        <Text className={style.title} text={section.section.name} />
      </div>

      {/* Картинка */}
      <div>
        <Img src="" />
      </div>

      {/* вес */}
      <div>
        <Text text={''} />
      </div>

      {/* кол во лент */}
      <div>
        <Text text={''} />
      </div>

      {/* Дата создания */}
      <div>
        <Text text={dateConverter(section.section.created_at)} />
      </div>

      {/* controls */}
      <div className={style.listItemControls}>
        <EditButton
          id={section?.section?.id}
          circle
          link={`/section/${section.section_type}/${Router.Edit}/${section.section.id}`}
        />
        <DeleteButton
          mode="circle"
          modalMode="modalCircle"
          entity="раздел"
          modalEntity="раздела"
          onClick={() => {
            section?.section_type === 'info' &&
              deleteInfoSection(section?.section?.id);
            section?.section_type === 'base' &&
              deleteBaseSection(section?.section?.id);
            section?.section_type === 'auto' &&
              deleteAutoSection(section?.section?.id);
          }}
        />
      </div>
    </div>
  )
);
