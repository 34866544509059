import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import React, { FC, HTMLAttributes } from 'react';

import { ThemeOrderCard } from '../ThemeOrderComponents/ThemeOrderCard';

type ItemProps = HTMLAttributes<HTMLDivElement> & {
  img: string;
  name: string;
};

const SortableItem: FC<ItemProps> = (props) => {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: props.id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition: transition || undefined,
  };

  return (
    <ThemeOrderCard
      ref={setNodeRef}
      style={style}
      {...props}
      {...attributes}
      {...listeners}
    />
  );
};

export default SortableItem;
