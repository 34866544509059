import { Suspense, lazy } from 'react';

import { Loader } from '../../../../../widgets';

const Keywords = lazy(() => import('./Keywords'));

export const Keywords_Lazy = (): JSX.Element => (
  <>
    <Suspense fallback={<Loader />}>
      <Keywords />
    </Suspense>
  </>
);
