import React from 'react';

import style from './ActivityStatus.module.scss';

interface IIsActive {
  isActive: boolean;
}

export const ActivityStatus = (props: IIsActive): JSX.Element => {
  const { isActive } = props;

  return isActive ? (
    <span className={style.active}></span>
  ) : (
    <span className={style.inactive}></span>
  );
};
