import React, { useEffect, useState } from 'react';

import { SectionList } from './SectionList/SectionList';
import {
  useDeleteAutoSectionMutation,
  useDeleteBaseSectionMutation,
  useDeleteInfoSectionMutation,
  useGetSectionsByConditionQuery,
} from '../../../../../app/api/services/sections/sections';
import { errorToast, getTitle, successToast } from '../../../../../utils';
import {
  CreateButton,
  Header,
  Loader,
  TextCount,
} from '../../../../../widgets';

import style from './Section.module.scss';

export const Section = (): JSX.Element => {
  const [isErrorMove, setIsErrorMove] = useState(false);

  const [title, setTitle] = useState('');
  const [isToast, setIsToast] = useState(false);

  const {
    data: sections,
    isLoading,
    isFetching,
    isError,
  } = useGetSectionsByConditionQuery({
    limit: 10,
    offset: 0,
  });

  const [
    deleteInfoSection,
    {
      isLoading: isLoadingDelete,
      isError: isErrorDelete,
      isSuccess: isSuccessDelete,
    },
  ] = useDeleteInfoSectionMutation();

  const [deleteAutoSection] = useDeleteAutoSectionMutation();

  const [deleteBaseSection] = useDeleteBaseSectionMutation();

  const infoSectionExists = sections?.response?.filter(
    (el) => el.section_type === 'info'
  ).length;

  const twoAutoSectionsExist =
    sections?.response?.filter((el) => el.section_type === 'auto').length === 2;

  useEffect(() => {
    setTitle(getTitle(sections?.totalCount, ['раздел', 'раздела', 'разделов']));
  }, [sections?.totalCount, setTitle]);

  useEffect(() => {
    if (isError) {
      errorToast(
        isError,
        'Не удалось загрузить список разделов. Обновите страницу',
        setIsToast
      );
    }
  }, [isError]);

  useEffect(() => {
    if (isErrorDelete) {
      errorToast(isErrorDelete, 'Не удалось удалить раздел.', setIsToast);
    }
  }, [isErrorDelete]);

  useEffect(() => {
    successToast({
      success: isSuccessDelete,
      message: 'Раздел успешно удален',
      setIsToast,
    });
  }, [isSuccessDelete]);

  useEffect(() => {
    if (isErrorMove) {
      errorToast(
        isErrorMove,
        'Не удалось поменять порядок элементов.',
        setIsToast
      );
    }
  }, [isErrorMove]);

  return (
    <div className={style.container}>
      <div className={style.top}>
        <div className={style.headingWrapper}>
          <Header className={style.headerClip} label="Разделы" />
          <TextCount text={title} />
        </div>
        <div className={style.controls}>
          <CreateButton
            description="Создать информационный раздел"
            link="/section/info/create"
            type="reverse"
            disabled={!!infoSectionExists}
          />
          <CreateButton
            description="Создать автоматический раздел"
            link="/section/auto/create"
            type="reverse"
            disabled={!!twoAutoSectionsExist}
          />

          <CreateButton
            link="/section/base/create"
            description="Создать раздел"
          />
        </div>
      </div>
      {(isLoading || isFetching || isLoadingDelete) && !isToast && <Loader />}
      {sections && (
        <SectionList
          sections={sections?.response}
          deleteInfoSection={(id) => deleteInfoSection(id)}
          deleteAutoSection={(id) => deleteAutoSection(id)}
          deleteBaseSection={(id) => deleteBaseSection(id)}
          setIsError={setIsErrorMove}
        />
      )}
    </div>
  );
};
