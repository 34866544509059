import cn from 'classnames';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import { Router } from '../../../components/Router/routerEnum';
import { Option } from '../../../pages/Navbar/Business/Contract/helpers';
import { ButtonShowAll, TextWithTooltip } from '../../../widgets';

import style from './InlineList.module.scss';

interface IProps {
  title: string;
  linkToEditEntity: string;
  data?: Option[];
  className?: string;
}

export const InlineList = ({
  title,
  data = [],
  linkToEditEntity,
  className,
}: IProps): JSX.Element => {
  const [show, setShow] = useState(false);

  return (
    <div className={cn(style.block, className)}>
      <h3 className={style.headerBlock}>{title}</h3>
      <div>
        <ul className={style.blockList}>
          {(data.length < 12 || show ? data : data.slice(0, 12)).map((item) => (
            <li className={style.blockListItem} key={item.id}>
              <Link to={`/${linkToEditEntity}/${Router.Edit}/${item.id}`}>
                <TextWithTooltip text={item.name} />
              </Link>
            </li>
          ))}
        </ul>
        {data.length > 12 && (
          <ButtonShowAll showAll={show} setShowAll={setShow} />
        )}
      </div>
    </div>
  );
};
