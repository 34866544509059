import { mainApi } from '../../app/api/services/config';
import { endpoints } from '../../shared/api/endpoints/endpoints';

interface IBody {
  ext: string;
  body: { name: string; key: string };
}

export interface IFile {
  name: string;
  url: string;
  uniqueKey: string;
  ext: string;
  id: number;
  uuid: string;
  created_at: Date;
  updated_at: Date;
}

export interface IFileUploader {
  response: IFile;
  message: string;
  statusCode: number;
  totalCount: number;
  timestamp: string;
}

export const uploadFileSlice = mainApi.injectEndpoints({
  endpoints: (builder) => ({
    uploadImage: builder.mutation<IFileUploader, IBody>({
      query: ({ ext, body }) => ({
        url: `${endpoints.imageUpload}?ext=${ext}`,
        method: 'POST',
        data: body,
      }),
    }),
    uploadVideo: builder.mutation<IFileUploader, IBody>({
      query: ({ ext, body }) => ({
        url: `${endpoints.videoUpload}?ext=${ext}`,
        method: 'POST',
        data: body,
      }),
    }),
    uploadDocument: builder.mutation<IFileUploader, IBody>({
      query: ({ ext, body }) => ({
        url: `${endpoints.documentUpload}?ext=${ext}`,
        method: 'POST',
        data: body,
      }),
    }),
    uploadArchive: builder.mutation<IFileUploader, IBody>({
      query: ({ ext, body }) => ({
        url: `${endpoints.archiveUpload}?ext=${ext}`,
        method: 'POST',
        data: body,
      }),
    }),
    uploadAudio: builder.mutation<IFileUploader, IBody>({
      query: ({ ext, body }) => ({
        url: `${endpoints.audioUpload}?ext=${ext}`,
        method: 'POST',
        data: body,
      }),
    }),
  }),
});

export const {
  useUploadImageMutation,
  useUploadVideoMutation,
  useUploadDocumentMutation,
  useUploadArchiveMutation,
  useUploadAudioMutation,
} = uploadFileSlice;
