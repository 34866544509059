import cn from 'classnames';
import { useFormikContext } from 'formik';
import React from 'react';

import { Feed } from '../../../../../app/api/services/feeds/types';
import { Edit } from '../../../../../shared/icons';
import { Img, TextWithTooltip } from '../../../../../widgets';

import style from './FeedListBlock.module.scss';
interface IProps {
  feed: Feed;
}

export const FeedListBlock = ({ feed }: IProps): JSX.Element => {
  // TODO временная типизация, пока не приходит бэк
  const { values } = useFormikContext<{
    isFix: boolean;
    toFix: number;
  }>();
  const feedList = feed?.collection?.elements || [];

  return (
    <div
      className={cn(style.blockFeed, {
        [style.blockHumanity]: feed?.collection?.collection_type?.id < 5,
      })}
    >
      {feedList.map((item, index) => (
        <div
          className={cn(style.blockItemFeed, {
            [style.blockItemHumanity]: item?.collection_type?.id < 5,
          })}
          key={item.id}
        >
          <div className={style.imageBlock}>
            <Img
              src={item.primary_image || item.primary_image_url}
              className={
                item?.collection_type?.id < 5 ? style.imagePeople : style.image
              }
            />
            {values.isFix && index <= values.toFix - 1 && (
              <button className={style.button_item}>
                <Edit />
              </button>
            )}
          </div>
          <TextWithTooltip
            className={
              item?.collection_type?.id < 5
                ? style.titleItemFeedPeople
                : style.titleItemFeed
            }
            text={item.name}
          />
          {values.isFix && (
            <div>
              <p
                className={cn(
                  index < values.toFix && style.blockItemMarked,
                  index === 0 && style.blockItemStart
                )}
              />
              <p
                className={cn(
                  index === values.toFix - 1 && style.blockItemFinish
                )}
              />
            </div>
          )}
        </div>
      ))}
    </div>
  );
};
