import { Suspense, lazy } from 'react';

import { Loader } from '../../../widgets';

const PassRecoveryPage = lazy(() => import('./PassRecoveryPage'));

export const PassRecoveryPage_Lazy = (): JSX.Element => (
  <>
    <Suspense fallback={<Loader />}>
      <PassRecoveryPage />
    </Suspense>
  </>
);
