export const typeOptions = {
  authors: 'Авторы',
  translators: 'Переводчики',
  persons: 'Персоны',
  readers: 'Чтецы',
  playlists: 'Плейлисты',
  channels: 'Каналы',
  restrictions: 'Ограничения',
  clips: 'Ролики',
  multiple: 'Несколько',
  collection_clips: 'Подборка роликов',
  collection_playlists: 'Подборка плейлистов',
  owners: 'Правообладатель',
  noneContract: 'Нет договора',
  discussedContract: 'Обсуждается',
  stageSingingContract: 'На стадии подписания',
  signedContract: 'Подписан',
  terminatedContract: 'Расторгнут',
  rubrics: 'Рубрики',
  tags: 'Тег',
  book_series: 'Книжная серия',
  supermixes: 'Супермикс',
  compilations: 'Подборки',
};

export type TOption = {
  id: number;
  name: string;
}[];
