import { useCallback, useEffect, useRef } from 'react';
import AsyncSelect from 'react-select';

import { IEntityMerging } from '../../../app/global/types';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { Close } from '../../../shared/icons';
import {
  ISelectState,
  SearchEntity,
  setSelectedValue,
} from '../../../store/slices/selectSlice';
import './SelectWithOptions.scss';
import { TOption } from 'shared/types/typesOptionsSelect';

interface IProps {
  searchEntity: SearchEntity;
  options: Array<IEntityMerging> | TOption;
  value?: number | null;
  onChange?: (options) => void;
  withUUID?: boolean;
  menuIsOpen?: boolean;
}

export const SelectWithOptions = ({
  searchEntity,
  options,
  value = null,
  onChange,
  withUUID,
  menuIsOpen,
}: IProps): JSX.Element => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const valueState: any = useAppSelector((state) => state.selectSliceReducer);
  const valueInput = valueState.inputValueInSelect
    ? valueState.inputValueInSelect[searchEntity]
    : '';
  const defaultValue = (options, value) =>
    options ? options.find((option) => option.value === value) : null;

  const selectRef = useRef(null);

  const dispatch = useAppDispatch();

  const onChangeHandle = useCallback(
    (value) => {
      if (!value) {
        return;
      }

      onChange(value);
      dispatch(setSelectedValue({ searchEntity, value: '' }));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [onChange]
  );
  const isLoading = useAppSelector(
    (state) => (state.selectSliceReducer as ISelectState).isLoading
  );

  const handleChangeValue = (value, meta) => {
    if (meta.action !== 'input-blur' && meta.action !== 'menu-close') {
      dispatch(setSelectedValue({ searchEntity, value }));
    }
  };

  const handleClear = () => {
    dispatch(setSelectedValue({ searchEntity, value: '' }));
    selectRef.current.clearValue();
  };

  const ClearIndicator = () => (
    <button onClick={handleClear}>
      <Close />
    </button>
  );

  useEffect(() => {
    selectRef.current.clearValue();
  }, [onChangeHandle]);

  return (
    <AsyncSelect
      menuIsOpen={menuIsOpen}
      ref={selectRef}
      placeholder="Поиск"
      classNamePrefix="select"
      value={defaultValue(options, value)}
      inputValue={valueInput}
      autoFocus
      openMenuOnFocus
      isClearable
      isSearchable
      options={options}
      getOptionValue={(option) => option.id}
      getOptionLabel={(option) =>
        withUUID ? `${option.name}; UUID ${option.uuid}` : option.name
      }
      onChange={(value) => onChangeHandle(value)}
      noOptionsMessage={() => 'Ничего не найдено'}
      loadingMessage={() => 'Поиск'}
      onInputChange={(value, meta) => handleChangeValue(value, meta)}
      isLoading={isLoading}
      components={{
        IndicatorSeparator: () => null,
        DropdownIndicator: () =>
          valueInput !== '' ? <ClearIndicator /> : null,
        ClearIndicator: () => null,
        DownChevron: () => <ClearIndicator />,
      }}
    />
  );
};
