import { createSlice } from '@reduxjs/toolkit';

export interface IExpandState {
  expand: boolean;
}

const initialState: IExpandState = {
  expand: false,
};

export const expandSlice = createSlice({
  name: 'expand',
  initialState,
  reducers: {
    setExpand(state) {
      state.expand = !state.expand;
    },
  },
});

export const { setExpand } = expandSlice.actions;

export const expandReducer = expandSlice.reducer;
