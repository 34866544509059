import cn from 'classnames';
import { memo, useEffect, useState } from 'react';

import { Check } from '../../../shared/icons';
import {
  ONE_CHAR_DIGIT,
  ONE_CHAR_IN_LOWERCASE,
  ONE_CHAR_IN_UPPERCASE,
  ONLY_EN_CHAR_AND_DIGIT,
} from '../../../utils/regExp/regExp';

import style from './PasswordTooltip.module.scss';
interface IProps {
  value: string;
  touch: boolean;
}

enum Color {
  Normal = '#7D7D7D',
  Valid = '#3BBA49',
  Invalid = '#e33d27',
}

export const PasswordTooltip = memo((props: IProps): JSX.Element => {
  const { value, touch } = props;
  const [passwordVal, setPasswordValidation] = useState({
    uppercase: false,
    lowercase: false,
    numeral: false,
    minchar: false,
    onlyEnChar: false,
  });

  useEffect(() => {
    setPasswordValidation((_passwordVal) => ({
      ..._passwordVal,
      lowercase: ONE_CHAR_IN_LOWERCASE.test(value),
      uppercase: ONE_CHAR_IN_UPPERCASE.test(value),
      numeral: ONE_CHAR_DIGIT.test(value),
      minchar: value.length >= 5,
      onlyEnChar: ONLY_EN_CHAR_AND_DIGIT.test(value),
    }));
  }, [value]);

  const strokeSetColor = (option: boolean) => {
    if (option) {
      return Color.Valid;
    }

    if (touch && !option) {
      return Color.Invalid;
    }

    if (!touch && !option) {
      return Color.Normal;
    }
  };

  const setColor = (option: boolean) => {
    if (option) {
      return style.valid;
    }

    if (touch && !option) {
      return style.invalid;
    }

    if (!touch && !option) {
      return style.normal;
    }
  };

  const validationDescription = [
    {
      passwordOption: passwordVal.onlyEnChar,
      text: 'Только латинские символы',
    },
    {
      passwordOption: passwordVal.minchar,
      text: 'Мин. 5 символов',
    },
    {
      passwordOption: passwordVal.uppercase,
      text: 'Мин. 1 заглавная буква',
    },
    {
      passwordOption: passwordVal.lowercase,
      text: 'Мин. 1 строчная буква',
    },
    {
      passwordOption: passwordVal.numeral,
      text: 'Мин. 1 цифра',
    },
  ];

  return (
    <div className={style.container}>
      <ul className={style.list}>
        {validationDescription.map((item) => (
          <li className={style.listItem} key={item.text}>
            <Check
              width="12"
              height="12"
              stroke={strokeSetColor(item.passwordOption)}
            />
            <span className={cn(style.desc, setColor(item.passwordOption))}>
              {item.text}
            </span>
          </li>
        ))}
      </ul>
      <div className={style.triangle} />
    </div>
  );
});
