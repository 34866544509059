import cn from 'classnames';
import { useFormikContext } from 'formik';
import {
  Dispatch,
  FC,
  SetStateAction,
  SyntheticEvent,
  useEffect,
  useMemo,
  useState,
} from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Link } from 'react-router-dom';

import { Playlist } from '../../../app/api/services/playlists/types';
import { Router } from '../../../components';
import { Modal } from '../../../components/Modal';
import { Plus, Point, Trash } from '../../../shared/icons';
import { SearchEntity } from '../../../store/slices/selectSlice';
import { ClickShowContainer } from '../../ClickShowContainer';
import { SelectWithOptions } from '../../SelectWithOptions';
import { Separator } from '../../Separator';
import { TextWithTooltip } from '../../TextWithTooltip';
import { getTheIdArray } from 'utils';
import { ObligatoryField } from 'widgets';

import style from './AddPlaylistsWithScroll.module.scss';

interface IProps {
  searchEntity: SearchEntity;
  options: Playlist[];
  playlists?: Playlist[];
  label: string;
  linkToEditEntity: string;
  isError?: boolean;
  setPlaylistsOffset: Dispatch<SetStateAction<number>>;
  totalCount: number;
  setAddedPlaylists: Dispatch<SetStateAction<number[]>>;
  field: string;
  setDeletedPlaylists: Dispatch<SetStateAction<number[]>>;
  isObligatory?: boolean;
  setPlaylists: Dispatch<SetStateAction<Playlist[]>>;
}

export const AddPlaylistsWithScroll: FC<IProps> = ({
  searchEntity,
  playlists,
  options,
  label,
  field,
  linkToEditEntity,
  isError = false,
  setPlaylistsOffset,
  setDeletedPlaylists,
  setAddedPlaylists,
  isObligatory,
  setPlaylists,
}) => {
  const [showPlaylistsSelect, setShowPlaylistsSelect] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const { setFieldValue } = useFormikContext();

  const handleAddPlaylist = (e: SyntheticEvent) => {
    e.preventDefault();
    setShowPlaylistsSelect(true);
  };

  const handlePlaylistsSelect = (option) => {
    setSelectedOption(null);

    const isInArray = !!playlists.find((item) => item?.id === option?.id);

    if (isInArray || option === null) {
      return;
    } else {
      setAddedPlaylists((prevAddedPlaylists) => [
        ...prevAddedPlaylists,
        option.id,
      ]);
      setPlaylists((prevPlaylists) => [option, ...prevPlaylists]);
      setShowPlaylistsSelect(false);
    }
  };

  const handleDeletePlaylist = (id: number) => {
    setPlaylists((prevPlaylists) =>
      prevPlaylists.filter((playlist) => playlist.id !== id)
    );

    setDeletedPlaylists((prevDeletedPlaylists) => [
      ...prevDeletedPlaylists,
      id,
    ]);
  };

  const handleShowAll = () => setModalOpen(true);

  const memoizedPlaylists = useMemo(() => playlists.slice(0, 9), [playlists]);

  useEffect(() => {
    setFieldValue(field, getTheIdArray(playlists));
  }, [field, playlists, setFieldValue]);

  const renderOptions = (options) => (
    <div className={style.selectedOptions}>
      {options?.map((option) => (
        <div key={option.id} className={cn(style.option)}>
          <div className={style.point}>
            <Point />
          </div>
          <Link
            className={style.name}
            to={`${linkToEditEntity}/${Router.Edit}/${option?.id}`}
          >
            <TextWithTooltip text={option?.name} />
          </Link>
          <button
            type="button"
            onClick={() => handleDeletePlaylist(option.id)}
            className={style.deleteButton}
          >
            <Trash />
          </button>
        </div>
      ))}
    </div>
  );

  return (
    <div className={style.container}>
      <div className={style.header}>
        <div className={style.headerAndPlus}>
          <h3 className={isError ? style.labelError : style.label}>
            {label} {isObligatory && <ObligatoryField />}
          </h3>
          <button className={style.add} onClick={handleAddPlaylist}>
            <Plus />
          </button>
        </div>
      </div>

      <Separator />

      {showPlaylistsSelect && (
        <ClickShowContainer
          dropdown={showPlaylistsSelect}
          setDropdown={setShowPlaylistsSelect}
          className={style.select}
        >
          <SelectWithOptions
            searchEntity={searchEntity}
            options={options}
            onChange={handlePlaylistsSelect}
            value={selectedOption}
          />
        </ClickShowContainer>
      )}

      <div>
        <div className={style.selectedOptions}>
          {renderOptions(memoizedPlaylists)}
        </div>

        {playlists?.length >= 9 && (
          <button
            type="button"
            className={style.buttonShowAll}
            onClick={handleShowAll}
          >
            Показать все
          </button>
        )}

        <Modal active={modalOpen} setActive={setModalOpen} title="Плейлисты">
          <div id="scrollableDiv" className={style.modal}>
            <InfiniteScroll
              scrollableTarget="scrollableDiv"
              dataLength={playlists?.length ?? 0}
              hasMore={true}
              next={() => setPlaylistsOffset((prev) => prev + 50)}
              loader={false}
              className={style.scrollContainer}
            >
              {renderOptions(playlists)}
            </InfiniteScroll>
          </div>
        </Modal>
      </div>
    </div>
  );
};
