import { Suspense, lazy } from 'react';

import { Loader } from '../../../../../widgets';

const FeedGroups = lazy(() => import('./FeedGroups'));

export const FeedGroups_Lazy = (): JSX.Element => (
  <>
    <Suspense fallback={<Loader />}>
      <FeedGroups />
    </Suspense>
  </>
);
