export const getWordEnding = (
  count: number | undefined,
  words: string[] | undefined
): string => {
  const value = Math.abs(count) % 100;
  const num = value % 10;
  if (value > 10 && value < 20) return words[2];
  if (num > 1 && num < 5) return words[1];
  if (num === 1) return words[0];
  if (!count && !words) return '';

  return words[2];
};
