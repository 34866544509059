import React from 'react';

export const EyeShow = (): JSX.Element => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="23"
    height="23"
    fill="none"
    viewBox="0 0 22 22"
  >
    <g fill="#7D7D7D" opacity="0.6">
      <path d="M11.216 5c-6.997 0-9.1 6.066-9.119 6.127l-.097.29.096.29c.02.06 2.123 6.126 9.12 6.126 6.997 0 9.1-6.065 9.12-6.127l.096-.29-.096-.289C20.316 11.066 18.213 5 11.216 5zm0 10.083a3.67 3.67 0 01-3.666-3.666 3.67 3.67 0 013.666-3.667 3.67 3.67 0 013.667 3.667 3.67 3.67 0 01-3.667 3.666z"></path>
      <path d="M11.216 9.583c-.993 0-1.833.84-1.833 1.834 0 .993.84 1.833 1.833 1.833.994 0 1.834-.84 1.834-1.833 0-.994-.84-1.834-1.834-1.834z"></path>
    </g>
  </svg>
);
