interface IProps {
  width?: number;
  height?: number;
  color?: string;
}

export const PlusInTheCircle = ({
  width = 16,
  height = 16,
  color = '#fff',
}: IProps): JSX.Element => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    fill="none"
    viewBox="0 0 16 16"
  >
    <rect width="15" height="15" x="0.5" y="0.5" stroke={color} rx="7.5"></rect>
    <path
      fill={color}
      stroke={color}
      strokeWidth="0.2"
      d="M11 7.472H8.529V5A.529.529 0 007.47 5v2.472H5a.529.529 0 000 1.057h2.471V11a.529.529 0 001.058 0V8.53H11a.528.528 0 100-1.057z"
    ></path>
  </svg>
);
