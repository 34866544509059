export interface Option {
  id: number;
  name: string;
  type?: string;
}

export const currency: Option[] = [
  { id: 1, name: 'Рубль' },
  { id: 2, name: 'USD' },
  { id: 3, name: 'Euro' },
];

export const costOptions: Option[] = [
  { id: 1, name: '100' },
  { id: 2, name: '1000' },
  { id: 3, name: '2000' },
];
export const typeSalesOptions: Option[] = [
  { id: 1, name: 'Продажа', type: 'sale' },
  { id: 2, name: 'Аренда', type: 'rent' },
  { id: 3, name: 'Бесплатно', type: 'free' },
  { id: 4, name: 'Аренда mix', type: 'rent_mix' },
];

export const ageLimit: Option[] = [
  { id: 1, name: '0+' },
  { id: 2, name: '6+' },
  { id: 3, name: '12+' },
  { id: 4, name: '16+' },
  { id: 5, name: '18+' },
];

export const territoriesData: Option[] = [
  { id: 1, name: 'Мир' },
  { id: 2, name: 'РФ' },
];

export const languageData: Option[] = [
  { id: 1, name: 'Русский' },
  { id: 2, name: 'Английский' },
  { id: 3, name: 'Немецкий' },
  { id: 4, name: 'Татарский' },
  { id: 5, name: 'Турецкий' },
  { id: 6, name: 'Китайский' },
  { id: 7, name: 'Испанский' },
  { id: 8, name: 'Итальянский' },
  { id: 9, name: 'Французский' },
];

export const Owners = {
  rightOwner: 'Эксмо',
  typeOwner: 'Издательство',
  level: '||',
  contragent: 'ВГТРК',
  URL: 'http://https://html5book.ru',
  activity: 'Не продлен',
  number: '667886667886',
  dateStart: '13.12.2021',
  dateFinish: '13.12.2023',
  status: 'Подписан',
};

export const StatisticsTariff = {
  month: {
    sale: '234',
    rent: '234',
    subscribe: '234',
    cost: '230 400',
  },
  allTime: {
    sale: '598',
    rent: '598',
    subscribe: '598',
    cost: '960 780',
  },
};
