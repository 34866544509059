import { skipToken } from '@reduxjs/toolkit/query';
import { Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import {
  useCreateAutoSectionMutation,
  useDeleteAutoSectionMutation,
  useGetAutoSectionByIdQuery,
  useGetSectionListsQuery,
  useUpdateAutoSectionMutation,
} from '../../../../../../app/api/services/sections/sections';
import { HeaderBlock, Router } from '../../../../../../components';
import { errorToast, successToast } from '../../../../../../utils';
import { ErrorAlert, Loader } from '../../../../../../widgets';
import { Description } from '../CreateAutoSectionComponents';
import { ListOfPlaylist } from '../CreateAutoSectionComponents/ListOfPlaylist';
import { MainParameters } from '../CreateAutoSectionComponents/MainParameters';
import {
  AutoSectionFields,
  IAutoSectionValues,
  getElements,
  validateCreateAutoSection,
} from '../helpers';

import style from './CreateAutoSection.module.scss';

export const CreateAutoSection = (): JSX.Element => {
  const { id } = useParams();
  const navigate = useNavigate();

  const isEdit = !!id;

  const [isValidateCheked, setIsValidateCheked] = useState(false);
  const [isToast, setIsToast] = useState(false);

  const {
    data: sectionLists,
    isLoading: isLoadingSectionLists,
    isError: errorSectionLists,
  } = useGetSectionListsQuery();

  const {
    data: section,
    isLoading: isLoadingGetById,
    isError: isErrorSectionById,
  } = useGetAutoSectionByIdQuery(id ? id : skipToken);

  const [
    createAutoSection,
    {
      isLoading: isLoadingCreate,
      isError: isCreateSectionError,
      isSuccess: isSuccessCreate,
      data,
    },
  ] = useCreateAutoSectionMutation();

  const [
    updateAutoSection,
    {
      isLoading: isLoadingUpdate,
      isError: isErrorUpdate,
      isSuccess: isSuccessUpdate,
    },
  ] = useUpdateAutoSectionMutation();

  const [
    deleteAutoSection,
    {
      isLoading: isLoadingDelete,
      isError: isErrorDelete,
      isSuccess: isSuccessDelete,
    },
  ] = useDeleteAutoSectionMutation();

  const sectionNames = sectionLists?.response?.auto_section.names.map(
    (el, index) => ({
      name: el.name,
      id: index,
    })
  );

  const sectionElements =
    sectionLists?.response?.auto_section.element_types.map((el, index) => ({
      name: el,
      id: index,
    }));

  const sectionFeeds = sectionLists?.response?.auto_section.feed_types.map(
    (el, index) => ({
      name: el,
      id: index,
    })
  );

  const sectionAlgorithms = sectionLists?.response?.auto_section.names.map(
    (el, index) => ({
      name: el.algorithms[0],
      id: index,
    })
  );

  const arrayForSection = (elements) => {
    // TODO: добавить проверку на screen_position > 4 ?
    const newElements = {
      screen1: [...Array(3)],
      screen2: [...Array(3)],
      screen3: [...Array(3)],
      screen4: [...Array(3)],
    };

    elements?.forEach((item) => {
      newElements[`screen${item?.screen_position}`][
        item?.screen_element_position - 1
      ] = { ...item, ...item?.entity };
    });

    return newElements;
  };

  const initialValues = {
    [AutoSectionFields.Element_type]: isEdit
      ? section?.response?.element_type
      : '',
    [AutoSectionFields.Name]: isEdit ? section?.response?.name : '',
    [AutoSectionFields.Is_active]: section?.response?.is_active,
    [AutoSectionFields.Feed_type]: isEdit ? section?.response?.feed_type : '',
    [AutoSectionFields.Algorithm]: isEdit ? section?.response?.algorithm : '',
    [AutoSectionFields.Elements]: isEdit
      ? arrayForSection(section?.response?.elements)
      : {},
  };

  const onSubmit = (values: IAutoSectionValues) => {
    const elements = getElements(values.elements);

    isEdit
      ? updateAutoSection({ values: { ...values, elements }, id: +id })
      : createAutoSection({ ...values, elements });
  };

  useEffect(() => {
    successToast({
      success: isSuccessCreate,
      message: 'Раздел успешно создан',
      setIsToast,
      navigate,
      path: `/${Router.Section}/auto/${Router.Edit}/${data?.response?.id}`,
    });

    successToast({
      success: isSuccessUpdate,
      message: 'Раздел успешно изменен',
      setIsToast,
    });

    successToast({
      success: isSuccessDelete,
      message: 'Раздел успешно удален',
      setIsToast,
      navigate,
      path: `/${Router.Section}`,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccessCreate, isSuccessDelete, isSuccessUpdate]);

  useEffect(() => {
    errorToast(isErrorDelete, 'Не удалось удалить раздел', setIsToast);
    errorToast(isErrorSectionById, 'Не удалось загрузить раздел', setIsToast);
    errorToast(isCreateSectionError, 'Не удалось создать раздел', setIsToast);
    errorToast(isErrorUpdate, 'Не удалось обновить раздел', setIsToast);
    errorToast(
      errorSectionLists,
      'Не удалось загрузить справочники',
      setIsToast
    );
  }, [
    isErrorSectionById,
    isCreateSectionError,
    isErrorUpdate,
    isErrorDelete,
    errorSectionLists,
  ]);

  return (
    <div className={style.container}>
      {(isLoadingCreate ||
        isLoadingGetById ||
        isLoadingDelete ||
        isLoadingUpdate ||
        isLoadingSectionLists) &&
      !isToast ? (
        <Loader />
      ) : null}
      {!isLoadingGetById && (
        <Formik
          initialValues={initialValues}
          enableReinitialize
          onSubmit={(values) =>
            onSubmit(values as unknown as IAutoSectionValues)
          }
          validateOnBlur={isValidateCheked}
          validateOnChange={isValidateCheked}
          validate={(e) =>
            validateCreateAutoSection(e as unknown as IAutoSectionValues)
          }
        >
          {(formik) => {
            if (Object.keys(formik.errors).length) {
              setIsValidateCheked(true);
            }

            return (
              <Form
                className={style.form}
                onKeyDown={(event) =>
                  event.key === 'Enter' && event.preventDefault()
                }
              >
                <HeaderBlock
                  deleteEntity={() => deleteAutoSection(+id)}
                  deleteBtnEntity="раздел"
                  editEntity="автоматического раздела"
                />
                <Description
                  sectionName={section?.response?.name}
                  sectionElement={section?.response?.element_type}
                  sectionFeed={section?.response?.feed_type}
                  sectionAlgorithm={section?.response?.algorithm}
                  sectionNames={sectionNames}
                  sectionElements={sectionElements}
                  sectionFeeds={sectionFeeds}
                  sectionAlgorithms={sectionAlgorithms}
                />
                <ListOfPlaylist />
                <MainParameters date={section?.response?.created_at} />
                {Object.keys(formik.errors).length > 0 && (
                  <ErrorAlert text="Пожалуйста, заполните все обязательные поля" />
                )}
              </Form>
            );
          }}
        </Formik>
      )}
    </div>
  );
};
