import cn from 'classnames';
import { Field, useField } from 'formik';
import { FC, memo, useRef, useState } from 'react';

import { IFieldDescription } from '../../../app/global/types';
import { EyeHide, EyeShow } from '../../../shared/icons';
import { PasswordTooltip } from '../../PasswordTooltip';

import style from './PasswordForTheLoginForm.module.scss';

export const PasswordForTheLoginForm: FC<IFieldDescription> = memo(
  (props): JSX.Element => {
    const [, meta] = useField(props);

    const [passwordShow, setPasswordShow] = useState(false);

    const fieldRef = useRef<HTMLInputElement>(null);

    const togglePasswordVisibility = () => {
      setPasswordShow(!passwordShow);
    };

    const errorText = meta.error && meta.touched ? meta.error : null;

    return (
      <div
        className={cn(style.inputContainer, {
          [style.valid]: !meta.error && meta.value,
        })}
      >
        <Field
          name={props.name}
          className={cn(style.input, {
            [style.invalid]: errorText,
            [style.valid_input]: meta.value,
          })}
          placeholder=" "
          type={passwordShow ? 'text' : 'password'}
          innerRef={fieldRef}
        />

        <label htmlFor={props.name} className={style.label}>
          {props.label}
        </label>

        <i className={style.passwordShow} onClick={togglePasswordVisibility}>
          {passwordShow ? <EyeHide /> : <EyeShow />}
        </i>

        <span className={style.errorText}>{errorText}</span>

        {props.type === 'passwordWithTooltip' && (
          <div className={style.tooltip}>
            <PasswordTooltip value={meta.value} touch={meta.touched} />
          </div>
        )}
      </div>
    );
  }
);
