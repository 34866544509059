import React, { useEffect, useState } from 'react';

import { useGetMassActionForEntityQuery } from '../../../../../app/api/services/massActions/massActions';
import { SearchEntity } from '../../../../../store/slices/selectSlice';
import { errorToast } from '../../../../../utils';
import { ClickShowContainer } from '../../../../../widgets';
import { SelectFilter } from '../../SelectFilter';

import style from './AddAttribute.module.scss';

interface IProps {
  type: 'commands';
  entity: SearchEntity;
}

export const AddAttribute = ({ type, entity }: IProps): JSX.Element => {
  const [showInput, setShowInput] = useState(false);

  const { data, isLoading, isError } = useGetMassActionForEntityQuery(entity, {
    skip: !showInput,
  });

  const massActions = data?.response?.[entity.replace('_', '-')];

  useEffect(() => {
    if (isError) {
      errorToast(
        isError,
        'Не удалось загрузить список фильтров. Обновите страницу'
      );
    }
  }, [isError]);

  return (
    <div className={style.container}>
      <button
        className={style.addAttribute}
        onClick={() => setShowInput(true)}
        type="button"
      >
        Добавить атрибут
      </button>
      {showInput && massActions && (
        <ClickShowContainer
          dropdown={showInput}
          setDropdown={setShowInput}
          className={style.select}
        >
          <SelectFilter
            massActions={massActions}
            type={type}
            isLoading={isLoading}
            setShowInput={setShowInput}
          />
        </ClickShowContainer>
      )}
    </div>
  );
};
