import { saveAs } from 'file-saver';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

type ReturnType = [
  (download_url: string, ext: string, file_name?: string) => void,
  boolean
];

export const useDownloadFile = (): ReturnType => {
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const downloadFile = async (
    download_url: string,
    ext: string,
    file_name?: string
  ) => {
    if (!download_url) return;

    setIsError(false);
    setIsLoading(true);

    try {
      const res = await fetch(download_url);
      const blob = await res.blob();
      if (!blob) return;
      const fileName = `${file_name || 'table'}.${ext || 'xlsx'}`;
      saveAs(blob, fileName);
    } catch (error) {
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (isError) {
      toast.error('Произошла ошибка при скачивании файла. Попробуйте еще раз.');
    }
  }, [isError]);

  return [downloadFile, isLoading];
};
