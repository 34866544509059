import React, { Suspense } from 'react';

import { Loader } from '../../../../../../widgets';

const CreateFeed = React.lazy(() => import('./CreateFeed'));

export const CreateFeed_Lazy = (): JSX.Element => (
  <>
    <Suspense fallback={<Loader />}>
      <CreateFeed />
    </Suspense>
  </>
);
